import pdfMake from 'pdfmake/build/pdfmake'
import pdfFonts from 'pdfmake/build/vfs_fonts'
import printJS from 'print-js';

import RobotoFont from '../fonts/Roboto'
import RobotoBoldFont from '../fonts/RobotoBold'

import reportConf from './components/reportConf'
import reportHeader from './components/reportHeader'
import cuadroTotal from './components/cuadroTotal'
import reportFooter from './components/reportFooter'
import { formatCurrency } from 'utils';

export default class ReportByAccountUnitDiezmo {
    //total
    // total = 0.00
    // totalDiezmo = 0.00
    // totalIngNeto = 0.00

    totalCategory = 0.00
    totalCatgDiezmo = 0.00
    totalCatgIngNeto = 0.00


    getItems(items, i) {
      
        let diezmoRubro = 0;
        let IngnetoRubro = 0;
        let totalRubro = 0;

        const filas = items[i].lista.map((item) => {
            let totalAdd = 0.0
            let diezmo = 0.0
            let ingNeto = 0.0

            totalAdd = parseFloat(item.total)
            diezmo = (parseFloat(item.total) * 0.1)
            ingNeto = (parseFloat(item.total) * 0.9)

            totalRubro += totalAdd;
            diezmoRubro += diezmo;
            IngnetoRubro += ingNeto;

            return [
                { text: item.codigo },
                { text: `${item.unidad}` },
                { text: item.cuenta },
                { text: formatCurrency(diezmo), alignment: 'right' },
                { text: formatCurrency(ingNeto), alignment: 'right' },
                { text: formatCurrency(totalAdd), alignment: 'right' },
            ]
        })
        return {filas, totalRubro, diezmoRubro, IngnetoRubro };
    }

    selectionFilter(isFilter, filter, isAdmin) {
        if(isFilter){
            if(isAdmin){
                return [

                    ['Estructura', ":", filter.estructura],
                    ['Rubro', ":", filter.rubro],
                    ['Unidad', ":", filter.unidad],
                    ['Concepto', ":", filter.concepto],
                    ['Cuenta', ":", filter.cuenta],

                ];
            }else{
                return [

                    ['Rubro', ":", filter.rubro],
                    ['Unidad', ":", filter.unidad],
                    ['Concepto', ":", filter.concepto],
                    ['Cuenta', ":", filter.cuenta],
                ];

            }

        }else{
            return [];
        }

    }

    /**
     * Funcion que genera el Informe Consolidado por Unidad y Cuenta con diezmo cuando la estructura seleccionada
     * es la facultad de tecnologia, manda a imprimir o a una visualizacion previa dependiendo del parametro isPrint
     *
     * @param JSON itemsReport
     * @param string userName
     * @param string initDate
     * @param string endDate
     * @param string receivedFrom
     * @param boolean isPrint
     * @param boolean isFilter
     * @param array filter
     * @param boolean isAdmin
     */
    async generateReport(itemsReport, userName, initDate, endDate, receivedFrom, isPrint, isFilter, filter, isAdmin,userUnidad, electronico, caja) {

        this.totalCategory = 0.00
        this.totalCatgDiezmo = 0.00
        this.totalCatgIngNeto = 0.00

        pdfMake.vfs = {
            ...pdfFonts.pdfMake.vfs,
            'Roboto-Regular.ttf': RobotoFont,
            'Roboto-Bold.ttf': RobotoBoldFont
        };

        pdfMake.fonts = {
            Roboto: {
                normal: 'Roboto-Regular.ttf',
                bold: 'Roboto-Bold.ttf'
            }
        };
   
        const headerPage = (currentPage, pageCount, pageSize) => {
            return reportHeader(
                currentPage,
                pageCount,
                'Informe Consolidado por Unidad y Cuenta',
                userUnidad,
                userName,
                initDate,
                endDate,
                electronico,
                caja,
            );
        }

        let tableDetail = [
            [
                {
                    fontSize: reportConf.fontSizeFilters,
                    margin: [0, 0, 20, 0],

                    table: {
                        body: [
                            ["", "", ""],
                            ...this.selectionFilter(isFilter, filter, isAdmin)

                        ],
                    },
                    layout: "noBorders",
                },

            ]
        ]

        if(itemsReport.length > 0){
            tableDetail.push(
                {
                    fontSize: reportConf.fontSizeTableHeader,
                    bold: true,
                    margin: [4, 0, 0, 0],
                    table: {
                        widths: [75, 'auto'],
                        body: [
                            [
                                { text: ' Cod. Rubro' },
                                { text: 'Rubro'}
                            ]
                        ]
                    },
                    layout: "noBorders",

                }
            );
        }

        for (let i = 0; i < itemsReport.length; i++) {
            const {filas, totalRubro, diezmoRubro, IngnetoRubro} = this.getItems(itemsReport, i)

            this.totalCategory += totalRubro;
            this.totalCatgDiezmo += diezmoRubro;
            this.totalCatgIngNeto += IngnetoRubro;

            let auxTable = [
                {
                    fontSize: reportConf.fontSizeTable,
                    table: {
                        headerRows: 1,
                        widths: [75, 90, "*", 80, 45, 55],
                        // widths: [75, 70, 142, 60, 60, 60],
                        body: [
                            [
                                { text: itemsReport[i].codigo, style: 'tableCategory' },
                                { text: itemsReport[i].rubro, style: 'tableCategory', colSpan: 4 }, {}, {}, {}, {}
                            ],
                            [
                                {
                                    text: 'Código', style: 'tableHeader'
                                },
                                {
                                    text: 'Unidad', style: 'tableHeader'
                                },
                                {
                                    text: 'Cuenta', style: 'tableHeader'
                                },
                                {
                                    text: 'Diezmo', alignment: 'right', style: 'tableHeader'
                                },
                                {
                                    text: 'Ing.Neto', alignment: 'right', style: 'tableHeader'
                                },
                                {
                                    text: 'Total', alignment: 'right', style: 'tableHeader'
                                }
                            ],
                            ...filas,
                            [
                                { text: 'Total Rubro', bold: true },
                                { text: " " },
                                { text: " " },
                                { text: formatCurrency(diezmoRubro), alignment: 'right', bold: true },
                                { text: formatCurrency(IngnetoRubro), alignment: 'right', bold: true },
                                { text: formatCurrency(totalRubro), alignment: 'right', bold: true },
                            ],
                        ]
                    },
                    layout: {
                        hLineWidth: function (i, node) {
                            return (i === 2 || i === node.table.body.length-1) ? 0.75 : 0;
                        },
                        vLineWidth: function (i, node) {
                            return 0;
                        },
                    },

                },
                { text: '', margin: [0, 0, 0, reportConf.spaceBetweenTable] }
            ]
            tableDetail.push(auxTable)
        }

        const totalLabel = [];
       // totalLabel.push(cuadroTotal(this.total));
       totalLabel.push({
        margin: [215, 5, 0, 0],
        // fontSize: reportConf.fontSizeTotalReporte,
        fontSize: reportConf.fontSizeTable,
        table: {
            widths: ["*", 80, 45,55],
            body: [
                [
                    { text: "Total Consolidado", bold: true },
                    { text: formatCurrency(this.totalCatgDiezmo), bold: true,alignment: 'right'  },
                    { text: formatCurrency(this.totalCatgIngNeto), bold: true,alignment: 'right'  },
                    { text: formatCurrency(this.totalCategory), bold: true,alignment: 'right'  },
                ],
            ],
        },
        layout: {
            hLineWidth: function (i, node) {
                return i === 0 || i === node.table.body.length ? 0.5 : 0;
            },
            vLineWidth: function (i, node) {
                return i === 0 || i === node.table.widths.length ? 0.5 : 0;
            },
        },
    });

        const footerPage = reportFooter(receivedFrom);

        const report = {
            pageSize: 'letter',
            pageMargins: reportConf.pageMargins,
            header: headerPage,
            content: [tableDetail, totalLabel],
            footer: footerPage,
            defaultStyle: {
                font: 'Roboto'
            },
            styles: {
                tableHeader: {
                    bold: true,
                    color: 'black'
                },
                tableCategory: {
                    bold: true,
                    fontSize: reportConf.fontSizeTableHeader,
                    color: 'black'
                },
            },
        }

        if (isPrint) {
            let document = pdfMake.createPdf(report)
            let docBlob = null;

            document.getBase64(function (result) {
                docBlob = result;
                printJS({
                    printable: docBlob,
                    type: "pdf",
                    base64: true
                });
            });
        } else {
            pdfMake.createPdf(report).open()
        }
    }
}