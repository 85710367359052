import { BasePickListInput } from '../base/BasePickList'
import { PickListOption } from './PickListOption'
export function PickListSide({
    options,
    optionLabel,
    optionTemplate,
    header,
    filterBy,
    selectedOptions,
    onToggleSelect,
    filterValue,
    onFilterChange,
}) {
    const isSelected = (option) => {
        return selectedOptions.some((o) => JSON.stringify(o) === JSON.stringify(option))
    }
    const headerElement = header && <div className='pls-header'>{header}</div>
    const filterElement = filterBy && (
        <div className='pls-filter'>
            <BasePickListInput value={filterValue} onChange={(e) => onFilterChange(e.target.value)} />
        </div>
    )
    return (
        <div className='pl-side'>
            {headerElement}
            {filterElement}
            <div className='pls-list'>
                {options.map((option, index) => (
                    <PickListOption
                        key={index}
                        option={option}
                        optionLabel={optionLabel}
                        optionTemplate={optionTemplate}
                        selected={isSelected(option)}
                        onToggleSelect={onToggleSelect}
                    />
                ))}
            </div>
        </div>
    )
}
