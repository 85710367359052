import { classNames, throttle } from '../../utils'
import { orders } from '../interfaces'
import ColumnFilterCell from './ColumnFilterCell'
import ArrowUp from '../../icons/ArrowUp'
import ArrowDown from '../../icons/ArrowDown'
import ArrowDownUp from '../../icons/ArrowDownUp'
import { BaseDataTableCheckbox, BaseTd, BaseTh, BaseThead, BaseTr } from '../../base/BaseDataTable'
import { useCallback } from 'react'
// Iconos de orden
const sortIcons = {
    [orders.ascOrder]: <ArrowDown />,
    [orders.descOrder]: <ArrowUp />,
    [`${orders.noOrder}`]: <ArrowDownUp />,
}
// Valores iniciales del orden del DataTable.
const initialSort = { field: orders.noOrder, order: orders.noOrder }
const containerHeaderClassName = 'dth-container'
const contentHeaderClassName = 'dth-content'
export function DataTableHeader({
    innerRef,
    columnHeaders,
    columnFilters,
    filters,
    setFilters,
    filterable,
    filterDelay,
    onFilter,
    sort,
    setSort,
    onSort,
    selectionMode,
    allSelected,
    onToggleSelectAll,
    headerElement,
    defaultAlignHeader,
}) {
    const handleFilter = useCallback(
        throttle((f) => {
            onFilter && onFilter(f)
        }, filterDelay || 0),
        [filterDelay],
    )
    const handleSort = (field) => {
        const _sort = { ...initialSort }
        if (field === sort.field) {
            if (sort.order !== orders.descOrder) {
                if (sort.order === orders.ascOrder) {
                    // ASC -> DESC
                    _sort.order = orders.descOrder
                } else {
                    // Sin orden -> ASC
                    _sort.order = orders.ascOrder
                }
                _sort.field = field
            }
        } else {
            _sort.order = orders.ascOrder
            _sort.field = field
        }
        setSort(_sort)
        // Ejecutar evento onSort del DataTable
        onSort && onSort(_sort.field, _sort.order)
    }
    const handleChangeFilter = (field, value) => {
        const _filters = { ...filters, [field]: value }
        setFilters(_filters)
        handleFilter(_filters)
    }
    /** Handler que se ejecuta cuando se presiona Enter sobre el input de de/seleccionar todas las filas del DataTable. */
    const handleSelectorEnter = ({ key }) => {
        key === 'Enter' && onToggleSelectAll()
    }
    const getHeaderCellProps = ({ field, sortable, alignHeader, header, className, style }) => {
        const cellClassName = className
        const cellStyle = style
        const sorted = field === sort.field
        const sortIcon = field === sort.field ? sortIcons[`${sort.order}`] : sortIcons[`${orders.noOrder}`]
        const sortIconClassName = 'dth-sort-icon'
        const sortElement = sortable ? <span className={sortIconClassName}>{sortIcon}</span> : undefined
        const _alignHeader = alignHeader ?? defaultAlignHeader
        const containerClassName =
            classNames(
                containerHeaderClassName,
                sortable && 'sortable',
                sorted && 'sorted',
                _alignHeader && `pos-${_alignHeader}`,
            ) ?? ''
        const contentClassName = contentHeaderClassName
        const headerElement =
            typeof header === 'function' ? (
                header({ sortElement, containerClassName, contentClassName })
            ) : (
                <div className={containerClassName}>
                    <span className={contentClassName}>{header}</span>
                    {sortElement}
                </div>
            )
        return {
            cellClassName,
            cellStyle,
            sorted,
            sortIcon,
            sortIconClassName,
            sortElement,
            onSort: sortable ? () => handleSort(field) : undefined,
            containerClassName,
            contentClassName,
            headerElement,
        }
    }
    const selectorElement =
        selectionMode === 'multiple' ? (
            <div className='dth-selector'>
                <BaseDataTableCheckbox
                    checked={allSelected}
                    onChange={onToggleSelectAll}
                    onKeyUp={handleSelectorEnter}
                />
            </div>
        ) : undefined
    const filterRow = filterable ? (
        <BaseTr>
            {columnFilters.map((columnFilter) =>
                columnFilter.selector || !columnFilter.filter ? (
                    <BaseTd key={columnFilter.id} />
                ) : (
                    <ColumnFilterCell
                        key={columnFilter.id}
                        {...columnFilter}
                        value={filters && filters[columnFilter.field] !== undefined ? filters[columnFilter.field] : ''}
                        onChangeFilter={handleChangeFilter}
                    />
                ),
            )}
        </BaseTr>
    ) : undefined
    const _headerElement = (
        <BaseThead ref={innerRef}>
            <BaseTr>
                {columnHeaders.map((columnHeader) => {
                    if (columnHeader.selector) {
                        return (
                            <BaseTd key={columnHeader.id}>
                                <div className={containerHeaderClassName}>{selectorElement}</div>
                            </BaseTd>
                        )
                    }
                    const props = getHeaderCellProps(columnHeader)
                    return (
                        <BaseTh
                            key={columnHeader.id}
                            onClick={props.onSort}
                            className={props.cellClassName}
                            style={props.cellStyle}
                        >
                            {props.headerElement}
                        </BaseTh>
                    )
                })}
            </BaseTr>
            {filterRow}
        </BaseThead>
    )
    const getHeaderElementProps = () => {
        return {
            filterRow,
            selectorElement,
            containerHeaderClassName,
            contentHeaderClassName,
            headers: columnHeaders.reduce((carry, column) => {
                carry[column.field || ''] = getHeaderCellProps(column)
                return carry
            }, {}),
            theadRef: innerRef,
        }
    }
    return typeof headerElement === 'function'
        ? headerElement(getHeaderElementProps())
        : (headerElement ?? _headerElement)
}
