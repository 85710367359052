import pdfMake from 'pdfmake/build/pdfmake'
import pdfFonts from 'pdfmake/build/vfs_fonts'
import printJS from 'print-js';

import RobotoFont from '../../fonts/Roboto'
import RobotoBoldFont from '../../fonts/RobotoBold'

import reportConf from '../components/reportConf'
import reportHeader from '../components/reportHeader'
import cuadroTotal from '../components/cuadroTotal'
import reportFooter from '../components/reportFooter'

import { formatCurrency } from '../../utils/utils';

//function ReportUnidadCuentaFacturas (){
export default class ReportUnidadCuentaFacturas{

    total = 0.0;
    totalCategory = 0.0;
    totalDescuentos = 0;
    totalSubTotal = 0.0;

    getItems(items, i) {

        let subtotalRubro = 0;
        let descuentoRubro = 0;
        let totalRubro = 0;

        //this.totalCategory = 0.00
        const filas = items[i].lista.map((item) => {
            let totalAdd = 0.0
            let subTotal = 0.0
            let descuento = 0.0

            subTotal = parseFloat(item.subtotal);
            totalAdd = parseFloat(item.total);
            descuento = parseFloat(item.descuento);

            descuentoRubro += descuento;
            totalRubro += totalAdd;
            subtotalRubro += subTotal;

            return [
                { text: item.codigo },
                { text: `   ${item.unidad}` },
                { text: item.cuenta },
                { text: formatCurrency(subTotal), alignment: 'right'  },
                { text: formatCurrency(descuento), alignment: 'right'  },
                { text: formatCurrency(totalAdd), alignment: 'right' },

            ]
        })
        return {filas, subtotalRubro, descuentoRubro, totalRubro};
    }
    selectionFilter(isFilter, filter, isAdmin) {
        if(isFilter){
            if(isAdmin){
                return [

                    ['Estructura', ":", filter.estructura],
                    ['Rubro', ":", filter.rubro],
                    ['Unidad', ":", filter.unidad],
                    ['Concepto', ":", filter.concepto],
                    ['Cuenta', ":", filter.cuenta],

                ];
            }else{
                return [

                    ['Rubro', ":", filter.rubro],
                    ['Unidad', ":", filter.unidad],
                    ['Concepto', ":", filter.concepto],
                    ['Cuenta', ":", filter.cuenta],
                ];

            }

        }else{
            return [];
        }

    }

    /**
     * Funcion que genera el Informe Consolidado por Unidad y Cuenta y lo manda a imprimir o a una
     * visualizacion previa dependiendo del parametro isPrint
     *
     * @param JSON itemsReport
     * @param string userName
     * @param string initDate
     * @param string endDate
     * @param string receivedFrom
     * @param boolean isPrint
     * @param boolean isFilter
     * @param array filter
     * @param boolean isAdmin
     */
    async generateReport(itemsReport, userName, initDate, endDate, receivedFrom, isPrint, isFilter, filter, isAdmin,userUnidad, electronico, caja) {

        this.total = 0.0;
        this.totalCategory = 0.0;
        this.totalDescuentos = 0;
        this.totalSubTotal = 0.0;

        pdfMake.vfs = {
            ...pdfFonts.pdfMake.vfs,
            'Roboto-Regular.ttf': RobotoFont,
            'Roboto-Bold.ttf': RobotoBoldFont
        };

        pdfMake.fonts = {
            Roboto: {
                normal: 'Roboto-Regular.ttf',
                bold: 'Roboto-Bold.ttf'
            }
        };

        const headerPage = (currentPage, pageCount, pageSize) => {
            return reportHeader(
                currentPage,
                pageCount,
                'Informe Consolidado por Unidad y Cuenta - Facturas',
                userUnidad,
                userName,
                initDate,
                endDate,
                electronico,
                caja
            );
        }

        let tableDetail = [
            [
                {
                    fontSize: reportConf.fontSizeFilters,
                    margin: [0, 0, 0, 5],

                    table: {
                        body: [
                            ["", "", ""],
                            ...this.selectionFilter(isFilter, filter, isAdmin)

                        ],
                    },
                    layout: "noBorders",
                },

            ]
        ]

        if(itemsReport.length > 0) {
            tableDetail.push(
                {
                    fontSize: reportConf.fontSizeTableHeader,
                    bold: true,
                    margin: [4, 0, 0, 0],
                    table: {
                        widths: [75, 'auto'],
                        body: [
                            [
                                { text: ' Cod. Rubro' },
                                { text: 'Rubro'}
                            ]
                        ]
                    },
                    layout: "noBorders",

                }
            );
        }

        for (let i = 0; i < itemsReport.length; i++) {
            const {filas, subtotalRubro, descuentoRubro, totalRubro} = this.getItems(itemsReport, i)
            this.totalSubTotal += subtotalRubro;
            this.totalDescuentos += descuentoRubro;
            this.totalCategory += totalRubro;
            
            let auxTable = [
                {
                    fontSize: reportConf.fontSizeTable,
                    table: {
                        headerRows: 1,
                        // widths: [75, 90, "*", 60, 40,40],
                        widths: [75, 90, "*", 80,45,55],
                        body: [
                            [
                                { text: itemsReport[i].codigo, style: 'tableCategory' },
                                { text: itemsReport[i].rubro, style: 'tableCategory', colSpan: 2 }, {}, {},{},{}
                            ],
                            [
                                { text: 'Código', style: 'tableHeader' },
                                { text: 'Unidad', style: 'tableHeader' },
                                { text: 'Cuenta', style: 'tableHeader' },
                                { text: 'Subtotal', style: 'tableHeader', alignment: 'right' },
                                { text: 'Descuento', style: 'tableHeader', alignment: 'right' },
                                { text: 'Total', style: 'tableHeader', alignment: 'right' },

                            ],
                            ...filas,
                            [
                                { text: 'Total Rubro', bold: true, colSpan: 3 }, {}, {},
                                { text: formatCurrency(subtotalRubro), bold: true,alignment: 'right'  },
                                { text: formatCurrency(descuentoRubro), bold: true,alignment: 'right'  },
                                { text: formatCurrency(totalRubro), bold: true,alignment: 'right'  },
                            ],
                        ]
                    },
                    layout: {
                        hLineWidth: function (i, node) {
                            return (i === 2 || i === node.table.body.length-1) ? 0.75 : 0;
                        },
                        vLineWidth: function (i, node) {
                            return 0;
                        },
                    },

                },
                { text: '', margin: [0, 0, 0, reportConf.spaceBetweenTable] }
            ]
            tableDetail.push(auxTable)
        }

        const totalLabel = [];
        // totalLabel.push(cuadroTotal(this.total));
        totalLabel.push({
            margin: [215, 5, 0, 0],
            // fontSize: reportConf.fontSizeTotalReporte,
            fontSize: reportConf.fontSizeTable,
            table: {
                widths: ["*", 80, 45,55],
                body: [
                    [
                        { text: "Total Consolidado", bold: true },
                        { text: formatCurrency(this.totalSubTotal), bold: true,alignment: 'right'  },
                        { text: formatCurrency(this.totalDescuentos), bold: true,alignment: 'right'  },
                        { text: formatCurrency(this.totalCategory), bold: true,alignment: 'right'  },
                    ],
                ],
            },
            layout: {
                hLineWidth: function (i, node) {
                    return i === 0 || i === node.table.body.length ? 0.5 : 0;
                },
                vLineWidth: function (i, node) {
                    return i === 0 || i === node.table.widths.length ? 0.5 : 0;
                },
            },
        });

        const footerPage = reportFooter(receivedFrom);

        const report = {
            pageSize: 'letter',
            pageMargins: reportConf.pageMargins,
            header: headerPage,
            content: [tableDetail, totalLabel],
            footer: footerPage,
            defaultStyle: {
                font: 'Roboto'
            },
            styles: {
                tableHeader: {
                    bold: true,
                    color: 'black'
                },
                tableCategory: {
                    bold: true,
                    fontSize: reportConf.fontSizeTableHeader,
                    color: 'black'
                },
            },
        }

        if (isPrint) {
            let document = pdfMake.createPdf(report)
            let docBlob = null;

            document.getBase64(function (result) {
                docBlob = result;
                printJS({
                    printable: docBlob,
                    type: "pdf",
                    base64: true
                });
            });
        } else {
            pdfMake.createPdf(report).open()
        }
    }
}
//export default ReportUnidadCuentaFacturas