import pdfMake from 'pdfmake/build/pdfmake'
import pdfFonts from 'pdfmake/build/vfs_fonts'
import printJS from 'print-js';

import RobotoFont from '../../fonts/Roboto'
import RobotoBoldFont from '../../fonts/RobotoBold'

import reportConf from '../components/reportConf'
import reportHeader from '../components/reportHeader'
import cuadroTotal from '../components/cuadroTotal'
import reportFooter from '../components/reportFooter'

import { formatCurrency } from '../../utils/utils';

export default class ReportConceptoFacturas {

    total = 0.0;
    totalCategory = 0.0;
    totalDescuentos = 0;
    totalSubTotal = 0.0;

    /**
     * método que recibe los arreglo de datos y retorna las filas de las tabla por cada items.
     * @param {Array} items
     * @param {int} i
     * @returns {Array} ->fila de las tablas
     */
    getItems(items) {

        this.totalCategory = 0.0;
        const list = items.map((item) => {
            let totalAdd = 0.0
            let subTotal = 0.0
            let descuento = 0.0

            subTotal = parseFloat(item.subtotal);
            totalAdd = parseFloat(item.total);
            descuento = parseFloat(item.descuento);

            this.total += totalAdd;
            this.totalDescuentos += descuento;
            this.totalCategory += totalAdd;
            this.totalSubTotal += subTotal;

            return [
                { text: item.da },
                { text: item.codigo },
                { text: `   ${item.concepto}` },
                { text: formatCurrency(subTotal), alignment: 'right'  },
                { text: formatCurrency(descuento), alignment: 'right'  },
                { text: formatCurrency(totalAdd), alignment: 'right' },

            ];
        });
        return list;
    }
    /**
     * método que retorna una tabla con los filtro si estos fueron requeridos.
     * @param {boolean} isFilter
     * @param {JSON} filter
     * @param {boolean} isAdmin
     * @returns {Array} ->Filtros seleccionados
     */
    selectionFilter(isFilter, filter, isAdmin) {
        if (isFilter) {
            if (isAdmin) {
                return [

                    ['Estructura', ":", filter.estructura],
                    ['Rubro', ":", filter.rubro],
                    ['Unidad', ":", filter.unidad],
                    ['Concepto', ":", filter.concepto],
                    ['Cuenta', ":", filter.cuenta],

                ];
            } else {
                return [
                    ['Rubro', ":", filter.rubro],
                    ['Unidad', ":", filter.unidad],
                    ['Concepto', ":", filter.concepto],
                    ['Cuenta', ":", filter.cuenta],
                ];

            }

        } else {
            return [];
        }

    }

    /**
     * método encargado de generar el reporte
     * @param {Array} itemsReport
     * @param {string} userName
     * @param {string} receivedFrom
     * @param {date} initDate
     * @param {date} endDate
     * @param {boolean} isPrint
     * @param {boolean} isFilter
     * @param {JSON} filter
     * @param {boolean} isAdmin
     */
    async generateReport(itemsReport, userName, receivedFrom, initDate, endDate, isPrint, isFilter, filter, isAdmin, userUnidad, electronico, caja) {
        
        this.total = 0.0;
        this.totalCategory = 0.0;
        this.totalDescuentos = 0;
        this.totalSubTotal = 0.0;

        pdfMake.vfs = {
            ...pdfFonts.pdfMake.vfs,
            'Roboto-Regular.ttf': RobotoFont,
            'Roboto-Bold.ttf': RobotoBoldFont
        };

        pdfMake.fonts = {
            Roboto: {
                normal: 'Roboto-Regular.ttf',
                bold: 'Roboto-Bold.ttf'
            }
        };

        const headerPage = (currentPage, pageCount, pageSize) => {
            return reportHeader(
                currentPage,
                pageCount,
                'Informe Consolidado por Concepto - Facturas',
                userUnidad,
                userName,
                initDate,
                endDate,
                electronico,
                caja
            );
        }

        var tableDetail = [[
            {
                fontSize: reportConf.fontSizeFilters,
                margin: [0, 0, 0, 5],
                table: {
                    body: [
                        ["", "", ""],
                        ...this.selectionFilter(isFilter, filter, isAdmin)

                    ],
                },
                layout: "noBorders",
            },

        ]
        ];

        if(itemsReport.length > 0) {
            tableDetail.push(
                {
                    fontSize: reportConf.fontSizeTableHeader,
                    bold: true,
                    margin: [4, 0, 0, 0],
                    table: {
                        widths: [50, 'auto'],
                        body: [
                            [
                                { text: ' Cod. Rubro' },
                                { text: 'Rubro'}
                            ]
                        ]
                    },
                    layout: "noBorders",

                }
            );
        }

        itemsReport.forEach(item => {
            const groupsByDa = this.groupConceptosByDa(item.lista)
            const rubro = item.rubro
            const codigo = item.codigo
            groupsByDa.forEach(group => {
                var auxTable = [
                    {
                        fontSize: reportConf.fontSizeTable,
                        table: {
                            headerRows: 1,
                            widths: [30, 30, "*", 80, 45,55],
                            body: [
                                [
                                    { text: codigo, style: "tableCategory" },
                                    { text: rubro, style: "tableCategory", colSpan: 2 }, {}, {},{},{}
                                ],
                                [
                                    {
                                        text: "D.A.", style: "tableHeader"
                                    },
                                    {
                                        text: "Código", style: "tableHeader"
                                    },
                                    {
                                        text: "Concepto", style: "tableHeader"
                                    },
                                    { text: 'Subtotal', style: 'tableHeader', alignment: 'right' },
                                    { text: 'Descuento', style: 'tableHeader', alignment: 'right' },
                                    { text: 'Total', style: 'tableHeader', alignment: 'right' },
    
                                ],
                                ...this.getItems(group),
                                [
                                    { text: "Total Rubro", bold: true, colSpan: 3 }, {}, {},
                                    { text: formatCurrency(this.totalSubTotal), bold: true,alignment: 'right'  },
                                    { text: formatCurrency(this.totalDescuentos), bold: true,alignment: 'right'  },
                                    { text: formatCurrency(this.totalCategory), bold: true,alignment: 'right'  },

                                ],
                            ],
                        },
                        layout: {
                            hLineWidth: function (i, node) {
                                return (i === 2 || i === node.table.body.length-1) ? 0.75 : 0;
                            },
                            vLineWidth: function (i, node) {
                                return 0;
                            },
                        },
                    },
                    { text: '', margin: [0, 0, 0, reportConf.spaceBetweenTable] },
                ];
                tableDetail.push(auxTable);
            })
        })

        const totalLabel = [];
        totalLabel.push({
            margin: [215, 5, 0, 0],
            fontSize: reportConf.fontSizeTable,
            table: {
                widths: ["*", 80, 45,55],
                body: [
                    [
                        { text: "Total Consolidado", bold: true },
                        { text: formatCurrency(this.totalSubTotal), bold: true,alignment: 'right'  },
                        { text: formatCurrency(this.totalDescuentos), bold: true,alignment: 'right'  },
                        { text: formatCurrency(this.totalCategory), bold: true,alignment: 'right'  },
                    ],
                ],
            },
            layout: {
                hLineWidth: function (i, node) {
                    return i === 0 || i === node.table.body.length ? 0.5 : 0;
                },
                vLineWidth: function (i, node) {
                    return i === 0 || i === node.table.widths.length ? 0.5 : 0;
                },
            },
        });
        
        const footerPage = reportFooter(receivedFrom);

        const report = {
            pageSize: "letter",
            pageMargins: reportConf.pageMargins,
            header: headerPage,
            content: [tableDetail, totalLabel],
            footer: footerPage,
            defaultStyle: {
                font: 'Roboto'
            },
            styles: {
                tableHeader: {
                    bold: true,
                    color: "black",
                },
                tableCategory: {
                    bold: true,
                    fontSize: reportConf.fontSizeTableHeader,
                    color: "black",
                },
            },
        };

        if (isPrint) {
            var document = pdfMake.createPdf(report)
            var docBlob = null;

            document.getBase64(function (result) {
                docBlob = result;
                printJS({
                    printable: docBlob,
                    type: "pdf",
                    base64: true
                });
            });
        } else {
            pdfMake.createPdf(report).open()
        }
    }

    groupConceptosByDa(conceptos) {
        const groups = {}
        conceptos.forEach(concepto => {
            // No todos los registros tienen el campo da.
            const key = concepto.da > 0 ? concepto.da : null
            if (groups[key]) {
                groups[key].push(concepto)
            } else {
                groups[key] = [concepto]
            }
        })
        return Object.values(groups)
    }
}
