/* Informe consolidado por Unidad */

import pdfMake from 'pdfmake/build/pdfmake'
import pdfFonts from 'pdfmake/build/vfs_fonts'
import printJS from 'print-js-updated';

import RobotoFont from '../fonts/Roboto'
import RobotoBoldFont from '../fonts/RobotoBold'

import reportConf from './components/reportConf'
import reportHeader from './components/reportHeader'
import cuadroTotal from './components/cuadroTotal'
import reportFooter from './components/reportFooter'
import retirarComillas from './components/retirarComillas';
import { formatCurrency } from 'utils';

export default class ReportDistribucionAportes {
    totalDa = 0
    total = 0

    /**
     * método que recibe los datos de la unidad y retorna las filas de las tabla por cada ítems de unidades sin D.A.
     * @param {Array} items
     * @returns {Array} filas de la tabla
     */
    addListaCentros(items) {
        let list = items.map((item) => {
            this.total += parseFloat(item.total);
            let totalCentro = new Intl.NumberFormat('en-US', { minimumFractionDigits: 2 }).format(item.total)
            return [
                { text: retirarComillas(item.plan_estudio) },
                { text: retirarComillas(item.carrera) },
                {
                    alignment: 'right',
                    text: item.cantidad,
                    margin: [0, 0, 5, 0]
                },
                {
                    text: totalCentro,
                    alignment: 'right',
                }
            ];
        })

        return list;
    }

    /**
     * método encargado de generar el reporte
     * @param {Array} items
     * @param {string} usuario
     * @param {string} procesado
     * @param {date} fechaInicio
     * @param {date} fechaFin
     * @param {boolean} isPrint
     */
    generateReport(items, usuario, procesado, fechaInicio, fechaFin, isPrint) {
        this.total = 0
        pdfMake.vfs = {
            ...pdfFonts.pdfMake.vfs,
            'Roboto-Regular.ttf': RobotoFont,
            'Roboto-Bold.ttf': RobotoBoldFont
        };

        pdfMake.fonts = {
            Roboto: {
                normal: 'Roboto-Regular.ttf',
                bold: 'Roboto-Bold.ttf'
            }
        };

        const headerPage = (currentPage, pageCount, pageSize) => {
            return reportHeader(
                currentPage,
                pageCount,
                'Informe de Distribucion de Aportes Estudiantiles',
                '',
                usuario,
                fechaInicio,
                fechaFin
            );
        }
        const structureList = [];
        items.forEach( (estructura, index) => {
            if (index === 0) {
                structureList.push({
                    fontSize: reportConf.fontSizeTableHeader,
                    bold: true,
                    margin: [4, 0, 0, 0],
                    table: {
                        widths: [80, 'auto'],
                        body: [
                            [
                                { text: 'Cod. Estructura' },
                                { text: 'Estructura'}
                            ]
                        ]
                    },
                    layout: "noBorders",

                });
            }
            structureList.push({
                fontSize: reportConf.fontSizeTable,
                table: {
                    headerRows: 1,
                    widths: [80, 320, 35, 40],
                    body: [
                        [
                            { text: estructura.codigo, style: 'tableHeader' },
                            { text: estructura.descripcion, style: 'tableHeader' },
                            {},
                            {},
                        ],
                        [
                            { text: 'Plan de Estudio', style: 'tableHeader' },
                            { text: 'Centro de Estudiantes', style: 'tableHeader' },
                            { text: 'Cantidad', style: 'tableHeader' },
                            { text: 'Total', style: 'tableHeader', alignment: 'right' }
                        ],
                        ...this.addListaCentros(estructura.carreras),
                        [
                            { text: 'Total facultad' },
                            {},
                            {},
                            { text: formatCurrency(estructura.total), alignment: 'right' },
                        ]
                    ]
                },
                layout: {
                    hLineWidth: function (i, node) {
                        return (i === 2 || i + 1 === node.table.body.length) ? 0.75 : 0;
                    },
                    vLineWidth: function (i, node) {
                        return 0;
                    },
                },
            });
        })

        const details = [
            structureList,
            { text: '', margin: [0, 0, 0, reportConf.spaceBetweenTable] }
        ]

        details.push(cuadroTotal(this.total, 'Total Consolidado'));

        const footerPage = reportFooter(procesado);

        const docDefinitions = {
            pageSize: 'letter',
            pageMargins: reportConf.pageMargins,
            header: headerPage,
            content: [details],
            defaultStyle: {
                font: 'Roboto'
            },
            styles: {
                tableHeader: {
                    bold: true,
                    color: 'black'
                },
                tableSubTotal: {
                    bold: true,
                    color: 'black'
                }
            },
            footer: footerPage
        }

        if (isPrint) {
            let document = pdfMake.createPdf(docDefinitions)
            let docBlob = null;

            document.getBase64(function (result) {
                docBlob = result;
                printJS({
                    printable: docBlob,
                    type: "pdf",
                    base64: true
                });
            });
        } else {
            pdfMake.createPdf(docDefinitions).open()
        }
    }
}