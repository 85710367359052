/* Detalle de recaudaciones según Recibos */

import pdfMake from 'pdfmake/build/pdfmake'
import pdfFonts from 'pdfmake/build/vfs_fonts'
import printJS from 'print-js';

import RobotoFont from '../fonts/Roboto'
import RobotoBoldFont from '../fonts/RobotoBold'

import reportConf from './components/reportConf'
import reportHeader from './components/reportHeader'
import cuadroTotal from './components/cuadroTotal'
import reportFooter from './components/reportFooter'

export default class ReportRecaudacionRecibos {

    total = 0.0;
    totalCategory = 0.0;

    constructor() {
        this.total = 0.0
        this.numUsuario = 0
    }
    /**
     * método que retorna una tabla con los filtro si estos fueron requeridos.
     * @param {boolean} isFilter
     * @param {JSON} filter
     * @param {boolean} isAdmin
     * @returns {Array} filtros selecionados
     */
    selectionFilter(isFilter, filter, isAdmin) {
        if (isFilter) {
            if (isAdmin) {
                return [
                    ['Estructura', ":", filter.estructura],
                    ['Rubro', ":", filter.rubro],
                    ['Unidad', ":", filter.unidad],
                    ['Concepto', ":", filter.concepto],
                    ['Cuenta', ":", filter.cuenta],
                ];
            } else {
                return [
                    ['Rubro', ":", filter.rubro],
                    ['Unidad', ":", filter.unidad],
                    ['Concepto', ":", filter.concepto],
                    ['Cuenta', ":", filter.cuenta],
                ];
            }
        } else {
            return [];
        }

    }
    /**
     * método que recibe los arreglo de datos, calcula los subtotales por cada recibo y retorna las filas de las tabla por cada items.
     * @param {Array} items
     * @param {int} i
     * @returns {Array} ->fila de las tablas
     */
    getItems(items, i) {
        let auxValue = 0.0;
        this.totalCategory = 0.0;
        const list = items[i].detalle.map((item) => {
            let observacion = ''
            let totalAdd = 0.0
            if (item.estado === 'A') {
                observacion = "ANULADO";
                totalAdd = 0.0;
            } else {
                observacion = " ";
                totalAdd = parseFloat(item.total);
            }
            this.total += totalAdd;
            this.totalCategory += totalAdd
            auxValue = new Intl.NumberFormat("en-US", {
                minimumFractionDigits: 2,
            }).format(totalAdd);
            return [
                { text: item.fecha },
                { text: item.nRec, alignment: 'right' },
                { text: item.nPre, alignment: 'right' },
                { text: item.descripcion },
                { text: auxValue, alignment: 'right' },
                { text: observacion, alignment: 'center' }
            ];
        });
        return list;
    }

    /**
     * método encargado de generar el reporte
     * @param {Array} itemsReport
     * @param {string} userName
     * @param {string} receivedFrom
     * @param {date} initDate
     * @param {date} endDate
     * @param {boolean} isPrint
     * @param {boolean} isFilter
     * @param {JSON} filter
     * @param {boolean} isAdmin
     */
    generarPdf(itemsReport, usuario, procesado, fechaInicio, fechaFin, isPrint, isFilter, filter, isAdmin,userUnidad, electronico, caja) {
        this.total = 0.0;

        pdfMake.vfs = {
            ...pdfFonts.pdfMake.vfs,
            'Roboto-Regular.ttf': RobotoFont,
            'Roboto-Bold.ttf': RobotoBoldFont
        };

        pdfMake.fonts = {
            Roboto: {
                normal: 'Roboto-Regular.ttf',
                bold: 'Roboto-Bold.ttf'
            }
        };

        const headerPage = (currentPage, pageCount, pageSize) => {
            return reportHeader(
                currentPage,
                pageCount,
                'Detalle de Recaudaciones Según Recibos',
                userUnidad,
                usuario,
                fechaInicio,
                fechaFin,
                electronico,
                caja
            );
        }

        let tableDetail = [[
            {
                fontSize: reportConf.fontSizeFilters,
                margin: [0, 0, 0, 5],
                table: {
                    body: [
                        ["", "", ""],
                        ...this.selectionFilter(isFilter, filter, isAdmin)

                    ],
                },
                layout: "noBorders",
            },

        ]
        ];
        for (let i = 0; i < itemsReport.length; i++) {
            let auxTable = [
                {
                    fontSize: reportConf.fontSizeTable,
                    table: {
                        headerRows: 2,
                        widths: [40, 40, 45, 240, 40, 58],
                        body: [
                            [
                                { text: 'Cajero:', style: 'tableUser' },
                                { text: `${itemsReport[i].usuario}`, style: 'tableUser', colSpan: 4, }, {}, {}, {}, {}
                            ],
                            [
                                { text: 'Fecha', style: 'tableHeader' },
                                { text: 'Nro.Rec.', style: 'tableHeader', alignment: 'right' },
                                { text: 'Nro.PreImp.', style: 'tableHeader', alignment: 'right' },
                                { text: 'Descripción', style: 'tableHeader' },
                                { text: 'Total', style: 'tableHeader', alignment: 'right' },
                                { text: 'Observación', style: 'tableHeader', alignment: 'center' },
                            ],
                            ...this.getItems(itemsReport, i),
                        ],
                    },
                    layout: {
                        hLineWidth: function (i, node) {
                            return i === 2 || i === node.table.body.length ? 0.75 : 0;
                        },
                        vLineWidth: function (i, node) {
                            return 0;
                        },
                    },
                },
                {
                    fontSize: reportConf.fontSizeTable,
                    bold: true,
                    table: {
                        widths: [90, 165, 270,],
                        body: [
                            [
                                { text: `${itemsReport[i].detalle.length}`, alignment: 'right' },
                                { text: 'Total Usuario', alignment: 'center' },
                                {
                                    text: new Intl.NumberFormat("en-US", {
                                        minimumFractionDigits: 2,
                                    }).format(this.totalCategory),
                                    alignment: "right",
                                    margin: [0, 0, 100, 0]
                                },
                            ],
                        ],
                    },
                    layout: "noBorders",
                },
                {text: '', margin: [0, 0, 0, reportConf.spaceBetweenTable]},
            ];
            tableDetail.push(auxTable);
        }

        const totalLabel = [];
        totalLabel.push(cuadroTotal(this.total, 'Total Reporte', [0, 0, 70, 0]));
        totalLabel.push([
            {
                fontSize: 10,
                margin: [0, 20, 20, 100],
                bold: true,

                table: {
                    widths: [200, 'auto'],
                    body: [
                        ['Formularios Físicos Pre-Impresos', 'Del:....................................... Al:.......................................'],
                        ['Recibos', 'Del:....................................... Al:.......................................'],
                        ['Recibos Físicos Anulados', '.........................................................................................']
                    ]
                },
                layout: 'noBorders',

            },
        ]);

        const footer = reportFooter(procesado)[0];

        const report = {
            pageSize: 'letter',
            pageMargins: reportConf.pageMargins,
            header: headerPage,
            content: [tableDetail, totalLabel],
            defaultStyle: {
                font: 'Roboto'
            },
            styles: {
                tableHeader: {
                    bold: true,
                    color: 'black'
                },
                tableUser: {
                    bold: true,
                    fontSize: reportConf.fontSizeTableHeader,
                    color: 'black'
                },
            },
            footer: (page, pages) => {
                return [
                    pages !== page ? {} : {
                        text: '..........................................................\n Cajero',
                        alignment: 'center',
                        bold: true,
                        margin: [0, 5, 0, 0],
                    },
                    {
                        ...footer,
                        margin: [20, 15, reportConf.pageMarginRight, 20], // menos 5 al top para que se mantenga donde estaba antes de juntarlo con el elemento para la firma del cajero
                    }
                ];
            },
        }

        if (isPrint) {
            let document = pdfMake.createPdf(report)

            document.getBase64(function (docBlob) {
                printJS({
                    printable: docBlob,
                    type: "pdf",
                    base64: true
                });
            });
        } else {
            pdfMake.createPdf(report).open()
        }
    }
}