import saveAs from "file-saver"
const Excel = require('exceljs')

export default class ExcelFile {
    //datos, titulo de la hoja, titulo del reporte, nombre de usuario, fecha de inicio, fecha fin, ancho columnas, celda final(D1 F3),
    //nombre de columnas, filas detalle, valor total, titulo total, rubro (true or false), columna title total, columna valor total

    /**
     * Funcion que genera el reporte en excel para los reportes: Informe Consolidado por Concepto, Informe Consolidado por Unidad
     * Informe Consolidado por Cuenta y el Informe Consolidado por Unidad y Cuenta
     * 
     * @param JSON details 
     * @param string titleSheet 
     * @param string titleReport 
     * @param string userName 
     * @param string initDate 
     * @param string endDate 
     * @param array columnWidth formato de las columnas
     * @param string cellEnd 
     * @param array columnHeader titulos de las coumnas
     * @param array rowsDetail 
     * @param float totalReport 
     * @param string totalTitle que llevara la columna total
     * @param string rubro 
     * @param string colmnTitleTotal columna donde se colocara el titulo del total
     * @param string colmnValueTotal columna donde se colocara el total obtenido
     * @param string fileName nombre del archivo al descargar
     */
    generateExcelFile(details, titleSheet, titleReport, userName, initDate, endDate, columnWidth, cellEnd, columnHeader, rowsDetail, totalReport, totalTitle, rubro, colmnTitleTotal, colmnValueTotal, fileName) {
        const date = new Date()
        const outputDate = date.getFullYear().toString() + String(date.getMonth() + 1).padStart(2, '0') + String(date.getDate()).padStart(2, '0')
        const outputTime = `${date.getHours()}${date.getMinutes()}${date.getSeconds()}`

        const workbook = new Excel.Workbook();
        const worksheet = workbook.addWorksheet(titleSheet, { views: { showGridlines: false } });

        worksheet.columns = columnWidth

        worksheet.mergeCells(`A1:${cellEnd}`)
        worksheet.getCell("A1").value = titleReport
        worksheet.getCell("A1").alignment = { horizontal: "center", vertical: "middle" }
        worksheet.getCell("A1").font = {
            bold: true,
            size: 14
        }
        worksheet.getRow(1).height = 25

        worksheet.getCell("A2").value = "Usuario"
        worksheet.getCell("B2").value = userName

        worksheet.getCell("A3").value = "Fecha"
        worksheet.getCell("B3").value = `Del ${initDate} al ${endDate}`

        var indexCell = 5

        if (rubro) {
            for (var i = 0; i < details.length; i++) {
                //codigo y rubro
                worksheet.getCell(`A${indexCell}`).value = details[i].codigo
                worksheet.getCell(`A${indexCell}`).font = {
                    bold: true,
                    size: 14
                }
                worksheet.getCell(`B${indexCell}`).value = details[i].rubro
                worksheet.getCell(`B${indexCell}`).font = {
                    bold: true,
                    size: 14
                }
                ++indexCell
                //tabla items
                const tableName = details[i].rubro.replace(/ /g, "")
                const auxTable = {
                    name: `${tableName}${i}`,
                    ref: `A${indexCell}`,
                    headerRow: true,
                    totalsRow: true,
                    columns: columnHeader,
                    rows: rowsDetail[i],
                }

                indexCell += (details[i].lista.length + 4)

                worksheet.addTable(auxTable)

                worksheet.getCell(`A${(indexCell - 3)}`).value = "Total Rubro"
                worksheet.getCell(`A${(indexCell - 3)}`).font = {
                    bold: true,
                }
                worksheet.getCell(`D${(indexCell - 3)}`).font = {
                    bold: true,
                }

                worksheet.getCell(`A${(indexCell - 3)}`).border = {
                    top: { style: "medium" },
                    left: { style: "medium" },
                    bottom: { style: "medium" }
                }
                worksheet.getCell(`B${(indexCell - 3)}`).border = {
                    top: { style: "medium" },
                    bottom: { style: "medium" }
                }
                worksheet.getCell(`C${(indexCell - 3)}`).border = {
                    top: { style: "medium" },
                    bottom: { style: "medium" }
                }
                worksheet.getCell(`D${(indexCell - 3)}`).border = {
                    top: { style: "medium" },
                    right: { style: "medium" },
                    bottom: { style: "medium" }
                }

            }
        } else {
            const auxTable = {
                name: `table${i}`,
                ref: `A${indexCell}`,
                headerRow: true,
                totalsRow: false,
                columns: columnHeader,
                rows: rowsDetail,
            }
            indexCell += (details.length + 4)

            worksheet.addTable(auxTable)
        }



        worksheet.getCell(`${colmnTitleTotal}${indexCell}`).value = totalTitle
        worksheet.getCell(`${colmnTitleTotal}${indexCell}`).font = {
            bold: true,
            size: 14
        }
        worksheet.getCell(`${colmnTitleTotal}${indexCell}`).border = {
            top: { style: "medium" },
            left: { style: "medium" },
            bottom: { style: "medium" }
        }
        worksheet.getCell(`${colmnTitleTotal}${indexCell}`).alignment = { horizontal: "left", vertical: "middle" }

        worksheet.getCell(`${colmnValueTotal}${indexCell}`).value = totalReport
        worksheet.getCell(`${colmnValueTotal}${indexCell}`).font = {
            bold: true,
            size: 14
        }
        worksheet.getCell(`${colmnValueTotal}${indexCell}`).border = {
            top: { style: "medium" },
            right: { style: "medium" },
            bottom: { style: "medium" }
        }
        worksheet.getCell(`${colmnValueTotal}${indexCell}`).alignment = { horizontal: "right", vertical: "middle" }

        worksheet.getRow(indexCell).height = 25

        workbook.xlsx.writeBuffer().then(function (buffer) {
            if(fileName === ''){
                saveAs(
                    new Blob([buffer], { type: "application/octet-stream" }),
                    `${outputDate}${outputTime}_Report.xlsx`
                );
            }else{
                saveAs(
                    new Blob([buffer], { type: "application/octet-stream" }),
                    `${fileName}.xlsx`
                );
            }
        });
    }

}