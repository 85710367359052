/* Informe consolidado por Concepto */

import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
import printJS from "print-js";

import RobotoFont from '../fonts/Roboto'
import RobotoBoldFont from '../fonts/RobotoBold'

import reportConf from "./components/reportConf"
import reportHeader from "./components/reportHeader"
import cuadroTotal from "./components/cuadroTotal"
import reportFooter from "./components/reportFooter"

export default class ReportConsolidadoConcepto {

    total = 0.0;
    totalCategory = 0.0;

    /**
     * método que recibe los arreglo de datos y retorna las filas de las tabla por cada items.
     * @param {Array} items
     * @param {int} i
     * @returns {Array} ->fila de las tablas
     */
    getItems(items) {
        let auxValue = 0.0;
        this.totalCategory = 0.0;
        const list = items.map((item) => {
            this.total += parseFloat(item.total);
            this.totalCategory += parseFloat(item.total);
            auxValue = new Intl.NumberFormat("en-US", {
                minimumFractionDigits: 2,
            }).format(item.total);
            return [
                { text: item.da },
                { text: item.codigo },
                { text: `   ${item.concepto}` },
                { text: auxValue, alignment: "right" },
            ];
        });
        return list;
    }
    /**
     * método que retorna una tabla con los filtro si estos fueron requeridos.
     * @param {boolean} isFilter
     * @param {JSON} filter
     * @param {boolean} isAdmin
     * @returns {Array} ->Filtros seleccionados
     */
    selectionFilter(isFilter, filter, isAdmin) {
        if (isFilter) {
            if (isAdmin) {
                return [

                    ['Estructura', ":", filter.estructura],
                    ['Rubro', ":", filter.rubro],
                    ['Unidad', ":", filter.unidad],
                    ['Concepto', ":", filter.concepto],
                    ['Cuenta', ":", filter.cuenta],

                ];
            } else {
                return [
                    ['Rubro', ":", filter.rubro],
                    ['Unidad', ":", filter.unidad],
                    ['Concepto', ":", filter.concepto],
                    ['Cuenta', ":", filter.cuenta],
                ];

            }

        } else {
            return [];
        }

    }

    /**
     * método encargado de generar el reporte
     * @param {Array} itemsReport
     * @param {string} userName
     * @param {string} receivedFrom
     * @param {date} initDate
     * @param {date} endDate
     * @param {boolean} isPrint
     * @param {boolean} isFilter
     * @param {JSON} filter
     * @param {boolean} isAdmin
     */
    async generateReport(itemsReport, userName, receivedFrom, initDate, endDate, isPrint, isFilter, filter, isAdmin, userUnidad, electronico, caja) {
        
        pdfMake.vfs = {
            ...pdfFonts.pdfMake.vfs,
            'Roboto-Regular.ttf': RobotoFont,
            'Roboto-Bold.ttf': RobotoBoldFont
        };

        pdfMake.fonts = {
            Roboto: {
                normal: 'Roboto-Regular.ttf',
                bold: 'Roboto-Bold.ttf'
            }
        };

        this.total = 0.0;

        const headerPage = (currentPage, pageCount, pageSize) => {
            return reportHeader(
                currentPage,
                pageCount,
                'Informe Consolidado por Concepto',
                userUnidad,
                userName,
                initDate,
                endDate,
                electronico,
                caja
            );
        }

        var tableDetail = [[
            {
                fontSize: reportConf.fontSizeFilters,
                margin: [0, 0, 0, 5],
                table: {
                    body: [
                        ["", "", ""],
                        ...this.selectionFilter(isFilter, filter, isAdmin)

                    ],
                },
                layout: "noBorders",
            },

        ]
        ];

        if(itemsReport.length > 0) {
            tableDetail.push(
                {
                    fontSize: reportConf.fontSizeTableHeader,
                    bold: true,
                    margin: [4, 0, 0, 0],
                    table: {
                        widths: [50, 'auto'],
                        body: [
                            [
                                { text: ' Cod. Rubro' },
                                { text: 'Rubro'}
                            ]
                        ]
                    },
                    layout: "noBorders",

                }
            );
        }

        itemsReport.forEach(item => {
            const groupsByDa = this.groupConceptosByDa(item.lista)
            const rubro = item.rubro
            const codigo = item.codigo
            groupsByDa.forEach(group => {
                var auxTable = [
                    {
                        fontSize: reportConf.fontSizeTable,
                        table: {
                            headerRows: 1,
                            widths: [50, 40, 310, 83],
                            body: [
                                [
                                    { text: codigo, style: "tableCategory" },
                                    { text: rubro, style: "tableCategory", colSpan: 3 }, {}, {}
                                ],
                                [
                                    {
                                        text: "D.A.", style: "tableHeader"
                                    },
                                    {
                                        text: "Código", style: "tableHeader"
                                    },
                                    {
                                        text: "Concepto", style: "tableHeader"
                                    },
                                    {
                                        text: "Total", style: "tableHeader", alignment: "right"
                                    },
                                ],
                                ...this.getItems(group),
                                [
                                    { text: "Total Rubro", bold: true, colSpan: 3 }, {}, {},
                                    {
                                        text: new Intl.NumberFormat("en-US", {
                                            minimumFractionDigits: 2,
                                        }).format(this.totalCategory),
                                        bold: true,
                                        alignment: "right",
                                    },
                                ],
                            ],
                        },
                        layout: {
                            hLineWidth: function (i, node) {
                                return (i === 2 || i === node.table.body.length-1) ? 0.75 : 0;
                            },
                            vLineWidth: function (i, node) {
                                return 0;
                            },
                        },
                    },
                    { text: '', margin: [0, 0, 0, reportConf.spaceBetweenTable] },
                ];
                tableDetail.push(auxTable);
            })
        })

        const totalLabel = [];
        totalLabel.push(cuadroTotal(this.total));

        const footerPage = reportFooter(receivedFrom);

        const report = {
            pageSize: "letter",
            pageMargins: reportConf.pageMargins,
            header: headerPage,
            content: [tableDetail, totalLabel],
            footer: footerPage,
            defaultStyle: {
                font: 'Roboto'
            },
            styles: {
                tableHeader: {
                    bold: true,
                    color: "black",
                },
                tableCategory: {
                    bold: true,
                    fontSize: reportConf.fontSizeTableHeader,
                    color: "black",
                },
            },
        };

        if (isPrint) {
            var document = pdfMake.createPdf(report)
            var docBlob = null;

            document.getBase64(function (result) {
                docBlob = result;
                printJS({
                    printable: docBlob,
                    type: "pdf",
                    base64: true
                });
            });
        } else {
            pdfMake.createPdf(report).open()
        }
    }

    groupConceptosByDa(conceptos) {
        const groups = {}
        conceptos.forEach(concepto => {
            // No todos los registros tienen el campo da.
            const key = concepto.da > 0 ? concepto.da : null
            if (groups[key]) {
                groups[key].push(concepto)
            } else {
                groups[key] = [concepto]
            }
        })
        return Object.values(groups)
    }
}
