import { React, Component } from 'react';
import '../Styles/reportStyles.css'

import Services from "../services/report.service"

import LoadingSpiner from '../Component/LoadingSpinner';
import PopUpMessage from '../Component/PopUpMessage';
import PopUpConfirm from '../Component/PopUpConfirm';

/**
 * Clase que genera la pagina de cierre de movimiento tanto para el dia como para la gestion
 *
 */
export default class Report extends Component {
    constructor(props) {
        super(props);
        this.state = {
            optionClosure: 1,
            cierre: 'del Día',
            btnLabel: 'CIERRE DEL DIA',
            jefeCajero: false,
            spinner: false,
            popUpMessage: '',
            popUpMessageVisible: false,
            popUpConfirmVisible: false
        }

        this.changeState = this.changeState.bind(this)
        this.registerClosureMoviment = this.registerClosureMoviment.bind(this)
        this.userRole = this.userRole.bind(this)
        this.closePopUp = this.closePopUp.bind(this)
        this.confirmClosureMoviment = this.confirmClosureMoviment.bind(this)
    }

    componentDidMount() {
        this.userRole()
    }

    async userRole() {
        this.setState({ spinner: true })
        await Services.accountPlanRole().then((data) => {
            this.setState({ jefeCajero: data.jefeCajero })
            this.setState({ spinner: false })
        })
    }

    /**
     * Metodo que cambia el estado de cierre para realizar la solicitud al backend
     *
     * @param string value puede ser 1 (cierre dia) o 2 (cierre gestion)
     */
    changeState(value) {
        var auxValue = parseInt(value)

        if (auxValue === 1) {
            this.setState({ optionClosure: 1 })
            this.setState({ cierre: 'del día' })
            this.setState({ btnLabel: 'CIERRE DEL DÍA' })
            return
        }
        if (auxValue === 2) {
            this.setState({ optionClosure: 2 })
            this.setState({ cierre: 'de la Gestión' })
            this.setState({ btnLabel: 'CIERRE DE GESTIÓN' })
            return
        }
    }

    verifyPermission() {
        if (!this.state.jefeCajero) {
            this.setState({ popUpMessage: 'No cuenta con permisos para realizar el cierre' })
            this.setState({ popUpMessageVisible: true })
            return
        } else {
            this.setState({ popUpMessage: `Esta seguro de realizar el cierre ${this.state.cierre}` })
            this.setState({ popUpConfirmVisible: true })
        }
    }

    confirmClosureMoviment() {
        this.closePopUp()
        this.registerClosureMoviment()
    }

    async registerClosureMoviment() {
        var actualDate = new Date()
        await Services.closureMoviment(actualDate, this.state.optionClosure).then((data) => {
            this.setState({ popUpMessage: data.message })
            this.setState({ popUpMessageVisible: true })
        })
    }

    closePopUp() {
        this.setState({ popUpMessageVisible: false })
        this.setState({ popUpConfirmVisible: false })
        this.setState({ popUpMessage: '' })
    }

    render() {
        document.title = 'Caja UMSS - Cierre movimiento'

        return (
            <div >
                {this.state.spinner ? <LoadingSpiner /> : null}
                <div className='container shadow-sm p-3 bg-body rounded' style={{ marginLeft: 'auto', marginRight: "auto" }} >
                    <center style={{ marginLeft: '-10%' }}>
                        <table className='tableInputs'>
                            <tbody>
                                <tr>
                                    <td className='sizetd2'><strong>Tipo de Cierre :</strong> </td>


                                    <td className='sizetd1' colSpan="3">
                                        <select className="form-select form-select-sm" onChange={(e) => this.changeState(e.target.value)}>
                                            <option value={1}>CIERRE DEL DÍA</option>
                                            <option value={2}>CIERRE DE GESTIÓN</option>

                                        </select>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </center>
                    <br></br>
                    <div className="container2">
                        <div className='fieldset'>
                            <div className="contTypeReport">
                                <h4>PRECAUCIÓN:</h4>
                                <h3 style={{ textAlign: 'center' }}>Una vez que se realiza el Cierre {this.state.cierre} , ya no se puede realizar ninguna venta</h3>
                            </div>
                        </div>
                    </div>
                    <br></br>
                    <div className='header'>
                        <table className='table table-responsive table-borderless mb-0'>
                            <tbody>
                                <tr>
                                    <td className='buttons'>
                                        <button type="button" className="btn btn-primary bt2 ms-0" onClick={(e) => { this.verifyPermission() }}>{this.state.btnLabel}</button>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <PopUpMessage
                        close={this.closePopUp}
                        visible={this.state.popUpMessageVisible}
                        message={this.state.popUpMessage}
                    />
                    <PopUpConfirm
                        close={this.closePopUp}
                        visible={this.state.popUpConfirmVisible}
                        message={this.state.popUpMessage}
                        confirmAction={this.confirmClosureMoviment}
                    />
                </div>
            </div>
        );
    }
}