import authHeader from './auth-header'
import { BaseRequest } from './request/baseRequest'
import { GenericRequest } from './request/request'

const ContabilidadServices = {
    fondo: {
        list: (params = {}) => GenericRequest.get('contabilidad/list-fondo', params),
        view: (id) => GenericRequest.get('contabilidad/view-fondo', { id }),
        update: (id, data) => GenericRequest.post('contabilidad/update-fondo', data, { id }),
        delete: (id) => GenericRequest.post('contabilidad/delete-fondo', {}, { id }),
        end: (id, data) => GenericRequest.post('contabilidad/end-fondo', data, { id }),
        pdf: (id) =>
            BaseRequest.get('contabilidad/fondo-pdf', { params: { id }, responseType: 'blob', headers: authHeader() }),
        increaseLimit: (id) => GenericRequest.post('contabilidad/increase-limit-fondo', {}, { id }),
        details: (id) => GenericRequest.get('contabilidad/fondo-detalles', { id }),
        reportPdf: (params = {}) =>
            BaseRequest.get('contabilidad/fondos-pdf', { params, responseType: 'blob', headers: authHeader() }),
    },
    fondoRotatorio: {
        list: (params = {}) => GenericRequest.get('contabilidad/list-fondo-rotatorio', params),
        create: (data = {}) => GenericRequest.get('contabilidad/create-fondo-rotatorio', data),
        hasActive: (data = {}) => GenericRequest.get('contabilidad/has-active-fondo-rotatorio', data),
    },
    fondoAvance: {
        list: (params = {}) => GenericRequest.get('contabilidad/list-fondo-avance', params),
        byUsuario: (usuarioId) => GenericRequest.get('contabilidad/fondo-avance-by-usuario', { usuarioId }),
        update: (data) => GenericRequest.post('contabilidad/update-fondo-avance', data),
    },
    fondoDetalle: {
        create: (data) => GenericRequest.post('contabilidad/create-fondo-detalle', data),
        update: (id, data) => GenericRequest.post('contabilidad/update-fondo-detalle', data, { id }),
        delete: (id) => GenericRequest.post('contabilidad/delete-fondo-detalle', {}, { id }),
    },
    usuario: {
        list: (params = {}) => GenericRequest.get('contabilidad/list-usuario', params),
        create: (data) => GenericRequest.post('contabilidad/create-usuario', data),
        update: (id, data) => GenericRequest.post('contabilidad/update-usuario', data, { id }),
        buscarFuncionario: (email) => GenericRequest.post('contabilidad/buscar-funcionario', { email }),
    },
    role: {
        getToAssign: (usuarioId) => GenericRequest.get('contabilidad/get-roles-to-assign', { usuarioId }),
        batchUpdate: (usuarioId, data) => GenericRequest.post('contabilidad/update-roles', data, { usuarioId }),
    },
    tipoDocumento: {
        all: () => GenericRequest.get('contabilidad/get-tipos-documento'),
    },
    searchActividades: (codigo) => GenericRequest.get('estructura-programatica/search-actividades', { codigo }),
}
export default ContabilidadServices
