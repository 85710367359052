import logoUpsi from 'assets/img/logo-dtic.png'

const year = new Date().getFullYear()

function Footer() {
    return (
        <footer className='text-center text-white bg-blue py-2'>
            <span className='me-3'>
                © {year} UMSS. Desarrollado por <span style={{ color: '#5ED6DE' }}>Software San Simón - DTIC</span>
            </span>
            <img
                style={{ height: '3.5rem' }}
                className='img-fluid'
                src={logoUpsi}
                alt=''
            />
        </footer>
    )
}
export default Footer
