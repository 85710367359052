import { createRef } from 'react'
import { CSSTransition, TransitionGroup } from 'react-transition-group'
import { classNames } from '../utils'
import { useToast } from './useToast'
import { Toast } from './Toast'
// clases de transición de los items del toast.
const t = {
    appear: '',
    appearActive: '',
    appearDone: '',
    enter: 'toast-enter',
    enterActive: 'toast-enter-active',
    enterDone: 'toast-enter-done',
    exit: 'toast-exit',
    exitActive: 'toast-exit-active',
    exitDone: '',
}
export function Toaster({ position = 'top-right', className, style, baseZIndex, onShow, onHide }) {
    const { toasts } = useToast()
    /** Ejecuta la prop onShow cuando se muestra un item. */
    const onShowItem = () => {
        onShow && onShow()
    }
    /** Ejecuta la prop onHide cuando se muestra un item. */
    const onHideItem = () => {
        onHide && onHide()
    }
    return (
        <div
            className={classNames(['modular-toaster', position, className])}
            style={{ zIndex: baseZIndex || 1090, ...style }}
        >
            <TransitionGroup>
                {toasts.map((toast) => {
                    const toastRef = createRef()
                    return (
                        <CSSTransition
                            key={toast.id}
                            index={toast.id}
                            nodeRef={toastRef}
                            timeout={{ enter: 300, exit: 300 }}
                            unmountOnExit={true}
                            onEntered={onShowItem}
                            onExited={onHideItem}
                            classNames={t}
                        >
                            <Toast {...toast} ref={toastRef} />
                        </CSSTransition>
                    )
                })}
            </TransitionGroup>
        </div>
    )
}
