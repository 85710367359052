import BaseModal from '@restart/ui/Modal'
import { classNames } from '../utils'
import { forwardRef, useCallback, useRef } from 'react'
import { Fade } from './Fade'
export const Modal = forwardRef(
    (
        {
            show,
            children,
            transition = true,
            keyboard,
            closeOnBackdrop = true,
            backdropClassName,
            backdropStyle,
            containerClassName,
            containerStyle,
            className,
            style,
            autoFocus = true,
            enforceFocus = true,
            restoreFocus = true,
            restoreFocusOptions,
            onShow,
            onHide,
            onEnter,
            onEntering,
            onEntered,
            onExit,
            onExiting,
            onExited,
            'aria-labelledby': ariaLabelledby,
            'aria-describedby': ariaDescribedby,
            'aria-label': ariaLabel,
        },
        ref,
    ) => {
        const containerRef = useRef(null)
        const renderBackdrop = useCallback(
            (props) => (
                <div
                    {...props}
                    className={classNames('modular-modal-backdrop', { show: !transition }, backdropClassName)}
                    style={backdropStyle}
                />
            ),
            [transition, backdropClassName, backdropStyle],
        )
        const renderDialog = (props) => (
            <div
                {...props}
                ref={containerRef}
                className={classNames('modular-modal-container', { show: !transition }, containerClassName)}
                style={containerStyle}
                onClick={closeOnBackdrop ? (e) => e.target === containerRef.current && onHide?.() : undefined}
                aria-label={ariaLabel}
                aria-labelledby={ariaLabelledby}
                aria-describedby={ariaDescribedby}
            >
                <div className={classNames('modular-modal-content', className)} style={style} tabIndex={-1}>
                    {children}
                </div>
            </div>
        )
        return (
            <BaseModal
                ref={ref}
                show={show}
                onShow={onShow}
                onHide={onHide}
                onEnter={onEnter}
                onEntering={onEntering}
                onEntered={onEntered}
                onExit={onExit}
                onExiting={onExiting}
                onExited={onExited}
                keyboard={keyboard}
                renderBackdrop={renderBackdrop}
                renderDialog={renderDialog}
                transition={transition ? Fade : undefined}
                backdropTransition={transition ? Fade : undefined}
                autoFocus={autoFocus}
                enforceFocus={enforceFocus}
                restoreFocus={restoreFocus}
                restoreFocusOptions={restoreFocusOptions}
            />
        )
    },
)
Modal.displayName = 'Modal'
