import './Styles/all.css'
import './App.css'
import 'font-awesome/css/font-awesome.min.css'
import 'modules/modular/dist/css/styles.css'
import 'modules/modular/dist/css/theme.css'
import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { BrowserRouter } from 'react-router-dom'
import Navbar from './Component/Navbar'
import { logout, setUser } from './actions/auth'
import { history } from './helpers/history'
import Footer from './Component/Footer'
import LoadingSpinner from './Component/LoadingSpinner'
import PrivateRoutes from './routes/PrivateRoutes'
import PublicRoutes from './routes/PublicRoutes'
import { Toaster } from 'modules/modular/Toaster'
import AuthServices from 'services/auth.service'
import useHttpToast from 'hooks/useHttpToast'

function App() {
    const dispatch = useDispatch()
    const { showHttpToast } = useHttpToast()
    const { isLoggedIn, user } = useSelector((state) => state.auth)

    useEffect(() => {
        if (isLoggedIn) {
            getDataUser()
        }
    }, [isLoggedIn])

    /** Obtiene la información y roles de usuario. */
    const getDataUser = async () => {
        const { status, data: { data } } = await AuthServices.info()
        if (status === 200) {
            const roles = Object.keys(data.roles).reduce((carry, rol) => {
                if (data.roles[rol] === true) carry.push(rol)
                return carry
            }, [])
            dispatch(setUser({ user: data.usuario, roles }))
        } else {
            showHttpToast(status, { detail: data.message })
            dispatch(logout())
        }
    }

    return (
        <BrowserRouter history={history}>
            <div className='page-container'>
                <Navbar />
                <main className='page-main'>
                    {isLoggedIn ? user ? <PrivateRoutes /> : <LoadingSpinner /> : <PublicRoutes />}
                </main>
                <Footer />
            </div>
            <Toaster />
        </BrowserRouter>
    )
}

export default App
