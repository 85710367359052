/**
 * Obtiene la parte decimal de un número.
 * @param {string | number} number Número.
 * @param {number} length cantidad de decimales.
 * @returns Retorna la parte decimal.
 */
export function getDecimalPart(number, length = 2) {
	let _number = 0
	if (typeof number === 'number') {
		_number = number
	} else if (typeof number === 'string') {
		_number = parseFloat(number)
	} else {
		_number = NaN
	}
	let _decimal = ''
	if (!isNaN(_number)) {
		_decimal = new Intl.NumberFormat('en-US', {
			minimumFractionDigits: length,
			maximumFractionDigits: length,
		}).format(_number)
		_decimal = _decimal.split('.')[1]
	}
	return _decimal
}
