// import store from '@/store'
// import { logout } from '@/store/authSlice'
// import { setHttpMessage } from '@/store/messageSlice'
import authHeader from '../auth-header'
import { BaseRequest } from './baseRequest'

const authenticate = (response) => {
    // if (response.status === 401) {
    //     store.dispatch(logout())
    //     store.dispatch(setHttpMessage(401, {
    //         title: 'Su sesión a expirado',
    //         detail: 'El tiempo de su sesión ha terminado o se ha iniciado sesión con su cuenta desde otro navegador.',
    //         sticky: true
    //     }))
    // }
}

export const GenericRequest = {
    get: async (url, params = {}) => {
        const response = await BaseRequest.get(url, { params, headers: authHeader() })
        authenticate(response)
        return response
    },
    post: async (url, data, params = {}) => {
        const response = await BaseRequest.post(url, data, { params, headers: authHeader() })
        authenticate(response)
        return response
    },
    put: async (url, data, params = {}) => {
        const response = await BaseRequest.put(url, data, { params, headers: authHeader() })
        authenticate(response)
        return response
    },
    delete: async (url, params = {}) => {
        const response = await BaseRequest.delete(url, { params, headers: authHeader() })
        authenticate(response)
        return response
    },
}

const controller = {}

export const SignalRequest = {
    get: async (url, params = {}) => {
        controller[url] && controller[url]?.abort()
        const newAC = new AbortController()
        controller[url] = newAC
        const response = await BaseRequest.get(url, { params, signal: newAC.signal, headers: authHeader() })
        controller[url] = null
        authenticate(response)
        return response
    },
    post: async (url, data, params = {}) => {
        controller[url] && controller[url]?.abort()
        const newAC = new AbortController()
        controller[url] = newAC
        const response = await BaseRequest.post(url, data, { params, signal: newAC.signal, headers: authHeader() })
        controller[url] = null
        authenticate(response)
        return response
    },
    put: async (url, data, params = {}) => {
        controller[url] && controller[url]?.abort()
        const newAC = new AbortController()
        controller[url] = newAC
        const response = await BaseRequest.put(url, data, { params, signal: newAC.signal, headers: authHeader() })
        controller[url] = null
        authenticate(response)
        return response
    },
    delete: async (url, params = {}) => {
        controller[url] && controller[url]?.abort()
        const newAC = new AbortController()
        controller[url] = newAC
        const response = await BaseRequest.delete(url, { params, signal: newAC.signal, headers: authHeader() })
        controller[url] = null
        authenticate(response)
        return response
    },
}
