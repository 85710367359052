import pdfMake from 'pdfmake/build/pdfmake'
import pdfFonts from 'pdfmake/build/vfs_fonts'
import printJS from 'print-js-updated';
import ConverNumber from './convertNumber';

import IBMPlexMonoFont  from '../fonts/IBMPlexMono';

import services from '../services/report.service';

export default class TuitionReport {

    total = 0.00
    auxTotal = 0.00

    auxCost = 0.00
    decimalPart = '00'

    costValue = new ConverNumber()
    totalCostValue = ''

    userName = ""

    unidad = ""

    constructor() {
        this.generateReport = this.generateReport.bind(this)
        this.getDecimalPart = this.getDecimalPart.bind(this)
        this.generatePages = this.generatePages.bind(this)
        this.getContent = this.getContent.bind(this)
    }

    getDecimalPart(){
        this.auxTotal = new Intl.NumberFormat('en-US', {minimumFractionDigits: 2}).format(this.total)
        const decimal = (this.auxTotal+"").split(".")[1]
        this.decimalPart = decimal.replace('.', '')
        if(this.decimalPart.length === 1){
            this.decimalPart += '0'
        }
        this.totalCostValue = this.costValue.numeroALetras(this.total)
    }

    async getUsername(){
        await services.getUserData().then((data) => {
            this.userName = data.user
            this.unidad = data.unidad
        }).catch(error => console.log(error))
    }

    /**
     * Funcion que realiza la configuracion de la fuente a usar, dimencion de la hoja, genera
     * el recibo y lo manda a imprimir
     *
     * @param string numberControl numero de control del recibo
     * @param string receivedFrom nombre de la persona que esta solicitando la compra
     * @param JSON details items que se estan vendiendo
     * @param string ci
     * @param string codSIS
     * @param string dateReceived
     */
    async generateReport(numberControl, receivedFrom, details, ci, codSIS, dateReceived, payload, user) {
        pdfMake.vfs = {
            ...pdfFonts.pdfMake.vfs,
            'IBMPlexMono-Regular.ttf': IBMPlexMonoFont
        };

        pdfMake.fonts = {
            IBMPlexMono: {
                normal: 'IBMPlexMono-Regular.ttf'
            }
        };


        await this.getUsername()

        const TuitionReport = {
            pageSize: {
                height: 420,
                width: 595.276
            },

            content: [...await this.generatePages(numberControl, `${dateReceived}`, receivedFrom, details, ci, codSIS, payload, user)],

            styles: {
                columnHeader: { columnGap: -2000 }
            },
            defaultStyle: {
                fontSize: 10,
                font: 'IBMPlexMono'
            }
        }

        let pdfDocument = pdfMake.createPdf(TuitionReport);

        pdfDocument.getBase64( function (result) {
            printJS({
                printable: result,
                type: "pdf",
                base64: true,
            });
        });
    }

    /**
     * Funcion que genera la cantidad de recibos a imprimir
     *
     * @param string user
     * @param string numberControl
     * @param string date
     * @param string receivedFrom
     * @param JSON items
     * @param string observation
     * @param string ci
     * @param string codSIS
     * @returns array auxContent recibos a imprimir
     */
    async generatePages(numberControl, date, receivedFrom, items, ci, codSIS, payload, user){
            let auxContent = [];

            auxContent.push( await this.getContent(numberControl[0], date, receivedFrom, items, ci, codSIS, payload, user))
            return auxContent;
    }

    /**
     * Funcion donde se genera la estructura del contenido del recibo
     *
     * @param string user
     * @param string numberControl
     * @param string date
     * @param string receivedFrom
     * @param string details
     * @param string ci
     * @param string codSIS
     *
     * @returns array contentReport contenido del recibo
     */
    async getContent(numberControl, date, receivedFrom, details, ci, codSIS, payload, user){
        this.total = 0.00
        this.auxTotal = 0.00
        this.auxCost = 0.00
        const acountsSize = 8;
        const infoSize = 9;

        let items = await details.map((item) => {
            this.total += parseFloat(item.cost)
            this.auxCost = new Intl.NumberFormat('en-US', {minimumFractionDigits: 2}).format(parseFloat(item.cost));
            return [{ text: item.concept, margin: [0, 0, 0, -4], fontSize: acountsSize}, { text: this.auxCost, noWrap: false, alignment: 'right', margin: [0, 0, 0, -4], fontSize: acountsSize }]
        })

        this.getDecimalPart()

        const contentReport = [
            {text: ' '},
            {text: ' '},
            {text: ' '},
            { text: `${this.unidad}`, bold: false, },
            {
                alignment: 'justify',
                style: 'columnHeader',
                columns: [
                    {
                        text: 'Nro.Control: ', bold: false, margin: [350, 0, 0, 0]
                    },
                    {
                        text: numberControl, bold: false, margin: [0, 0, 0, 0], alignment: 'right'
                    }
                ]
            },
            {
                alignment: 'justify',
                style: 'columnHeader',
                columns: [
                    {
                        text: 'Fecha: ', bold: false, margin: [350, 0, 0, 0]
                    },
                    {
                        text: `${date}`, bold: false, margin: [0, 0, 0, 0], alignment: 'right'
                    }
                ]
            },
            {
                alignment: 'justify',
                style: 'columnHeader',
                columns: [
                    {
                        text: 'Usuario: ', bold: false, margin: [350, 0, 0, 0]
                    },
                    {
                        text: (user.length > 0) ? user : this.userName , bold: false, margin: [0, 0, 0, 0], alignment: 'right'
                    }
                ]
            },
            { text: `Datos de matrícula:` },
            { text: `Periodo : ${payload.BCI||payload.BC} - ${payload.CYR}`, fontSize: infoSize, margin: [10, 0, 0, 0]},
            { text: `Carrera : ${payload.qual} - ${payload.qname}`, fontSize: infoSize, margin: [10, 0, 0, 0]},
            { text:'\n'},
            { text: `Recibi de : ${receivedFrom}`},
            { text: 'Por concepto de:', fontSize: acountsSize},
            {
                margin: [10, 0, 0, 0],
                table: {
                    headerRows: 1,
                    widths: [375, '*'],
                    body: [
                        ...items
                    ]
                },
                layout: 'noBorders',
            },
            { text: '— — — — — — — — —', bold: false, alignment: 'right' },
            {
                alignment: 'justify',
                style: 'columnHeader',
                columns: [
                    {
                        text: 'Total: Bs ', bold: false, margin: [380, 0, 0, 0]
                    },
                    {
                        text: this.auxTotal, bold: false, margin: [0, 0, 0, 0], alignment: 'right'
                    }
                ]
            },
            { text: `La suma de: ${this.totalCostValue}       ${this.decimalPart}/100    BOLIVIANOS`, bold: false },
            { text: ' ' },
            { text: ' ' },
            {
                margin: [0, 0, 0, 0],
                table: {
                    headerRows: 1,
                    widths: [75, 100, 300],
                    body: [
                        [{ text: 'Documento : ', alignment: 'right' }, { text: ci, alignment: 'left' }, { text: '— — — — — — — — — — — — — — — — — —', alignment: 'center' }],
                        [{ text: 'Codigo : ', alignment: 'right' }, { text: codSIS, alignment: 'left' }, { text: 'Cajero', alignment: 'center' }],
                    ]
                },
                layout: 'noBorders',

            }
        ]

        return contentReport
    }

}