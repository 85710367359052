import { useEffect, useRef, useState } from "react";
import FacturaServices from "../../services/factura.service";
import ModalCuentas from "./ModalCuentas";
import { classNames } from "../../utils/ClassNames";

const datosCuentaInicial = {"codigo":"","nombre":"","unidad":"", "precio":"","cantidad":1,"total":0.0,"tipo_monto":"F"}

function DatosCuenta({datos, setDatos, facturarRef, errors, touched, validar}) {
    
    const [cuentas, setCuentas] = useState([])
    const [show, setShow] = useState(false)
    const precioRef = useRef(null)
    
    useEffect(() => {
        const total = parseFloat(datos.precio * datos.cantidad).toFixed(2)
        setDatos({ ...datos, total })
    }, [datos.precio,datos.cantidad])
    

    const handleChange= (e)=>{
        const {name, value} = e.target
        let _datos= name === 'codigo' ? datosCuentaInicial : datos
        _datos = { ..._datos, [name]: value }
        setDatos(_datos)
        validar({cuenta: _datos}, name)
    };

    const handleKeyUp= (e)=>{
        if(e.key === "Enter"){
            // console.log(e.key);
            buscarCuenta(datos.codigo);

        }
    };        

    const seleccionarCuenta = (cuenta) =>{
        const {id, codigo, nombre, unidad, precio, tipo_monto}=cuenta;//cuando el array devuelve un solo elemento 
        const _datos = {...datos, id, codigo, nombre, unidad, precio, tipo_monto}
        setDatos(_datos)
        validar({cuenta: _datos}, 'codigo')
        if(cuenta.tipo_monto ==='V'){
            // precioRef.current.focus()
            setTimeout(() => { precioRef.current.focus() }, 200)
        }else{
            setTimeout(() => { facturarRef.current.focus() }, 200)
         }
    }

    const buscarCuenta = async(codigo, nombre='')=>{
        
        const response= await FacturaServices.listarCuentasFacturas(codigo, nombre);
        if(response.data.isOk){
            const data = response.data.result;
            if(show){
                setCuentas(data)
            }else{
                if(data.length === 1){
                    seleccionarCuenta(data[0])
                }else{
                    setCuentas(data)
                    setShow(true)
                }
    
            }
        }    
    };

    return <>
        <div className='header clearfix'>
            <label className='title float-start'>CUENTAS</label>
        </div>
        <table className='table table-responsive table-borderless'>
            <tbody>
                <tr>
                    <th>Código: </th>
                    <td>
                        <input 
                            type='text' 
                            name="codigo" 
                            value={datos.codigo} 
                            onChange={handleChange} 
                            onKeyUp={handleKeyUp}
                            className={classNames([
                                'form-control form-control-sm', 
                                touched.codigo && errors.codigo && 'is-invalid',
                            ])} 
                            required 
                        />
                    </td>
                    <th>Nombre: </th>
                    <td>
                    <input 
                            type='text' 
                            name="nombre" 
                            value={datos.nombre} 
                            onChange={handleChange} 
                            onKeyUp={handleKeyUp}
                            className='form-control form-control-sm' 
                            disabled 
                        />

                    </td>
                    <th>Unidad: </th>
                    <td>
                    <input 
                            type='text' 
                            name="unidad" 
                            value={datos.unidad} 
                            onChange={handleChange} 
                            onKeyUp={handleKeyUp}
                            className='form-control form-control-sm' 
                            disabled
                        />

                    </td>
                </tr>
                <tr>
                    <th>Precio Bs.: </th>
                    <td>
                        <input 
                            name="precio" 
                            type='number' 
                            value={datos.precio} 
                            ref={precioRef}
                            //onFocus={()=>console.log('precio')}
                            style={{textAlign: "right"}}
                            onChange={handleChange} 
                            className='form-control form-control-sm' 
                            disabled={datos.tipo_monto==="F"}
                        />
                    </td>
                    <th>Cantidad: </th>
                    <td>
                        <input 
                            name="cantidad" 
                            type='number' 
                            style={{textAlign: "right"}}
                            value={datos.cantidad} 
                            onChange={handleChange} 
                            className='form-control form-control-sm'
                            min={1}
                            //required 
                        />
                    </td>
                    <th>Total Importe Bs. </th>
                    <td>
                        <input 
                            name="total" 
                            type='number' 
                            value={datos.total} 
                            style={{textAlign: "right"}}
                            onChange={handleChange} 
                            className={classNames([
                                'form-control form-control-sm', 
                                touched.total && errors.total && 'is-invalid'
                            ])} 
                            disabled
                        />
                    </td>
                </tr> 
              
            </tbody>
        </table>
        <ModalCuentas show={show} onHide={()=>{setShow(false)}} cuentas={cuentas} valor={datos.codigo} buscarCuentas={buscarCuenta} seleccionarCuenta={seleccionarCuenta}/>


    </>;

} 

export default DatosCuenta;