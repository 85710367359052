import '../Styles/SuccessfulPayment.css'

function SuccessfulPayment() {

    return (
        <div className="payment-success-main-container">
            <div className='payment-succes-content shadow-sm p-3 bg-body rounded row'>
                <h2 className='centered-text'>Transacción Registrada</h2>
            </div>
        </div>
    )
}

export default SuccessfulPayment;
