/* Informe institucional posgrados */

import pdfMake from 'pdfmake/build/pdfmake'
import pdfFonts from 'pdfmake/build/vfs_fonts'
import printJS from 'print-js';

import RobotoFont from '../../fonts/Roboto'
import RobotoBoldFont from '../../fonts/RobotoBold'

import reportConf from '../components/reportConf'
import reportHeader from '../components/reportHeader'
import cuadroTotal from '../components/cuadroTotal'
import reportFooter from '../components/reportFooter'

import { formatCurrency } from '../../utils/utils';

export default class ReportInstitucionalFacturas {
    totalDa = 0
    total = 0.0;
    totalDescuentos = 0;
    totalSubTotal = 0.0;

    /**
     * método que recibe los datos de la unidad y retorna las filas de las tabla por cada ítems de unidades sin D.A.
     * @param {Array} items
     * @returns {Array} filas de la tabla
     */
    addListStructura(items) {
        const list = items.map((item) => {
            let totalAdd = 0.0
            let subTotal = 0.0
            let descuento = 0.0

            subTotal = parseFloat(item.subtotal);
            descuento = parseFloat(item.descuento);
            totalAdd = parseFloat(item.total);

            this.total += totalAdd;
            this.totalDescuentos += descuento;
            this.totalSubTotal += subTotal;

            //let totalUnidad = new Intl.NumberFormat('en-US', { minimumFractionDigits: 2 }).format(item.total)
            return [
                { text: item.codigo_corto},
                { text: item.descripcion},
                { text: formatCurrency(subTotal), alignment: 'right'  },
                { text: formatCurrency(descuento), alignment: 'right'  },
                { text: formatCurrency(totalAdd), alignment: 'right' },
                // {
                //     text: totalUnidad,
                //     alignment: 'right',
                //     margin: [0, 0, 60, 0],
                // }
            ];
        })

        return list
    }

    /**
     * método encargado de generar el reporte
     * @param {Array} items
     * @param {string} usuario
     * @param {string} procesado
     * @param {date} fechaInicio
     * @param {date} fechaFin
     * @param {boolean} isPrint
     * @param {string} userUnidad
     * @param {boolean} electronico
     */
    generateReport(items, usuario, procesado, fechaInicio, fechaFin, isPrint, userUnidad, electronico, caja) {

        this.total = 0.0;
        this.totalDescuentos = 0;
        this.totalSubTotal = 0.0;

        pdfMake.vfs = {
            ...pdfFonts.pdfMake.vfs,
            'Roboto-Regular.ttf': RobotoFont,
            'Roboto-Bold.ttf': RobotoBoldFont
        };

        pdfMake.fonts = {
            Roboto: {
                normal: 'Roboto-Regular.ttf',
                bold: 'Roboto-Bold.ttf'
            }
        };

        const headerPage = (currentPage, pageCount, pageSize) => {
            return reportHeader(
                currentPage,
                pageCount,
                'Informe de Recaudación Consolidado Institucional-Posgrado',
                userUnidad,
                usuario,
                fechaInicio,
                fechaFin,
                electronico,
                caja,
            );
        }

        const details = [
            {
                fontSize: reportConf.fontSizeTable,
                table: {
                    headerRows: 1,
                    widths: [40, "*", 140,55,58],
                    body: [
                        [
                            { text: 'N° D.A.', bold: true },
                            { text: 'Descripción D.A.', style: 'tableHeader'},
                            { text: 'Subtotal', style: 'tableHeader', alignment: 'right'},
                            { text: 'Descuento', style: 'tableHeader', alignment: 'right'},
                            { text: 'Total', style: 'tableHeader', alignment: 'right'},
                        ],
                        ...this.addListStructura(items),

                    ]
                },
                layout: {
                    hLineWidth: function (i, node) {
                        return (i === 1 || i === node.table.body.length) ? 0.75 : 0;
                    },
                    vLineWidth: function (i, node) {
                        return 0;
                    },
                },

            },
            {text: '',  margin: [0, 0, 0, reportConf.spaceBetweenTable]}
        ]

        //details.push(cuadroTotal(this.total, 'Total Consolidado', [0, 0, 62, 0]));
        details.push({
            margin: [205, 5, 0, 0],
            fontSize: reportConf.fontSizeTable,
            table: {
                widths: ["*", 55, 55, 58],
                //widths: ["*", 55, 40, 55],

                body: [
                    [
                        { text: "Total Consolidado", bold: true, noWrap: true },
                        { text: formatCurrency(this.totalSubTotal), bold: true,alignment: 'right'  },
                        { text: formatCurrency(this.totalDescuentos), bold: true,alignment: 'right'  },
                        { text: formatCurrency(this.total), bold: true,alignment: 'right'  },
                    ],
                ],
            },
            layout: {
                hLineWidth: function (i, node) {
                    return i === 0 || i === node.table.body.length ? 0.5 : 0;
                },
                vLineWidth: function (i, node) {
                    return i === 0 || i === node.table.widths.length ? 0.5 : 0;
                },
            },
        });

        const footerPage = reportFooter(procesado);

        const docDefinitions = {
            pageSize: 'letter',
            pageMargins: reportConf.pageMargins,
            header: headerPage,
            content: [details],
            defaultStyle: {
                font: 'Roboto'
            },
            styles: {
                tableHeader: {
                    bold: true,
                    color: 'black'
                },
                tableSubTotal: {
                    bold: true,
                    color: 'black'
                }
            },
            footer: footerPage
        }

        if (isPrint) {
            let document = pdfMake.createPdf(docDefinitions)
            let docBlob = null;

            document.getBase64(function (result) {
                docBlob = result;
                printJS({
                    printable: docBlob,
                    type: "pdf",
                    base64: true
                });
            });
        } else {
            pdfMake.createPdf(docDefinitions).open()
        }
    }
}