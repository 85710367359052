import { rolesByPriority, routesByRole } from 'data/constants'
import { useMemo } from 'react'
import { useSelector } from 'react-redux'

/**
 * Obtiene las rutas de un usuario dependiendo de los roles que posee.
 * @returns {Array<string>} Retorna un array de rutas que le corresponden al usuario actual.
 */
export default function useRoutesByRole() {
    // Array de roles almacenados en redux
    const { roles } = useSelector((state) => state.auth)
    const routes = useMemo(() => {
        const _routes = []
        for (let index = 0; index < rolesByPriority.length; index++) {
            // role puede ser string o array de strings
            const role = rolesByPriority[index]
            if (Array.isArray(role)) {
                // Para rutas que requieren más de un rol asignado
                if (role.every((_role) => roles.includes(_role))) {
                    /*
                     * Obtiene las rutas concatenando los roles requeridos con la sintaxis 'role-_-role'
                     * ordenando los roles alfabéticamente.
                     */
                    _routes.push(...(routesByRole[role.sort().join('-_-')] || []))
                    break
                }
            } else {
                // Para rutas que requieren solo un rol
                if (roles.includes(role)) {
                    _routes.push(...(routesByRole[role] || []))
                    break
                }
            }
        }
        return _routes
    }, [roles])

    return routes
}
