import ExcelFile from "./ExcelFile";

export default class ExcelConCuentaUnidad{

    totalReport = 0.00
    list = []

    /**
     * Metodo que manda a generar el Informe Consolidado por Unidad y Cuenta en formato excel
     * 
     * @param JSON details 
     * @param string userName 
     * @param string initDate 
     * @param string endDate 
     * @param string fileName 
     */
    excelReport(details, userName, initDate, endDate, fileName){
        const report = new ExcelFile
        this.totalReport = 0.00
        this.listItems(details)
        const columnWidth = [ 
            { width: 20, alignmen: 'left' }, 
            { width: 40 }, 
            { width: 60 }, 
            { width: 15, alignment: 'right', style: { numFmt: '#,##0.00_);(#,##0.00)' } }
        ]
        const columnHeader = [
            { name: "codigo" },
            { name: "unidad" },
            { name: "cuenta" },
            { name: "total", totalsRowFunction: 'sum' }
        ]
        
        report.generateExcelFile(
            details,
            "Inf-Con_Unid_Cuent",
            "Informe Consolidado por Unidad y Cuenta",
            userName,
            initDate,
            endDate,
            columnWidth,
            "D1",
            columnHeader,
            this.list,
            this.totalReport,
            "Total Consolidado",
            true,
            'C',
            'D',
            fileName
        )
    }

    listItems(items){
        this.list = []
        for(var i=0; i<items.length; i++){
            var list2 = []            
            for(var a=0; a<items[i].lista.length; a++){
                this.totalReport += parseFloat(items[i].lista[a].total)
                list2.push([
                    `${items[i].lista[a].codigo}`,
                    items[i].lista[a].unidad,
                    items[i].lista[a].cuenta,
                    parseFloat(items[i].lista[a].total)
                ]) 
            }
            this.list.push(list2)
        }
    }
}