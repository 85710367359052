import axios from 'axios';
//import { useSelector } from 'react-redux';

/**
 * Constante que inicializa la conexion al servidor
 */
const API = axios.create({
    baseURL: process.env.REACT_APP_BASE_URL,
});

export default API;
