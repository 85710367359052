import { useEffect, useRef, useState } from 'react'
import { Modal } from 'react-bootstrap'

function ModalAdicionarCuentas({ show, onHide, cuentas, valor, buscarCuentas, seleccionarCuenta }) {
    const inputRef = useRef(null)
    const filasRef = useRef([])
    const aceptarRef = useRef(null)
    const [busqueda, setBusqueda] = useState('')
    const [seleccionado, setSeleccionado] = useState(-1)

    useEffect(() => {
        valor && setBusqueda(valor)
    }, [valor])

    useEffect(()=>{
      if(seleccionado>=0 && seleccionado < cuentas.length){
        filasRef.current[seleccionado].focus()
      }
    }, [seleccionado])

    useEffect(()=>{
      if(!show){
        setSeleccionado(-1)
      }
    },[show])
    

    const handleChange = (e) => {
        setBusqueda(e.target.value)
        buscarCuentas(e.target.value, e.target.value)
    }

    const seleccionar = () => {
      if(seleccionado>=0){
        seleccionarCuenta(cuentas[seleccionado])
        onHide()
      }
    }

    const handleKeyUpInput = (e)=>{
      if(e.key==='Enter' && cuentas.length>=0){
        setSeleccionado(0)
        cuentas.precio.current.focus()
      }

    }
    const handleKeyUpRow = (e, index) => {
        switch (e.key) {
            case 'Enter':
                aceptarRef.current.focus()
                break
            case 'q':
            case 'Q':
                setSeleccionado(-1)
                inputRef.current.focus()
                break
            case 'w':
            case 'W':
                if (index-1>=0) {
                  setSeleccionado(index-1)
                }
                break
            case 'S':
            case 's':
              if (index+1<cuentas.length) {
                setSeleccionado(index+1)
              }
              break
            default:
                break
        }
    }

    return (
        <Modal show={show} onHide={onHide} size='xl' centered>
            <Modal.Header closeButton>
                <Modal.Title>Buscar Cuentas</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className='App me-4'>
                    <div className='container'>
                        <div className='row'>
                            <div className='input-group'>
                                <label className='size-buscar'>Buscar</label>
                                <input
                                    ref={inputRef}
                                    type='text'
                                    className='form-control'
                                    value={busqueda}
                                    placeholder='Busqueda por Código, Nombre'
                                    onChange={handleChange}
                                    autoFocus
                                    onKeyUp={handleKeyUpInput}
                                />
                            </div>
                        </div>
                    </div>
                    <div className='container' style={{ minWidth: '700px' }}>
                        <div className='table-scroll'>
                            <table className='table table-hover'>
                                <thead>
                                    <tr>
                                        <th>Código</th>
                                        <th>Nombre</th>
                                        <th>Precio</th>
                                        <th>Unidad</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {cuentas.length > 0 ? (
                                        cuentas.map((cuenta, index) => {
                                            return (
                                                <tr
                                                    key={index}
                                                    ref={(el) => {
                                                        filasRef.current[index]=el
                                                    }}
                                                    onClick={() => {
                                                        setSeleccionado(index)
                                                    }}
                                                    className={seleccionado === index ? 'tableSelected' : ''}
                                                    onKeyUp={(e)=>{handleKeyUpRow(e,index)}}
                                                    tabIndex={0}
                                                >
                                                    <td style={{ textAlign: 'left' }}>{cuenta.codigo}</td>
                                                    <td style={{ textAlign: 'left' }}>{cuenta.nombre}</td>
                                                    <td style={{ textAlign: 'right' }}>{cuenta.precio}</td>
                                                    <td style={{ textAlign: 'left' }}>{cuenta.unidad}</td>
                                                </tr>
                                            )
                                        })
                                    ) : (
                                        <tr>
                                            <td colSpan={4}>No existen resultados</td>
                                        </tr>
                                    )}
                                </tbody>
                            </table>
                        </div>
                    </div>
                    <div className='container'>
                        <div className='btnCuenta'>
                            <button className='btn btn-primary' onClick={seleccionar} ref={aceptarRef}>
                                Aceptar
                            </button>
                            <button className='btn btn-success' onClick={onHide}>
                                Cancelar
                            </button>
                        </div>
                    </div>
                </div>
            </Modal.Body>
        </Modal>
    )
}

export default ModalAdicionarCuentas
