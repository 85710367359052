import React, { Fragment } from "react";
import { Modal } from "react-bootstrap";
import TableAccount from "../Component/Table";

export default class Modals extends React.Component{
  render() {

    return (
      <Fragment>
        <Modal animation={false} show={this.props.showModal} onHide={this.props.handleCloseModal} size={this.props.textInputName === 'inputPayLine' ? "xl" : "lg"} aria-labelledby="contained-modal-title-vcenter" centered>
          <Modal.Header closeButton>
            <Modal.Title>{this.props.title}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <TableAccount
              handleCloseModal={this.props.handleCloseModal.bind(this)}
              showInputAccount={this.props.showInputAccount.bind(this)}
              showInputPerson={this.props.showInputPerson.bind(this)}
              setBusquedaData={this.props.setBusquedaData.bind(this)}
              getListAssocAccounts={this.props.getListAssocAccounts.bind(this)}
              busqueda={this.props.busqueda}
              accounts={this.props.accounts}
              textInputName={this.props.textInputName}
              refSearch={this.props.refSearch}
              typeSearch={this.props.typeSearch}
              detailSisPay={this.props.detailSisPay}
              searchAccount={this.props.searchAccount.bind(this)}
              searchPerson={this.props.searchPerson.bind(this)}
              searchAddAccount={this.props.searchAddAccount.bind(this)}
              addAssocAccounts={this.props.addAssocAccounts.bind(this)}
              handleShowModal={this.props.handleShowModal.bind(this)}
            />
          </Modal.Body>
        </Modal>
      </Fragment>
    );
  }
}
