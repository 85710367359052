import pdfMake from 'pdfmake/build/pdfmake'
import pdfFonts from 'pdfmake/build/vfs_fonts'
import printJS from 'print-js';

import RobotoFont from '../fonts/Roboto'
import RobotoBoldFont from '../fonts/RobotoBold'

import reportConf from './components/reportConf'
import reportHeader from './components/reportHeader'
import reportFooter from './components/reportFooter'

export default class KardexValores {
    fillValores(valores) {
        var totalValores = 0;
        const list = valores.map((item) => {
            totalValores = item.total + totalValores;
            return [
                { text: item.codigo, alignment: 'left' },
                { text: item.concepto, alignment: 'left' },
                { text: item.saldoAnterior, alignment: 'right' },
                { text: item.ingresos, alignment: 'right' },
                { text: item.egresos, alignment: 'right' },
                { text: item.precioUnitario, alignment: 'right' },
                { text: new Intl.NumberFormat('en-US', { minimumFractionDigits: 2 }).format(item.total), alignment: 'right' },
                { text: item.SaldoActual, alignment: 'right' }
            ]
        });

        totalValores = new Intl.NumberFormat('en-US', { minimumFractionDigits: 2 }).format(totalValores)

        let result = [list, totalValores];

        return result
    }

    generatePDF(valores, userName, startDate, endDate, processedBy, isPrint,userUnidad) {
        pdfMake.vfs = {
            ...pdfFonts.pdfMake.vfs,
            'Roboto-Regular.ttf': RobotoFont,
            'Roboto-Bold.ttf': RobotoBoldFont
        };

        pdfMake.fonts = {
            Roboto: {
                normal: 'Roboto-Regular.ttf',
                bold: 'Roboto-Bold.ttf'
            }
        };

        const headerPage = (currentPage, pageCount, pageSize) => {
            return reportHeader(
                currentPage,
                pageCount,
                'Kardex de Valores',
                userUnidad,
                userName,
                startDate,
                endDate
            );
        }

        const content = []

        var valoresL = this.fillValores(valores);
        var total = valoresL[1]

        content.push(
            {
                fontSize: reportConf.fontSizeTable,
                table: {
                    headerRows: 1,
                    widths: [70, 90, 48, 48, 48, 48, 48, 48],
                    body: [
                        [
                            { text: 'Código', style: 'tableHeader', alignment: 'leght' },
                            { text: 'Concepto', style: 'tableHeader', alignment: 'leght' },
                            { text: 'Saldo', style: 'tableHeader', alignment: 'right' },
                            { text: 'Cantidad', style: 'tableHeader', colSpan: 2, alignment: 'center' },{},
                            { text: 'Precio', style: 'tableHeader', alignment: 'right' },
                            { text: 'Total', style: 'tableHeader', alignment: 'right' },
                            { text: 'Saldo', style: 'tableHeader', alignment: 'right' },
                        ],

                        [
                            { text: '', style: 'tableHeader', colSpan: 2, alignment: 'leght' },{},
                            { text: 'Anterior', style: 'tableHeader', alignment: 'right' },
                            { text: 'Ingresos', style: 'tableHeader', alignment: 'right' },
                            { text: 'Egresos', style: 'tableHeader', alignment: 'right' },
                            { text: 'Unitario', style: 'tableHeader', alignment: 'right' },
                            { text: 'Total', style: 'tableHeader', alignment: 'right' },
                            { text: 'Actual', style: 'tableHeader', alignment: 'right' },
                        ],

                        ...valoresL[0],

                        [
                            { text: 'Total Valores', style: 'totalValores', colSpan: 5, alignment: 'right' }, {}, {}, {}, {},
                            {text:''},
                            { text: total, style: 'totalValores', alignment: 'right'},
                            {text:''},
                        ]
                    ]
                },
                layout: {
                    hLineWidth: function (i, node) {
                        return (i === 2 || i === node.table.body.length - 1) ? 0.75 : 0;
                    },
                    vLineWidth: function (i, node) {
                        return (i === 0 || i === node.table.widths.length) ? 0 : 0;
                    },
                },
            }
        )

        const footerPage = reportFooter(processedBy);

        const docDefinitions = {
            pageSize: 'letter',
            pageMargins: reportConf.pageMargins,
            header: headerPage,
            content: [content],
            footer: footerPage,
            defaultStyle: {
                font: 'Roboto'
            },
            styles: {
                tableHeader: {
                    bold: true
                },
                totalValores: {
                    fontSize: reportConf.fontSizeTotalReporte,
                    bold: true,
                }
            }
        }

        if (isPrint) {
            let document = pdfMake.createPdf(docDefinitions)
            let docBlob = null;

            document.getBase64(function (result) {
                docBlob = result;
                printJS({
                    printable: docBlob,
                    type: "pdf",
                    base64: true
                });
            });
        } else {
            pdfMake.createPdf(docDefinitions).open()
        }
    }

}
