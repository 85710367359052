import React, { useEffect, useRef } from 'react'
import { Modal } from 'react-bootstrap';
import "../Styles/PopUp.css";


function PopUpConfirm(props) {

    const refButton = useRef(null);

    useEffect(() => {
        if(props.visible === true)
            if(refButton.current) refButton.current.focus()
        return () => {
        }
    }, [props.visible])
    
    return (
        <Modal show={props.visible} onHide={props.close} centered>
            <div className='PopUp' style={{visibility:props.visible?"visible":"hidden"}}> 
                <h3>
                    {props.message}
                </h3>
                <div style={{display: 'flex', justifyContent: 'center'}}>
                    <button className={'btn btn-primary bt2'} style={{marginRight: '16px', minWidth: '90px'}} onClick={props.confirmAction} ref={refButton}>
                        Si
                    </button>
                    <button className={'btn btn-danger bt2'} style={{minWidth: '90px'}} onClick={props.close}>
                        Cancelar
                    </button>
                </div>
            </div>
        </Modal>
    )
}

export default PopUpConfirm