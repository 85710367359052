import React, { Component } from 'react'

import '../Styles/reportStyles.css'

import Services from "../services/report.service"

import ReportRecaudacionRecibos from '../Reports/ReportRecaudacionesRecibos';
import ReportConCuentaUnid from '../Reports/ReportConCuentaUnid';
import ReportConsolidadoUnidad from '../Reports/ReportConsolidadoUnidad';
import ReportConsolidadoConcepto from '../Reports/ReportConsolidadoConcepto';
import ReportByAccountUnitDiezmo from '../Reports/ReportByAccountUnitDiezmo';
import ReportAnulados from '../Reports/ReportAnulados';
import KardexValores from '../Reports/KardexValores';

import ExcelConCuentaUnidad from '../ReportsExcel/ExcelConCuentaUnidad';
import ExcelUnidad from '../ReportsExcel/ExcelUnidad';
import ExcelRecRecibos from '../ReportsExcel/ExcelRecRecibos';
import ExcelValores from '../ReportsExcel/ExcelValores';
import ExcelAnulados from '../ReportsExcel/ExcelAnulados';
import ExcelConcepto from '../ReportsExcel/ExcelConcepto';
import ExcelReportDiezmoAccountUnit from '../ReportsExcel/ExcelReportDiezmoAccountUnit';

import LoadingSpiner from '../Component/LoadingSpinner';
import PopUpMessage from '../Component/PopUpMessage';
import ReportConsolidadoInstitucional from '../Reports/ReportConsolidadoInstitucional';
import ReportComisionBancaria from '../Reports/ReportComisionBancaria';
import ReportDistribucionAportes from '../Reports/ReportDistribucionAportes';

import ReportUtis from '../Reports/ReportUtis';
import ExcelUtis from '../ReportsExcel/ExcelUtis';

import ReportTiposEstudiante from '../Reports/ReportTiposEstudiante';
import ExcelTiposEstudiante from '../ReportsExcel/ExcelTiposEstudiante';

import ReportCobroComisionBanco from '../Reports/ReportCobroComisionBanco';
import FacturaServices from 'services/factura.service';
import ReportResumenRecibos from 'Reports/ReportResumenRecibos';
import RecibosServices from 'services/recibos.services';

const reportAnulado = new ReportAnulados();
const reportKardex = new KardexValores();
const reportUnidad = new ReportConsolidadoUnidad();
const reportConcepto = new ReportConsolidadoConcepto();
const reportRecibos = new ReportRecaudacionRecibos();
const reportUnidAccount = new ReportConCuentaUnid();
const reportIntitutional = new ReportConsolidadoInstitucional();
const reportComision = new ReportComisionBancaria();
const reportAportes = new ReportDistribucionAportes();
const reportAccountUnitDiezmo = new ReportByAccountUnitDiezmo();
const reportUtis = new ReportUtis();
const reportTiposEstudiante = new ReportTiposEstudiante();
const reportCobroComision = new ReportCobroComisionBanco();
const reportResumenRecibos = new ReportResumenRecibos();

const excelUnitAccount = new ExcelConCuentaUnidad()
const excelUnitConcept = new ExcelUnidad()
const excelReceipts = new ExcelRecRecibos()
const excelValores = new ExcelValores()
const excelAnulados = new ExcelAnulados()
const excelConcepto = new ExcelConcepto()
const excelDiezmo = new ExcelReportDiezmoAccountUnit()
const excelUtis = new ExcelUtis()
const excelTiposEstudiante = new ExcelTiposEstudiante()



export default class Reports extends Component {

    constructor(props) {
        super(props);
        this.state = {
            visible: false,
            startDate: this.getFechaActual(),
            endDate: this.getFechaActual(),
            isVisible: false,
            listReceipt: null,
            errorDate: false,
            admin: true,
            jefeCajero: false,
            adminReport: false,
            report: false,
            dtic: false,
            user: '',
            userUnidad: '',
            unidadReport: '',
            userselected: 'Todos',
            structureName: "Todos",
            excelFileName: '',
            idUser: 0,
            idRubro: 0,
            idUnidad: 0,
            idConcepto: 0,
            idCuenta: 0,
            idStructure: 0,
            units: [],
            items: [],
            concept: [],
            accounts: [],
            usuarios: [],
            structures: [],
            structureDiezmo: false,
            reportUnit: [],
            filter: {},
            rubro: "Todos",
            unidad: "Todos",
            concepto: "Todos",
            cuenta: "Todos",
            estructura: "Todos",
            loading: false,
            loadingExcel: false,
            loadingPrint: false,
            spinner: false,
            reportMessage: 'Reporte vacío',
            popUpVisible: false,
            cobrosElectronicos: false,
            cobrosCaja: false,
            indistintoTipo: false, // Se refiere a que es indistinto si el reporte es electronico o de caja por ende se bloquean esas opciones
            indistintoFiltros: false,
        }

        this.setIdUser = this.setIdUser.bind(this)
        this.loadReportAnulados = this.loadReportAnulados.bind(this);
        this.setListConcepto = this.setListConcepto.bind(this)
        this.setStartDate = this.setStartDate.bind(this);
        this.setEndDate = this.setEndDate.bind(this);
        this.imprimir = this.imprimir.bind(this);
        this.setExcelFileName = this.setExcelFileName.bind(this)
        this.excelFile = this.excelFile.bind(this);
        this.setStateValues = this.setStateValues.bind(this)
        this.setIdStructure = this.setIdStructure.bind(this)
        this.setFilter = this.setFilter.bind(this)
        this.loadUsers = this.loadUsers.bind(this)
        this.loadRoleUser = this.loadRoleUser.bind(this)
        this.setButtonSpinner = this.setButtonSpinner.bind(this)
        this.hiddenButtonSpinner = this.hiddenButtonSpinner.bind(this)
        this.getDataUserUnidad = this.getDataUserUnidad.bind(this)
        this.closePopUp = this.closePopUp.bind(this)
    }

    componentDidMount() {
        this.loadRoleUser();
        this.getDataUserUnidad();
    }


    async loadRoleUser() {
        this.setState({ spinner: true })
        await Services.getUserRole().then(async (data) => {
            if (data.admin || data.adminReport) {
                this.setState({ admin: data.admin })
                this.setState({ jefeCajero: false })
                this.setState({ dtic: false })
                this.setState({ adminReport: data.adminReport })
                this.setState({ report: false })
                await this.loadUsers(0)
                await this.loadSelectIni()
                this.setState({ spinner: false })
                return
            }

            if (data.jefeCajero || data.report) {
                this.setState({ admin: false })
                this.setState({ dtic: false })
                this.setState({ jefeCajero: data.jefeCajero })
                this.setState({ adminReport: false })
                this.setState({ report: data.report })
                this.setState({ idStructure: data.estructura })
                this.setState({ structureName: data.nombreEstructura })
                this.setState({ structureDiezmo: data.diezmo })
                await this.loadUsers(data.estructura)
                await this.loadSelectIni()
                this.setState({ spinner: false })
                return
            }

            this.setState({ dtic: data.dtic })
            this.setState({ admin: false })
            this.setState({ jefeCajero: false })
            this.setState({ report: false })
            this.setState({ jefeCajero: false })
            this.setState({ idStructure: data.estructura })
            this.setState({ structureName: data.nombreEstructura })
            this.setState({ structureDiezmo: data.diezmo })
            await this.loadUsers(data.estructura)
            await this.loadSelectIni()
            this.setState({ spinner: false })
        })
    }
    /**
     * método que ejecuta la carga de listas se selectores como ítems, unidad, concepto, rubro y estructura según el rol del Usuario a los estados.
     */
    async loadSelectIni() {
        await Services.getListItems().then((data) => {
            this.setState({ items: data })
        });
        await Services.getListUnits(this.state.idStructure).then((data) => {
            this.setState({ units: data })
        });
        await Services.getListConcept(0).then((data) => {
            this.setState({ concept: data })
        });
        await Services.getListAccounts(0, this.state.idStructure).then((data) => {
            this.setState({ accounts: data })
        });
        if (this.state.admin || this.state.jefeCajero) {
            await FacturaServices.listarEstructuras().then((response) => {
                this.setState({ structures: response.data })
            })
        }
        if (this.state.adminReport) {
            await FacturaServices.listarEstructuras().then((response) => {
                this.setState({ structures: response.data })
            })
        }

    }
    /**
     * método que carga la lista de usuarios según a la estructura que pertenece.
     * @param {int} structure id de la estuctura
     * @returns
     */
    async loadUsers(structure) {
        await Services.getUserData().then((data) => {
            this.setState({ user: data.name })
        })

        if (this.state.admin || this.state.adminReport || this.state.jefeCajero || this.state.report) {
            await Services.getListUser(structure).then((data) => {
                this.setState({ usuarios: data })
            })
            return
        }

        this.setState({ userselected: this.state.user })
    }

    setIdUser(id, text) {
        this.setState({ idUser: id })
        this.setState({ userselected: text })
    }

    async setIdStructure(id, index, text) {
        if (id === '0') {
            this.setIdUser(0, 'Todos')
            this.setState({ unidadReport: this.state.userUnidad })
        } else {
            let structureName = text.slice(text.indexOf(' '));
            this.setState({ unidadReport: structureName });
        }
        this.setState({ estructura: text })
        this.setState({ idStructure: id })
        var diezmo = index > 0 ? this.state.structures[index - 1].diezmo : false
        this.setState({ structureDiezmo: diezmo })
        await Services.getListAccounts(this.state.idConcepto, id).then((data) => {
            this.setState({ accounts: data })
        });
        await Services.getListUnits(id).then((data) => {
            this.setState({ units: data })
        });
        await Services.getListUser(id).then((data) => {
            this.setState({ usuarios: data })
        })
    }

    setStateValues(state, value, text) {
        this.setState({ [state]: value })
        if ("idUnidad" === state) {
            this.setState({ unidad: text })
        }
        if ("idCuenta" === state) {
            this.setState({ cuenta: text })
        }
    }
    /**
     * método que actualiza la lista de conceptos que pertenecen a un Rubro previamente seleccionado.
     * @param {int} idRubro
     * @param {string} text
     */
    async setListConcepto(idRubro, text) {
        this.setState({ idRubro: idRubro })
        this.setState({ rubro: text })
        await Services.getListConcept(idRubro).then((data) => {
            this.setState({ concept: data })
            this.setState({ idConcepto: 0 })
            this.setState({ concepto: 'Todos' })
        });
        await Services.getListAccounts(0, this.state.idStructure).then((data) => {
            this.setState({ accounts: data })
            this.setState({ idCuenta: 0 })
            this.setState({ cuenta: 'Todos' })
        });
    }

    /**
     * método que actualiza la lista de Cuentas que pertenecen a un Concepto previamente seleccionado.
     * @param {int} idConcepto
     * @param {string} text
     */
    async setListCuenta(idConcepto, text) {
        this.setState({ concepto: text })
        this.setState({ idConcepto: idConcepto })
        this.setState({ idCuenta: 0 })
        this.setState({ cuenta: 'Todos' })
        await Services.getListAccounts(idConcepto, this.state.idStructure).then((data) => {
            this.setState({ accounts: data })
        });
    }

    getFechaActual() {
        const diaActual = new Date();
        const day = diaActual.getDate().toString().padStart(2, '0');
        const month = (diaActual.getMonth() + 1).toString().padStart(2, '0');
        const year = diaActual.getFullYear();
        const fecha = year + '-' + month + '-' + day;
        return fecha;
    }

    setStartDate(e) {
        if (e.target.value === '') return;
        this.setState({ startDate: e.target.value });

        let today = new Date();
        let end = new Date(this.state.endDate);
        let start = new Date(e.target.value);

        this.setState({ errorDate: start > end || start > today || end > today });
    }

    setEndDate(e) {
        if (e.target.value === '') return;
        this.setState({ endDate: e.target.value });

        let today = new Date();
        let end = new Date(e.target.value);
        let start = new Date(this.state.startDate);

        this.setState({ errorDate: start > end || end > today || start > today });
    }

    setExcelFileName(e) {
        this.setState({ excelFileName: e.target.value })
    }

    dateConvert(date) {
        return date.toString().split('-').reverse().join('-');
    }

    setFilter() {
        this.setState({
            filter: {
                rubro: this.state.rubro,
                unidad: this.state.unidad,
                concepto: this.state.concepto,
                cuenta: this.state.cuenta,
                estructura: this.state.estructura
            }
        })
    }

    setButtonSpinner(isPrint) {
        if (isPrint) {
            this.setState({ loading: false })
            this.setState({ loadingPrint: true })
        } else {
            this.setState({ loading: true })
            this.setState({ loadingPrint: false })
        }
    }

    hiddenButtonSpinner() {
        this.setState({ loading: false })
        this.setState({ loadingPrint: false })
        this.setState({ loadingExcel: false })
    }


    /**
     * método que  ejecuta el servicio para traer los datos de unidad y el método para generar el reporte Consolidado por unidad.
     * @param {boolean} datoB -> variable que indica si es impresion o vista previa
     */
    loadReportUnidad(datoB) {
        this.setButtonSpinner(datoB)
        this.setFilter()
        Services.getReportUnit(this.state.idUser, this.dateConvert(this.state.startDate), this.dateConvert(this.state.endDate), this.state.idRubro, this.state.idUnidad, this.state.idConcepto, this.state.idCuenta, this.state.idStructure, this.state.cobrosElectronicos, this.state.cobrosCaja).then((data) => {

            reportUnidad.generateReport(data, this.state.userselected, this.state.user, this.dateConvert(this.state.startDate), this.dateConvert(this.state.endDate), datoB, this.state.visible, this.state.filter, this.state.admin, this.state.unidadReport, this.state.cobrosElectronicos, this.state.cobrosCaja)
            this.hiddenButtonSpinner()
        })
    }

    /**
     * método que  ejecuta el servicio para traer los datos de recibos y el método para generar el reporte Recaudación por recibos.
     * @param {boolean} datoB -> variable que indica si es impresion o vista previa
     */
    loadReportRecibo(datoB) {
        this.setButtonSpinner(datoB)
        this.setFilter()
        Services.getReportRecibo(this.state.idUser, this.dateConvert(this.state.startDate), this.dateConvert(this.state.endDate), this.state.idRubro, this.state.idUnidad, this.state.idConcepto, this.state.idCuenta, this.state.idStructure, this.state.cobrosElectronicos, this.state.cobrosCaja).then((data) => {
            reportRecibos.generarPdf(data, this.state.userselected, this.state.user, this.dateConvert(this.state.startDate), this.dateConvert(this.state.endDate), datoB, this.state.visible, this.state.filter, this.state.admin, this.state.unidadReport, this.state.cobrosElectronicos, this.state.cobrosCaja)
            this.hiddenButtonSpinner()
        })
    }
    /**
     * método que ejecuta el servicio para traer los datos de concepto y el método para generar el reporte Consolidado.
     * @param {boolean} datoB -> variable que indica si es impresion o vista previa
     */
    loadReportConcepto(datoB) {
        this.setButtonSpinner(datoB)
        this.setFilter()
        Services.getReportConcepto(this.state.idUser, this.dateConvert(this.state.startDate), this.dateConvert(this.state.endDate), this.state.idRubro, this.state.idUnidad, this.state.idConcepto, this.state.idCuenta, this.state.idStructure, this.state.cobrosElectronicos, this.state.cobrosCaja).then((data) => {
            reportConcepto.generateReport(data, this.state.userselected, this.state.user, this.dateConvert(this.state.startDate), this.dateConvert(this.state.endDate), datoB, this.state.visible, this.state.filter, this.state.admin, this.state.unidadReport, this.state.cobrosElectronicos, this.state.cobrosCaja)
            this.hiddenButtonSpinner()
        })
    }

    loadReportAnulados(dateInit, dateEnd, datoB) {
        this.setButtonSpinner(datoB)
        this.setFilter()
        Services.getReportAnulados(dateInit, dateEnd, this.state.idUser, this.state.idRubro, this.state.idUnidad, this.state.idConcepto, this.state.idCuenta, this.state.idStructure, this.state.cobrosElectronicos, this.state.cobrosCaja).then((data) => {
            reportAnulado.generatePDF(data, this.state.userselected, this.dateConvert(this.state.startDate), this.dateConvert(this.state.endDate), this.state.user, datoB, this.state.visible, this.state.filter, this.state.admin, this.state.unidadReport, this.state.cobrosElectronicos, this.state.cobrosCaja);
            this.hiddenButtonSpinner()
        })
    }

    loadReportKardex(datoB) {
        this.setButtonSpinner(datoB)
        Services.getReportKardex(this.state.startDate, this.state.endDate, this.state.idUser, this.state.idRubro, this.state.idUnidad, this.state.idConcepto, this.state.idCuenta, this.state.idStructure).then((data) => {
            reportKardex.generatePDF(data, this.state.userselected, this.dateConvert(this.state.startDate), this.dateConvert(this.state.endDate), this.state.user, datoB, this.state.unidadReport);
            this.hiddenButtonSpinner()
        });
    }

    loadReportIntitucional(datoB) {
        this.setButtonSpinner(datoB)
        Services.getReportIntitucional(this.state.startDate, this.state.endDate, this.state.cobrosElectronicos, this.state.cobrosCaja).then(data => {

            reportIntitutional.generateReport(data, 'Todos', this.state.user, this.dateConvert(this.state.startDate), this.dateConvert(this.state.endDate), datoB, this.state.unidadReport, this.state.cobrosElectronicos, this.state.cobrosCaja);
            this.hiddenButtonSpinner()
        });
    }

    loadReporteComision(datoB) {
        this.setButtonSpinner(datoB)
        Services.getReportComision(this.state.startDate, this.state.endDate).then(data => {

            reportComision.generateReport(data, 'Todos', this.state.user, this.dateConvert(this.state.startDate), this.dateConvert(this.state.endDate), datoB, this.state.unidadReport, this.state.cobrosElectronicos);
            this.hiddenButtonSpinner()
        });
    }

    loadReporteAportes(datoB) {
        this.setButtonSpinner(datoB)
        Services.getReportAportes(this.state.startDate, this.state.endDate).then(data => {

            reportAportes.generateReport(data, 'Todos', this.state.user, this.dateConvert(this.state.startDate), this.dateConvert(this.state.endDate), datoB);
            this.hiddenButtonSpinner()
        });
    }

    /**
     * método que  ejecuta el servicio para traer los datos y el método para generar el reporte Recaudación para las Utis.
     * @param {boolean} datoB -> variable que indica si es impresion o vista previa
     */
    loadReportUtis(datoB) {
            this.setButtonSpinner(datoB)
            this.setFilter()
            Services.getReportUtis(this.state.idUser, this.dateConvert(this.state.startDate), this.dateConvert(this.state.endDate), this.state.idRubro, this.state.idUnidad, this.state.idConcepto, this.state.idCuenta, this.state.idStructure, this.state.cobrosElectronicos, this.state.cobrosCaja).then((data) => {
                reportUtis.generarPdf(data, this.state.userselected, this.state.user, this.dateConvert(this.state.startDate), this.dateConvert(this.state.endDate), datoB, this.state.visible, this.state.filter, this.state.admin, this.state.unidadReport, this.state.cobrosElectronicos, this.state.cobrosCaja)
                this.hiddenButtonSpinner()
            })
        }

    /**
     * método que  ejecuta el servicio para traer los datos y el método para generar el reporte por tipos de estudiante.
     * @param {boolean} datoB -> variable que indica si es impresion o vista previa
     */
    loadReportTiposEstudiante(datoB) {
            this.setButtonSpinner(datoB)
            this.setFilter()
            Services.getReportTiposEstudiante(this.state.idUser, this.dateConvert(this.state.startDate), this.dateConvert(this.state.endDate), this.state.idRubro, this.state.idUnidad, this.state.idConcepto, this.state.idCuenta, this.state.idStructure, this.state.cobrosElectronicos, this.state.cobrosCaja).then((data) => {
                reportTiposEstudiante.generarPdf(data, this.state.userselected, this.state.user, this.dateConvert(this.state.startDate), this.dateConvert(this.state.endDate), datoB, this.state.visible, this.state.filter, this.state.admin, this.state.unidadReport, this.state.cobrosElectronicos, this.state.cobrosCaja)
                this.hiddenButtonSpinner()
            })
    }

    loadReporteCobroComision(datoB) {
        this.setButtonSpinner(datoB)
        Services.getReportCobroComision(this.state.startDate, this.state.endDate).then(data => {

            reportCobroComision.generateReport(data, 'Todos', this.state.user, this.dateConvert(this.state.startDate), this.dateConvert(this.state.endDate), datoB, this.state.unidadReport, this.state.cobrosElectronicos);
            this.hiddenButtonSpinner()
        });
    }

    // loadReporteResumenRecibos(datoB) {
    //     this.setButtonSpinner(datoB)
    //     Services.getReportResumenRecibos(this.state.startDate, this.state.endDate).then(data => {

    //         reportResumenRecibos.generarPdf(data, 'Todos', this.state.user, this.dateConvert(this.state.startDate), this.dateConvert(this.state.endDate), datoB, this.state.unidadReport, this.state.cobrosElectronicos);
    //         this.hiddenButtonSpinner()
    //     });
    // }

    loadReportAnuladosExcel() {
        this.setState({ loadingExcel: true })
        Services.getReportAnulados(this.state.startDate, this.state.endDate, this.state.idUser, this.state.idRubro, this.state.idUnidad, this.state.idConcepto, this.state.idCuenta, this.state.idStructure).then((data) => {
            if (data.length > 0) {
                excelAnulados.excelReport(data, this.state.userselected, String(this.dateConvert(this.state.startDate)), String(this.dateConvert(this.state.endDate)), this.state.excelFileName)
                this.hiddenButtonSpinner()
            }
            else {
                this.hiddenButtonSpinner()
                this.setState({ popUpVisible: true })
            }
        })
    }
    getDataUserUnidad() {
        Services.getUserData().then((data) => {
            this.setState({ unidadReport: data.unidad })
            this.setState({ userUnidad: data.unidad })
        })
    }
    /**matricula
     * método que manda que imprimir el Reporte seleccionado de las opciones existentes (RadioButon).
     * @param {boolean} datoB -> variable que indica si es impresion o vista previa
     * @returns
     */
    async imprimir(datoB) {
        if (this.state.errorDate) {
            this.setState({ reportMessage: 'Rango de fechas ingresado no valido' })
            this.setState({ popUpVisible: true })
            return
        }

        const filtros = {
            idUser: this.state.idUser,
            initDate: this.state.startDate,
            endDate: this.state.endDate,
            idRubro: this.state.idRubro,
            idUnidad: this.state.idUnidad,
            idConcepto: this.state.idConcepto,
            idCuenta: this.state.idCuenta,
            idEstructura: this.state.idStructure,
            electronico: this.state.cobrosElectronicos,
            caja: this.state.cobrosCaja,
            
        } 

        if (document.getElementById('radio1')?.checked) {
            this.setButtonSpinner(datoB)
            this.setFilter()
            await Services.getReportByUnitAccount(this.state.idUser, this.dateConvert(this.state.startDate), this.dateConvert(this.state.endDate), this.state.idRubro, this.state.idUnidad, this.state.idConcepto, this.state.idCuenta, this.state.idStructure, this.state.cobrosElectronicos, this.state.cobrosCaja).then((data) => {
                !this.state.structureDiezmo ?
                    reportUnidAccount.generateReport(data, this.state.userselected, this.dateConvert(this.state.startDate), this.dateConvert(this.state.endDate), this.state.user, datoB, this.state.visible, this.state.filter, this.state.admin, this.state.unidadReport, this.state.cobrosElectronicos, this.state.cobrosCaja)
                    : reportAccountUnitDiezmo.generateReport(data, this.state.userselected, this.dateConvert(this.state.startDate), this.dateConvert(this.state.endDate), this.state.user, datoB, this.state.visible, this.state.filter, this.state.admin, this.state.unidadReport, this.state.cobrosElectronicos, this.state.cobrosCaja)
                this.hiddenButtonSpinner()
            });
        } else if (document.getElementById('radio2')?.checked) {
            this.loadReportRecibo(datoB);
        } else if (document.getElementById('radio4')?.checked) {
            this.loadReportConcepto(datoB);
        } else if (document.getElementById('radio5')?.checked) {
            this.loadReportAnulados(this.state.startDate, this.state.endDate, datoB);
        } else if (document.getElementById('radio6')?.checked) {
            this.loadReportKardex(datoB);
        } else if (document.getElementById('radio7')?.checked) {
            this.loadReportUnidad(datoB);
        } else if (document.getElementById('radio8')?.checked) {
            this.loadReportIntitucional(datoB);
        } else if (document.getElementById('radio9')?.checked) {
            this.loadReporteComision(datoB);
        } else if (document.getElementById('radio10')?.checked) {
            this.loadReporteAportes(datoB);
        } else if (document.getElementById('radio11')?.checked) {
            this.loadReportUtis(datoB);
        } else if (document.getElementById('radio12')?.checked) {
            this.loadReportTiposEstudiante(datoB); 
        } else if (document.getElementById('radio13')?.checked) {
            this.loadReporteCobroComision(datoB);
        } else if (document.getElementById('radio14')?.checked) {
              this.setButtonSpinner(datoB)
            this.setFilter()
            const {status, data} = await RecibosServices.reportResumenRecibos(filtros)
            if (status===200 && data.success){
                console.log(data)
                reportResumenRecibos.generarPdf(data.reporte, this.state.userselected, this.state.user, this.dateConvert(this.state.startDate), this.dateConvert(this.state.endDate), datoB, this.state.visible, this.state.filter, this.state.admin, this.state.unidadReport, this.state.cobrosElectronicos, this.state.cobrosCaja)
            }
            this.hiddenButtonSpinner()
        } else {
            this.setState({ reportMessage: 'Seleccionar tipo de reporte' });
            this.setState({ popUpVisible: true });
        }
    }

    async excelFile() {
        if (this.state.errorDate) {
            this.setState({ reportMessage: 'Rango de fechas ingresado no valido' })
            this.setState({ popUpVisible: true })
            return
        }

        if (document.getElementById('radio1')?.checked) {
            this.setState({ loadingExcel: true })
            await Services.getReportByUnitAccount(this.state.idUser, this.dateConvert(this.state.startDate), this.dateConvert(this.state.endDate), this.state.idRubro, this.state.idUnidad, this.state.idConcepto, this.state.idCuenta, this.state.idStructure).then((data) => {
                if (data.length > 0) {
                    !this.state.structureDiezmo ?
                        excelUnitAccount.excelReport(data, this.state.userselected, String(this.dateConvert(this.state.startDate)), String(this.dateConvert(this.state.endDate)), this.state.excelFileName)
                        : excelDiezmo.generateExcelFile(data, this.state.userselected, String(this.dateConvert(this.state.startDate)), String(this.dateConvert(this.state.endDate)), this.state.excelFileName)
                    this.hiddenButtonSpinner()
                } else {
                    this.hiddenButtonSpinner()
                    this.setState({ popUpVisible: true })
                }
            });
        }
        if (document.getElementById('radio2')?.checked) {
            this.setState({ loadingExcel: true })
            await Services.getReportRecibo(this.state.idUser, this.dateConvert(this.state.startDate), this.dateConvert(this.state.endDate), this.state.idRubro, this.state.idUnidad, this.state.idConcepto, this.state.idCuenta, this.state.idStructure).then((data) => {
                if (data.length > 0) {
                    excelReceipts.excelReport(data, this.state.userselected, String(this.dateConvert(this.state.startDate)), String(this.dateConvert(this.state.endDate)), this.state.excelFileName)
                    this.hiddenButtonSpinner()
                } else {
                    this.hiddenButtonSpinner()
                    this.setState({ popUpVisible: true })
                }
            })
        }

        if (document.getElementById('radio11')?.checked) {
            this.setState({ loadingExcel: true })
            await Services.getReportUtis(this.state.idUser, this.dateConvert(this.state.startDate), this.dateConvert(this.state.endDate), this.state.idRubro, this.state.idUnidad, this.state.idConcepto, this.state.idCuenta, this.state.idStructure).then((data) => {
                if (data.length > 0) {
                    excelUtis.excelReport(data, this.state.userselected, String(this.dateConvert(this.state.startDate)), String(this.dateConvert(this.state.endDate)), this.state.excelFileName)
                    this.hiddenButtonSpinner()
                } else {
                    this.hiddenButtonSpinner()
                    this.setState({ popUpVisible: true })
                }
            })
        }

        if (document.getElementById('radio12')?.checked) {
            this.setState({ loadingExcel: true })
            await Services.getReportTiposEstudiante(this.state.idUser, this.dateConvert(this.state.startDate), this.dateConvert(this.state.endDate), this.state.idRubro, this.state.idUnidad, this.state.idConcepto, this.state.idCuenta, this.state.idStructure).then((data) => {
                if (data.length > 0) {
                    excelTiposEstudiante.excelReport(data, this.state.userselected, String(this.dateConvert(this.state.startDate)), String(this.dateConvert(this.state.endDate)), this.state.excelFileName)
                    this.hiddenButtonSpinner()
                } else {
                    this.hiddenButtonSpinner()
                    this.setState({ popUpVisible: true })
                }
            })
        }

        if (document.getElementById('radio4')?.checked) {
            this.setState({ loadingExcel: true })
            await Services.getReportConcepto(this.state.idUser, this.dateConvert(this.state.startDate), this.dateConvert(this.state.endDate), this.state.idRubro, this.state.idUnidad, this.state.idConcepto, this.state.idCuenta, this.state.idStructure).then((data) => {
                if (data.length > 0) {
                    excelConcepto.excelReport(data, this.state.userselected, String(this.dateConvert(this.state.startDate)), String(this.dateConvert(this.state.endDate)), this.state.excelFileName)
                    this.hiddenButtonSpinner()
                } else {
                    this.hiddenButtonSpinner()
                    this.setState({ popUpVisible: true })
                }
            })
        }
        if (document.getElementById('radio5')?.checked) {
            this.loadReportAnuladosExcel()
        }
        if (document.getElementById('radio6')?.checked) {
            this.setState({ loadingExcel: true })
            Services.getReportKardex(this.state.startDate, this.state.endDate, this.state.idUser, this.state.idRubro, this.state.idUnidad, this.state.idConcepto, this.state.idCuenta, this.state.idStructure).then((data) => {
                if (data.length > 0) {
                    excelValores.excelReport(data, this.state.userselected, String(this.dateConvert(this.state.startDate)), String(this.dateConvert(this.state.endDate)), this.state.excelFileName)
                    this.hiddenButtonSpinner()
                }
                else {
                    this.hiddenButtonSpinner()
                    this.setState({ popUpVisible: true })
                }
            })
        }
        if (document.getElementById('radio7')?.checked) {
            this.setState({ loadingExcel: true })
            await Services.getReportUnit(this.state.idUser, this.dateConvert(this.state.startDate), this.dateConvert(this.state.endDate), this.state.idRubro, this.state.idUnidad, this.state.idConcepto, this.state.idCuenta, this.state.idStructure).then((data) => {
                if (data.length > 0) {
                    excelUnitConcept.excelReport(data, this.state.userselected, String(this.dateConvert(this.state.startDate)), String(this.dateConvert(this.state.endDate)), this.state.excelFileName)
                    this.hiddenButtonSpinner()
                } else {
                    this.hiddenButtonSpinner()
                    this.setState({ popUpVisible: true })
                }
            })
        }
    }

    resetFiltros = async () => {
        this.setState({
            idRubro: 0,
            rubro: 'Todos',
            idConcepto: 0,
            concepto: 'Todos',
            idCuenta: 0,
            cuenta: 'Todos',
            idUnidad: 0,
            unidad: 'Todos'
        });
        await this.loadSelectIni()
    }

    handleChange = e => {
        this.setState({ visible: e.target.checked });
        this.resetFiltros();
    }

    bloquearFiltros = () => {
        this.setState({
            indistintoFiltros: true,
            visible: false,
        });
        this.resetFiltros();
    }

    desbloquearFiltros = () => {
        this.setState({
            indistintoFiltros: false,
        })
    }

    bloquearTipo = () => {
        this.setState({
            indistintoTipo: true,
            cobrosCaja: false,
            cobrosElectronicos: false,
        })
    }

    desbloquearTipo = () => {
        this.setState({
            indistintoTipo: false
        })
    }

    closePopUp() {
        this.setState({ popUpVisible: false })
        this.setState({ reportMessage: 'Reporte vacío' })
    }

    render() {
        document.title = 'Caja UMSS - Reporte de movimiento'

        return (
            <>
                {this.state.spinner ? <LoadingSpiner /> : null}

                <div className='container shadow-sm p-3 bg-body rounded min-width-web-only' style={{ marginLeft: 'auto', marginRight: "auto", }} >
                    <div className="container2">
                        <div className='fieldset'>
                            <h1 className='legend'>Escoja el Tipo de Reporte</h1>
                            <div className="contTypeReport">
                                <div className='d-flex'>
                                    <div className="form-check">
                                        <input type="checkbox" className="form-check-input me-2" id="electronico" checked={this.state.cobrosElectronicos && !this.state.indistintoTipo} onChange={(e) => {
                                            this.setState({
                                                cobrosElectronicos: !this.state.cobrosElectronicos,
                                                cobrosCaja: false
                                            });
                                        }}
                                            disabled={this.state.indistintoTipo || this.state.dtic}
                                        />
                                        <label className="form-check-label" htmlFor="electronico">Reporte de Cobros Electrónicos</label>
                                    </div>
                                    <div className="form-check ms-2">
                                        <input type="checkbox" className="form-check-input me-2" id="caja" checked={this.state.cobrosCaja && !this.state.indistintoTipo} onChange={(e) => {
                                            this.setState({
                                                cobrosCaja: !this.state.cobrosCaja,
                                                cobrosElectronicos: false,
                                            });
                                        }}
                                            disabled={this.state.indistintoTipo || this.state.dtic}
                                        />
                                        <label className="form-check-label" htmlFor="caja">Reporte de Cobros en Caja Efectivo</label>
                                    </div>
                                </div>
                                <hr />
                                {this.state.dtic ?
                                    <div className="form-check">
                                        <input type="radio" className="form-check-input" id="radio13" name="radio" value="option13" defaultChecked />
                                        <label className="form-check-label" htmlFor="radio13">Informe Cobro Comision Bancaria</label>
                                    </div>
                                    :
                                    <>
                                        <div className="form-check">
                                            <input type="radio" className="form-check-input" id="radio1" name="radio" value="option1" defaultChecked
                                                onChange={(e) => {
                                                    this.desbloquearFiltros();
                                                    this.desbloquearTipo();
                                                }}
                                            />
                                            <label className="form-check-label" htmlFor="radio1">Informe Consolidado por Unidad y Cuenta</label>
                                        </div>
                                        <div className="form-check">
                                            <input type="radio" className="form-check-input" id="radio4" name="radio" value="option4"
                                                onChange={(e) => {
                                                    this.desbloquearFiltros();
                                                    this.desbloquearTipo();
                                                }}
                                            />
                                            <label className="form-check-label" htmlFor="radio4">Informe Consolidado por Concepto</label>
                                        </div>
                                        <div className="form-check">
                                            <input type="radio" className="form-check-input" id="radio7" name="radio" value="option5"
                                                onChange={(e) => {
                                                    this.desbloquearFiltros();
                                                    this.desbloquearTipo();
                                                }}
                                            />
                                            <label className="form-check-label" htmlFor="radio7">Informe Consolidado por Unidad</label>
                                        </div>
                                        {
                                            this.state.adminReport || this.state.admin ?
                                                <>
                                                    <div className="form-check">
                                                        <input type="radio" className="form-check-input" id="radio8" name="radio" value="option8"
                                                            onChange={(e) => {
                                                                this.bloquearFiltros();
                                                                this.desbloquearTipo();
                                                            }}
                                                        />
                                                        <label className="form-check-label" htmlFor="radio8">Informe Consolidado Institucional</label>
                                                    </div>
                                                    {/* <div className="form-check">
                                                        <input type="radio" className="form-check-input" id="radio9" name="radio" value="option9"
                                                            onChange={(e) => {
                                                                this.bloquearFiltros();
                                                                this.bloquearTipo();
                                                            }}
                                                        />
                                                        <label className="form-check-label" htmlFor="radio9">Informe Comision Bancaria</label>
                                                    </div> */}
                                                    <div className="form-check">
                                                        <input type="radio" className="form-check-input" id="radio13" name="radio" value="option13"
                                                            onChange={(e) => {
                                                                this.bloquearFiltros();
                                                                this.bloquearTipo();
                                                            }}
                                                        />
                                                        <label className="form-check-label" htmlFor="radio9">Informe Cobro Comision Banco</label>
                                                    </div>

                                                    <div className="form-check">
                                                        <input type="radio" className="form-check-input" id="radio10" name="radio" value="option10"
                                                            onChange={(e) => {
                                                                this.bloquearFiltros();
                                                                this.bloquearTipo();
                                                            }}
                                                        />
                                                        <label className="form-check-label" htmlFor="radio10">Informe Distribución Aportes</label>
                                                    </div>
                                                </> : null
                                        }
                                        <div className="form-check">
                                            <input type="radio" className="form-check-input" id="radio2" name="radio" value="option2"
                                                onChange={(e) => {
                                                    this.desbloquearFiltros();
                                                    this.desbloquearTipo();
                                                }}
                                            />
                                            <label className="form-check-label" htmlFor="radio2">Detalle de Recaudaciones según Recibos</label>
                                        </div>
                                        
                                        <div className="form-check">
                                            <input type="radio" className="form-check-input" id="radio11" name="radio" value="option11"
                                                onChange={(e) => {
                                                    this.desbloquearFiltros();
                                                    this.desbloquearTipo();
                                                }}
                                            />
                                            <label className="form-check-label" htmlFor="radio11">Detalle de Recaudaciones Utis</label>
                                        </div>

                                        <div className="form-check">
                                            <input type="radio" className="form-check-input" id="radio12" name="radio" value="option12"
                                                onChange={(e) => {
                                                    this.desbloquearFiltros();
                                                    this.desbloquearTipo();
                                                }}
                                            />
                                            <label className="form-check-label" htmlFor="radio12">Detalle de Recaudaciones Tipos de Estudiante</label>
                                        </div>


                                        <div className="form-check">
                                            <input type="radio" className="form-check-input" id="radio5" name="radio" value="option5"
                                                onChange={(e) => {
                                                    this.desbloquearFiltros();
                                                    this.desbloquearTipo();
                                                }}
                                            />
                                            <label className="form-check-label" htmlFor="radio5">Anulados</label>
                                        </div>
                                        <div className="form-check">
                                            <input type="radio" className="form-check-input" id="radio6" name="radio" value="option5"
                                                onChange={(e) => {
                                                    this.desbloquearFiltros();
                                                    this.bloquearTipo();
                                                }}
                                            />
                                            <label className="form-check-label" htmlFor="radio6">Valores</label>
                                        </div>
                                        <div className="form-check">
                                            <input type="radio" className="form-check-input" id="radio14" name="radio" value="option14"
                                                onChange={(e) => {
                                                    this.desbloquearFiltros();
                                                    this.desbloquearTipo();
                                                }}
                                            />
                                            <label className="form-check-label" htmlFor="radio14">Informe Resumen Recibos</label>
                                        </div>
                                    </>}
                            </div>
                        </div>
                    </div>
                    <center style={{ marginLeft: '-10%' }}>
                        <table className='tableInputs'>
                            <tbody>
                                {(this.state.admin || this.state.adminReport) ?

                                    <tr>
                                        <td className='sizetd4'>Dirección Administrativa: </td>


                                        <td className='sizetd1' colSpan="3">
                                            <select className="form-select form-select-sm" onChange={(e) => this.setIdStructure(e.target.value, e.target.options[e.target.selectedIndex].index, e.target.options[e.target.selectedIndex].text)}>
                                                <option name='false' value={0}>Todos</option>
                                                {this.state.structures.map((structure) => {
                                                    return (
                                                        <option key={structure.id} value={structure.id} name={structure.diezmo.toString()}>
                                                            {`${structure.codigo} ${structure.descripcion}`}
                                                        </option>
                                                    );
                                                })}
                                            </select>
                                        </td>
                                    </tr>
                                    :
                                    <tr>
                                        <td className='sizetd4'>Dirección Administrativa: </td>
                                        <td className='sizetd1' colSpan="3"><input className='form-control form-control-sm' value={this.state.structureName} disabled></input></td>
                                    </tr>
                                }

                                <tr>
                                    <td className='sizetd4'>Usuario: </td>
                                    {(this.state.admin || this.state.jefeCajero || this.state.adminReport || this.state.report) ?

                                        <td className='sizetd1' colSpan="3">
                                            <select className="form-select form-select-sm" value={this.state.idUser} onChange={(e) => this.setIdUser(e.target.value, e.target.options[e.target.selectedIndex].text)} >
                                                <option value={0}>Todos</option>
                                                {this.state.usuarios.map((usuario) => {
                                                    return (
                                                        <option key={usuario.id} value={usuario.id}>
                                                            {`${usuario.apellidos} ${usuario.nombres}`}
                                                        </option>
                                                    );
                                                })}
                                            </select>
                                        </td>
                                        :
                                        <td className='sizetd1' colSpan="3"><input className='form-control form-control-sm' value={this.state.user} disabled></input></td>
                                    }
                                </tr>
                                <tr>
                                    <td className='sizetd4'>Fecha del </td>
                                    <td className='sizetd2'>
                                        <input type="date" onChange={this.setStartDate} value={(this.state.startDate)} className='form-control form-control-sm'></input>
                                    </td>
                                    <td className='sizetd2'>Fecha al </td>
                                    <td className='sizetd2'>
                                        <input type="date" onChange={this.setEndDate} value={this.state.endDate} className='form-control form-control-sm'></input>
                                    </td>
                                </tr>
                                {
                                    this.state.errorDate ?
                                        <tr>
                                            <td className='sizetd2'>
                                                <p style={{ color: "red", fontSize: "12px" }}> </p>
                                            </td>
                                            <td className='sizetd2'>
                                                <p style={{ color: "red", fontSize: "12px" }}>Fecha invalida</p>
                                            </td>
                                            <td className='sizetd2'>
                                                <p style={{ color: "red", fontSize: "12px" }}> </p>
                                            </td>
                                            <td className='sizetd2'>
                                                <p style={{ color: "red", fontSize: "12px" }}>Fecha invalida</p>
                                            </td>
                                        </tr>
                                        : null
                                }
                            </tbody>
                        </table>

                        {
                            this.state.visible && !this.state.dtic ? <table className='tableInputs'>
                                <tbody>
                                    <tr>
                                        <td className='sizetd3'>Rubro: </td>
                                        <td className='sizetd1' colSpan="2">
                                            <select className="form-select form-select-sm" onChange={(e) => this.setListConcepto(e.target.value, e.target.options[e.target.selectedIndex].text)}>
                                                <option value={0}>Todos</option>
                                                {this.state.items.map((rubro) => {
                                                    return (
                                                        <option key={rubro.id} value={rubro.id}>
                                                            {`${rubro.descripcion} - ${rubro.codigo}`}
                                                        </option>
                                                    );
                                                })}
                                            </select>
                                        </td>

                                    </tr>
                                    <tr>
                                        <td className='sizetd3'>Unidad: </td>
                                        <td className='sizetd1' colSpan="2">
                                            <select className="form-select form-select-sm" onChange={(e) => this.setStateValues("idUnidad", e.target.value, e.target.options[e.target.selectedIndex].text)}>
                                                <option value={0}>Todos</option>
                                                {this.state.units.map((unidad) => {
                                                    return (
                                                        <option key={unidad.id} value={unidad.id}>
                                                            {`${unidad.descripcion} - ${unidad.codigo}`}
                                                        </option>
                                                    );
                                                })}
                                            </select>
                                        </td>

                                    </tr>
                                    <tr>
                                        <td className='sizetd3'>Concepto: </td>
                                        <td className='sizetd1' colSpan="2">
                                            <select className="form-select form-select-sm" value={this.state.idConcepto} onChange={(e) => this.setListCuenta(e.target.value, e.target.options[e.target.selectedIndex].text)}>
                                                <option value={0}>Todos</option>
                                                {this.state.concept.map((concepto) => {
                                                    return (
                                                        <option key={concepto.id} value={concepto.id}>
                                                            {`${concepto.descripcion} - ${concepto.codigo}`}
                                                        </option>
                                                    );
                                                })}
                                            </select>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className='sizetd3'>Cuenta: </td>
                                        <td className='sizetd1' colSpan="2">
                                            <select className="form-select form-select-sm" value={this.state.idCuenta} onChange={(e) => this.setStateValues("idCuenta", e.target.value, e.target.options[e.target.selectedIndex].text)}>
                                                <option value={0}>Todos</option>
                                                {this.state.accounts.map((cuenta) => {
                                                    return (
                                                        <option key={cuenta.id} value={cuenta.id}>
                                                            {`${cuenta.nombre} - ${cuenta.codigo}`}
                                                        </option>
                                                    );
                                                })}
                                            </select>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className='sizetd3'>Nombre del Archivo: </td>
                                        <td className='sizetd2'><input className='form-control form-control-sm' onChange={this.setExcelFileName} value={this.state.excelFileName}></input></td>
                                        <td className='sizetd2' style={{ paddingLeft: '1%', textAlign: 'left' }}>Dirección por defecto C:\Descargas\fechaActual_Report.xlsx</td>
                                    </tr>
                                </tbody>
                            </table>
                                : null
                        }
                    </center>

                    <div className='contentCheck'>

                        <div className="form-check check">
                            <input className="form-check-input check"
                                name='check'
                                checked={this.state.visible && !this.state.indistintoFiltros}
                                onChange={this.handleChange}
                                type="checkbox"
                                value={this.state.visible}
                                id="flexCheckDefault"
                                disabled={this.state.indistintoFiltros || this.state.dtic}
                            />
                            <label className="form-check-label" htmlFor='flexCheckDefault'>
                                Filtros de Reporte Avanzado
                            </label>
                        </div>
                    </div>
                    <div className='header'>
                        <table className='table table-responsive table-borderless mb-0'>
                            <tbody>
                                <tr>
                                    <td className='buttons'>
                                        <button type="button" className="btn btn-primary bt2"
                                            disabled={this.state.loading}
                                            onClick={() => this.imprimir(false)}>
                                            {this.state.loading && (
                                                <span className="spinner-border spinner-border-sm"></span>
                                            )} <span>IMPRIMIR</span>
                                        </button>
                                        {
                                            this.state.visible ?
                                                <button type="button" className="btn btn-success bt2"
                                                    disabled={this.state.loadingExcel}
                                                    onClick={this.excelFile}>
                                                    {this.state.loadingExcel && (
                                                        <span className="spinner-border spinner-border-sm"></span>
                                                    )} <span>EXPORTAR A EXCEL</span>
                                                </button>
                                                : null
                                        }
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <PopUpMessage
                        visible={this.state.popUpVisible}
                        message={this.state.reportMessage}
                        close={this.closePopUp}
                    />
                </div>
            </>
        )
    }
}
