import ExcelFile from "./ExcelFile";
export default class ExcelValores{
    totalReport = 0.00
    list = [] 
    excelReport(details, userName, initDate, endDate, fileName){
        const report = new ExcelFile
        this.totalReport = 0.00
        this.listItems(details)
        const columnWidth = [ 
            { width: 20, alignmen: 'left' }, 
            { width: 40 },
            { width: 20 },
            { width: 20 }, 
            { width: 20 }, 
            { width: 20, alignment: 'right', style: { numFmt: '#,##0.00_);(#,##0.00)' }},   
            { width: 20, alignment: 'right', style: { numFmt: '#,##0.00_);(#,##0.00)' }}, 
            { width: 20, alignment: 'right', style: { numFmt: '#,##0.00_);(#,##0.00)' }}
        ]
        const columnHeader = [
            { name: "codigo" },
            { name: "concepto" },
            { name: "Saldo Anterior" },
            { name: "Ingresos" },
            { name: "Egresos" },
            { name: "Precio unitario" },
            { name: "Total" },
            { name:'Saldo Actual'},
          
        ]
        
        report.generateExcelFile(
            details,
            "Kardex_de_valores",
            "Kardex de Valores",
            userName,
            initDate,
            endDate,
            columnWidth,
            "H1",
            columnHeader,
            this.list,
            this.totalReport,
            "Total Valores",
            false,
            'F',
            'G',
            fileName
        )
    }
    listItems(items){
        this.list = []
        var list2 = []     
          items.map((item)=>{
            this.totalReport +=item.total
            list2.push(
                item.codigo,
                item.concepto,
                item.saldoAnterior,
                item.ingresos,
                item.egresos,
                item.precioUnitario,
                item.total,
                item.SaldoActual
                )
               this.list.push(list2)
               list2 = []

          })  
         
        
      
    }

}