import { classNames, propFromName } from '../utils'
export function PickListOption({ option, optionLabel, optionTemplate, selected, onToggleSelect }) {
    const handleSelect = () => {
        onToggleSelect(option, selected)
    }
    return (
        <div className={classNames(['pls-option', selected && 'active'])} onClick={handleSelect}>
            {optionTemplate
                ? optionTemplate(option)
                : typeof option === 'object'
                  ? propFromName(option, optionLabel)
                  : option}
        </div>
    )
}
