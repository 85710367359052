import { useState } from "react";
import FacturaServices from "../../services/factura.service";
import PopUpMessage from "../PopUpMessage";


function CobrosElectronicos() {
    
    const [ci, setCi] = useState("")

    const [deudas, setDeudas] = useState([])

    const [sinDeudas, setSinDeudas] = useState(false)

    const [visible, setVisible] = useState(false)

    const [mensaje, setMensaje] = useState("")
    
    const handleChange= (e)=>{
        setCi(e.target.value)
        sinDeudas && setSinDeudas(false);
        deudas.length > 0 && setDeudas([]);
    } ;

    const handleKeyUp= (e)=>{
        if(e.key === "Enter"){
            buscarDeuda();
        }
    };        

    const buscarDeuda = async()=>{
        const response= await FacturaServices.listarDeudasFacturas(ci);
        if(response.data.isOk){
            if(response.data.result.length === 0){
                setSinDeudas(true);
            }
            setDeudas(response.data.result);
        }else{
            setMensaje("Ocurrio un error al buscar las Deudas")
            setVisible(true)
        }
            
    };

    const facturar= async(id)=>{
        const response= await FacturaServices.facturar(id);
        if(response.data.isOk){
            //console.log(response.data)
            if(response.data.respuesta_facturacion){
                if (response.data.respuesta_facturacion.mensaje ==='VALIDADA'){
                        const url= response.data.respuesta_facturacion.ver_factura;
                        window.open(url, '_blank');
                }else{
                    setMensaje(response.data.message)
                    setVisible(true)
                }    
            }else{
                setMensaje("No encuentra la deuda......")
                setVisible(true)
            }
            
            if (deudas.length === 1){
                setDeudas([])
                setCi("")
            }else{
                setDeudas(deudas.filter((deuda)=>deuda.id !== id)) //quitar la deuda pagada de la tabla
            }
        }else{
            setMensaje(response.data.message)
            setVisible(true)
        }

    }

    return <>
        <div className='header clearfix'>
            <label className='title float-start'>COBROS ELECTRONICOS-FACTURAS</label>
        </div>
        <table className='table table-responsive table-borderless'>
            <tbody>
                <tr>
                    <th>Documento: </th>
                    <td className="w-100">
                        <input 
                            type='text' 
                            value={ci} 
                            onChange={handleChange} 
                            onKeyUp={handleKeyUp}
                            className='form-control form-control-sm' 
                            required 
                        />
                    </td>

            </tr>    
            </tbody>
        </table>
        <div className='PayLine table-responsive'>
            {deudas.length > 0 ?
                
            <table className='table table-borderless table-hover' style={{ textAlign: 'center' }} >
                <thead>
                    <tr>
                        <th style={{backgroundColor:'#fff'}}>Cuenta</th>
                        <th style={{backgroundColor:'#fff'}} colSpan='2'>Persona</th>
                        <th style={{backgroundColor:'#fff'}} colSpan='3'>Varios</th>
                    </tr>
                    <tr>
                        <th>Nombre</th>
                        <th>Documento</th>
                        <th>Apellidos y Nombres</th>
                        <th>Periodo</th>
                        <th>Precio Total Bs.</th>
                        <th>Pagar</th>
                    </tr>
                </thead>
                <tbody>
                    {deudas.map((deuda, index) => {
                      return (
                        <tr
                          key={index}
                        >
                            <td>{deuda.cuenta_nombre}</td>
                            <td>{deuda.persona_ci}</td>
                            <td>{deuda.persona_nombres}</td>
                            <td>{deuda.periodo}</td>
                            <td>{parseFloat(deuda.precio).toFixed(2)}</td>                            
                            <td><button type='button' 
                                className='btn btn-light' 
                                onClick={(e) => facturar(deuda.id)}><i className='fa fa-money fa-lg'></i>
                                </button>
                            </td>
                        </tr>

                      )
                    }) 
                    }
                </tbody>
            </table>
            :   (sinDeudas && <div className='header mb-2' style={{ textAlign: 'center' }}>No existen resultados</div>)
                
            }
        </div>

        <PopUpMessage
            close={()=>setVisible(false)}
            visible={visible}
            message={mensaje}
        />

    </>;

} 

export default CobrosElectronicos;