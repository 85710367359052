import { forwardRef } from 'react'
import { classNames } from '../utils'
import { BaseButton } from '../base/BaseButton'
export const ButtonIcon = forwardRef(({ children, loading, disabled, className, ...props }, ref) => {
    return (
        <BaseButton
            type='button'
            {...props}
            ref={ref}
            className={classNames('modular-button-icon', className)}
            disabled={loading ?? disabled}
        >
            <div className='mod-button-icon-content'>
                {loading ? <span className='button-icon-spinner' /> : children}
            </div>
        </BaseButton>
    )
})
ButtonIcon.displayName = 'ButtonIcon'
