import saveAs from "file-saver"
const Excel = require('exceljs')

export default class ExcelFile {
    //datos, titulo de la hoja, titulo del reporte, nombre de usuario, fecha de inicio, fecha fin, ancho columnas, celda final(D1 F3),
    //nombre de columnas, filas detalle, valor total, titulo total, rubro (true or false), columna title total, columna valor total
    generateExcelFile(details, titleSheet, titleReport, userName, initDate, endDate, columnWidth, cellEnd, columnHeader, rowsDetail, totalReport, totalTitle, rubro, colmnTitleTotal, colmnValueTotal,listSum, fileName) {
        const date = new Date()
        var count=0;
        const outputDate = date.getFullYear().toString() + String(date.getMonth() + 1).padStart(2, '0') + String(date.getDate()).padStart(2, '0')
        const outputTime = `${date.getHours()}${date.getMinutes()}${date.getSeconds()}`

        const workbook = new Excel.Workbook();
        const worksheet = workbook.addWorksheet(titleSheet, { views: { showGridlines: false } });

        worksheet.columns = columnWidth

        worksheet.mergeCells(`A1:${cellEnd}`)
        worksheet.getCell("A1").value = titleReport
        worksheet.getCell("A1").alignment = { horizontal: "center", vertical: "middle" }
        worksheet.getCell("A1").font = {
            bold: true,
            size: 14
        }
        worksheet.getRow(1).height = 25

        worksheet.getCell("A2").value = "Usuario"
        worksheet.getCell("B2").value = userName

        worksheet.getCell("A3").value = "Fecha"
        worksheet.getCell("B3").value = `Del ${initDate} al ${endDate}`

        var indexCell = 5

        if (rubro) {
            for (var i = 0; i < details.length; i++) {
               
                //codigo y rubro
                worksheet.getCell(`A${indexCell}`).value = details[i].codigo
                worksheet.getCell(`A${indexCell}`).font = {
                    bold: true,
                    size: 14
                }
                worksheet.getCell(`B${indexCell}`).value = details[i].rubro
                worksheet.getCell(`B${indexCell}`).font = {
                    bold: true,
                    size: 14
                }
                
                ++indexCell
                //tabla items
                var cuenta=new Array(details[i].cuenta);
                //for(var j=0;j<details[i].cuenta.length;j++){
                for(var j=0;j<cuenta.length;j++){ 
                  
                   // const tableName = details[i].rubro.replace(/ /g, "")
                  // worksheet.getCell(`A${indexCell}`).value = details[i].cuenta[j].codigo
                   worksheet.getCell(`A${indexCell}`).value = cuenta.codigo
                   worksheet.getCell(`A${indexCell}`).font = {
                       bold: true,
                       size: 14
                   }
                   worksheet.getCell(`B${indexCell}`).value =cuenta.asunto
                   worksheet.getCell(`B${indexCell}`).font = {
                       bold: true,
                       size: 14
                   }
                   ++indexCell
                 
                    const auxTable = {
                        
                        name: `Tabla${i}${j}${count}`,
                        ref: `A${indexCell}`,
                        headerRow: true,
                        totalsRow: true,
                        style: {
    
                        },
                        columns: columnHeader,
                        rows: rowsDetail[count],
                       
                    }
                    
                    indexCell += (  cuenta.length + 4)
                  // indexCell += (rowsDetail[j].length + 4) 
                    worksheet.addTable(auxTable)
                    count=count+1;
                   
                 
                }
              
                ++indexCell
                worksheet.getCell(`A${(indexCell - 2)}`).value = `${cuenta.length} Recibos total de la cuenta`
                worksheet.getCell(`A${(indexCell - 2)}`).font = {
                    bold: true,
                }
                worksheet.getCell(`F${(indexCell - 2)}`).font = {
                    bold: true,
                }

                // worksheet.getCell(`A${(indexCell-2)}`).border = {
                //     top: { style: "medium" },
                //     left: { style: "medium" },
                //     bottom: { style: "medium" }
                // }
                // worksheet.getCell(`B${(indexCell)}`).border = {
                //     top: { style: "medium" },
                //     bottom: { style: "medium" }
                // }
                // worksheet.getCell(`C${(indexCell)}`).border = {
                //     top: { style: "medium" },
                //     bottom: { style: "medium" }
                // }
                // worksheet.getCell(`D${(indexCell)}`).border = {
                //     top: { style: "medium" },
                //     bottom: { style: "medium" }
                // }
                // worksheet.getCell(`E${(indexCell)}`).border = {
                //     top: { style: "medium" },
                //     bottom: { style: "medium" }
                // }
                // worksheet.getCell(`F${(indexCell)}`).border = {
                //     top: { style: "medium" },
                //     right: { style: "medium" },
                //     bottom: { style: "medium" }
                // }
                //++indexCell
                worksheet.getCell(`A${indexCell - 1}`).value = `${cuenta.length}  Movimiento del rubro`
                worksheet.getCell(`A${indexCell - 1}`).font = {
                    bold: true,
                    size: 11
                }
                worksheet.getCell(`F${indexCell - 1}`).value = listSum[i]
                worksheet.getCell(`F${indexCell - 1}`).font = {
                    bold: true,
                    size: 11
                }
                ++indexCell
            }
        } else {
            const auxTable = {
                name: `table${i}`,
                ref: `A${indexCell}`,
                headerRow: true,
                totalsRow: true,
                style: {

                },
                columns: columnHeader,
                rows: rowsDetail,
            }
            indexCell += (details.length + 4)

            worksheet.addTable(auxTable)
        }



        worksheet.getCell(`${colmnTitleTotal}${indexCell}`).value = `${count} ${totalTitle}`
        worksheet.getCell(`${colmnTitleTotal}${indexCell}`).font = {
            bold: true,
            size: 14
        }
        worksheet.getCell(`${colmnTitleTotal}${indexCell}`).border = {
            top: { style: "medium" },
            left: { style: "medium" },
            bottom: { style: "medium" }
        }
        worksheet.getCell(`${colmnTitleTotal}${indexCell}`).alignment = { horizontal: "left", vertical: "middle" }

        worksheet.getCell(`${colmnValueTotal}${indexCell}`).value = totalReport
        worksheet.getCell(`${colmnValueTotal}${indexCell}`).font = {
            bold: true,
            size: 14
        }
        worksheet.getCell(`${colmnValueTotal}${indexCell}`).border = {
            top: { style: "medium" },
            right: { style: "medium" },
            bottom: { style: "medium" }
        }
        worksheet.getCell(`${colmnValueTotal}${indexCell}`).alignment = { horizontal: "right", vertical: "middle" }

        worksheet.getCell(`D${indexCell}`).border = {
            top: { style: "medium" },
            bottom: { style: "medium" },
        }

        worksheet.getCell(`E${indexCell}`).border = {
            top: { style: "medium" },
            bottom: { style: "medium" },
        }

        worksheet.getRow(indexCell).height = 25

        workbook.xlsx.writeBuffer().then(function (buffer) {
            if(fileName === ''){
                saveAs(
                    new Blob([buffer], { type: "application/octet-stream" }),
                    `${outputDate}${outputTime}_Report.xlsx`
                );
            }else{
                saveAs(
                    new Blob([buffer], { type: "application/octet-stream" }),
                    `${fileName}.xlsx`
                );
            }
        });
    }

}