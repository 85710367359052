import store from 'store'

/**
 * Verifica si el usuario actual tiene todos los roles requeridos.
 * @param {string | Array<string>} required Roles requeridos.
 * @param {'AND' | 'OR'} operator Operador de comprobación de roles. Solo los roles requeridos esta en un arreglo.
 * @returns Retorna true si el usuario tiene los roles requeridos.
 */
export const verifyAccess = (required, operator = 'AND') => {
    const roles = store.getState()?.auth?.roles ?? []
    let ok = false
    if (!required) {
        ok = true
    } else if (typeof required === 'string') {
        ok = roles.includes(required)
    } else if (Array.isArray(required)) {
        const _roles = roles.filter((role) => required.includes(role))
        if (operator === 'OR') {
            ok = _roles.length > 0
        } else {
            ok = _roles.length === required.length
        }
    }
    return ok
}
