//valida que un campo sea obligatorio
//necesita el valor del campo en cuestion
export const required = (value) => {
    //verifica si NO se ha ingresado valores al campo
    if (!value.trim()) {
        return (
        <div className="alert alert-danger p-1 mb-0" role="alert">
            Este campo es requerido!
        </div>
        )
    }
}

//valida que un campo cumpla con las condiciones de un correo valido
//necesita el valor del campo en cuestion
export const validatorEmail = (value) => {
    //expresion regular que valida que el correo sea valido y pertenezca al dominio umss.edu
    let exp = /^[-\w.%+]{1,64}@(?:umss\.)edu+$/
    //verifica si el valor proporcionado NO cumple con las condiciones de la expresion regular
    if (!exp.test(value)) {
        return (
            <div className="alert alert-danger p-1 mb-0" role="alert">
                Este correo no es valido
            </div>
        )
    //verifica si el valor proporcionado se pasa del limite de 50 caracteres    
    }else if(value.length > 50){
        return (
            <div className="alert alert-danger p-1 mb-0" role="alert">
                Maximo 50 caracteres
            </div>
        )
    }
}

//valida que un campo cumpla con las condiciones de una contrasenia
//necesita el valor del campo en cuestion
export const validatorPassword = (value) => {
    //verifica si el valor proporcionado no cumple con el limite establecido
    if(value.length < 8 || value.length > 20){
        return (
            <div className="alert alert-danger p-1 mb-0" role="alert">
                Minimo 8 y maximo 20 caracteres
            </div>
        )
    //verifica si el valor proporcionado NO cumple con las condiciones de la expresion regular
    }else if(!value.match(/^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{3,}$/)){
        return (
            <div className="alert alert-danger p-1 mb-0" role="alert">
                Minimo 1 mayuscula, 1 minuscula, 1 numero y 1 caracter especial
            </div>
        )
    }
}

//valida que un campo cumpla con las condiciones de un nombre de usuario
//necesita el valor del campo en cuestion
export const validatorUsername = (value) => {
    //verifica si el valor proporcionado no cumple con el limite establecido
    if(value.length < 5 || value.length > 50){
        return (
            <div className="alert alert-danger p-1 mb-0" role="alert">
              Minimo 5 y maximo 50 caracteres
            </div>
        )
    }
    // else if(!value.match(/^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{3,}$/)){
    //     return (
    //         <div className="alert alert-danger p-1 mb-0" role="alert">
    //           Minimo 1 mayuscula, 1 minuscula, 1 numero y 1 caracter especial
    //         </div>
    //     )
    // }
}