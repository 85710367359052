import services from '../services/report.service';
import { useEffect, useState } from 'react';
import { Modal } from "react-bootstrap";
import "../Styles/tableStyles.css"
import "../Styles/ReceiptTable.css"

const LastMovement = () => {
    const [movements, setMovements] = useState([]);
    const [modalOpen, setModalOpen] = useState(false);
    const [movementId, setMovementId] = useState();
    const [accountId, setAccountId] = useState();
    const [amount, setAmount] = useState();
    const [reloadFlag, setReloadFlag] = useState(false);

    async function getMovements() {
        let res = await services.getValuedList();
        setMovements(res);
    }

    function deleteMovement() {        
        services.deleteMovement(movementId, amount, accountId)
        .then(() => {
            services.saveIngress(amount, accountId)
            .then(()=>{
                alert("Movimiento eliminado con éxito.");
                setReloadFlag(!reloadFlag);
            }).catch((err) => {
                console.log(err);
                alert("Ocurrió un error, no se pudieron reestablecer los valores");
            });             
        }).catch((err) => {            
            console.log(err);
            alert("Ocurrió un error, no se pudo eliminar el movimiento.");
        });
    }

    function handleClick(movementToDelete, accountInMovement, amountToAddBack){
        setModalOpen(true); 
        setMovementId(movementToDelete);
        setAccountId(accountInMovement);
        setAmount(amountToAddBack);
    }

    useEffect(() => {
        getMovements();
    }, [reloadFlag]);    

  return (
    <div className="container shadow-sm p-3 bg-body rounded text-center mx-auto">
        <table className='tablaRecibos' style={{position: 'static', width: '100%'}}>
            <thead className='table-secondary'>
                <tr>
                    <th>Fecha de pago</th>
                    <th>Cuenta</th>
                    <th>Precio Unitario Bs.</th>
                    <th>Cantidad</th>
                    <th>Total Bs.</th>
                    <th>Usuario</th>
                    <th>Acciones</th>
                </tr>
            </thead>
            <tbody style={{textAlign:'center'}}>
                {movements.length > 0 ? movements.map((movement, index)=>{
                    return(
                        <tr key={index}>
                            <td><div>{movement.fecha_pago}</div></td>
                            <td><div>{movement.nombre}</div></td>
                            <td><div>{movement.precio}</div></td>
                            <td><div>{movement.cantidad}</div></td>
                            <td><div>{movement.total}</div></td>
                            <td><div>{movement.username}</div></td>
                            <td>
                                <div>
                                    <button className='btn btn-primary bt2' onClick={()=>handleClick(movement.movimiento_id, movement.cuenta_id, movement.cantidad)}>Eliminar</button>
                                </div>
                            </td>
                        </tr>
                    );
                }) : <h1>No hay movimientos que se puedan eliminar.</h1>}
            </tbody>
        </table>
        <Modal animation={false} show={modalOpen} onHide={()=>setModalOpen(false)} size="xl" aria-labelledby="contained-modal-title-vcenter" centered>
          <Modal.Header closeButton>
            <Modal.Title>Alerta</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <p>Esta acción es irreversible, ¿Está seguro de que desea continuar?</p>
            <div style={{display:'grid', gridAutoFlow: 'column', gap: '5px'}}>
                <button onClick={()=>{deleteMovement(); setModalOpen(false);}} className='btn btn-danger bt2'>Borrar</button> 
                <button onClick={()=>setModalOpen(false)} className='btn btn-primary bt2'>Cancelar</button>
            </div>        
          </Modal.Body>
        </Modal>     
    </div>
  )
}

export default LastMovement