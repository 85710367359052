/* Recibos Anulados */

import pdfMake from 'pdfmake/build/pdfmake'
import pdfFonts from 'pdfmake/build/vfs_fonts'
import printJS from 'print-js';

import RobotoFont from '../fonts/Roboto'
import RobotoBoldFont from '../fonts/RobotoBold'

import reportConf from './components/reportConf'
import reportHeader from './components/reportHeader'
import reportFooter from './components/reportFooter'
import cuadroTotal from './components/cuadroTotal'

export default class ReportAnulados {

    fillAccount(cuenta) {
        var totalCuenta = 0;
        var arr = [];
        cuenta.map((itemR) => {
            totalCuenta = parseFloat(itemR.total) + totalCuenta;
            arr.push([
                { text: itemR.fecha },
                itemR.usuario, itemR.descripcion,
                {text:itemR.noRecibo, alignment: 'right'},
                {text:itemR.preImpreso, alignment: 'right'},
                {text:new Intl.NumberFormat('en-US', { minimumFractionDigits: 2 }).format(itemR.total), alignment:'right'}
            ]);
            return null;
        });

        var totalCuentaTable = new Intl.NumberFormat('en-US', { minimumFractionDigits: 2 }).format(totalCuenta)

        arr.push([
            { text: cuenta.length, bold: true, alignment: 'center' },
            { text: `${cuenta.length>1?'Recibos anulados':'Recibo anulado'} Cuenta`, bold: true, colSpan:4  }, {}, {}, {},
            { text: totalCuentaTable, bold: true, alignment:'right' }
        ]);

        var list = [arr, totalCuenta, cuenta.length];
        return list;
    }

    async generatePDF(receipt, userName, startDate, endDate, processedBy, isPrint, isFilter, filter, isAdmin, userUnidad, electronico, caja) {
        pdfMake.vfs = {
            ...pdfFonts.pdfMake.vfs,
            'Roboto-Regular.ttf': RobotoFont,
            'Roboto-Bold.ttf': RobotoBoldFont
        };

        pdfMake.fonts = {
            Roboto: {
                normal: 'Roboto-Regular.ttf',
                bold: 'Roboto-Bold.ttf'
            }
        };

        const headerPage = (currentPage, pageCount, pageSize) => {
            return reportHeader(
                currentPage,
                pageCount,
                'Recibos Anulados',
                userUnidad,
                userName,
                startDate,
                endDate,
                electronico,
                caja,
            );
        }

        const content = [
            [
                {
                    fontSize: reportConf.fontSizeFilters,
                    margin: [0, 0, 0, 5],

                    table: {
                        body: [
                            ["", "", ""],
                            ...this.selectionFilter(isFilter, filter, isAdmin)

                        ],
                    },
                    layout: "noBorders",
                },
            ]
        ];
        var movTotalRubro = 0;
        var movreport = 0;

        receipt.forEach((item) => {
            content.push({
                fontSize: reportConf.fontSizeTableHeader,
                bold: true,
                table: {
                    widths: [40, 80, 100, 100, 100, 45,],
                    body: [
                        [
                            { text: item.codigo },
                            { text: item.rubro, colSpan: 4, alignment: 'left' }, {}, {}, {}, {}
                        ],
                    ]
                },
                layout: {
                    hLineWidth: function (i) {
                        return i === 1 ? 0.75 : 0;
                    },
                    vLineWidth: function (i) {
                        return 0;
                    },
                },
            })

            item.cuenta.forEach((itemC) => {
                const recibo = this.fillAccount(itemC.recibo);
                movTotalRubro = recibo[1] + movTotalRubro;
                content.push({
                    fontSize: reportConf.fontSizeTable,
                    bold: true,
                    table: {
                        headerRows: 1,
                        widths: [80, 'auto'],
                        body: [[
                            { text: itemC.codigo },
                            { text: itemC.asunto, alignment: 'left' },
                        ]]
                    },
                    layout: 'noBorders',
                });
                content.push(
                    {
                        fontSize: reportConf.fontSizeTable,
                        table: {
                            headerRows: 1,
                            widths: [40, 65, 165, 60, 75, 60],
                            body: [
                                [
                                    { text: 'Fecha', style: 'tableHeader'},
                                    { text: 'User-cajero', style: 'tableHeader' },
                                    { text: 'Persona', style: 'tableHeader' },
                                    { text: 'No.Recibo', style: 'tableHeader', alignment:'right' },
                                    { text: 'Pre-impreso', style: 'tableHeader', alignment:'right' },
                                    { text: 'Total', style: 'tableHeader',alignment:'right' }
                                ],
                                ...recibo[0],
                            ]
                        },
                        layout: {
                            hLineWidth: function (i, node) {
                                return (i === 1 || i === node.table.body.length-1) ? 0.75 : 0;
                            },
                            vLineWidth: function (i, node) {
                                return 0;
                            },
                        },
                    });
            })

            content.push({text: '', margin: [0, 0, 0, reportConf.spaceBetweenTable]});

            movreport = movTotalRubro + movreport;
            movTotalRubro = 0;
        });

        const totalLabel = [];
        totalLabel.push(cuadroTotal(movreport, 'Importe total de recibos anulados'));


        const footerPage = reportFooter(processedBy);

        const docDefinitions = {
            pageSize: 'letter',
            pageMargins: reportConf.pageMargins,
            header: headerPage,
            content: [content, totalLabel],
            footer: footerPage,
            defaultStyle: {
                font: 'Roboto'
            },
            styles: {
                tableHeader: {
                    bold: true,
                    color: 'black'
                },
            }

        }

        if (isPrint) {
            var document = pdfMake.createPdf(docDefinitions)
            var docBlob = null;

            document.getBase64(function (result) {
                docBlob = result;
                printJS({
                    printable: docBlob,
                    type: "pdf",
                    base64: true
                });
            });
        } else {
            pdfMake.createPdf(docDefinitions).open()
        }
    }

    selectionFilter(isFilter, filter, isAdmin) {
        if(isFilter){
            if(isAdmin){
                return [

                    ['Estructura', ":", filter.estructura],
                    ['Rubro', ":", filter.rubro],
                    ['Unidad', ":", filter.unidad],
                    ['Concepto', ":", filter.concepto],
                    ['Cuenta', ":", filter.cuenta],

                ];
            }else{
                return [

                    ['Rubro', ":", filter.rubro],
                    ['Unidad', ":", filter.unidad],
                    ['Concepto', ":", filter.concepto],
                    ['Cuenta', ":", filter.cuenta],
                ];

            }

        }else{
            return [];
        }

    }

}
