/*
tamaño de margenes de la pagina,
no afecta al header ni al footer,
se debe considerar el alto del header o el footer
para establecer el valor de los margenes de top y bottom,
los margenes siguen el siguiente
orden [left, top, right, bottom]
*/
const marginLeft = 63 
const marginTop = 160
const marginRight = 38
const marginBottom = 70
const pageMargins = [marginLeft, marginTop, marginRight, marginBottom]

// ------------------- Cabecera de pagina --------------------------
/*
tamaño de letra de la cabecera, nombre de la UMSS, nombre de caja,
número de pagina, fecha y hora de reporte
*/
const fontSizeHeader = 9;
// tamaño de letra del titulo
const fontSizeTitle = 14;
//tamaño de letra de "(Expresado en Bolivianos)"
const fontSizeTitleFooter = 9;
// tamaño de letra de los datos del reporte, nombre cajero, fechas Del-Al
const fontSizeReportData = 10;

// ------------------- Contenido de pagina -------------------------

// tamaño de letra de los filtros
const fontSizeFilters = 9;

// tamaño de letra de cabecera de tabla, de rubro
const fontSizeTableHeader = 9;
// tamaño de letra de tabla, nombre de columna, registros y totales
const fontSizeTable = 8;
// tamaño de letra del total final del reporte
const fontSizeTotalReporte = 9;
// tamaño de letra del pie de pagina
const fontSizeFooter = 8;

// espacio entre rubro y rubro
const spaceBetweenTable = 5;

// margenes de la pagina por lado
const pageMarginLeft = pageMargins[0]
const pageMarginTop = pageMargins[1]
const pageMarginRight = pageMargins[2]
const pageMarginBottom = pageMargins[3]


const reportConf = {
    pageMargins: pageMargins,
    pageMarginLeft: pageMarginLeft,
    pageMarginTop: pageMarginTop,
    pageMarginRight: pageMarginRight,
    pageMarginBottom: pageMarginBottom,

    spaceBetweenTable: spaceBetweenTable,

    fontSizeHeader: fontSizeHeader,
    fontSizeTitle: fontSizeTitle,
    fontSizeTitleFooter: fontSizeTitleFooter,
    fontSizeReportData: fontSizeReportData,

    fontSizeFilters: fontSizeFilters,

    fontSizeTableHeader: fontSizeTableHeader,
    fontSizeTable: fontSizeTable,
    fontSizeTotalReporte: fontSizeTotalReporte,
    fontSizeFooter: fontSizeFooter
}

/* Clases de estilo para pdfmake */
const marginHeader = 6
export const styles = {
    'font-roboto-condensed': {
        font: 'RobotoCondensed'
    },
    tableHeader: {
        bold: true,
    },
    'bg-gray': {
        fillColor: '#EDEDED',
        background: '#EDEDED'
    },
    'text-center': {
        alignment: 'center'
    },
    'text-end': {
        alignment: 'right'
    },
    'text-start': {
        alignment: 'left'
    },
    'text-justify': {
        alignment: 'justify'
    },
    'text-decoration-underline': {
        decoration: 'underline'
    },
    highlight: {
        background: '#8BF878'
    },
    'fw-bold': {
        bold: true
    },
    'text-red': {
        color: '#FF0000'
    },
    title: {
        alignment: 'center',
        fontSize: 14,
        bold: true,
    },
    'm-top-page': {
        marginTop
    },
    'm-bottom-page': {
        marginBottom
    },
    'm-left-page': {
        marginLeft
    },
    'm-right-page': {
        marginRight
    },
    header: {
        bold: true,
        marginBottom: marginHeader,
        marginTop: marginHeader,
    },
    'ms-1': { marginLeft: 5 },
    'mt-1': { marginTop: 5 },
    'me-1': { marginRight: 5 },
    'mb-1': { marginBottom: 5 },
    'my-1': { marginTop: 5, marginBottom: 5 },
    'my-2': { marginTop: 10, marginBottom: 10 },
    'my-3': { marginTop: 15, marginBottom: 15 },
    'mx-1': { marginLeft: 5, marginRight: 5 },
    'mx-2': { marginLeft: 10, marginRight: 10 },
    'mx-3': { marginLeft: 15, marginRight: 15 },
    'm-0': {
        marginLeft: 0,
        marginTop: 0,
        marginRight: 0,
        marginBottom: 0,
    }
}


export default reportConf