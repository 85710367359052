import saveAs from "file-saver";
const Excel = require("exceljs");

export default class ExcelFileDataAccount {
    cellItems = []
    cellConcepts = []

    /**
     * Metodo que genera el reporte de Plan de cuentas en formato excel
     * 
     * @param JSON details 
     * @param string titleSheet titulo de la hoja
     * @param string fileName nombre que tendra el archivo al descargar 
     */
    generateExcel(details, titleSheet, fileName) {
        this.cellConcepts = []
        this.cellItems = []

        const date = new Date();
        const outputDate =
            date.getFullYear().toString() +
            String(date.getMonth() + 1).padStart(2, "0") +
            String(date.getDate()).padStart(2, "0");
        const outputTime = `${date.getHours()}${date.getMinutes()}${date.getSeconds()}`;

        const workbook = new Excel.Workbook();
        const worksheet = workbook.addWorksheet(titleSheet, {
            views: { showGridlines: false },
        });

        worksheet.columns = [
            { width: 20, alignmen: "left" },
            { width: 50 },
            { width: 70 },
            { width: 10, style: { alignment: "center" } },
            { width: 20, alignment: "right", style: { numFmt: "#,##0.00_);(#,##0.00)" } },
            { width: 20 },
        ];

        worksheet.mergeCells(`A1:F1`);
        worksheet.getCell("A1").value = "Tarifario de cuentas";
        worksheet.getCell("A1").alignment = {
            horizontal: "center",
            vertical: "middle",
        };
        worksheet.getCell("A1").font = {
            bold: true,
            size: 14,
        };
        worksheet.getRow(1).height = 25;

        var indexCell = 3;

        const auxTable = {
            name: `table${indexCell}`,
            ref: `A${indexCell}`,
            headerRow: true,
            columns: [
                { name: "Cuenta" },
                { name: "Unidad" },
                { name: "Nombre de la cuenta" },
                { name: "T.M." },
                { name: "Total" },
                { name: "Documento" },
            ],
            rows: this.getItems(details),
        };
        worksheet.addTable(auxTable);

        worksheet.getCell(`A${indexCell}`).alignment = { horizontal: "center", vertical: "middle" }
        worksheet.getCell(`A${indexCell}`).font = {
            bold: true,
            size: 12
        }
        worksheet.getCell(`B${indexCell}`).alignment = { horizontal: "center", vertical: "middle" }
        worksheet.getCell(`B${indexCell}`).font = {
            bold: true,
            size: 12
        }
        worksheet.getCell(`C${indexCell}`).alignment = { horizontal: "center", vertical: "middle" }
        worksheet.getCell(`C${indexCell}`).font = {
            bold: true,
            size: 12
        }
        worksheet.getCell(`D${indexCell}`).alignment = { horizontal: "center", vertical: "middle" }
        worksheet.getCell(`D${indexCell}`).font = {
            bold: true,
            size: 12
        }
        worksheet.getCell(`E${indexCell}`).alignment = { horizontal: "center", vertical: "middle" }
        worksheet.getCell(`E${indexCell}`).font = {
            bold: true,
            size: 12
        }
        worksheet.getCell(`F${indexCell}`).alignment = { horizontal: "center", vertical: "middle" }
        worksheet.getCell(`F${indexCell}`).font = {
            bold: true,
            size: 12
        }

        this.cellItems.forEach((cell) => {
            worksheet.getCell(`A${cell}`).font = {
                bold: true,
                size: 14
            }
            worksheet.getCell(`B${cell}`).font = {
                bold: true,
                size: 14
            }
        })

        this.cellConcepts.forEach((cell) => {
            worksheet.getCell(`A${cell}`).font = {
                size: 12,
            }
            worksheet.getCell(`B${cell}`).font = {
                size: 12,
            }
        })

        workbook.xlsx.writeBuffer().then(function (buffer) {
            if (fileName === "") {
                saveAs(
                    new Blob([buffer], { type: "application/octet-stream" }),
                    `${outputDate}${outputTime}_Report.xlsx`
                );
            } else {
                saveAs(
                    new Blob([buffer], { type: "application/octet-stream" }),
                    `${fileName}.xlsx`
                );
            }
        });
    }

    getItems(items) {
        var details = [];

        var auxCodR = "";
        var auxCodc = "";

        var index = 4

        for (var i = 0; i < items.length; i++) {
            if (items[i].codRubro !== auxCodR || i === 0) {
                auxCodR = items[i].codRubro;
                auxCodc = "";

                details.push([
                    items[i].codRubro,
                    items[i].desRubro,
                    " ",
                    " ",
                    " ",
                    " ",
                ]);

                this.cellItems.push(index)
                index += 1
            }

            if (auxCodc !== items[i].codConcepto || i === 0) {
                auxCodc = items[i].codConcepto;

                details.push([
                    items[i].codConcepto,
                    items[i].desConcepto,
                    " ",
                    " ",
                    " ",
                    " ",
                ]);

                this.cellConcepts.push(index)
                index += 1
            }

            var auxPrice = items[i].tipoMonto === "V" ? 0.0 : items[i].precio;

            details.push([
                items[i].codCuenta,
                items[i].desUnidad,
                items[i].nombCuebta,
                items[i].tipoMonto,
                auxPrice,
                items[i].numero,
            ]);

            index += 1
        }

        return details;
    }
}
