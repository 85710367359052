import { useMemo, useState } from 'react'
import { classNames, propFromName } from '../utils'
import { PickListSide } from './PickListSide'
export function PickList({
    optionLabel = 'label',
    optionTemplate,
    source,
    target,
    sourceHeader,
    targetHeader,
    filterBy,
    onChange,
    className,
    style,
    responsive,
}) {
    const [selectedSourceOptions, setSelectedSourceOptions] = useState([])
    const [selectedTargetOptions, setSelectedTargetOptions] = useState([])
    const [sourceFilterValue, setSourceFilterValue] = useState('')
    const [targetFilterValue, setTargetFilterValue] = useState('')
    const filteredSource = useMemo(() => {
        return filterBy && sourceFilterValue
            ? source.filter((o) => (propFromName(o, filterBy) || '').toLowerCase().includes(sourceFilterValue))
            : source
    }, [source, filterBy, sourceFilterValue])
    const filteredTarget = useMemo(() => {
        return filterBy && targetFilterValue
            ? target.filter((o) => (propFromName(o, filterBy) || '').toLowerCase().includes(targetFilterValue))
            : target
    }, [target, filterBy, targetFilterValue])
    const subtractArrays = (array1, array2) => {
        return array1.filter((el1) => !array2.some((el2) => JSON.stringify(el1) === JSON.stringify(el2)))
    }
    const addOptions = () => {
        if (selectedSourceOptions.length > 0) {
            const _source = subtractArrays(source, selectedSourceOptions)
            const _target = [...target, ...selectedSourceOptions]
            if (onChange) {
                onChange({ source: _source, target: _target })
                setSelectedSourceOptions([])
            }
        }
    }
    const addAllOptions = () => {
        if (filteredSource.length > 0) {
            const _source = filterBy ? subtractArrays(source, filteredSource) : []
            const _target = [...target, ...filteredSource]
            if (onChange) {
                onChange({ source: _source, target: _target })
                selectedSourceOptions.length > 0 && setSelectedSourceOptions([])
            }
        }
    }
    const removeOptions = () => {
        if (selectedTargetOptions.length > 0) {
            const _source = [...source, ...selectedTargetOptions]
            const _target = subtractArrays(target, selectedTargetOptions)
            if (onChange) {
                onChange({ source: _source, target: _target })
                setSelectedTargetOptions([])
            }
        }
    }
    const removeAllOptions = () => {
        if (filteredTarget.length > 0) {
            const _source = [...source, ...filteredTarget]
            const _target = filterBy ? subtractArrays(target, filteredTarget) : []
            if (onChange) {
                onChange({ source: _source, target: _target })
                selectedTargetOptions.length > 0 && setSelectedTargetOptions([])
            }
        }
    }
    const handleToggleSelectSource = (option, selected) => {
        const _options = selected
            ? selectedSourceOptions.filter((o) => JSON.stringify(o) !== JSON.stringify(option))
            : [...selectedSourceOptions, option]
        if (selectedTargetOptions.length > 0) setSelectedTargetOptions([])
        setSelectedSourceOptions(_options)
    }
    const handleToggleSelectTarget = (option, selected) => {
        const _options = selected
            ? selectedTargetOptions.filter((o) => JSON.stringify(o) !== JSON.stringify(option))
            : [...selectedTargetOptions, option]
        if (selectedSourceOptions.length > 0) setSelectedSourceOptions([])
        setSelectedTargetOptions(_options)
    }
    return (
        <div
            className={classNames(['modular-picklist', responsive && `responsive-${responsive}`, className])}
            style={style}
        >
            {/* Source */}
            <PickListSide
                options={filteredSource}
                optionLabel={optionLabel}
                optionTemplate={optionTemplate}
                header={sourceHeader}
                filterBy={filterBy}
                selectedOptions={selectedSourceOptions}
                onToggleSelect={handleToggleSelectSource}
                filterValue={sourceFilterValue}
                onFilterChange={setSourceFilterValue}
            />
            <div className='pl-controls'>
                <button className='plc-button plc-button-add' onClick={addOptions} />
                <button className='plc-button plc-button-add-all' onClick={addAllOptions} />
                <button className='plc-button plc-button-remove-all' onClick={removeAllOptions} />
                <button className='plc-button plc-button-remove' onClick={removeOptions} />
            </div>
            {/* Target */}
            <PickListSide
                options={filteredTarget}
                optionLabel={optionLabel}
                optionTemplate={optionTemplate}
                header={targetHeader}
                filterBy={filterBy}
                selectedOptions={selectedTargetOptions}
                onToggleSelect={handleToggleSelectTarget}
                filterValue={targetFilterValue}
                onFilterChange={setTargetFilterValue}
            />
        </div>
    )
}
