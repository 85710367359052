/* Informe consolidado por Unidad */
import pdfMake from 'pdfmake/build/pdfmake'
import pdfFonts from 'pdfmake/build/vfs_fonts'
import printJS from 'print-js';

import RobotoFont from '../../fonts/Roboto'
import RobotoBoldFont from '../../fonts/RobotoBold'

import reportConf from '../components/reportConf'
import reportHeader from '../components/reportHeader'
import cuadroTotal from '../components/cuadroTotal'
import reportFooter from '../components/reportFooter'

export default class ReportUnidadFacturas {
    totalDa = 0
    total = 0

    /**
     * método que recibe los datos de la unidad y retorna las filas de las tabla por cada ítems de unidades con D.A.
     * @param {Array} items
     * @returns {Array} filas de la tabla
     */
    addListUnidadDa(items) {
        let list = []
        items.map((item) => {
            if (item.da !== null) {
                this.totalDa += parseFloat(item.total)
                let totalUnidad = new Intl.NumberFormat('en-US', { minimumFractionDigits: 2 }).format(item.total)

                list.push([
                    { text: " " },
                    { text: item.codigo },
                    { text: item.unidad },
                    {
                        text: totalUnidad,
                        alignment: 'right',
                        margin: [0, 0, 60, 0],
                    }
                ])
            }
            return null;
        })

        if (list.length > 0) {
            let totalUnidad = new Intl.NumberFormat('en-US', { minimumFractionDigits: 2 }).format(this.totalDa)
            list.push([
                { text: " " },
                { text: " " },
                {
                    text: "Total DA",
                    style: 'tableSubTotal',
                },
                {
                    text: totalUnidad,
                    alignment: 'right',
                    margin: [0, 0, 60, 0],
                    style: 'tableSubTotal'
                }
            ])
        }
        return list
    }
    /**
     * método que recibe los datos de la unidad y retorna las filas de las tabla por cada ítems de unidades sin D.A.
     * @param {Array} items
     * @returns {Array} filas de la tabla
     */
    addListUnidad(items) {
        let list = []
        items.map((item) => {
            if (item.da === null) {
                this.total += parseFloat(item.total)
                let totalUnidad = new Intl.NumberFormat('en-US', { minimumFractionDigits: 2 }).format(item.total)

                list.push([
                    { text: " " },
                    { text: item.codigo },
                    { text: item.unidad },
                    {
                        text: totalUnidad,
                        alignment: 'right',
                        margin: [0, 0, 60, 0],
                    }
                ])
            }
            return null;
        })

        if (list.length > 0) {
            let totalUnidad = new Intl.NumberFormat('en-US', { minimumFractionDigits: 2 }).format(this.total)
            list.push([
                { text: " " },
                { text: " " },
                {
                    text: "Total DA",
                    style: 'tableSubTotal',
                },
                {
                    text: totalUnidad,
                    alignment: 'right',
                    margin: [0, 0, 60, 0],
                    style: 'tableSubTotal'
                }
            ])
        }
        return list
    }
    /**
     * método que retorna una tabla con los filtro si estos fueron requeridos.
     * @param {boolean} isFilter
     * @param {JSON} filter
     * @param {boolean} isAdmin
     * @returns {Array} filtros selecionados
     */
    selectionFilter(isFilter, filter, isAdmin) {

        if (isFilter) {
            if (isAdmin) {
                return [

                    ['Estructura', ":", filter.estructura],
                    ['Rubro', ":", filter.rubro],
                    ['Unidad', ":", filter.unidad],
                    ['Concepto', ":", filter.concepto],
                    ['Cuenta', ":", filter.cuenta],

                ];
            } else {
                return [

                    ['Rubro', ":", filter.rubro],
                    ['Unidad', ":", filter.unidad],
                    ['Concepto', ":", filter.concepto],
                    ['Cuenta', ":", filter.cuenta],
                ];

            }

        } else {
            return [];
        }

    }
    /**
     * método encargado de generar el reporte
     * @param {Array} items
     * @param {string} usuario
     * @param {string} procesado
     * @param {date} fechaInicio
     * @param {date} fechaFin
     * @param {boolean} isPrint
     * @param {boolean} isFilter
     * @param {JSON} filter
     * @param {boolean} isAdmin
     */
    generateReport(items, usuario, procesado, fechaInicio, fechaFin, isPrint, isFilter, filter, isAdmin, userUnidad, electronico, caja) {
        this.total = 0
        this.totalDa = 0

        this.totalCategory = 0.0;
        this.totalDescuentos = 0;
        this.totalSubTotal = 0.0;


        pdfMake.vfs = {
            ...pdfFonts.pdfMake.vfs,
            'Roboto-Regular.ttf': RobotoFont,
            'Roboto-Bold.ttf': RobotoBoldFont
        };

        pdfMake.fonts = {
            Roboto: {
                normal: 'Roboto-Regular.ttf',
                bold: 'Roboto-Bold.ttf'
            }
        };

        const headerPage = (currentPage, pageCount, pageSize) => {
            return reportHeader(
                currentPage,
                pageCount,
                'Informe Consolidado por Unidad - Facturas',
                userUnidad,
                usuario,
                fechaInicio,
                fechaFin,
                electronico,
                caja,
            );
        }

        const details = [
            {
                fontSize: reportConf.fontSizeFilters,
                margin: [0, 0, 0, 5],
                table: {
                    body: [
                        ["", "", ""],
                        ...this.selectionFilter(isFilter, filter, isAdmin)

                    ],
                },
                layout: "noBorders",

            },
            {
                fontSize: reportConf.fontSizeTable,
                table: {
                    headerRows: 1,
                    widths: [40, 80, 220, 140],
                    body: [
                        [
                            { text: '' },
                            { text: 'Código', style: 'tableHeader' },
                            { text: 'Unidad', style: 'tableHeader'},
                            { text: 'Total', style: 'tableHeader', alignment: 'right', margin: [0, 0, 60, 0] }
                        ],
                        ...this.addListUnidadDa(items),
                        ...this.addListUnidad(items)

                    ]
                },
                layout: {
                    hLineWidth: function (i, node) {
                        return (i === 1 || i === node.table.body.length) ? 0.75 : 0;
                    },
                    vLineWidth: function (i, node) {
                        return 0;
                    },
                },

            },
            {text: '',  margin: [0, 0, 0, reportConf.spaceBetweenTable]}
        ]

        details.push(cuadroTotal(this.total+this.totalDa, 'Total Consolidado', [0, 0, 62, 0]));

        const footerPage = reportFooter(procesado);

        const docDefinitions = {
            pageSize: 'letter',
            pageMargins: reportConf.pageMargins,
            header: headerPage,
            content: [details],
            defaultStyle: {
                font: 'Roboto'
            },
            styles: {
                tableHeader: {
                    bold: true,
                    color: 'black'
                },
                tableSubTotal: {
                    bold: true,
                    color: 'black'
                }
            },
            footer: footerPage
        }

        if (isPrint) {
            let document = pdfMake.createPdf(docDefinitions)
            let docBlob = null;

            document.getBase64(function (result) {
                docBlob = result;
                printJS({
                    printable: docBlob,
                    type: "pdf",
                    base64: true
                });
            });
        } else {
            pdfMake.createPdf(docDefinitions).open()
        }
    }
}