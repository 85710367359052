import ExcelFile from "./ExcelFile";

export default class ExcelUnidad {
    totalReportUnid = 0.00

    listUnid = []

    /**
     * Metodo que manda a generar el informe consolidado por unidad en formato excel
     * 
     * @param JSON detailsUnid 
     * @param string userName 
     * @param string initDate 
     * @param string endDate 
     * @param string fileName nombre que tendra el archivo al descargar
     */
    excelReport(detailsUnid, userName, initDate, endDate, fileName) {
        const reportUnid = new ExcelFile()

        this.totalReportUnid = 0.00

        this.listItemsUnid(detailsUnid)

        const columnWidth = [
            { width: 20, alignmen: 'left' },
            { width: 70 },
            { width: 20, alignment: 'right', style: { numFmt: '#,##0.00_);(#,##0.00)' } }
        ]
        const columnHeader = [
            { name: "codigo" },
            { name: "unidad" },
            { name: "total" }
        ]

        reportUnid.generateExcelFile(
            detailsUnid,
            "Inf-Con_Unidad",
            "Informe Consolidado por Unidad",
            userName,
            initDate,
            endDate,
            columnWidth,
            "C1",
            columnHeader,
            this.listUnid,
            this.totalReportUnid,
            "Total Consolidado",
            false,
            'B',
            'C',
            fileName
        )
    }

    listItemsUnid(items) {
        var auxDA = items[0].da
        var auxSubTotal = 0.00

        this.listUnid = []

        var list3 = []

        for (var i = 0; i < items.length; i++) {
            var list2 = []

            this.totalReportUnid += parseFloat(items[i].total)

            if (auxDA === items[i].da) {
                auxSubTotal += parseFloat(items[i].total)
                list2.push(
                    items[i].codigo,
                    items[i].unidad,
                    items[i].total
                )
            } else {
                var listSub = []
                listSub.push(
                    "Sub Total",
                    "",
                    auxSubTotal
                )
                this.listUnid.push(listSub)

                auxDA = items[i].da
                auxSubTotal = 0.00
                auxSubTotal += parseFloat(items[i].total)
                list2.push(
                    items[i].codigo,
                    items[i].unidad,
                    items[i].total
                )
            }
           
            this.listUnid.push(list2)
        }
        list3.push(
            "Sub Total",
            "",
            auxSubTotal
        )
        this.listUnid.push(list3)
    }
}