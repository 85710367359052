import pdfMake from 'pdfmake/build/pdfmake'
import pdfFonts from 'pdfmake/build/vfs_fonts'
import printJS from 'print-js';

import RobotoFont from '../../fonts/Roboto'
import RobotoBoldFont from '../../fonts/RobotoBold'

import reportConf from '../components/reportConf'
import reportHeader from '../components/reportHeader'
import cuadroTotal from '../components/cuadroTotal'
import reportFooter from '../components/reportFooter'
import { formatCurrency } from '../../utils/utils';

export default class ReportLibroVentas {

    total = 0.0;
    totalCategory = 0.0;
    totalDescuentos = 0;
    totalSubTotal = 0.0;

    constructor() {
        this.total = 0.0
        this.numUsuario = 0
    }
    /**
     * método que retorna una tabla con los filtro si estos fueron requeridos.
     * @param {boolean} isFilter
     * @param {JSON} filter
     * @param {boolean} isAdmin
     * @returns {Array} filtros selecionados
     */
    selectionFilter(isFilter, filter, isAdmin) {
        if (isFilter) {
            if (isAdmin) {
                return [
                    ['Estructura', ":", filter.estructura],
                    ['Rubro', ":", filter.rubro],
                    ['Unidad', ":", filter.unidad],
                    ['Concepto', ":", filter.concepto],
                    ['Cuenta', ":", filter.cuenta],
                ];
            } else {
                return [
                    ['Rubro', ":", filter.rubro],
                    ['Unidad', ":", filter.unidad],
                    ['Concepto', ":", filter.concepto],
                    ['Cuenta', ":", filter.cuenta],
                ];
            }
        } else {
            return [];
        }

    }
    /**
     * método que recibe los arreglo de datos, calcula los subtotales por cada recibo y retorna las filas de las tabla por cada items.
     * @param {Array} items
     * @param {int} i
     * @returns {Array} ->fila de las tablas
     */
    getItems(items) {
        // this.totalCategory = 0.0;

        let subtotalCajero = 0;
        let descuentoCajero = 0;
        let totalCajero = 0;
        let creditoCajero = 0;

        const filas = items.map((item) => {
            let observacion = ''
            let totalAdd = 0.0
            let subTotal = 0.0
            let descuento = 0.0
            let credito = 0.0
            if (item.estado === 'A') {
                observacion = "A";
                totalAdd = 0.0;
                credito = 0.0;
            } else {
                observacion = "V";
                subTotal = parseFloat(item.subtotal);
                totalAdd = parseFloat(item.total);
                descuento = parseFloat(item.descuento);
                credito = parseFloat(item.credito);
            }
           
            // this.total += totalAdd;
            subtotalCajero += subTotal;
            descuentoCajero += descuento;
            totalCajero += totalAdd;
            creditoCajero += credito;

            return [
                { text: item.fecha },
                { text: item.nFact, alignment: 'right' },
                { text: item.nit },
                { text: item.descripcion },
                { text: item.sigla },
                { text: formatCurrency(subTotal), alignment: 'right'  },
                { text: formatCurrency(descuento), alignment: 'right'  },
                { text: formatCurrency(totalAdd), alignment: 'right' },
                { text: formatCurrency(credito), alignment: 'right' },
                { text: observacion, alignment: 'center' }
            ];
        });
        return {filas, subtotalCajero, descuentoCajero, totalCajero, creditoCajero};
    }

    /**
     * método encargado de generar el reporte
     * @param {Array} itemsReport
     * @param {string} userName
     * @param {string} receivedFrom
     * @param {date} initDate
     * @param {date} endDate
     * @param {boolean} isPrint
     * @param {boolean} isFilter
     * @param {JSON} filter
     * @param {boolean} isAdmin
     */
    generarPdf(itemsReport, usuario, procesado, fechaInicio, fechaFin, isPrint, isFilter, filter, isAdmin,userUnidad, electronico, caja) {
        // this.total = 0.0;
        this.totalCategory = 0.0;
        this.totalDescuentos = 0;
        this.totalSubTotal = 0.0;


        pdfMake.vfs = {
            ...pdfFonts.pdfMake.vfs,
            'Roboto-Regular.ttf': RobotoFont,
            'Roboto-Bold.ttf': RobotoBoldFont
        };

        pdfMake.fonts = {
            Roboto: {
                normal: 'Roboto-Regular.ttf',
                bold: 'Roboto-Bold.ttf'
            }
        };

        const headerPage = (currentPage, pageCount, pageSize) => {
            return reportHeader(
                currentPage,
                pageCount,
                'Libro Ventas',
                userUnidad,
                usuario,
                fechaInicio,
                fechaFin,
                electronico,
                caja
            );
        }

        let tableDetail = [[
            {
                fontSize: reportConf.fontSizeFilters,
                margin: [0, 0, 0, 5],
                table: {
                    body: [
                        ["", "", ""],
                        ...this.selectionFilter(isFilter, filter, isAdmin)

                    ],
                },
                layout: "noBorders",
            },

        ]
        ];
        
        // for (let i = 0; i < itemsReport.length; i++) {
            const {filas, subtotalCajero, descuentoCajero, totalCajero, creditoCajero} = this.getItems(itemsReport)
            // this.totalSubTotal += subtotalCajero;
            // this.totalDescuentos += descuentoCajero;
            // this.totalCategory += totalCajero;

            let auxTable = [
                {
                    // fontSize: reportConf.fontSizeTable,
                    fontSize: 7,
                    table: {
                        headerRows: 1,
                        widths: [28, 28, 40, "*",40, 50, 50, 50,50,19],
                        body: [
                            // [
                            //     { text: 'Cajero:', style: 'tableUser' },
                            //     { text: `${itemsReport[i].usuario}`, style: 'tableUser', colSpan: 4, }, {}, {}, {}, {}, {},{},{},{}
                            // ],
                            [
                                { text: 'Fecha', style: 'tableHeader' },
                                { text: 'Nro.Fac.', style: 'tableHeader', alignment: 'right' },
                                { text: 'NIT', style: 'tableHeader' },
                                { text: 'Razón Social', style: 'tableHeader' },
                                { text: 'Unidad', style: 'tableHeader' },
                                { text: 'Importe Total', style: 'tableHeader', alignment: 'right' },
                                { text: 'Descuento', style: 'tableHeader', alignment: 'right' },
                                { text: 'Sujeto a Débito', style: 'tableHeader', alignment: 'right' },
                                { text: 'Débito Fiscal', style: 'tableHeader', alignment: 'right' },
                                { text: 'Obs.', style: 'tableHeader', alignment: 'center' },
                            ],
                            ...filas,
                                [
                                    { text: `${itemsReport.length}`, alignment: 'right', colSpan:2, bold: true}, {},
                                    { text: 'Total', alignment: 'center',colSpan:3, bold:true},{},{},
                                    { text: formatCurrency(subtotalCajero), bold: true,alignment: 'right'  },
                                    { text: formatCurrency(descuentoCajero), bold: true,alignment: 'right'  },
                                    { text: formatCurrency(totalCajero), bold: true,alignment: 'right'  },
                                    { text: formatCurrency(creditoCajero), bold: true,alignment: 'right'  },
                                    {},
                                ],
                        ],
                    },
                    layout: {
                        hLineWidth: function (i, node) {
                            return i === 1 || i === node.table.body.length-1 ? 0.75 : 0;
                        },
                        vLineWidth: function (i, node) {
                            return 0;
                        },
                    },
                },
                
                {text: '', margin: [0, 0, 0, reportConf.spaceBetweenTable]},
            ];
            tableDetail.push(auxTable);
        // }

        const totalLabel = [];
        //totalLabel.push(cuadroTotal(this.total,  'Total Repofdfdrte', [0, 0, 62, 0]));

        // totalLabel.push({
        //     margin: [205, 5, 0, 0],
        //     fontSize: reportConf.fontSizeTable,
        //     table: {
        //         widths: ["*", 55, 40, 55,58],
        //         body: [
        //             [
        //                 { text: "Total Reporte", bold: true, noWrap: true },
        //                 { text: formatCurrency(this.totalSubTotal), bold: true,alignment: 'right'  },
        //                 { text: formatCurrency(this.totalDescuentos), bold: true,alignment: 'right'  },
        //                 { text: formatCurrency(this.totalCategory), bold: true,alignment: 'right'  },
        //                 {},
        //             ],
        //         ],
        //     },
        //     layout: {
        //         hLineWidth: function (i, node) {
        //             return i === 0 || i === node.table.body.length ? 0.5 : 0;
        //         },
        //         vLineWidth: function (i, node) {
        //             return i === 0 || i === node.table.widths.length ? 0.5 : 0;
        //         },
        //     },
        // });

        const footer = reportFooter(procesado)[0];

        const report = {
            pageSize: 'letter',
            pageMargins: reportConf.pageMargins,
            header: headerPage,
            content: [tableDetail, totalLabel],
            defaultStyle: {
                font: 'Roboto',
            },
            styles: {
                tableHeader: {
                    bold: true,
                    color: 'black'
                },
                tableUser: {
                    bold: true,
                    fontSize: reportConf.fontSizeTableHeader,
                    color: 'black'
                },
            },
            // footer: (page, pages) => {
            //     return [
            //         pages !== page ? {} : {
            //             text: '..........................................................\n Cajero',
            //             alignment: 'center',
            //             bold: true,
            //             margin: [0, 5, 0, 0],
            //         },
            //         {
            //             ...footer,
            //             margin: [20, 15, reportConf.pageMarginRight, 20], // menos 5 al top para que se mantenga donde estaba antes de juntarlo con el elemento para la firma del cajero
            //         }
            //     ];
            // },
        }

        if (isPrint) {
            let document = pdfMake.createPdf(report)

            document.getBase64(function (docBlob) {
                printJS({
                    printable: docBlob,
                    type: "pdf",
                    base64: true
                });
            });
        } else {
            pdfMake.createPdf(report).open()
        }
    }
}