import { createStore, applyMiddleware,  } from "redux";
import { composeWithDevTools } from "redux-devtools-extension";
import thunk from "redux-thunk";
import rootReducer from "./reducers";

/**
 * Reunirá las acciones y los reductores y mantendrá el estado de la aplicación.
 */

const middleware = [thunk];
const store = createStore(
    rootReducer,
    composeWithDevTools(applyMiddleware(...middleware))
);

export default store;