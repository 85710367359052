import { useEffect, useState } from "react";
import FacturaServices from "../../services/factura.service";
import PopUpMessage from "../PopUpMessage";
import { classNames } from "../../utils/utils";


function DatosFactura({datos, setDatos, errors, touched, validar}) {
    const [tipos, setTipos] = useState([])
    const [visible, setVisible] = useState(false)
    const [mensaje, setMensaje] = useState("")

    const handleChange= (e)=>{
        const {name, value} = e.target
        let _datos = datos
        if (name == "nit"){
            _datos={"nit":"","tipo_documento":1,"nombre":"", "correo":""}//estado inicial del componente
        }
        _datos = {..._datos, [name]: value}
        setDatos(_datos)
        validar({cliente: _datos}, name)
    } ;

    const handleKeyUp= (e)=>{
        if(e.key === "Enter"){
            // console.log(e.key);
            buscarCliente();
        }
    };        

    const buscarCliente = async()=>{
        const response= await FacturaServices.listarPersonasFacturas(datos.nit);
        if(response.data.isOk){
            // console.log(response.data);
            setDatos(response.data.cliente);
        }else{
            setMensaje(response.data.result)
            setVisible(true)
        } 

    };

    useEffect(() => {
        listarTipos();
    }, [])

    const listarTipos = async () =>{
        const response = await FacturaServices.listarTiposDocumentos()
        // console.log(response);
        if (response.data.isOk) {
          setTipos(response.data.result)
        }

    };
    
    return <>
        <div className='header clearfix'>
            <label className='title float-start'>DATOS CLIENTE</label>
        </div>
        <table className='table table-responsive table-borderless'>
            <tbody>
                <tr>
                    <th style={{width: '12rem'}}>Documento: </th>
                    <td>
                        <input 
                            type='text' 
                            name="nit" 
                            value={datos.nit} 
                            onChange={handleChange} 
                            onKeyUp={handleKeyUp}
                            className={classNames([
                                'form-control form-control-sm', 
                                touched.nit && errors.nit && 'is-invalid',
                            ])} 
                            required 
                        />
                    </td>

                    <th>Tipo Doc.: </th>
                    <td>
                    <select 
                        name="tipo_documento"
                        className='form-select form-select-sm' 
                        value={datos.tipo_documento}
                        onChange={handleChange}
                    >
                        {tipos.map((tipo)=>(<option value={tipo.codigo} key={tipo.codigo}> {tipo.descripcion} </option>))}    

                    </select>
                    </td>
                </tr>
                <tr>
                    <th>Razón Social: </th>
                    <td colSpan="3">
                        <input 
                            name="nombre" 
                            type='text' 
                            value={datos.nombre} 
                            onChange={handleChange} 
                            className={classNames([
                                'form-control form-control-sm', 
                                touched.nombre && errors.nombre && 'is-invalid',
                            ])} 
                            required 
                        />
                    </td>
                </tr> 
                <tr>
                    <th>Correo Electrónico:</th>
                    <td colSpan="3">
                        <input 
                            type='email' 
                            name="correo" 
                            value={datos.correo} 
                            onChange={handleChange} 
                            className={classNames([
                                'form-control form-control-sm', 
                                touched.correo && errors.correo && 'is-invalid',
                            ])} 
                            required 
                        />
                    </td>
            </tr>    
            </tbody>
        </table>

        <PopUpMessage
            close={()=>setVisible(false)}
           // visible={visible}
            message={"Error"}
        />


    </>;

} 

export default DatosFactura;