import { Route, Routes } from 'react-router-dom'
import Login from 'Pages/Login'
import ForgotPassword from 'Pages/ForgotPassword'
import ChangePassword from 'Pages/ChangePassword'
import SuccessfulPayment from 'Pages/SuccessfulPayment'
import FacturaPDF from 'Pages/Facturacion/FacturaPDF'
import ComprobantePdf from 'Pages/ComprobantePdf'

export default function PublicRoutes() {
    return (
        <Routes>
            <Route exact path='/forgotPassword' element={<ForgotPassword />} />
            <Route exact path='/change-password' element={<ChangePassword />} />
            <Route path='/factura/:id' element={<FacturaPDF />} />
            <Route path='/comprobante/:id' element={<ComprobantePdf />} />
            <Route path='/pago_terminado' element={<SuccessfulPayment />} />
            <Route path='*' element={<Login />} />
        </Routes>
    )
}
