import pdfMake from 'pdfmake/build/pdfmake'
import pdfFonts from 'pdfmake/build/vfs_fonts'
import printJS from 'print-js';

import RobotoFont from '../fonts/Roboto'
import RobotoBoldFont from '../fonts/RobotoBold'

import reportConf from './components/reportConf'
import reportHeader from './components/reportHeader'
import cuadroTotal from './components/cuadroTotal'
import reportFooter from './components/reportFooter'

export default class ReportConCuentaUnid {

    total = 0.00
    totalCategory = 0.00

    getItems(items, i) {
        let auxValue = 0.00
        this.totalCategory = 0.00
        const list = items[i].lista.map((item) => {
            this.total += parseFloat(item.total)
            this.totalCategory += parseFloat(item.total)
            auxValue = new Intl.NumberFormat('en-US', { minimumFractionDigits: 2 }).format(parseFloat(item.total));
            return [{ text: item.codigo }, { text: `   ${item.unidad}` }, { text: item.cuenta }, { text: auxValue, alignment: 'right' }]
        })
        return list
    }
    selectionFilter(isFilter, filter, isAdmin) {
        if(isFilter){
            if(isAdmin){
                return [

                    ['Estructura', ":", filter.estructura],
                    ['Rubro', ":", filter.rubro],
                    ['Unidad', ":", filter.unidad],
                    ['Concepto', ":", filter.concepto],
                    ['Cuenta', ":", filter.cuenta],

                ];
            }else{
                return [

                    ['Rubro', ":", filter.rubro],
                    ['Unidad', ":", filter.unidad],
                    ['Concepto', ":", filter.concepto],
                    ['Cuenta', ":", filter.cuenta],
                ];

            }

        }else{
            return [];
        }

    }

    /**
     * Funcion que genera el Informe Consolidado por Unidad y Cuenta y lo manda a imprimir o a una
     * visualizacion previa dependiendo del parametro isPrint
     *
     * @param JSON itemsReport
     * @param string userName
     * @param string initDate
     * @param string endDate
     * @param string receivedFrom
     * @param boolean isPrint
     * @param boolean isFilter
     * @param array filter
     * @param boolean isAdmin
     */
    async generateReport(itemsReport, userName, initDate, endDate, receivedFrom, isPrint, isFilter, filter, isAdmin,userUnidad, electronico, caja) {

        pdfMake.vfs = {
            ...pdfFonts.pdfMake.vfs,
            'Roboto-Regular.ttf': RobotoFont,
            'Roboto-Bold.ttf': RobotoBoldFont
        };

        pdfMake.fonts = {
            Roboto: {
                normal: 'Roboto-Regular.ttf',
                bold: 'Roboto-Bold.ttf'
            }
        };

        this.total = 0.00

        const headerPage = (currentPage, pageCount, pageSize) => {
            return reportHeader(
                currentPage,
                pageCount,
                'Informe Consolidado por Unidad y Cuenta',
                userUnidad,
                userName,
                initDate,
                endDate,
                electronico,
                caja
            );
        }

        let tableDetail = [
            [
                {
                    fontSize: reportConf.fontSizeFilters,
                    margin: [0, 0, 0, 5],

                    table: {
                        body: [
                            ["", "", ""],
                            ...this.selectionFilter(isFilter, filter, isAdmin)

                        ],
                    },
                    layout: "noBorders",
                },

            ]
        ]

        if(itemsReport.length > 0) {
            tableDetail.push(
                {
                    fontSize: reportConf.fontSizeTableHeader,
                    bold: true,
                    margin: [4, 0, 0, 0],
                    table: {
                        widths: [75, 'auto'],
                        body: [
                            [
                                { text: ' Cod. Rubro' },
                                { text: 'Rubro'}
                            ]
                        ]
                    },
                    layout: "noBorders",

                }
            );
        }

        for (let i = 0; i < itemsReport.length; i++) {
            let auxTable = [
                {
                    fontSize: reportConf.fontSizeTable,
                    table: {
                        headerRows: 1,
                        widths: [75, 100, 250, 60],
                        body: [
                            [
                                { text: itemsReport[i].codigo, style: 'tableCategory' },
                                { text: itemsReport[i].rubro, style: 'tableCategory', colSpan: 3 }, {}, {}
                            ],
                            [
                                {
                                    text: 'Código', style: 'tableHeader'
                                },
                                {
                                    text: 'Unidad', style: 'tableHeader'
                                },
                                {
                                    text: 'Cuenta', style: 'tableHeader'
                                },
                                {
                                    text: 'Total', style: 'tableHeader', alignment: 'right'
                                }
                            ],
                            ...this.getItems(itemsReport, i),
                            [
                                { text: 'Total Rubro', bold: true, colSpan: 3 }, {}, {},
                                {
                                    text: new Intl.NumberFormat('en-US', { minimumFractionDigits: 2 }).format(this.totalCategory),
                                    alignment: 'right',
                                    bold: true
                                }
                            ],
                        ]
                    },
                    layout: {
                        hLineWidth: function (i, node) {
                            return (i === 2 || i === node.table.body.length-1) ? 0.75 : 0;
                        },
                        vLineWidth: function (i, node) {
                            return 0;
                        },
                    },

                },
                { text: '', margin: [0, 0, 0, reportConf.spaceBetweenTable] }
            ]
            tableDetail.push(auxTable)
        }

        const totalLabel = [];
        totalLabel.push(cuadroTotal(this.total));

        const footerPage = reportFooter(receivedFrom);

        const report = {
            pageSize: 'letter',
            pageMargins: reportConf.pageMargins,
            header: headerPage,
            content: [tableDetail, totalLabel],
            footer: footerPage,
            defaultStyle: {
                font: 'Roboto'
            },
            styles: {
                tableHeader: {
                    bold: true,
                    color: 'black'
                },
                tableCategory: {
                    bold: true,
                    fontSize: reportConf.fontSizeTableHeader,
                    color: 'black'
                },
            },
        }

        if (isPrint) {
            let document = pdfMake.createPdf(report)
            let docBlob = null;

            document.getBase64(function (result) {
                docBlob = result;
                printJS({
                    printable: docBlob,
                    type: "pdf",
                    base64: true
                });
            });
        } else {
            pdfMake.createPdf(report).open()
        }
    }
}