import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import Header from 'Component/Header'
import { DataTable } from 'modules/modular/DataTable'
import { ButtonIcon } from 'modules/modular/ButtonIcon'
import { Confirm } from 'modules/modular/Modal'
import ContabilidadServices from 'services/contabilidad.service'
import { initialPagination } from 'data/constants'
import { formatCurrency, formatDate } from 'utils'
import useFilters from 'hooks/useFilters'
import useHttpToast from 'hooks/useHttpToast'
import { Button } from 'modules/modular/Button'

export default function ListFondo({ tipo }) {
    const navigate = useNavigate()
    const { showHttpToast } = useHttpToast()
    const { filters, handlePage } = useFilters()
    const [show, setShow] = useState({ update: false, delete: false })
    const [selected, setSelected] = useState(null)
    const [fondos, setFondos] = useState([])
    const [pagination, setPagination] = useState(initialPagination)
    const [loading, setLoading] = useState(true)

    useEffect(() => {
        load()
    }, [tipo, filters])

    const load = () => {
        if (['A', 'R'].includes(tipo)) {
            loadList()
            verifyEnableCreate()
        } else {
            setFondos([])
            setPagination(initialPagination)
        }
    }
    const loadList = async () => {
        const getList = tipo === 'R' ? ContabilidadServices.fondoRotatorio.list : ContabilidadServices.fondoAvance.list
        const { status, data } = await getList(filters)
        if (status === 200) {
            setFondos(data.data)
            setPagination(data.pagination)
        }
    }
    const verifyEnableCreate = async () => {
        if (tipo === 'R') {
            const { status, data } = await ContabilidadServices.fondoRotatorio.hasActive()
            if (status === 200) setLoading(data.data) 
        }
    }

    const openModal = (key) => setShow({ ...show, [key]: true })
    const closeModal = (key) => setShow({ ...show, [key]: false })
    const onCloseModal = () => setSelected(null)

    const handleAction = (action, rowData) => {
        if (action === 'view') {
            navigate(`/contabilidad/fondo/${rowData.id}`)
        } else if (action === 'update') {
            if (rowData.cantidad_finalizado > 0) {
                setSelected(rowData)
                openModal(action)
            } else {
                navigate(`/contabilidad/fondo/update/${rowData.id}`)
            }
        } else if (action === 'confirm-update') {
            navigate(`/contabilidad/fondo/update/${rowData.id}`)
        } else if (action === 'create') {
            handleCreate()
        } else if (action === 'delete') {
            setSelected(rowData)
            openModal('delete')
        }
    }
    const handleCreate = async () => {
        setLoading(true)
        const { status, data } = await ContabilidadServices.fondoRotatorio.create()
        showHttpToast(status, { detail: data.message })
        if (status === 201) {
            load()
        }
        setLoading(false)
    }
    const handleDelete = async () => {
        if (selected?.id) {
            const { status, data } = await ContabilidadServices.fondo.delete(selected.id)
            showHttpToast(status, { detail: data.message })
            if (status === 200) {
                load()
                closeModal('delete')
            }
        }
    }

    const optionsTemplate = (rowData) => (
        <div className='text-center'>
            <ButtonIcon variant='outline-primary' className='me-2' onClick={() => handleAction('view', rowData)}>
                <span className='bi-eye lh-1' />
            </ButtonIcon>
            {rowData.activo && rowData.cantidad_finalizado < rowData.limite_finalizado && (
                <ButtonIcon onClick={() => handleAction('update', rowData)}>
                    <span className='bi-pencil lh-1' />
                </ButtonIcon>
            )}
            {rowData.activo && rowData.cantidad_finalizado === 0 && rowData.tipo === 'R' && (
                <ButtonIcon variant='danger' className='ms-2' onClick={() => handleAction('delete', rowData)}>
                    <span className='bi-trash lh-1' />
                </ButtonIcon>
            )}
        </div>
    )
    const columns = [
        ...(tipo === 'A'
            ? []
            : [{ field: 'numero', header: 'Número', alignHeader: 'center', bodyClassName: 'text-center' }]),
        {
            field: 'monto_asignado',
            header: 'Monto Bs.',
            alignHeader: 'center',
            body: (rowData) => formatCurrency(rowData.monto_asignado),
            bodyClassName: 'text-end',
        },
        { field: 'fuente_fondo', header: 'Fondos', alignHeader: 'center', bodyClassName: 'text-center' },
        {
            field: 'fecha_creacion',
            header: 'Fecha Registro',
            alignHeader: 'center',
            body: (rowData) => formatDate(rowData.fecha_creacion, 'd/m/Y'),
            bodyClassName: 'text-center',
        },
        {
            field: 'cantidad_finalizado',
            header: 'Finalizado',
            alignHeader: 'center',
            body: (rowData) => (rowData.cantidad_finalizado > 0 ? 'Si' : 'No'),
            bodyClassName: 'text-center',
        },
        { field: 'id', header: 'Opciones', alignHeader: 'center', body: optionsTemplate },
    ]
    return (
        <>
            <Header title={tipo === 'R' ? 'LISTA DE FONDOS ROTATORIO' : 'LISTA DE FONDOS EN AVANCE'} />
            <div className='container p-3 bg-body rounded min-width-web-only h-100 mx-auto'>
                {tipo === 'R' && (
                    <div className='text-end p-3 bg-body-tertiary'>
                        <Button
                            startIcon={<span className='bi-plus-lg' />}
                            onClick={() => handleAction('create')}
                            disabled={loading}
                        >
                            Nuevo Fondo
                        </Button>
                    </div>
                )}
                <DataTable
                    columns={columns}
                    values={fondos}
                    responsive
                    lazy
                    pagination
                    currentPage={pagination.currentPage}
                    pageSize={pagination.pageSize}
                    totalItems={pagination.totalRecords}
                    onPage={handlePage}
                />
            </div>
            <Confirm
                show={show.update}
                onHide={() => closeModal('update')}
                onReject={() => closeModal('update')}
                onAccept={() => handleAction('confirm-update', selected)}
                onExited={onCloseModal}
            >
                <span className='lh-1' style={{ fontSize: '1.1rem' }}>
                    El
                    <span className='fw-bold mx-2'>
                        Fondo {selected?.tipo === 'R' ? `Rotatorio N° ${selected?.numero}` : 'en Avance'}
                    </span>
                    fue finalizado anteriormente. Usted podrá editarlo{' '}
                    {selected?.limite_finalizado - selected?.cantidad_finalizado} vez/veces más.
                    <br />
                    ¿Esta seguro de <span className='text-primary'>EDITAR</span> el Fondo{' '}
                    {selected?.tipo === 'R' ? 'Rotatorio' : 'en Avance'}?
                </span>
            </Confirm>
            <Confirm
                show={show.delete}
                onHide={() => closeModal('delete')}
                onReject={() => closeModal('delete')}
                onAccept={handleDelete}
                onExited={onCloseModal}
            >
                <span className='lh-1' style={{ fontSize: '1.1rem' }}>
                    ¿Esta seguro de <span className='text-primary'>ELIMINAR</span> el Fondo{' '}
                    {selected?.tipo === 'R' ? `Rotatorio Nro ${selected?.numero}` : 'en Avance'}?
                </span>
            </Confirm>
        </>
    )
}
