import React, { useState } from 'react'
import Form from 'react-validation/build/form'
import Input from 'react-validation/build/input'
import { Link } from "react-router-dom";
import CheckButton from "react-validation/build/button";
import { required, validatorEmail } from '../Component/Validation/validations'
import Services from '../services/report.service';

/*Forgot Password
Retorna el formulario previo al reseteo de contrasenia.
Valida el correo proporcionado, verificando su existencia en el sistema y enviando un mensaje al mismo
donde se proporcionara un link para el reseteo formal de la contrasenia del usuario que solicita
*/
export default function ForgotPassword() {
    //Declaracion de estados (variables)
    const [checkbtn, setCheckbtn] = useState('');
    const [form, setForm] = useState('');
    const [email, setEmail] = useState('');
    const [loading, setLoading] = useState(false);
    const [send, setSend] = useState(false);
    const [send2, setSend2] = useState(false);
    const [message, setMessage] = useState('');

    //Envia el mensaje de reseteo al correo electronico proporcionado
    const handleSubmit = async (e) => {
      e.preventDefault();
      //cambia el valor del loading al true
      setLoading(true);
      //valida los campos del formulario
      form.validateAll();
      //verifica si NO hay errores en el formulario
      if (checkbtn.context._errors.length === 0) {
        //consulta a backend si el correo proporcionado esta asociado a un usuario del sistema
        await Services.resetPassword(email).then((res) => {
                //verifica que no hay errores
                if(res.errors.length === 0){
                    //cambia el valor de message al mensaje que se ha recibido
                    setMessage(res.results.message)
                    //cambia el calor de send a true (Muestra el mensaje de envio exitoso)
                    setSend(true)
                    //guarga en el localstorage el token (valida la usuario para realizar la accion solicitada) que se ha recibido
                    localStorage.setItem('tokenP', res.results.token)
                }else{
                    //cambia el valor de message al mensaje de error que se ha recibido
                    setMessage(res.errors[0])
                    //cambia el valor de send2 a true (Muestra el mensaje de error)
                    setSend2(true)
                }
            })
            .catch(() => {
                //cambia el valor de loading a false
                setLoading(false)
            });
        }else {
            //cambia el valor de loading a false
            setLoading(false)
        }
      }

  return (
    <center>
        <div className='container shadow-sm p-5 bg-body rounded' style={{minWidth:"40%"}}>
            <center>
                <i class="fa fa-question-circle fa-3x" aria-hidden="true" style={{color:'#003770'}}></i>
                <h5>Olvidaste tu contraseña?</h5>
                <label>Puedes RESTABLECER tu contraseña aqui</label>
                {   //verifica si hay un mensaje de exito para visualizar
                    send ? <div className='alert alert-success' style={{marginBlock:'1vw', alignItems:'center', justifyContent:'center', display:'flex'}}>
                      <i className="fa fa-check fa-2x" aria-hidden="true" style={{color:'green'}}></i>
                      <label style={{paddingLeft:'0.5vw'}}>{message}</label>
                    </div> :
                    //verifica si hay mensaje de error para visualizar
                    send2 ? <div className='alert alert-danger' style={{marginBlock:'1vw', alignItems:'center', justifyContent:'center', display:'flex'}}>
                    <i className="fa fa-times fa-2x" aria-hidden="true" style={{color:'#e30613'}}></i>
                    <label style={{paddingLeft:'0.5vw'}}>{message}</label>
                  </div> :
                  //si no hay mensajes, se visualiza el formulario
                    <Form onSubmit={ handleSubmit } style={{paddingInline:"10%"}} ref={(c) => { setForm(c)}}>
                      <label style={{float:'left', marginTop:'1vw'}}>Correo electronico</label>
                        <Input
                            type="text"
                            className="form-control"
                            id="email"
                            name="email"
                            value={email}
                            onChange={(e)=>setEmail(e.target.value)}
                            validations={[required, validatorEmail]}
                            placeholder='Correo electronico '
                        />
                        <button className="btn btn-dark" disabled={loading}  style={{width:'100%', marginBlock:'1vw'}}>
                            {loading && (
                                <span className="spinner-border spinner-border-sm"></span>
                            )}
                            <span>RESTABLECER CONTRASEÑA</span>
                        </button>
                        <CheckButton
                            style={{ display: "none" }}
                            ref={(c) => {
                                setCheckbtn(c)
                            }}
                        />
                    </Form>
                }
                <Link to='/login'>Volver</Link>
            </center>
        </div>
    </center>
  )
}

