import { React, Component } from 'react';
import ExcelFileDataAccount from '../ReportsExcel/ExcelFileDataAccount'
import ReportAccountPlan from '../Reports/ReportAccountPlan';
import Services from '../services/report.service'

import LoadingSpiner from '../Component/LoadingSpinner';
import PopUpMessage from '../Component/PopUpMessage';
import FacturaServices from 'services/factura.service';

const excelPlanCuenta = new ExcelFileDataAccount()

const reportPdf = new ReportAccountPlan()

/**
 * Clase que genera la pagina para generar el reporte plan de cuentas
 *
 */
export default class ChartAccount extends Component {

    constructor(props) {
        super(props);
        this.state = {
            visible: false,
            inputExport: '',
            showModal: false,
            user: '',
            structureName: '',
            structureReport: '',
            itemsR: [],
            units: [],
            estructuras: [],
            unitseleccted: 0,
            structureSeleccted: 0,
            loading: false,
            loadingExcel: false,
            loadingPrint: false,
            spinner: false,
            popUpVisible: false
        }

        this.handleStateChange = this.handleStateChange.bind(this)
        this.generateExcelReport = this.generateExcelReport.bind(this)
        this.generatePdfReport = this.generatePdfReport.bind(this)
        this.handleModal = this.handleModal.bind(this)
        this.handleStateUnit = this.handleStateUnit.bind(this)
        this.handleUnitsList = this.handleUnitsList.bind(this)
        this.getUserName = this.getUserName.bind(this)
        this.getUserRole = this.getUserRole.bind(this)
        this.setButtonSpinner = this.setButtonSpinner.bind(this)
        this.hiddenButtonSpinner = this.hiddenButtonSpinner.bind(this)
        this.closePopUp = this.closePopUp.bind(this)
    }

    componentDidMount() {
        this.getUserRole()
        this.getUserName()
    }

    /**
     * Obtiene el rol del usuario carga las opciones que puede modificar
     *
     */
    async getUserRole() {
        this.setState({ spinner: true })
        await Services.accountPlanRole().then(async (data) => {
            if (!data.admin && !data.adminReport) {
                this.setState({ visible: false })
                this.setState({ structureSeleccted: data.estructura })
                await Services.getLitUnitByStructure(this.state.structureSeleccted).then((data) => {
                    this.setState({ units: data })
                })
                this.setState({ spinner: false })
            } else {
                this.setState({ visible: true })
                await this.loadSelectIni()
                this.setState({ spinner: false })
            }
        })
    }

    async getUserName() {
        await Services.getUserData().then((data) => {
            this.setState({ user: data.name })
            this.setState({ structureName: data.unidad })
            this.setState({ structureReport: data.unidad });
        })
    }

    handleStateUnit(id) {
        this.setState({ unitseleccted: id })
    }

    async handleUnitsList(structure, structureToPrint) {
        if (structure > 0) {
            this.setState({ structureReport: structureToPrint });
        } else {
            this.setState({ structureReport: this.state.structureName });
        }
        this.setState({ structureSeleccted: structure })
        await Services.getLitUnitByStructure(structure).then((data) => {
            this.setState({ units: data })
        });
    }

    /**
     * Carga la estructura a la que pertenece el usuario
     */
    async loadSelectIni() {

        await FacturaServices.listarEstructurasTarifario().then((response) => {
            console.log(response.data)    
            this.setState({ estructuras: response.data })
 
            //     await Services.getListEstructure().then((data) => {
            // this.setState({ estructure: data })
        })
        await Services.getLitUnitByStructure(0).then((data) => {
            this.setState({ units: data })
        });
        // if (this.state.adminReport) {
        //     await FacturaServices.listarEstructurasFacturas().then((response) => {
        //         this.setState({ structures: response.data })
        //     })
        // }
        //     await Services.getLitUnitByStructure(0).then((data) => {
        //     this.setState({ units: data })
        // });

    }

    setButtonSpinner(isPrint) {
        if (isPrint) {
            this.setState({ loading: false })
            this.setState({ loadingPrint: true })
        } else {
            this.setState({ loading: true })
            this.setState({ loadingPrint: false })
        }
    }

    hiddenButtonSpinner() {
        this.setState({ loading: false })
        this.setState({ loadingPrint: false })
        this.setState({ loadingExcel: false })
    }

    async generateExcelReport() {
        this.setState({ loadingExcel: true })
        await Services.getAccountPlan(this.state.unitseleccted, this.state.structureSeleccted).then((data) => {
            this.setState({ itemsR: data })
        })
        if (this.state.itemsR.length > 0) {
            excelPlanCuenta.generateExcel(this.state.itemsR, 'Plan-Cuentas', this.state.inputExport.trim())
            this.hiddenButtonSpinner()
        } else {
            this.hiddenButtonSpinner()
            this.setState({ popUpVisible: true })
        }
    }

    async generatePdfReport(isPrint) {
        this.setButtonSpinner(isPrint)
        await Services.getAccountPlan(this.state.unitseleccted, this.state.structureSeleccted).then((data) => {
            this.setState({ itemsR: data })
        })
        if (this.state.itemsR.length > 0) {
            reportPdf.generateReportPDF(this.state.itemsR, this.state.user, isPrint, this.state.structureReport)
            this.hiddenButtonSpinner()
        } else {
            this.hiddenButtonSpinner()
            this.setState({ popUpVisible: true })
        }
    }

    handleStateChange(e) {
        this.setState({ [e.target.name]: e.target.value })
    }

    handleModal() {
        this.setState({ showModal: !this.state.showModal });
    }

    closePopUp() {
        this.setState({ popUpVisible: false })
    }

    render() {
        document.title = 'Caja UMSS - Tarifario de cuentas'

        return (
            <div>
                {this.state.spinner ? <LoadingSpiner /> : null}
                <div className='container shadow-sm p-3 bg-body rounded' style={{ marginLeft: 'auto', marginRight: "auto" }} >
                    <div className='container2'>
                        <form className='justify-content-center '>
                            <table className='table table-responsive table-borderless'>
                                <tbody>
                                    {this.state.visible ?
                                        <tr>
                                            <th> Dirección Administrativa: </th>
                                            <td> <select className="form-select form-select-sm" aria-label="Default select example" onChange={(e) => this.handleUnitsList(e.target.value, e.target.options[e.target.selectedIndex].text)}>
                                                <option value={0} >--TODAS LAS DIRECCIONES ADMINISTRATIVAS--</option>
                                                {this.state.estructuras.map((estruct) => {
                                                    return (
                                                        <option key={estruct.id} value={estruct.id}>
                                                            {`${estruct.descripcion}`}
                                                        </option>
                                                    );
                                                })}
                                            </select>
                                            </td>
                                            <td></td>
                                        </tr>
                                        : null
                                    }

                                    <tr>
                                        <th> Unidad: </th>
                                        <td> <select className="form-select form-select-sm" aria-label="Default select example" onChange={(e) => this.handleStateUnit(e.target.value)}>
                                            <option value={0}>--TODAS LAS UNIDADES--</option>
                                            {this.state.units.map((unidad, index) => {
                                                return (
                                                    <option key={unidad.codigo + index} value={unidad.id}>
                                                        {`${unidad.codigo} - ${unidad.descripcion}`}
                                                    </option>
                                                );
                                            })}
                                        </select>
                                        </td>
                                    </tr>
                                    <tr>
                                        <th> Nombre del Archivo: </th>
                                        <td><input className='form-control form-control-sm' name="inputExport" tabIndex="export" value={this.state.inputExport} onChange={this.handleStateChange} /></td>
                                    </tr>
                                </tbody>
                            </table>
                            <label style={{ textAlign: 'left' }}>Dirección por defecto C:\Descargas\fechaActual_Report.xlsx caso contrario escribir el nombre con el que se quiera grabar Ejemplo: plandecuentas.xlsx</label>

                        </form>
                    </div>
                    <div className='header'>
                        <table className='table table-responsive table-borderless mb-0'>
                            <tbody>
                                <tr>
                                    <td className='buttons'>
                                        <button type="button" className="btn btn-primary bt2"
                                            disabled={this.state.loading}
                                            onClick={() => this.generatePdfReport(false)} >
                                            {this.state.loading && (
                                                <span className="spinner-border spinner-border-sm"></span>
                                            )} <span>IMPRIMIR</span>
                                        </button>
                                        <button type="button" className="btn btn-success bt2"
                                            disabled={this.state.loadingExcel}
                                            onClick={this.generateExcelReport}>
                                            {this.state.loadingExcel && (
                                                <span className="spinner-border spinner-border-sm"></span>
                                            )} <span>EXPORTAR A EXCEL</span>
                                        </button>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
                <PopUpMessage
                    close={this.closePopUp}
                    visible={this.state.popUpVisible}
                    message={'Reporte vacío'}
                />
            </div>
        )
    }
}