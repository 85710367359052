import axios from 'axios'
import API from '../../Conection/conection'

// Respuesta de petición fallida.
const failureResponse = {
    data: { message: 'Fallo en la solicitud' },
    status: 500,
    statusText: 'Fail Request',
}
// Respuesta de petición cancelada.
const cancellationResponse = {
    data: { message: 'Solicitud Cancelada' },
    status: 499,
    statusText: 'Client Closed Request',
}

const buildResponse = (response) => {
    const { data, status, statusText } = response
    return { data, status, statusText }
}

export const BaseRequest = {
    get: async (url, config = {}) => {
        let response = failureResponse
        await API.get(url, config)
            .then((res) => {
                response = buildResponse(res)
            })
            .catch((error) => {
                if (axios.isCancel(error)) response = cancellationResponse
                else response = error.response ? buildResponse(error.response) : failureResponse
            })
        return response
    },
    post: async (url, data = {}, config = {}) => {
        let response = failureResponse
        await API.post(url, data, config)
            .then((res) => {
                response = buildResponse(res)
            })
            .catch((error) => {
                if (axios.isCancel(error)) response = cancellationResponse
                else response = error.response ? buildResponse(error.response) : failureResponse
            })
        return response
    },
    put: async (url, data = {}, config = {}) => {
        let response = failureResponse
        await API.put(url, data, config)
            .then((res) => {
                response = buildResponse(res)
            })
            .catch((error) => {
                if (axios.isCancel(error)) response = cancellationResponse
                else response = error.response ? buildResponse(error.response) : failureResponse
            })
        return response
    },
    delete: async (url, config = {}) => {
        let response = failureResponse
        await API.delete(url, config)
            .then((res) => {
                response = buildResponse(res)
            })
            .catch((error) => {
                if (axios.isCancel(error)) response = cancellationResponse
                else response = error.response ? buildResponse(error.response) : failureResponse
            })
        return response
    },
}
