import API from '../Conection/conection';
import authHeader from './auth-header';

class UserService {

  getAsociateAccounts(cod) {
    return new Promise(
      (resolve, reject) => {
        API.post('/api/listar-cuentas-asociadas', { cuenta_principal: cod }, { headers: authHeader() })
          .then(res => res.data.result)
          .then(data => resolve(data))
          .catch(err => reject(err))
      }
    )
  }

  async searchReceipt(parameter, type, action, idEstructura) {
    let res = null;
    if (type === "receipt")
      await API.post('/api/buscar-recibos', { recibo: parameter, accion: action, estructura: idEstructura }, { headers: authHeader() }).then(result => res = result).catch(err => console.log(err));
    if (type === "person")
      await API.post('/api/buscar-personas', { persona: parameter, accion: action, estructura: idEstructura }, { headers: authHeader() }).then(result => res = result).catch(err => console.log(err));
    if (type === "document")
      await API.post('/api/buscar-recibos-personas', { ci: parameter, sis: "null", accion: action, estructura: idEstructura}, { headers: authHeader() }).then(result => res = result).catch(err => console.log(err));
    if (type === "sis")
      await API.post('/api/buscar-recibos-personas', { ci: "null", sis: parameter, accion: action, estructura: idEstructura}, { headers: authHeader() }).then(result => res = result).catch(err => console.log(err));
    if (parameter === '') return [];
    return res.data;
  }

  async sendReceipt(parameter, type, id, motivo, idMovimiento) {
    let res = null;
    let data = {};
    if (type === 'anular')
      data = { recibo: parameter, accion: type, motivo: motivo, userId: id , idMovimiento};
    else
      data = { recibo: parameter, accion: type }
    await API.post('/api/recibos', data, { headers: authHeader() }).then(result => res = result).catch(err => console.log(err));
    return res;
  }
  async saveIngress(quantity, id) {
    let res = null;
    let data = {};
    data = {
      id: id,
      cantidad: quantity
    }
    await API.post('/api/ingresar-valorados', data, { headers: authHeader() }).then(result => res = result).catch(err => console.log(err));
    return res;
  }
  async getValorados() {
    let res = null;
    await API.get('/api/valorados', { headers: authHeader() }).then(result => res = result).catch(err => console.log(err));
    return res.data.result;
  }
  async getCantidadValorados(idCuenta) {
    let res = null;
    let data = {};
    data = {
      cuenta_id: idCuenta,
    }
    await API.post('/api/cantidad-valorados', data, { headers: authHeader() }).then(result => res = result).catch(err => console.log(err));
    return res.data.result;
  }

  postAccounts(busqueda, tipo) {
    return new Promise(
      (resolve, reject) => {
        var data = {};
        if (tipo === 'codigo')
          data = { codigo: busqueda };
        if (tipo === 'nombre')
          data = { nombre: busqueda };

        API.post('/api/listar-cuentas', data, { headers: authHeader() })
          .then(res => res.data.result)
          .then(data => resolve(data))
          .catch(err => reject(err))
      })
  }

  postPersons(busqueda, tipo) {
    return new Promise(
      (resolve, reject) => {
        var data = {};
        if (tipo === 'ci')
          data = { documento: busqueda };
        if (tipo === 'sis')
          data = { codigo: busqueda };

        API.post('/api/listar-personas', data, { headers: authHeader() })
          .then(res => res.data.result)
          .then(data => resolve(data))
          .catch(err => reject(err))
      }
    )
  }

  postDebts(busqueda, tipo) {
    return new Promise(
      (resolve, reject) => {
        var data = {};
        if (tipo === 'sispay')
          data = { sis: busqueda };
        if (tipo === 'cipay')
          data = { ci: busqueda };

        API.post('/api/listar-deudas/', data, { headers: authHeader() })
          .then(res => res.data.result)
          .then(data => resolve(data))
          .catch(err => reject(err))
      }
    )
  }

  postDebtsDetails(busqueda) {
    return new Promise(
      (resolve, reject) => {
        var data = { padre: busqueda };

        API.post('/api/listar-detalle-deudas/', data, { headers: authHeader() })
          .then(res => res.data.result)
          .then(data => resolve(data))
          .catch(err => reject(err))
      })
  }

  registerMovement(data) {
    return new Promise(
      (resolve, reject) => {
        API.post('/api/registrar-movimientos', data, { headers: authHeader() })
          .then(res => res.data)
          .then(data => resolve(data))
          .catch(err => reject(err))
      }
    )
  }
  async searchReceiptAll(numCi, numSis) {
    let res = null;
     await API.post('/api/buscar-recibos-personas', { ci: numCi, sis: numSis }, { headers: authHeader() }).then(result => res = result).catch(err => console.log(err));
    return res.data.result;
  }
  async getItemsReceipt(recibo, idUser, fecha_pago) {

    try {

      const list = await API.get(`/api/get-items-movimiento?recibo=${recibo}&idUser=${idUser}&idEstructura=${0}&fecha_pago=${fecha_pago}`, { headers: authHeader() });
      return list.data;
  } catch (e) {
      console.log(e);
  }
  }
}

export default UserService;