import { combineReducers } from "redux";
import auth from "./auth";
import message from "./message";

/**
 * Solo contenemos un solo Store en esta aplicacion, 
 * Usamos la composición reductora para dividir la lógica de manejo de datos.
 */
export default combineReducers({
    auth,
    message,
});