import { forwardRef } from 'react'
import { classNames } from '../utils'
const CheckboxFunction = ({ value, triState, onChange, onFocus, onBlur, onInput, ...props }, ref) => {
    const buildEvent = (e, v) => {
        return {
            ...e,
            originalTarget: e.target,
            target: {
                id: e.target.id,
                name: e.target.name,
                value: v,
            },
        }
    }
    const handleChange = (e) => {
        const _value = triState ? (value === false ? null : !value) : !value
        onChange && onChange(buildEvent(e, _value))
    }
    const handleInput = (e) => {
        const _value = triState ? (value === false ? null : !value) : !value
        onInput && onInput(buildEvent(e, _value))
    }
    const handleFocus = (e) => {
        const _value = triState ? (typeof value === 'boolean' ? value : null) : !!value
        onFocus && onFocus(buildEvent(e, _value))
    }
    const handleBlur = (e) => {
        const _value = triState ? (typeof value === 'boolean' ? value : null) : !!value
        onBlur && onBlur(buildEvent(e, _value))
    }
    return (
        <input
            {...props}
            ref={ref}
            type='checkbox'
            checked={value === undefined ? value : triState ? typeof value === 'boolean' : value}
            className={classNames(
                'modular-checkbox',
                value ? 'checked' : triState && value === false ? 'unchecked' : '',
                props.className,
            )}
            onChange={handleChange}
            onFocus={handleFocus}
            onBlur={handleBlur}
            onInput={handleInput}
        />
    )
}
export const Checkbox = forwardRef(CheckboxFunction)
