import reportConf from "./reportConf"

/**
 * Devuelve el pie de pagina del reporte
 *
 * @param {string} usuario
 * @returns
 */
const reportFooter = (usuario) => {
    return [
        {
            text: `Procesado por: ${usuario}`,
            fontSize: reportConf.fontSizeFooter,
            margin: [20, 20, reportConf.pageMarginRight, 20],
            alignment: 'right',
        },
    ];
}

export default reportFooter