import { useEffect, useRef, useState } from 'react'
import Form from 'react-validation/build/form'
import Input from 'react-validation/build/input'
import CheckButton from 'react-validation/build/button'
import { useDispatch } from 'react-redux'
import { successLogin } from '../actions/auth'
import { Link, useNavigate } from 'react-router-dom'
import { validatorPassword } from '../Component/Validation/validations'
import logoUpsi from '../data/img/logoSoftUmss.png'
import { Button, OverlayTrigger, Tooltip } from 'react-bootstrap'
import CapsLockDetector from '../Component/CapsLockDetector'
import { classNames } from 'utils'
import AuthServices from 'services/auth.service'
import useHttpToast from 'hooks/useHttpToast'

const failLoginMessage = 'Usuario y/o Contraseña incorrectos'

/** Pagina de Inicio de Sesión. */
export default function Login() {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const formRef = useRef(null)
    const checkRef = useRef(null)
    const { showHttpToast } = useHttpToast()
    const [loading, setLoading] = useState(false)
    const [{ username, password }, setCredentials] = useState({ username: '', password: '' })
    const [showPass, setShowPass] = useState(false)
    const [error, setError] = useState('')

    useEffect(() => {
        document.title = 'Cajas v3 - Software San Simón'
    }, [])

    const handleChange = (e) => {
        error && setError('')
        setCredentials((credentials) => ({ ...credentials, [e.target.name]: e.target.value }))
    }

    const handleLogin = async (e) => {
        e.preventDefault()
        setLoading(true)
        // Validar campos de formulario
        formRef.current?.validateAll()
        // valida si NO hay errores en algun campo del formulario
        if (checkRef.current?.context._errors.length === 0) {
            const credentials = {
                username: username.trim(),
                password: password.trim(),
            }
            if (credentials.username && credentials.password) {
                const {
                    status,
                    data: { data, message },
                } = await AuthServices.login(credentials)
                if (status === 200) {
                    dispatch(successLogin(data))
                    navigate('/')
                } else if (status === 400) {
                    setError(failLoginMessage)
                } else {
                    showHttpToast(status, { detail: message })
                }
            } else {
                setError(failLoginMessage)
            }
        }
        setLoading(false)
    }

    const tooltip = (
        <Tooltip id='passTooltip'>
            <strong>{`${showPass ? 'Ocultar' : 'Mostrar'} contraseña`}</strong>
        </Tooltip>
    )

    return (
        <div className='page-main justify-content-center align-items-center'>
            <div className='min-width-web-only my-5'>
                <div className='shadow-sm p-5 bg-body rounded row g-0'>
                    <div className='col-md-6 d-flex align-items-center justify-content-center'>
                        <img className='img-fluid' src={logoUpsi} alt='Software San Simón' width='350' />
                    </div>
                    <div className='col-md-6 p-5'>
                        <div className='text-center'>
                            <h5>INICIAR SESIÓN</h5>
                        </div>
                        <Form onSubmit={handleLogin} ref={formRef} style={{ paddingInline: '10%' }}>
                            <div className='mb-3'>
                                <label className='form-label'>Usuario</label>
                                <input
                                    type='text'
                                    className='form-control'
                                    name='username'
                                    value={username}
                                    onChange={handleChange}
                                    placeholder='Nombre de usuario'
                                ></input>
                            </div>
                            <div className='mb-3'>
                                <div className='d-flex'>
                                    <label className='form-label'>
                                        Contraseña
                                        <OverlayTrigger placement='right' overlay={tooltip} delay={100}>
                                            <span className='d-inline-block ms-2 cursor-pointer'>
                                                <i
                                                    onClick={() => setShowPass(!showPass)}
                                                    style={{ fontSize: '1.25rem', lineHeight: 1 }}
                                                    className={classNames([
                                                        'bi',
                                                        showPass ? 'bi-eye-slash-fill' : 'bi-eye',
                                                    ])}
                                                ></i>
                                            </span>
                                        </OverlayTrigger>
                                    </label>
                                    <CapsLockDetector />
                                </div>
                                <Input
                                    type={showPass ? 'text' : 'password'}
                                    className='form-control mb-2'
                                    name='password'
                                    value={password}
                                    onChange={handleChange}
                                    validations={[validatorPassword]}
                                    placeholder='contraseña'
                                ></Input>
                                {error && <div className='msg-error text-center'>{error}</div>}
                            </div>
                            <Button type='submit' className='d-block w-100 mb-2' disabled={loading}>
                                {loading && <span className='spinner-border spinner-border-sm me-2'></span>}
                                INGRESAR
                            </Button>
                            <div className='text-center'>
                                <Link to='/forgotPassword'>Olvidaste tu contraseña?</Link>
                            </div>
                            <CheckButton style={{ display: 'none' }} ref={checkRef} />
                        </Form>
                    </div>
                </div>
            </div>
        </div>
    )
}
