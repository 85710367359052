import ExcelFile from "./ExcelFile";

export default class ExcelConcepto{
    totalReportConcept = 0.00

    listConcept = []

    /**
     * Metodo que manda a generar el informe consolidado por concepto en formato excel
     * 
     * @param JSON detailsConcept 
     * @param string userName 
     * @param string initDate 
     * @param string endDate 
     * @param string fileName nombre que tendra el archivo al descargar 
     */
    excelReport(detailsConcept, userName, initDate, endDate, fileName){
       
        const reportConcept =new ExcelFile()

        this.totalReportConcept = 0.00

        this.listItemsConcept(detailsConcept)

        const columnWidth = [ 
            { width: 20, alignmen: 'left' }, 
            { width: 20, alignmen: 'left' }, 
            { width: 70 }, 
            { width: 20, alignment: 'right', style: { numFmt: '#,##0.00_);(#,##0.00)' } }
        ]

        const columnHeaderConcept = [
            { name: "D.A." },
            { name: "codigo" },
            { name: "Concepto" },
            { name: "total", totalsRowFunction: 'sum' }
        ]

    // , columna title total, columna valor total

        reportConcept.generateExcelFile(
            detailsConcept,
            "Inf-Con_Concepto",
            "Informe Consolidado por Concepto",
            userName,
            initDate,
            endDate,
            columnWidth,
            "D1",
            columnHeaderConcept,
            this.listConcept,
            this.totalReportConcept,
            "Total Consolidado",
            true,
            'C',
            'D',
            fileName
        )
    }

    listItemsConcept(items){
        this.listConcept = []
        for(var i=0; i<items.length; i++){
            var list2 = []            
            for(var a=0; a<items[i].lista.length; a++){
                this.totalReportConcept += parseFloat(items[i].lista[a].total)
                list2.push([
                    items[i].lista[a].da,
                    items[i].lista[a].codigo,
                    items[i].lista[a].concepto,
                    parseFloat(items[i].lista[a].total)
                ]) 
            }
            this.listConcept.push(list2)
        }
    }
}