import Header from 'Component/Header'
import LoadingSpinner from 'Component/LoadingSpinner'
import useHttpToast from 'hooks/useHttpToast'
import { formatCurrency, formatDate, getDecimalPart, numberName } from 'utils'
import { Button } from 'modules/modular/Button'
import { ButtonIcon } from 'modules/modular/ButtonIcon'
import { DataTable } from 'modules/modular/DataTable'
import { EditableSelect } from 'modules/modular/EditableSelect'
import { Input } from 'modules/modular/Input'
import { Select } from 'modules/modular/Select'
import { useEffect, useRef, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import ContabilidadServices from 'services/contabilidad.service'
import { Confirm, Modal } from 'modules/modular/Modal'

const mapTiposDocumento = {}
const currentDate = formatDate(new Date(), 'Y-m-d')
const initialDetalle = {
    fecha: currentDate,
    detalle: '',
    numero_documento: '',
    tipo_documento: '',
    monto_total: '',
    rciva: '',
    it: '',
    iue: '',
    liquido_pagable: '',
    actividad: '',
}

const headerElement = ({ headers }) => (
    <thead>
        <tr>
            <th rowSpan={2}>{headers.numero?.headerElement}</th>
            <th rowSpan={2}>{headers.fecha?.headerElement}</th>
            <th rowSpan={2}>{headers.detalle?.headerElement}</th>
            <th rowSpan={2}>{headers.numero_documento?.headerElement}</th>
            <th rowSpan={2}>{headers.tipo_documento?.headerElement}</th>
            <th rowSpan={2}>{headers.monto_total?.headerElement}</th>
            <th colSpan={3} className='text-center'>
                RETENCIONES
            </th>
            <th rowSpan={2}>{headers.liquido_pagable?.headerElement}</th>
            <th rowSpan={2}>{headers.categoria_programatica?.headerElement}</th>
            {headers.id?.headerElement && <th rowSpan={2}>{headers.id.headerElement}</th>}
        </tr>
        <tr>
            <th>{headers.rciva?.headerElement}</th>
            <th>{headers.it?.headerElement}</th>
            <th>{headers.iue?.headerElement}</th>
        </tr>
    </thead>
)

export default function FormFondo({ tipo = 'view' }) {
    const { id } = useParams()
    const navigate = useNavigate()
    const { showHttpToast } = useHttpToast()
    const fechaRef = useRef(null)
    const [loading, setLoading] = useState(true)
    const [submitting, setSubmitting] = useState(false)
    const [show, setShow] = useState({ form: false, end: false, delete: false })
    const [info, setInfo] = useState(null)
    const [detalles, setDetalles] = useState([])
    const [selectedDetalle, setSelectedDetalle] = useState({ index: null, value: initialDetalle })
    const [actividades, setActividades] = useState([])
    const [errors, setErrors] = useState({})
    const [tiposDocumento, setTiposDocumento] = useState([])

    const enableUpdate = tipo !== 'view' && info?.activo && info?.cantidad_finalizado < info?.limite_finalizado

    useEffect(() => {
        const load = async () => {
            const { status, data } = await ContabilidadServices.tipoDocumento.all()
            if (status === 200) setTiposDocumento(data.data)
        }
        load()
    }, [])
    useEffect(() => {
        const loadInforme = async () => {
            if (id) {
                const { status, data } = await ContabilidadServices.fondo.view(id)
                if (status === 200) {
                    const { detalles, ...infoFondo } = data.data
                    setInfo(infoFondo)
                    setDetalles(detalles.map((d) => ({ ...d, actividad: d.categoria_programatica })))
                    // Inicializar el detalle con información del fondo
                    setSelectedDetalle({
                        ...selectedDetalle,
                        value: { ...selectedDetalle.value, actividad: infoFondo.categoria_programatica },
                    })
                    if (infoFondo.categoria_programatica) {
                        searchActividades(infoFondo.categoria_programatica)
                    }
                    setLoading(false)
                } else {
                    showHttpToast(status, { detail: data.message })
                    navigate('/')
                }
            }
        }
        loadInforme()
    }, [id])

    const openModal = (key) => setShow({ ...show, [key]: true })
    const closeModal = (key) => setShow({ ...show, [key]: false })

    const handleAction = (action, rowData, index = null) => {
        setSelectedDetalle({ index, value: rowData })
        if (['create', 'update'].includes(action)) {
            openModal('form')
        } else if (action === 'delete') {
            openModal('delete')
        }
    }

    /** Función que valida los campos del formulario de adición/edición de detalle de reembolso. */
    const validateForm = () => {
        const _errors = {}
        if (!selectedDetalle.value.fecha) {
            _errors.fecha = 'Requerido'
        }
        if (!selectedDetalle.value.detalle.trim()) {
            _errors.detalle = 'Requerido'
        }
        if (!selectedDetalle.value.tipo_documento) {
            _errors.tipo_documento = 'Requerido'
        }
        if (!selectedDetalle.value.numero_documento.toString().trim()) {
            _errors.numero_documento = 'Requerido'
        }
        if (!(selectedDetalle.value.monto_total > 0)) {
            _errors.monto_total = 'Requerido'
        }
        if (!selectedDetalle.value.actividad_id && !selectedDetalle.value.actividad?.id) {
            _errors.actividad = 'Requerido'
        }
        return _errors
    }
    /** Handler que se encarga de controlar los campos del formulario de adición/edición de detalle de reembolso. */
    const handleChange = (e) => {
        const { name, value } = e.target
        setSelectedDetalle({ ...selectedDetalle, value: { ...selectedDetalle.value, [name]: value } })
        let _errors = null
        if (errors[name]) {
            _errors = { ...errors, [name]: false }
        }
        if (name === 'monto_total' && errors.liquido_pagable) {
            _errors = { ..._errors, liquido_pagable: false }
        }
        if (_errors) {
            setErrors(_errors)
        }
    }
    /** Establece el detalle seleccionado a su valor inicial. */
    const resetSelectedDetalle = () => {
        setSelectedDetalle({ index: null, value: { ...initialDetalle, actividad: info.categoria_programatica } })
        setErrors({})
    }
    /** Registra/Edita un detalle del fondo */
    const handleCreateUpdate = async () => {
        const _errors = validateForm()
        if (Object.values(_errors).length > 0) {
            setErrors(_errors)
        } else {
            const value = {
                ...selectedDetalle.value,
                categoria_programatica:
                    selectedDetalle.value.actividad?.codigo_actividad ?? selectedDetalle.value.actividad,
                actividad_id: selectedDetalle.value.actividad?.id ?? selectedDetalle.value.actividad_id,
                ...getRetenciones(),
                fondo_id: info.id,
            }
            const _detalle =
                selectedDetalle.index === null
                    ? [...detalles, value]
                    : [...detalles.map((d, i) => (i === selectedDetalle.index ? value : d))]
            // Verificar que el saldo sea mayor a cero
            const _totales = getTotales(_detalle)
            const saldo = info?.monto_asignado - _totales.total_liquido_pagable
            if (saldo > 0) {
                const { status, data } = value.id
                    ? await ContabilidadServices.fondoDetalle.update(value.id, value)
                    : await ContabilidadServices.fondoDetalle.create(value)
                showHttpToast(status, { detail: data.message })
                if (status === 200) {
                    getDetalles()
                    closeModal('form')
                } else if (status === 201) {
                    getDetalles()
                    resetSelectedDetalle()
                    fechaRef.current?.focus()
                }
            } else {
                setErrors({ liquido_pagable: 'No debe exceder el saldo' })
            }
        }
    }
    /** Elimina un detalle del fondo. */
    const handleDelete = async () => {
        if (selectedDetalle.value.id) {
            const { status, data } = await ContabilidadServices.fondoDetalle.delete(selectedDetalle.value.id)
            showHttpToast(status, { detail: data.message })
            if (status === 200) {
                getDetalles()
                closeModal('delete')
            }
        }
    }
    /** Calcula las retenciones del detalle en registro/edición. */
    const getRetenciones = () => {
        let rciva = 0
        let it = 0
        let iue = 0
        const monto = selectedDetalle.value.monto_total
        const tipoDoc = selectedDetalle.value.tipo_documento
        if (tipoDoc && monto > 0) {
            const tipo = mapTiposDocumento[tipoDoc] ?? tiposDocumento.filter((td) => td.nombre === tipoDoc)?.[0]
            if (tipo) {
                if (tipo.rciva) rciva = monto * 0.13
                if (tipo.it) it = monto * 0.03
                if (tipo.iue) iue = monto * 0.05
                mapTiposDocumento[tipoDoc] = tipo
            }
        }
        const lp = monto - rciva - it - iue
        return { rciva, it, iue, liquido_pagable: lp }
    }
    /**
     * Obtiene los totales de los detalles del reembolso.
     * @param {Array<object>} det Array de detalles del reembolso.
     */
    const getTotales = (det) => {
        return det.reduce(
            (carry, d) => {
                carry.total_monto_total += d.monto_total * 1
                carry.total_rciva += d.rciva * 1
                carry.total_it += d.it * 1
                carry.total_iue += d.iue * 1
                carry.total_liquido_pagable += d.liquido_pagable * 1
                return carry
            },
            {
                total_monto_total: 0,
                total_rciva: 0,
                total_it: 0,
                total_iue: 0,
                total_liquido_pagable: 0,
            },
        )
    }
    /**
     * Busca las actividades de la estructura programática que coinciden con el código pasado como parametro.
     * @param {string} codigo Código de la actividad con el siguiente formato:
     * - 01-02-000-000-10 -> DAF, 2 dígitos DA + 2 dígitos UE + 3 dígitos Programa + 3 dígitos Proyecto + 2 dígitos Actividad
     */
    const searchActividades = async (codigo) => {
        if (codigo.length > 0) {
            if (codigo.length < 17) {
                const { status, data } = await ContabilidadServices.searchActividades(codigo)
                if (status === 200) {
                    setActividades(data)
                }
            }
        } else {
            setActividades([])
        }
    }
    /** Llama al servicio para guardar el reembolso del fondo. */
    // const handleSave = async () => {
    //     setSubmitting(true)
    //     const { status, data } = await ContabilidadServices.fondo.update(info.id, { detalles })
    //     showHttpToast(status, { detail: data.message })
    //     setSubmitting(false)
    // }
    /** Llama al servicio para finalizar el reembolso del fondo. */
    const handleEnd = async () => {
        setSubmitting(true)
        closeModal('end')
        const { status, data } = await ContabilidadServices.fondo.end(info.id, {})
        showHttpToast(status, { detail: data.message })
        if (status === 200) {
            navigate(`/contabilidad/fondo/${info.id}`)
        }
        setSubmitting(false)
    }
    /** Obtiene el pdf del reembolso del fondo. */
    const generatePdf = async () => {
        setSubmitting(true)
        const { status, data } = await ContabilidadServices.fondo.pdf(info.id)
        if (status === 200) {
            const pdfContent = data
            // Crear una URL local para el blob recibido
            const blobUrl = window.URL.createObjectURL(new Blob([pdfContent], { type: 'application/pdf' }))

            // Abrir una nueva pestaña con el PDF
            window.open(blobUrl, '_blank')
        } else {
            showHttpToast(status, { detail: 'Ocurrió un error al obtener el PDF' })
        }
        setSubmitting(false)
    }
    /** Obtiene los detalles del fondo. */
    const getDetalles = async () => {
        if (info.id) {
            const { status, data } = await ContabilidadServices.fondo.details(info.id)
            if (status === 200) setDetalles(data.data.map((d) => ({ ...d, actividad: d.categoria_programatica })))
        }
    }

    const retenciones = getRetenciones()
    const totales = getTotales(detalles)

    const actividadOptionTemplate = (actividad) => {
        return (
            <span title={actividad.descripcion}>
                {`${actividad.codigo_actividad} / ${actividad.abreviacion_da} -  ${actividad.descripcion}`}
            </span>
        )
    }
    const optionsTemplate = (rowData, { rowIndex }) => (
        <div>
            <ButtonIcon variant='danger' className='me-2' onClick={() => handleAction('delete', rowData, rowIndex)}>
                <span className='bi-trash lh-1' />
            </ButtonIcon>
            <ButtonIcon onClick={() => handleAction('update', rowData, rowIndex)}>
                <span className='bi-pencil lh-1' />
            </ButtonIcon>
        </div>
    )
    const columns = [
        {
            field: 'numero',
            header: 'N°',
            alignHeader: 'center',
            body: (_, { rowIndex }) => rowIndex + 1,
            bodyClassName: 'text-center',
        },
        {
            field: 'fecha',
            header: 'FECHA',
            alignHeader: 'center',
            bodyClassName: 'text-center',
            body: (rowData) => formatDate(rowData.fecha, 'd/m/Y'),
        },
        { field: 'detalle', header: 'DETALLE', alignHeader: 'center' },
        { field: 'numero_documento', header: 'N° DOCUMENTO', alignHeader: 'center', bodyClassName: 'text-center' },
        { field: 'tipo_documento', header: 'TIPO DOCUMENTO', alignHeader: 'center' },
        {
            field: 'monto_total',
            header: 'MONTO TOTAL',
            alignHeader: 'center',
            body: (rowData) => formatCurrency(rowData.monto_total),
            bodyClassName: 'text-end',
        },
        {
            field: 'rciva',
            header: '13% RC-IVA',
            alignHeader: 'center',
            body: (rowData) => formatCurrency(rowData.rciva),
            bodyClassName: 'text-end',
        },
        {
            field: 'it',
            header: '3% I.T.',
            alignHeader: 'center',
            body: (rowData) => formatCurrency(rowData.it),
            bodyClassName: 'text-end',
        },
        {
            field: 'iue',
            header: '5% I.U.E',
            alignHeader: 'center',
            body: (rowData) => formatCurrency(rowData.iue),
            bodyClassName: 'text-end',
        },
        {
            field: 'liquido_pagable',
            header: 'LIQUIDO PAGABLE',
            alignHeader: 'center',
            body: (rowData) => formatCurrency(rowData.liquido_pagable),
            bodyClassName: 'text-end',
        },
        {
            field: 'categoria_programatica',
            header: 'CATEGORIA PROGRAMATICA',
            alignHeader: 'center',
            bodyClassName: 'text-center',
        },
        ...(enableUpdate
            ? [
                  {
                      field: 'id',
                      header: 'OPCIONES',
                      alignHeader: 'center',
                      body: optionsTemplate,
                      bodyClassName: 'text-center',
                  },
              ]
            : []),
    ]
    const footerElement = (
        <tfoot>
            <tr>
                <th colSpan={5} className='text-center'>
                    TOTALES
                </th>
                <th className='text-end'>{formatCurrency(totales.total_monto_total)}</th>
                <th className='text-end'>{formatCurrency(totales.total_rciva)}</th>
                <th className='text-end'>{formatCurrency(totales.total_it)}</th>
                <th className='text-end'>{formatCurrency(totales.total_iue)}</th>
                <th className='text-end'>{formatCurrency(totales.total_liquido_pagable)}</th>
                <th colSpan={2}></th>
            </tr>
            <tr>
                <th colSpan={9} className='text-end'>
                    {info?.tipo === 'R' ? 'SALDO EN CAJA' : 'DEPOSITO BANCARIO'}
                </th>
                <th className='text-end'>{formatCurrency(info?.monto_asignado - totales.total_liquido_pagable)}</th>
                <th colSpan={2}></th>
            </tr>
        </tfoot>
    )

    return loading ? (
        <LoadingSpinner />
    ) : (
        <>
            <Header
                title={info.tipo === 'R' ? `FONDO ROTATORIO NRO ${info.numero ?? '#'}` : 'CIERRE DE FONDO EN AVANCE'}
            />
            <div className='p-3 mx-5 my-4 bg-body rounded'>
                <div className='d-flex mb-3'>
                    <div>
                        <span className='fw-bold me-2'>BENEFICIARIO:</span>
                        {info.beneficiario}
                        <br />
                        <span className='fw-bold me-2'>UNIDAD:</span>
                        {info.actividad}
                        <br />
                        <span className='fw-bold me-2'>MONTO ASIGNADO:</span>
                        {info ? formatCurrency(info.monto_asignado) : '0.00'}{' '}
                        {info &&
                            `- ${numberName(info.monto_asignado).toUpperCase()} ${getDecimalPart(
                                info.monto_asignado,
                            )}/100 BOLIVIANOS`}
                        <br />
                        <span className='fw-bold me-2'>FONDOS:</span>
                        {info.fuente_fondo}
                        <br />
                    </div>
                    <div className='ms-auto'>
                        {enableUpdate && (
                            <Button startIcon={<span className='bi-plus-lg' />} onClick={() => openModal('form')}>
                                Añadir
                            </Button>
                        )}
                    </div>
                </div>
                <DataTable
                    columns={columns}
                    values={detalles}
                    className='mb-3'
                    tableClassName='table-bordered'
                    headerElement={headerElement}
                    footerElement={footerElement}
                    rowKeyField='id'
                />
                <div className='text-center p-3 bg-blue rounded'>
                    {(tipo === 'view' || (enableUpdate && info.cantidad_finalizado === 0)) && (
                        <Button
                            loading={submitting}
                            startIcon={<span className='bi-arrow-left' />}
                            className='me-3'
                            onClick={() =>
                                navigate(`/contabilidad/fondo-${info.tipo === 'A' ? 'avance' : 'rotatorio'}`)
                            }
                        >
                            VOLVER
                        </Button>
                    )}
                    <Button className='me-3' onClick={generatePdf} loading={submitting}>
                        GENERAR PDF
                    </Button>
                    {enableUpdate && (
                        <>
                            <Button
                                variant='success'
                                className='me-3'
                                onClick={() => openModal('end')}
                                loading={submitting}
                            >
                                FINALIZAR
                            </Button>
                            {/* {info.cantidad_finalizado === 0 && (
                                <Button loading={submitting} onClick={handleSave}>
                                    GUARDAR
                                </Button>
                            )} */}
                        </>
                    )}
                </div>
                <Confirm
                    show={show.end}
                    onHide={() => closeModal('end')}
                    onReject={() => closeModal('end')}
                    onAccept={handleEnd}
                >
                    <span className='lh-1' style={{ fontSize: '1.1rem' }}>
                        Una vez finalice el fondo no podrá realizar más modificaciones.
                        <br />
                        ¿Esta seguro de <span className='text-primary'>FINALIZAR</span> el
                        {info.tipo === 'R'
                            ? ` Reembolso del Fondo Rotatorio N° ${info.numero}`
                            : ' Cierre del Fondo en avance'}
                        ?
                    </span>
                </Confirm>
                <Confirm
                    show={show.delete}
                    onHide={() => closeModal('delete')}
                    onReject={() => closeModal('delete')}
                    onAccept={handleDelete}
                    onExited={resetSelectedDetalle}
                >
                    <span className='lh-1' style={{ fontSize: '1.1rem' }}>
                        ¿Esta seguro de <span className='text-primary'>ELIMINAR</span> el Detalle{' '}
                        {selectedDetalle.value.detalle} con Nro. de Documento {selectedDetalle.value.numero_documento}?
                    </span>
                </Confirm>
                <Modal
                    show={show.form}
                    onHide={() => closeModal('form')}
                    onExited={resetSelectedDetalle}
                    closeOnBackdrop={false}
                    keyboard={false}
                    style={{ width: '30rem' }}
                >
                    <div className='px-3 py-2 border-bottom fs-4 fw-medium'>
                        {selectedDetalle.index === null ? 'Añadir' : ' Editar'} detalle
                    </div>
                    <div className='p-3'>
                        <table className='w-100'>
                            <thead>
                                <tr>
                                    <th style={{ width: '8.5rem' }}></th>
                                    <th></th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td className='pb-2 pe-2'>Fecha:</td>
                                    <td className='pb-2 text-end'>
                                        <Input
                                            ref={fechaRef}
                                            type='date'
                                            name='fecha'
                                            value={selectedDetalle.value.fecha}
                                            onChange={handleChange}
                                            isInvalid={!!errors.fecha}
                                        />
                                    </td>
                                </tr>
                                <tr>
                                    <td className='pb-2 pe-2'>Detalle:</td>
                                    <td className='pb-2 text-end'>
                                        <Input
                                            name='detalle'
                                            value={selectedDetalle.value.detalle}
                                            onChange={handleChange}
                                            isInvalid={!!errors.detalle}
                                        />
                                    </td>
                                </tr>
                                <tr>
                                    <td className='pb-2 pe-2'>N° Documento:</td>
                                    <td className='pb-2 text-end'>
                                        <Input
                                            name='numero_documento'
                                            value={selectedDetalle.value.numero_documento}
                                            onChange={handleChange}
                                            isInvalid={!!errors.numero_documento}
                                        />
                                    </td>
                                </tr>
                                <tr>
                                    <td className='pb-2 pe-2'>Tipo Documento:</td>
                                    <td className='pb-2 text-end'>
                                        <Select
                                            options={tiposDocumento}
                                            optionLabel='nombre'
                                            optionValue='nombre'
                                            name='tipo_documento'
                                            value={selectedDetalle.value.tipo_documento}
                                            onChange={handleChange}
                                            isInvalid={!!errors.tipo_documento}
                                        />
                                    </td>
                                </tr>
                                <tr>
                                    <td className='pb-2 pe-2 fw-bold'>Monto Total:</td>
                                    <td className='pb-2 text-end'>
                                        <Input
                                            name='monto_total'
                                            type='currency'
                                            value={selectedDetalle.value.monto_total}
                                            onChange={handleChange}
                                            isInvalid={!!errors.monto_total}
                                            className='text-end'
                                        />
                                    </td>
                                </tr>
                                <tr>
                                    <td className='pb-2 pe-2'>13% RC-IVA:</td>
                                    <td className='pb-2 text-end'>
                                        <div className='form-control bg-body-secondary'>
                                            {formatCurrency(retenciones.rciva)}
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <td className='pb-2 pe-2'>3% I.T.:</td>
                                    <td className='pb-2 text-end'>
                                        <div className='form-control bg-body-secondary'>
                                            {formatCurrency(retenciones.it)}
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <td className='pb-2 pe-2'>5% I.U.E:</td>
                                    <td className='pb-2 text-end'>
                                        <div className='form-control bg-body-secondary'>
                                            {formatCurrency(retenciones.iue)}
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <th className='pb-2 pe-2'>Liq. Pagable:</th>
                                    <td className='pb-2'>
                                        <div
                                            className='form-control  bg-body-secondary text-end'
                                            style={
                                                errors.liquido_pagable
                                                    ? { borderColor: 'var(--bs-form-invalid-border-color)' }
                                                    : undefined
                                            }
                                        >
                                            {formatCurrency(retenciones.liquido_pagable)}
                                        </div>
                                        <span className='d-block invalid-feedback mt-0'>{errors.liquido_pagable}</span>
                                    </td>
                                </tr>
                                <tr>
                                    <td className='pb-2 pe-2'>Cat. Prog.:</td>
                                    <td className='pb-2'>
                                        <EditableSelect
                                            options={actividades}
                                            optionValue='codigo_actividad'
                                            optionTemplate={actividadOptionTemplate}
                                            valueAsOption
                                            name='actividad'
                                            value={selectedDetalle.value.actividad}
                                            onChange={handleChange}
                                            lazy
                                            autoSelect
                                            isInvalid={!!errors.actividad}
                                            placeholder='Buscar'
                                            containerClassName='w-100'
                                            menuStyle={{ minWidth: '100%' }}
                                        />
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <div className='px-3 py-2 border-top text-end'>
                        <Button className='me-2' variant='outline-primary' onClick={() => closeModal('form')}>
                            Cancelar
                        </Button>
                        <Button onClick={handleCreateUpdate}>Guardar</Button>
                    </div>
                </Modal>
            </div>
        </>
    )
}
