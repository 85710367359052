/**
 * Formatea una fecha según un formato definido.
 * @param {any} date Fecha a formatear. Acepta fechas js, cadenas de texto y fechas en formato unix.
 * @param {string} format Formato que tomara la fecha, igual al formato de PHP, por defecto 'Y-m-d'.
 * @param {string} defaultValue Valor devuelto si la fecha es invalida. Por defecto string vacío.
 * @returns {string} Retorna la fecha con el formato especificado, en caso que fecha sea invalida retorna el valor por defecto.
 */
export function formatDate(date, format = 'Y-m-d', defaultValue = '') {
    const _date = parseDate(date)
    if (isValidDate(_date)) {
        const year = _date.getFullYear()
        const month = _date.getMonth() + 1 // 1 es Enero y 12 es Diciembre
        const day = _date.getDate()
        const weekDay = _date.getDay()
        const weekDayISO = weekDay === 0 ? 7 : weekDay // ISO-8601
        let formatted = ''
        format.split('').forEach((value) => {
            let add = ''
            switch (value) {
                case 'Y':
                    add = year
                    break
                case 'y':
                    add = year.toString().slice(-2)
                    break
                case 'm':
                    add = `0${month}`.slice(-2)
                    break
                case 'n':
                    add = month
                    break
                case 'M':
                    add = _shortMonths[month]
                    break
                case 'F':
                    add = _months[month]
                    break
                case 'd':
                    add = `0${day}`.slice(-2)
                    break
                case 'j':
                    add = day
                    break
                case 'w':
                    add = weekDay
                    break
                case 'N':
                    add = weekDayISO
                    break
                case 'D':
                    add = _shortDays[weekDayISO]
                    break
                case 'l':
                    add = _days[weekDayISO]
                    break
                case 'H':
                    add = `0${_date.getHours()}`.slice(-2)
                    break
                case 'i':
                    add = `0${_date.getMinutes()}`.slice(-2)
                    break
                case 's':
                    add = `0${_date.getSeconds()}`.slice(-2)
                    break
                default:
                    add = value
                    break
            }
            formatted = `${formatted}${add}`
        })
        return formatted
    } else {
        return defaultValue
    }
}

/**
 * Verifica si un dato es de tipo Date valido.
 * @param {any} date Dato a verificar.
 * @returns {boolean} Retorna true si es un Date valido, false en caso contrario.
 */
export function isValidDate(date) {
    return !isNaN(date) && date instanceof Date
}

/**
 * Obtiene una fecha con el tiempo pasado como parámetro.
 * @param {Date | string | number} date Fecha a aumentar la hora.
 * @param {string} time Hora a agregar a la fecha.
 * @return {Date} Fecha con la hora definida.
 */
export function getDateTime(date, time) {
    const regexTime = /^\d{1,2}:\d{2}(:\d{2}(\.\d{0,4})?)?$/
    const d = parseDate(date, new Date())
    if (typeof time === 'string' && regexTime.test(time)) {
        const times = time.split(':')
        d.setUTCHours(times[0])
        d.setUTCMinutes(times[1])
        d.setUTCSeconds(times.length > 2 ? times[2] : 0)
    }
    return d
}

/**
 * Convierte un dato a tipo Date.
 * @param {any} date Dato a convertir a Date. Unix en caso numérico.
 * @param {any} defaultValue Valor por devuelto si la fecha es invalida. Por defecto NaN.
 * @returns {any} Retorna un Date valido o el valor por defecto.
 */
export function parseDate(date, defaultValue = null) {
    let result = date
    if (typeof date === 'number') {
        result = new Date(date * 1000)
    } else if (typeof date === 'string') {
        result = new Date(date.includes(':') ? date : `${date} 00:00:00`)
    }
    return isValidDate(result) ? result : defaultValue
}

const _months = {
    1: 'Enero',
    2: 'Febrero',
    3: 'Marzo',
    4: 'Abril',
    5: 'Mayo',
    6: 'Junio',
    7: 'Julio',
    8: 'Agosto',
    9: 'Septiembre',
    10: 'Octubre',
    11: 'Noviembre',
    12: 'Diciembre',
}
const _shortMonths = {
    1: 'Ene',
    2: 'Feb',
    3: 'Mar',
    4: 'Abr',
    5: 'May',
    6: 'Jun',
    7: 'Jul',
    8: 'Ago',
    9: 'Sep',
    10: 'Oct',
    11: 'Nov',
    12: 'Dic',
}
const _days = {
    1: 'Lunes',
    2: 'Martes',
    3: 'Miércoles',
    4: 'Jueves',
    5: 'Viernes',
    6: 'Sábado',
    7: 'Domingo',
}
const _shortDays = {
    1: 'Lu',
    2: 'Ma',
    3: 'Mi',
    4: 'Ju',
    5: 'Vi',
    6: 'Sa',
    7: 'Do',
}

export const months = Object.values(_months)
export const days = Object.values(_days)

/**
 * Obtiene el nombre del mes.
 * @param {number | string} month Representación numérica de un mes, 1 es Enero y 12 es Diciembre.
 * @param {boolean} short Especifica si el nombre del mes sera corto o no.
 * @returns {string} Retorna el nombre del mes.
 */
export function monthName(month, short = false) {
    return (short ? _shortMonths[month] : _months[month]) || ''
}
/**
 * Obtiene el nombre del día.
 * @param {number | string} day Representación numérica ISO-8601 del día de la semana, 1 es Lunes y 7 es Domingo.
 * @param {boolean} short Especifica si el nombre del día sera corto o no.
 * @returns {string} Retorna el nombre del día.
 */
export function dayName(day, short = false) {
    return (short ? _shortDays[day] : _days[day]) || ''
}
