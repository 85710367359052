import reportConf from "./reportConf"
/**
 * Devuelve el cuadro del total consolidado de un reporte
 *
 * @param {integer} total
 * @param {string} text
 * @param {array} marginTotal array opcional de margenes del total
 * @returns
 */

const cuadroTotal = (total, text='Total Consolidado', marginTotal=[0,0,0,0]) => {
    return {
        margin: [215, 5, 0, 0],
        fontSize: reportConf.fontSizeTotalReporte,
        table: {
            widths: [145, 138],
            body: [
                [
                    { text: text, bold: true },
                    {
                        text: new Intl.NumberFormat("en-US", {
                            minimumFractionDigits: 2,
                        }).format(total),
                        bold: true,
                        alignment: "right",
                        margin: marginTotal
                    },
                ],
            ],
        },
        layout: {
            hLineWidth: function (i, node) {
                return i === 0 || i === node.table.body.length ? 0.5 : 0;
            },
            vLineWidth: function (i, node) {
                return i === 0 || i === node.table.widths.length ? 0.5 : 0;
            },
        },
    };
}

export default cuadroTotal