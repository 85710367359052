import TuitionReport from "../../TuitionReport";

const generateMatricula = async (debt, persona, distribucion, payload, personaIsObject = true, documento = null, user = '') => {
    const report = new TuitionReport();
    const items = [];
    const distributionSums = {};
    const cashPay = debt.metodo_pago === 'C' || debt.cobro_caja;
    distribucion.forEach(element => {
      if (cashPay && element.SC === "PAELE") return;
      if (distributionSums[element.CD] !== undefined) {
        distributionSums[element.CD] += element.M;
      } else {
        if (element.CD !== 0) {
          distributionSums[element.CD] = element.M;
        }
      }
    });
    Object.entries(distributionSums).forEach( ([distribucion, suma], i) => {
      items.push(
        {
          concept: `${distribucion}`,
          cost: parseFloat(suma),
        }
      )

    });
    await report.generateReport([debt.recibo], personaIsObject ?
    `${persona.apellido_1 || ''} ${persona.apellido_2 || ''} ${persona.nombre_1 || ''} ${persona.nombre_2 || ''}` : persona,
    items, personaIsObject ? persona.documento : documento, payload.STNO, debt.fecha_pago, payload, user);
  }

export default generateMatricula;

