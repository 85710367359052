import { useSelector } from 'react-redux'
import LoadingSpinner from '../Component/LoadingSpinner'
import { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { redirectByRole, rolesByPriority } from 'data/constants'

// Ruta de redireccion predetereminada
const defaultRedirect = '/inicio'

/**
 * Componente que re-direcciona al usuario a la pagina correspondiente dependiendo sus roles
 * @returns Retorna el componente LoadingSection.
 */
export default function LoadingSection() {
    const navigate = useNavigate()
    const { roles, user } = useSelector((state) => state.auth)

    useEffect(() => {
        if (user) {
            let redirectTo = defaultRedirect
            for (let index = 0; index < rolesByPriority.length; index++) {
                // role puede ser string o array de strings
                const role = rolesByPriority[index]
                if (Array.isArray(role)) {
                    // Para rutas que requieren más de un rol asignado
                    if (role.every((_role) => roles.includes(_role))) {
                        redirectTo = redirectByRole[role.sort().join('-_-')] ?? defaultRedirect
                        break
                    }
                } else {
                    // Para rutas que requieren solo un rol
                    if (roles.includes(role)) {
                        redirectTo = redirectByRole[role] ?? defaultRedirect
                        break
                    }
                }
            }
            navigate(redirectTo)
        }
    }, [user, roles])

    return <LoadingSpinner />
}
