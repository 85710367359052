import logo from './img/logoumss.png'

import reportConf from './reportConf'

/**
 * Función que devuelve el encabezado del reporte, con nombre de la institución, caja de emisión
 * número de pagina, fecha y hora de emisión, titulo del reporte, usuario emisor, y fecha inicio y
 * fecha fin de los datos generados.
 *
 * @param {integer} currentPage
 * @param {integer} pageCount
 * @param {string} reportTitle
 * @param {string} unidadName
 * @param {string} userName
 * @param {string} initDate
 * @param {string} endDate
 * @param {date} date
 * @returns
 */
const reportHeader = (currentPage, pageCount, reportTitle, unidadName, userName, initDate, endDate, electornico, caja) => {
    const date = new Date();
    const outputDate =
        String(date.getDate()).padStart(2, "0") +
        "-" +
        String(date.getMonth() + 1).padStart(2, "0") +
        "-" +
        date.getFullYear().toString();
    const outputTime =
        date.getHours() +
        ":" +
        date.getMinutes().toLocaleString(undefined,{minimumIntegerDigits: 2}) +
        ":" +
        date.getSeconds().toLocaleString(undefined,{minimumIntegerDigits: 2});
    const logoWidth = 30;
    let tipoCobro = '';
    if (electornico) {
        tipoCobro = 'Cobros electronicos';
    } else if (caja) {
        tipoCobro = 'Cobros de caja';
    } else {
        tipoCobro = 'Todos';
    }
    return [
        {
            fontSize: reportConf.fontSizeHeader,
            margin: [reportConf.pageMarginLeft, 20, reportConf.pageMarginRight, 0],
            table: {
                headerRows: 1,
                widths: [logoWidth, '*', 'auto'],
                body: [
                    [
                        {
                            image: logo,
                            width: logoWidth,
                        },
                        {
                            text: `UNIVERSIDAD MAYOR DE SAN SIMÓN\n ${unidadName}`,
                            margin: [0, 10, 0, 0],
                        },
                        {
                            text: `Página: ${currentPage}/${pageCount}\n ${outputDate}  ${outputTime}`,
                            noWrap: false,
                            margin: [0, 10, 0, 0],
                        },
                    ],
                ],
            },
            layout: 'noBorders',
        },
        {
            text: `${reportTitle}`,
            fontSize: reportConf.fontSizeTitle,
            bold: true,
            alignment: 'center',

        },
        {
            text: '(Expresado en Bolivianos)',
            fontSize: reportConf.fontSizeTitleFooter,
            bold: true,
            margin: [0, 0, 0, 10],
            alignment: 'center',

        },
        {
            fontSize: reportConf.fontSizeReportData,
            margin: [63, 10, 0, 0],
            table: {
                body: [
                    ['Modalidad de cobro', ':', tipoCobro],
                    ['Cajero', ':', `${userName}`],
                    ['Fecha', ':', `Del ${initDate}   Al ${endDate}`],
                ]
            },
            layout: {
                hLineWidth: () => 0,
                vLineWidth: () => 0,
                paddingTop: () => 0,
                paddingBottom: () => 0,
                paddingLeft: () => 0,
            },
        },
    ]
}

export default reportHeader