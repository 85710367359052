import { forwardRef, useEffect } from 'react'
import { classNames } from '../utils'
import { useToast } from './useToast'
import InfoCircle from '../icons/InfoCircle'
import CheckLg from '../icons/CheckLg'
import ExclamationTriangle from '../icons/ExclamationTriangle'
import { XLg } from '../icons/XLg'
// Iconos por severidad.
const variantIcons = {
    info: <InfoCircle className='toast-icon' width='1em' height='1em' />,
    success: <CheckLg className='toast-icon' width='1em' height='1em' />,
    neutral: <InfoCircle className='toast-icon' width='1em' height='1em' />,
    warning: <ExclamationTriangle className='toast-icon' width='1em' height='1em' />,
    error: <XLg className='toast-icon' width='1em' height='1em' />,
}
export const Toast = forwardRef(
    (
        {
            id,
            title,
            detail,
            delay,
            variant = 'info',
            sticky,
            className,
            style,
            icon,
            content,
            contentClassName,
            contentStyle,
        },
        ref,
    ) => {
        const { pauseToast, dismissToast, removeToast } = useToast()
        useEffect(() => {
            !sticky && dismissToast(id, delay)
        }, [])
        /** Quita el tiempo de desaparición del mensaje del Toast. */
        const handleMouseEnter = () => {
            pauseToast(id)
        }
        /** Quita el hover del mensaje. */
        const handleMouseLeave = () => {
            dismissToast(id, delay)
        }
        /** Cierra el toast */
        const handleClose = () => {
            removeToast(id)
        }
        return (
            <div
                ref={ref}
                style={style}
                {...(!sticky && {
                    onMouseEnter: handleMouseEnter,
                    onMouseLeave: handleMouseLeave,
                })}
                className={classNames(['modular-toast', `toast-${variant}`, className])}
            >
                <div className={classNames(['toast-content', contentClassName])} style={contentStyle}>
                    {content ? (
                        content
                    ) : (
                        <>
                            {icon ? (
                                typeof icon === 'string' ? (
                                    <span className={classNames(['toast-icon', icon])} />
                                ) : (
                                    icon
                                )
                            ) : (
                                variantIcons[variant]
                            )}
                            <div className='toast-message'>
                                <span className='toast-title'>{title}</span>
                                <div className='toast-detail'>{detail}</div>
                            </div>
                        </>
                    )}
                    <div>
                        <button type='button' onClick={handleClose} className='toast-close-button'>
                            <XLg />
                        </button>
                    </div>
                </div>
            </div>
        )
    },
)
