import React, { useState, useEffect } from 'react';

function CapsLockDetector() {
  const [capsLockIsOn, setCapsLockIsOn] = useState(false);

  useEffect(() => {

    function handleCapsLockState(event) {
        setCapsLockIsOn(event.getModifierState('CapsLock'));
    }
    function handleKeyDown(event) {
      if (event.getModifierState){
        if (event.getModifierState('CapsLock')) {
          setCapsLockIsOn(true);
        }
      }
    }

    function handleKeyUp(event) {
      if (event.getModifierState){
        if (!event.getModifierState('CapsLock')) {
          setCapsLockIsOn(false);
        }
      }
    }

    window.addEventListener('keydown', handleKeyDown);
    window.addEventListener('keyup', handleKeyUp);
    window.addEventListener('keypress', handleCapsLockState);

    return () => {
      window.removeEventListener('keydown', handleKeyDown);
      window.removeEventListener('keyup', handleKeyUp);
      window.removeEventListener('keypress', handleCapsLockState);
    };
  }, []);

  return (
    <div>
      {capsLockIsOn &&
        <i className="bi bi-capslock-fill ms-2"> mayusculas activadas</i>
      }
    </div>
  );
}

export default CapsLockDetector;