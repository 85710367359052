import { BaseRequest } from "./request/baseRequest";
import { GenericRequest } from "./request/request";

const FacturaServices = {
    
    listarPersonasFacturas:(nit)=> GenericRequest.post(`/api/listar-personas-facturas`,{nit}),
    listarCuentasFacturas:(codigo,nombre='')=> GenericRequest.post(`/api/listar-cuentas-facturas`,{codigo,nombre}),
    listarDeudasFacturas:(ci)=> GenericRequest.post(`/api/listar-deudas-eupg`,{ci}),
    facturar:(id)=> GenericRequest.post(`/api/pagar-deuda-eupg`,{id}),
    listarTiposDocumentos:()=> GenericRequest.get(`/tipo-documento-persona/listar-tipos`),
    
    registrarMovimientoFacturas:(data)=> GenericRequest.post(`/api/registrar-movimiento-factura`,data),
    buscarFacturas:(factura)=> GenericRequest.post(`/api/buscar-facturas`,{factura}),
    anularFacturas:(id)=> GenericRequest.post(`/api/anular-factura`,{id}),
    reimprimirFacturas:(id)=> GenericRequest.post(`/api/reimprimir-factura`,{id}),
    //servicios para reportes
    reporteUnidadCuentaFacturas:(data)=> GenericRequest.post(`/api/reporte-unidad-cuenta-facturas`,data),
    reporteConceptoFacturas:(data)=> GenericRequest.post(`/api/reporte-concepto-facturas`,data),
    reporteUnidadFacturas:(data)=> GenericRequest.post(`/api/reporte-unidad-facturas`,data),
    reporteDetalleFacturas:(data)=> GenericRequest.post(`/api/reporte-detalle-facturas`,data),
    reporteFacturas:(data)=> GenericRequest.post(`/api/reporte-facturas`,data),
    reporteLibroVentas:(data)=> GenericRequest.post(`/api/reporte-libro-ventas`,data),
    reporteResumenRecibos:(data)=> GenericRequest.post(`/api/reporte-resumen-recibos`,data),
    reporteInstitucionalFacturas:(data)=> GenericRequest.post(`/api/reporte-institucional-facturas`,data),
    
    listarEstructuras:()=> GenericRequest.get(`/api/listar-estructuras`),
    listarEstructurasTarifario:()=> GenericRequest.get(`/api/lista-estructuras-tarifario`),
    listarEstructurasFacturas:()=> GenericRequest.get(`/api/listar-estructuras-facturas`),
    listarUsuariosFacturas:(estructura)=> GenericRequest.get(`/api/lista-usuarios-facturas`,{estructura}),
    
    obtenerFactura: (id) => BaseRequest.get('/factura/view', { params: { id }, responseType: 'blob' }),

}
export default FacturaServices;

