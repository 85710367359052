import { LOGOUT, LOGIN_SUCCESS, SET_USER } from './types'

export const successLogin = (payload) => (dispatch) => {
    localStorage.setItem('user', JSON.stringify(payload))
    dispatch({ type: LOGIN_SUCCESS })
}

export const logout = () => (dispatch) => {
    localStorage.removeItem('user')
    dispatch({ type: LOGOUT })
}

export const setUser = (payload) => (dispatch) => {
    dispatch({ type: SET_USER, payload })
}
