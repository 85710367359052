import React, { useState, useEffect } from 'react'
import Form from 'react-validation/build/form'
import Input from 'react-validation/build/input'
import CheckButton from "react-validation/build/button";
import { required, validatorPassword } from '../Component/Validation/validations'
import services from '../services/report.service';
import { Link } from "react-router-dom";

/*Change Password
Retorna el formulario para el reseteo de contrasenia.
Valida la nueva contrasenia proporcionada
Resetea la contrasenia del usuario a la nueva proporcionada
*/
export default function ChangePassword() {

    const [checkbtn, setCheckbtn] = useState('');
    const [form, setForm] = useState('');
    const [password1, setPassword1] = useState('');
    const [password2, setPassword2] = useState('');
    const [show, setShow] = useState({show1:false, show2:false});
    const [loading, setLoading] = useState(false);
    const [message, setMessage] = useState(false);
    const [alert, setAlert] = useState(true);
    const [messAlert, setmessAlert] = useState('');
    const [allowed, setAllowed] = useState(false);
    const [exit, setExit] = useState(false);
    let token = localStorage.getItem('tokenP')

    //Resetea la contrasenia
    const handleSubmit = async e => {
        e.preventDefault();
        //cambia el valor de loading al true
        setLoading(true);
        //valida todos los campos del formulario
        form.validateAll();
        //verifica que NO hay errores
        if (checkbtn.context._errors.length === 0) {
            //verifica si las contrasenias proporcionadas son iguales
            if(password1 === password2){
                //envia la nueva contrasenia y el token a backend
                await services.changePassword(password1, token).then((res)=>{
                    //verifica que no haya errores
                    if(res.errors.length === 0){
                        //cambia el valor de message al mensaje recibido
                        setmessAlert(res.results.message)
                        //cambia el valor de alert a false
                        setAlert(false)
                        //elimina el token almacenado en localstorage
                        localStorage.removeItem('tokenP')
                        //cambia el valor de exit a true
                        setExit(true)
                    }else{
                        //cambia el valor de messAlert (mensaje para un alerta)
                        setmessAlert(res.errors[0])
                        setAlert(false)
                        setExit(false)
                    }
                })
            }else{
                setMessage(true)
            }
        }
        setLoading(false);
    }

    useEffect(() => {
        let tokenCheck = async () => {
            //verifica si el valor del token en localstorage NO es nulo
            if(token !== null){
                //consulta si el token obtenido corresponde a un usuario activo
                await services.controlToken(token).then((res) => {
                    //verifica si el resultado es true
                    if(res.results){
                        //obtiene la ruta completa del navegador
                        let url = window.location.search
                        //concatena el token con una cadena
                        let token2 = '?token='.concat(token)
                        //verifica si la url obtenida es igual a la ruta habilitada con el token
                        if(url == token2){
                            //cambia el valor de la variable allowed a true
                            setAllowed(true)
                        }else{
                            //cambia el valor de la variable allowed a false
                            setAllowed(false)
                        }
                    }else{
                        setAllowed(false)
                    }
                })
            }else{
                setAllowed(false)
            }
        }
        tokenCheck();
    }, []);

  return (
    <center>
        <div className='container shadow-sm p-5 bg-body rounded' style={{minWidth:"40%"}}>
            {
                //si el usuario esta permitido le permite visualizar el formulario
                allowed ?
                <center>
                {   //si no hay alerta de error se permite visualizar el formulario
                    alert ?
                    <>
                        <h5>CAMBIAR CONTRASEÑA</h5>
                        <Form onSubmit={ handleSubmit } style={{paddingInline:"10%", paddingTop:'1vw'}} ref={(c) => { setForm(c) }}>
                            <label style={{float:'left'}}>Nueva contraseña</label>
                            <div className="input-group" >
                                <Input
                                    type={show.show1 ? "text" : "password"}
                                    className="form-control"
                                    id="pwd1"
                                    name="pwd1"
                                    value={password1}
                                    onChange={e => setPassword1(e.target.value)}
                                    validations={[required, validatorPassword]}
                                    placeholder='Nueva contraseña'
                                    style={{width:'25vw'}}
                                />
                                <div className="input-group-append" style={{position:'absolute', marginLeft:'25vw'}}>
                                    <span className="input-group-text" onClick={()=>setShow(show => ({...show, ...{show1:!show.show1}}))}><i className={show.show1 ? 'fa fa-eye' : 'fa fa-eye-slash'} aria-hidden="true" style={{padding:'4px'}} ></i></span>
                                </div>
                            </div>
                            <label style={{float:'left', marginTop:'1vw'}}>Confirmar contraseña</label>
                            <div className="input-group">
                                <Input
                                    type={show.show2 ? "text" : "password"}
                                    className="form-control"
                                    id="pwd2"
                                    name="pwd2"
                                    value={password2}
                                    onChange={e => setPassword2(e.target.value)}
                                    validations={[required, validatorPassword]}
                                    placeholder='Confirmar contraseña'
                                    style={{width:'25vw'}}
                                />
                                <div class="input-group-append" style={{position:'absolute', marginLeft:'25vw'}}>
                                    <span class="input-group-text"  onClick={()=>setShow(show => ({...show, ...{show2:!show.show2}}))}><i className={show.show2 ? 'fa fa-eye' : 'fa fa-eye-slash'} aria-hidden="true" style={{padding:'4px'}}></i></span>
                                </div>
                            </div>
                            {
                                message ? <div className="alert alert-danger p-1 mb-0" role="alert">Las contraseñas no coinciden</div> : null
                            }
                            <button className="btn btn-dark" disabled={loading}  style={{width:'100%', marginBlock:'1vw'}}>
                                {loading && (
                                    <span className="spinner-border spinner-border-sm"></span>
                                )}
                                <span> CAMBIAR</span>
                            </button>
                            <CheckButton
                                style={{ display: "none" }}
                                ref={(c) => {
                                    setCheckbtn(c)
                                }}
                            />
                        </Form>
                    </> :
                    //si hay algun alerta se visualiza
                    <>
                        <div className={exit ? 'alert alert-success' : 'alert alert-danger'} style={{marginBlock:'1vw', alignItems:'center', justifyContent:'center', display:'flex'}}>
                            <i className={exit ? 'fa fa-check fa-2x' : 'fa fa-times fa-2x'} aria-hidden="true" style={exit ? {color:'green'} : {color:'#e31610'}}></i>
                            <label style={{paddingLeft:'0.5vw'}}>{messAlert}</label>
                        </div>
                        <Link to='/login'>Iniciar Sesion</Link>
                    </>
                }
                </center> :
                //alerta en caso de no estar habilitado para cambiar su constrasenia
                <div>
                    <i class="fa fa-exclamation-circle fa-3x" aria-hidden="true" style={{color:'#e31610'}}></i>
                    <h5>Ocurrio un error</h5>
                    <label>Lo sentimos, vuelva a solicitar el cambio de contraseña por favor</label>
                </div>
            }
        </div>
    </center>
  )
}
