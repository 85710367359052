import React from 'react';
import SearchTable from './SearchTable';
import DetailPayModal from './DetailPayModal';
import services from '../services/report.service';
import SalesReport from '../Reports/SalesReport';
import generateMatricula from '../Reports/components/matricula/printTuition';
import PopUp from './PopUpMessage';

export default class TableAccount extends React.Component {

  constructor(props){
    super(props);
    this.state = {
      btnblock: true,
      selectRowColor: -1,
      personaS: {},
      refs: [],
      selectRowAccount: {},
      showModalPay: false,
      titleModal: 'Detalle',
      popUpMessage: '',
      popUpMessageVisible: false,
    }
    this.refAcceptar = React.createRef(null);
    this.refPreprint = React.createRef(null);
    this.cleanSelectRowColor = this.cleanSelectRowColor.bind(this);
    this.focusFirstRow = this.focusFirstRow.bind(this);
    this.handleCloseModalPay = this.handleCloseModalPay.bind(this);
    this.closePopUp = this.closePopUp.bind(this);
  }

  /** select an account */
  addclass=(key, account)=>{
    this.setState({personaS:account})
    if (this.props.textInputName === "inputPerson") {
      let nameSeparation = account.nombres.split(' ')
      this.setState({
        personaS: {
          ...account,
          nombre_1: nameSeparation[0],
          nombre_2: nameSeparation[1],
          sis: account.codigo,
        }
      });
    }
    if(this.state.selectRowColor !== undefined)
      this.setState({ selectRowColor: key, btnblock: false});

  }

  handleCloseModalPay = () => {
    this.setState({ showModalPay: false});
  }

  add = () =>{
    if(this.props.textInputName === "inputAccount"){
      this.props.showInputAccount(this.state.personaS);
      this.props.getListAssocAccounts(this.state.personaS.id);
    }

    if(this.props.textInputName === "extra"){
      var newAssocAccount = {
        id: this.state.personaS.id,
        codigo: this.state.personaS.codigo,
        cuenta_asociada: this.state.personaS.nombre,
        cantidad: 1,
        precio: this.state.personaS.precio,
        unidad: this.state.personaS.unidad
      }
      this.props.addAssocAccounts(newAssocAccount);
    }

    if(this.props.textInputName === "inputPerson")
      this.props.showInputPerson(this.state.personaS);

    this.props.handleCloseModal();
  }

  payAccount = async (data,key) => {
    if(window.confirm('¿Esta Seguro?\n Confirmar la transanccion de pago de: '+data.cuenta_nombre+' de la persona: '+data.persona_paterno+' '+data.persona_nombres)){
      let response = await services.payDebt(data.id, this.refPreprint.current.value);
      let action;
      if (response.status === 200) {
        if (response.data.debt.sistema === 'sis' && response.data.debt.distribucion_matricula !== null) { // Solo matriculas deberian tener asignado al sistema sis
          action = async () => await generateMatricula(response.data.debt, response.data.persona, response.data.debt.distribucion_matricula, response.data.debt.payload)
        } else {
          action = async () => await this.generateReport(response.data.debt, response.data.extraAccounts, response.data.persona, response.data.unitName, response.data.accountName)
        }
        await action().then(() => {
          this.props.accounts.splice(key,1);
          this.props.updatePreprint();
          this.forceUpdate();
          this.props.cleanInputPayline();
        });
      } else if (response.status === 404) {
        this.setState({
          popUpMessageVisible: true,
          popUpMessage: 'La deuda no se encuentra.',
        });
      } else if (response.status === 400) {
        this.setState({
          popUpMessageVisible: true,
          popUpMessage: 'La deuda ya ha sido cancelada',
        });
      } else {
        this.setState({
          popUpMessageVisible: true,
          popUpMessage: 'Ha ocurrido un error al cancelar la deuda'
        });
      }
    }
  }

  expireAccount = async (data, key) => {
    if(window.confirm('¿Esta Seguro?\n Caducar la transanccion de pago de: ' + data.cuenta_nombre + ' de la persona: ' + data.persona_paterno + ' ' + data.persona_nombres)){
      let response = await services.expireDebt(data.id);

      if (response.status === 200){
        this.props.accounts.splice(key,1);
        this.props.updatePreprint();
        this.forceUpdate();
        this.props.cleanInputPayline();
        this.setState({
          popUpMessageVisible: true,
          popUpMessage: 'La deuda ha sido caducada con exito.',
        });
      } else if (response.status === 404) {
        this.setState({
          popUpMessageVisible: true,
          popUpMessage: 'La deuda no se encuentra.',
        });
      } else if (response.status === 400) {
        this.setState({
          popUpMessageVisible: true,
          popUpMessage: 'La deuda ya ha sido caducada',
        });
      } else {
        this.setState({
          popUpMessageVisible: true,
          popUpMessage: 'Ha ocurrido un error al caducar la deuda'
        });
      }
    }
  }

  generateReport = async (debt, extraAccounts, persona, unidad, accountName) => {
    const report = new SalesReport();
    const items = [
      {
          concept: `${unidad} — ${accountName}`,
          cost: debt.cantidad * parseFloat(debt.precio),
      }
    ]
    let observacion = debt.observacion
    if (debt.sistema === 'generico') {
      if (debt.payload?.aclaracion) {
        observacion = debt.payload.aclaracion
      }
    }
    extraAccounts.forEach(account => {
      items.push({
        concept: `${account.unitName} — ${account.accountName}`,
        cost: account.cantidad * parseFloat(account.precio),
      })
    });

    await report.generateReport(
      [debt.recibo],
      "",
      `${persona.apellido_1 || ''} ${persona.apellido_2 || ''} ${persona.nombre_1 || ''} ${persona.nombre_2 || ''}`,
      items,
      observacion,
      persona.documento,
      persona.codigo,
      debt.fecha_pago,
      1,
    );
  }

  focusAceptar = () =>{
    if(this.refAcceptar.current) this.refAcceptar.current.focus();
  }

  focusSearch = () =>{
    if(this.props.refSearch.current) this.props.refSearch.current.focus();
  }

  focusFirstRow = () =>{
    if(this.state.refs[0]){
      this.addclass(0, this.props.accounts[0]);
      this.state.refs[0].focus();
    }
  }

  saveRef = (e) => {
    if(this.state.refs.length > this.props.accounts.length)
      this.state.refs.pop(this.props.accounts.length-1)
    if(e!==null) this.state.refs.push(e);
  }

  cleanSelectRowColor = () => {
    this.setState({selectRowColor: -1 })
  }

  handleCheck = (e) => {
    this.setState({ account: {
      ...this.state.account,
      estado: !this.state.account,
    }});
  }

  payDetails = (data) =>{
    this.props.searchDebtsDetails(data);
    this.setState({
      selectRowAccount: data,
      showModalPay: true
    })
  }

  keyPress = (e) =>{
  let id = e.currentTarget.rowIndex-1;
    switch (e.key) {
        case "Enter":
          this.focusAceptar();
          break;
        case "w":
        case "W":
           if(this.props.accounts[id-1]){
              this.addclass(id-1,this.props.accounts[id-1]);
              this.state.refs[id-1].focus();
           }
          break;
        case "S":
        case "s":
          if(this.props.accounts[id+1]){
            this.addclass(id+1,this.props.accounts[id+1]);
            this.state.refs[id+1].focus();
          }
          break;
        case "Q":
        case "q":
            this.setState({ selectRowColor: -1 });
            this.focusSearch();
            break;
        default:
            break;
    }
  }

  closePopUp() {
    this.setState({
      popUpMessageVisible: false,
      popUpMessage: ''
    });
}

  render() {

    return (
      <div className="App me-4">
        <div className='container'>
            <div className='row'>
              { this.props.textInputName === 'inputPayLine' ?
              <></>
              :
              <SearchTable
                  setBusquedaData={this.props.setBusquedaData.bind(this)}
                  accounts = {this.props.accounts}
                  busqueda = {this.props.busqueda}
                  textInputName = {this.props.textInputName}
                  refSearch = {this.props.refSearch}
                  typeSearch={this.props.typeSearch}
                  cleanSelectRowColor = {this.cleanSelectRowColor}
                  focusFirstRow={this.focusFirstRow}
                  cleanRefs = {this.cleanRefs}
                  searchAccount={this.props.searchAccount.bind(this)}
                  searchPerson={this.props.searchPerson.bind(this)}
                  searchAddAccount={this.props.searchAddAccount.bind(this)}
              />
              }
            </div>
        </div>

        <div className="container" style={{minWidth: '700px'}}>
          <div className={this.props.textInputName === 'inputPayLine' ? 'PayLine table-responsive' : 'table-scroll'}>
              <table className={this.props.textInputName === 'inputPayLine' ? 'table table-borderless table-hover' : 'table table-hover'}>
                <thead>
                    {
                      this.props.textInputName === "inputAccount" || this.props.textInputName === "extra" ?
                      <tr>
                          <th>Codigo</th>
                          <th>Nombre</th>
                          <th>Precio</th>
                          <th>Unidad</th>
                      </tr>
                      :  this.props.textInputName === "inputPerson" ?
                      <tr>
                          <th>Nombre</th>
                          <th></th>
                          <th>Documento</th>
                          <th>Complemento</th>
                      </tr>
                      :
                      <>
                      <tr >
                          <th style={{backgroundColor:'#fff'}}>Cuenta</th>
                          <th style={{backgroundColor:'#fff'}} colSpan='3'>Persona</th>
                          <th colSpan={3} className='bg-light'>
                            <span>Nro. Preimpreso: </span>
                            <input
                              className='ms-1'
                              type="number"
                              value={this.props.preprintNumber}
                              onChange={(e) => { this.props.changePreprint(e.target.value) }}
                              style={{ textAlign: "right", height: '1.5rem', fontSize: '0.9rem' }}
                              ref={this.refPreprint}/>
                          </th>
                      </tr>
                      <tr>
                          <th>Nombre</th>
                          {/* <th>Cantidad</th>
                          <th>Precio</th> */}
                          <th>Documento</th>
                          <th>Código</th>
                          <th>Apellidos y Nombres</th>
                          <th>Precio Total Bs.</th>
                          <th>Detalle</th>
                          <th>Pagar</th>
                          <th>Caducar</th>
                      </tr>
                      </>
                    }

                </thead>
                <tbody>
                    {this.props.accounts.length > 0 ? this.props.accounts.map((account, key) => {
                      return (
                        <tr
                          key={key}
                          ref={this.saveRef}
                          onClick={this.props.textInputName === "inputPayLine" ? null : (e)=> this.addclass(key, account)}
                          className={this.state.selectRowColor === key ? "tableSelected" : "" }
                          onKeyPressCapture={this.keyPress}
                          tabIndex={this.props.textInputName === "inputPayLine" ? -1 : 0}
                        >
                          {
                            this.props.textInputName === "inputAccount" || this.props.textInputName === "extra" ?
                            <>
                                <td style={{textAlign: 'left'}}>{account.codigo}</td>
                                <td style={{textAlign: 'left'}}>{account.nombre}</td>
                                <td style={{textAlign: 'right'}}>{account.precio}</td>
                                <td style={{textAlign: 'left'}}>{account.unidad}</td>
                            </>
                            : this.props.textInputName === "inputPerson" ?
                            <>
                                <td style={{textAlign: 'left'}}>{account.paterno+" "+account.materno+" "+account.nombres}</td>
                                <td>{account.tic}</td>
                                <td style={{textAlign: 'right'}}>{account.documento}</td>
                                <td>{account.complemento}</td>
                            </>:
                            <>
                                <td>{account.cuenta_nombre}</td>
                                {/* <td>{account.cantidad}</td>
                                <td>{account.precio}</td> */}
                                <td>{account.persona_ci}</td>
                                <td>{account.persona_codigo}</td>
                                <td>{account.persona_paterno+' '+account.persona_nombres}</td>
                                {/* Backend retorna ambos apellidos separados con un espacio en persona_paterno */}
                                <td className='text-end'>{parseFloat(account.total).toFixed(2)}</td>
                                <td><button type='button' className='btn btn-light' onClick={(e) => this.payDetails(account)}><i className="fa fa-info-circle fa-lg"></i></button></td>
                                <td><button type='button' className='btn btn-light' onClick={(e) => this.payAccount(account,key)}><i className='fa fa-money fa-lg'></i></button></td>
                                <td><button type='button' className='btn btn-light' onClick={(e) => this.expireAccount(account,key)}><i className='bi bi-trash3-fill'></i></button></td>
                            </>
                          }
                        </tr>
                      )
                    }) :
                    <tr>
                      <td colSpan={this.props.textInputName === "inputAccount" || this.props.textInputName === "extra" ? "3" : this.props.textInputName === "inputPerson" ? "4" : "11" }>
                        No existen resultados
                      </td>
                    </tr>}
                </tbody>
              </table>
          </div>
        </div>
        { this.props.textInputName === 'inputPayLine' ?
        <></>
        :
          <div className='container'>
            <div className='btnCuenta'>
                  <button className='btn btn-primary' onClick={this.add} disabled={this.state.btnblock} ref={this.refAcceptar}>Aceptar</button>
                  <button className='btn btn-success' onClick={this.props.handleCloseModal}>Cancelar</button>
            </div>
          </div>
        }
     { <DetailPayModal
         handleCloseModalPay={this.handleCloseModalPay}
         showModalPay={this.state.showModalPay}
         title={this.state.titleModal}
         selectRowAccount={this.state.selectRowAccount}
         detailSisPay={this.props.detailSisPay}
      />}
      {
        <PopUp
        close={this.closePopUp}
        visible={this.state.popUpMessageVisible}
        message={this.state.popUpMessage}
    />
      }
      </div>
    );
  }
}
