import { forwardRef } from 'react'
import { Button } from 'react-bootstrap'
export const BaseAcceptButton = forwardRef(({ children, ...props }, ref) => {
    return (
        <Button {...props} ref={ref}>
            {children}
        </Button>
    )
})
BaseAcceptButton.displayName = 'BaseAcceptButton'
export const BaseRejectButton = forwardRef(({ children, ...props }, ref) => {
    return (
        <Button {...props} variant='outline-primary' ref={ref}>
            {children}
        </Button>
    )
})
BaseRejectButton.displayName = 'BaseRejectButton'
