import pdfMake from 'pdfmake/build/pdfmake'
import pdfFonts from 'pdfmake/build/vfs_fonts'
import printJS from 'print-js';
import logo from './components/img/logoumss.png'

import RobotoFont from '../fonts/Roboto'
import RobotoBoldFont from '../fonts/RobotoBold'

export default class ReportAccountPlan {

    formatNumber(number){
        const numberFormat = new Intl.NumberFormat('en-US',
            { minimumFractionDigits: 2 }).format(number)

        return numberFormat
    }

    /**
     * Funcion que genera el reporte Plan de cuentas y lo manda a imprimir o a una visualizacion previa
     * dependiendo del parametro isPrint
     *
     * @param JSON itemsReport
     * @param string proccessBy
     * @param boolean isPrint
     */
    generateReportPDF(itemsReport, proccessBy, isPrint, unitName) {
        const date = new Date()
        const outputDate = String(date.getDate()).padStart(2, '0') + '—' + String(date.getMonth() + 1).padStart(2, '0') + '—' + date.getFullYear().toString()
        const outputTime = String(date.getHours()).padStart(2, '0') + ':' + String(date.getMinutes()).padStart(2, '0') + ':' + String(date.getSeconds()).padStart(2, '0')

        pdfMake.vfs = {
            ...pdfFonts.pdfMake.vfs,
            'Roboto-Regular.ttf': RobotoFont,
            'Roboto-Bold.ttf': RobotoBoldFont
        };

        pdfMake.fonts = {
            Roboto: {
                normal: 'Roboto-Regular.ttf',
                bold: 'Roboto-Bold.ttf'
            }
        };

        const reportTitle = (currentPage, pageCount, pageSize) => {
            const logoWidth = 25;
            return [
                {
                    fontSize: 9,
                    margin: [40, 35, 20, 5],
                    table: {
                        headerRows: 1,
                        widths: [logoWidth, '*', 'auto'],
                        body: [
                            [
                                {
                                    image: logo,
                                    width: logoWidth,
                                },
                                {
                                    text: 'UNIVERSIDAD MAYOR DE SAN SIMON\n' + unitName,
                                    margin: [0, 10, 0, 0],
                                },
                                {
                                    text: `Página: ${currentPage}/${pageCount}\n ${outputDate} ${outputTime}`,
                                    noWrap: false,
                                    margin: [0, 10, 20, 0],
                                    alignment: 'right',
                                }
                            ],
                        ]
                    },
                    layout: 'noBorders',

                },
                {
                    text: 'Tarifario de Cuentas',
                    fontSize: 13,
                    bold: true,
                    margin: [50, 5, 20, 5],
                    alignment: 'center'

                },
            ]
        }

        let tableItems = {
            fontSize: 9,
            table: {
                headerRows: 1,
                widths: [80, 190, 240, 25, 45, 80],
                body: [
                    [
                        { text: "Cuenta", style: 'tableHeader' },
                        { text: "Unidad", style: 'tableHeader' },
                        { text: "Nombre de la Cuenta", style: 'tableHeader' },
                        { text: "T.M.", style: 'totalTableHeader' },
                        { text: "Total", style: 'totalTableHeader' },
                        { text: "Documento", style: 'docTableHeader' }
                    ],
                    ...this.getItems(itemsReport)
                ]
            },
            margin: [0, -10, 0, 0],
            layout: {
                hLineWidth: function (i, node) {
                    return (i === 1 ) ? 0.75 : 0;
                },
                vLineWidth: function (i, node) {
                    return (i === 0 || i === node.table.widths.length) ? 0 : 0;
                },
            },

        }


        const footer = {
            text: `Procesado por Usuario: ${proccessBy}`,
            fontSize: 9,
            margin: [20, 20, 50, 20],
            alignment: 'right'
        }

        const report = {
            pageSize: 'letter',
            pageOrientation: 'landscape',
            pageMargins: [40, 120, 25, 70],
            header: reportTitle,
            content: tableItems,
            footer: footer,
            defaultStyle: {
                font: 'Roboto'
            },
            styles: {
                tableHeader: {
                    bold: true,
                    color: 'black',
                    fontSize: 9
                },
                totalTableHeader: {
                    bold: true,
                    color: 'black',
                    fontSize: 9,
                    alignment: 'right'
                },
                docTableHeader: {
                    bold: true,
                    color: 'black',
                    fontSize: 9,
                    alignment: 'center'
                },
                tableCategory: {
                    bold: true,
                    color: 'black',
                    fontSize: 10,
                },
                subCateg: {
                    bold: false,
                    color: 'black',
                    fontSize: 9
                },
                rowStyle: {
                    bold: false,
                    color: 'black',
                    fontSize: 7
                },
                total: {
                    bold: false,
                    color: 'black',
                    fontSize: 7,
                    alignment: 'right',
                },
                doc: {
                    bold: false,
                    color: 'black',
                    fontSize: 7,
                    alignment: 'left',
                    margin: [15, 0, 0, 0],
                }
            },
        }

        if (isPrint) {
            let document = pdfMake.createPdf(report)
            let docBlob = null;

            document.getBase64(function (result) {
                docBlob = result;
                printJS({
                    printable: docBlob,
                    type: "pdf",
                    base64: true
                });
            });
        } else {
            pdfMake.createPdf(report).open()
        }
    }

    getItems(items) {
        let details = []

        let auxCodR = ''
        let auxCodc = ''

        for (let i = 0; i < items.length; i++){
            if(items[i].codRubro !== auxCodR || i===0){
                auxCodR = items[i].codRubro
                auxCodc = ''

                details.push([
                    { text: items[i].codRubro, style: 'tableCategory' },
                    { text: items[i].desRubro, style: 'tableCategory' },
                    { text: "", style: 'tableCategory' },
                    { text: "", style: 'tableCategory' },
                    { text: "", style: 'tableCategory' },
                    { text: "", style: 'tableCategory' },
                ])
            }

            if(auxCodc !== items[i].codConcepto || i === 0){
                auxCodc = items[i].codConcepto

                details.push([
                    { text: items[i].codConcepto, style: 'subCateg' },
                    { text: items[i].desConcepto, style: 'subCateg' },
                    { text: "", style: 'subCateg' },
                    { text: "", style: 'subCateg' },
                    { text: "", style: 'subCateg' },
                    { text: "", style: 'subCateg' },
                ])
            }

            let auxPrice = items[i].tipoMonto === 'V' ? 0.00 : items[i].precio

            details.push([
                { text: items[i].codCuenta, style: 'rowStyle' },
                { text: items[i].desUnidad, style: 'rowStyle' },
                { text: items[i].nombCuebta, style: 'rowStyle' },
                { text: items[i].tipoMonto, style: 'total' },
                { text: this.formatNumber(auxPrice), style: 'total' },
                { text: items[i].numero, style: 'doc' },
            ])


        }

        return details
    }
}