import API from '../Conection/conection';
import authHeader from './auth-header';

const services = {
    getAsociateAccounts: cod => new Promise(
        (resolve, reject) => {
            API.post('/api/listar-cuentas-asociadas', { cuenta_principal: cod }, { headers: authHeader() })
                .then(res => res.data.result)
                .then(data => resolve(data))
                .catch(err => reject(err))
        }
    ),

    searchReceipt: async (parameter, type, action) => {
        let res = null;
        if (type === "receipt")
            await API.post('/api/buscar-recibos', { recibo: parameter, accion: action }, { headers: authHeader() }).then(result => res = result).catch(err => console.log(err));
        if (type === "person")
            await API.post('/api/buscar-personas', { persona: parameter, accion: action }, { headers: authHeader() }).then(result => res = result).catch(err => console.log(err));
        if (parameter === '') return [];
        return res.data;
    },

    getListUnits: async (idEstructura) => {
        try {
            const list = await API.get(`/api/lista-unidades?idEstructura=${idEstructura}`, { headers: authHeader() });
            return list.data;
        } catch (e) {
            console.log(e);
        }

    },

    getListItems: async () => {
        try {
            const list = await API.get(`/api/lista-rubros/`, { headers: authHeader() });
            return list.data;
        } catch (e) {
            console.log(e);
        }

    },

    getListAccounts: async (idConcepto, idEstructura = 0) => {
        try {
            const list = await API.get(`/api/lista-cuentas?id_concepto=${idConcepto}&estructura=${idEstructura}`, { headers: authHeader() });
            return list.data;
        } catch (e) {
            console.log(e);
        }

    },

    getListConcept: async (idRubro) => {

        try {
            const list = await API.get(`/api/lista-conceptos?id_rubro=${idRubro}`, { headers: authHeader() });
            return list.data;
        } catch (e) {
            console.log(e);
        }

    },

    getListUser: async (structure) => {
        try {
            const list = await API.post(`/api/lista-usuarios/`, { estructura: structure }, { headers: authHeader() });
            return list.data;
        } catch (e) {
            console.log(e);
        }
    },

    getAccountPlan: async (idUni, idStructure) => {
        try {
            let data = new FormData()
            data.append("idStructure", idStructure)
            data.append("idUnit", idUni)

            const res = await API.post(`/api/account-plan`, data, { headers: authHeader() })
            return res.data
        } catch (ex) {
            console.log(ex)
        }
    },

    getListEstructure: async () => {
        try {
            const list = await API.get(`/api/listar-estructuras/`, { headers: authHeader() });
            return list.data;
        } catch (e) {
            console.log(e);
        }
    },

    getReportAnulados: async (startDate, endDate, idUser, idRubro, idUnidad, idConcepto, idCuenta, idStructure, electronico, caja) => {
        let data = new FormData()
        data.append("idUser", idUser)
        data.append("startDate", startDate)
        data.append("endDate", endDate)
        data.append("idRubro", idRubro)
        data.append("idUnidad", idUnidad)
        data.append("idConcepto", idConcepto)
        data.append("idCuenta", idCuenta)
        data.append("idStructure", idStructure)
        data.append("electronico", electronico)
        data.append("caja", caja)
        try {
            const list = await API.post(`/api/reporte-anulados`, data, { headers: authHeader() });
            return list.data;
        } catch (e) {
            console.log(e);
        }
    },

    getReportKardex: async (startDate, endDate, idUser, idRubro, idUnidad, idConcepto, idCuenta, idStructure) => {
        let data = new FormData()
        data.append("idUser", idUser)
        data.append("startDate", startDate)
        data.append("endDate", endDate)
        data.append("idRubro", idRubro)
        data.append("idUnidad", idUnidad)
        data.append("idConcepto", idConcepto)
        data.append("idCuenta", idCuenta)
        data.append("idStructure", idStructure)
        try {
            const list = await API.post(`/api/reporte-valores`, data, { headers: authHeader() });
            return list.data;
        } catch (e) {
            console.log(e);
        }
    },

    getLitUnitByStructure: async (structure) => {
        try {
            const list = await API.get(`/api/get-units-by-structure?id=${structure}`, { headers: authHeader() })
            return list.data
        } catch (ex) {
            console.log(ex)
        }
    },

    getReportByUnitAccount: async (user, initDate, endDate, rubro, unidad, concepto, cuenta, estructura, electronico, caja) => {
        try {
            let data = new FormData()
            data.append("idUser", user)
            data.append("initDate", initDate)
            data.append("endDate", endDate)
            data.append("idRubro", rubro)
            data.append("idUnidad", unidad)
            data.append("idConcepto", concepto)
            data.append("idCuenta", cuenta)
            data.append("idEstructura", estructura)
            data.append("electronico", electronico)
            data.append("caja", caja)

            const res = await API.post(`/api/report-by-unit-account`, data, { headers: authHeader() })
            return res.data
        } catch (ex) {
            console.log(ex)
        }
    },

    getReportConcepto: async (user, initDate, endDate, rubro, unidad, concepto, cuenta, estructura, electronico, caja) => {
        try {
            let data = new FormData()
            data.append("idUser", user)
            data.append("initDate", initDate)
            data.append("endDate", endDate)
            data.append("idRubro", rubro)
            data.append("idUnidad", unidad)
            data.append("idConcepto", concepto)
            data.append("idCuenta", cuenta)
            data.append("idEstructura", estructura)
            data.append("electronico", electronico)
            data.append("caja", caja)

            const list = await API.post(`/api/reporte-concepto`, data, { headers: authHeader() });
            return list.data;
        } catch (e) {
            console.log(e);
        }
    },

    getReportUnit: async (user, initDate, endDate, rubro, unidad, concepto, cuenta, estructura, electronico, caja) => {
        try {
            let data = new FormData()
            data.append("idUser", user)
            data.append("initDate", initDate)
            data.append("endDate", endDate)
            data.append("idRubro", rubro)
            data.append("idUnidad", unidad)
            data.append("idConcepto", concepto)
            data.append("idCuenta", cuenta)
            data.append("idEstructura", estructura)
            data.append("electronico", electronico)
            data.append("caja", caja)

            const list = await API.post(`/api/reporte-unidad`, data, { headers: authHeader() });
            return list.data;
        } catch (e) {
            console.log(e);
        }
    },

    getReportRecibo: async (user, initDate, endDate, rubro, unidad, concepto, cuenta, estructura, electronico, caja) => {
        try {
            let data = new FormData()
            data.append("idUser", user)
            data.append("initDate", initDate)
            data.append("endDate", endDate)
            data.append("idRubro", rubro)
            data.append("idUnidad", unidad)
            data.append("idConcepto", concepto)
            data.append("idCuenta", cuenta)
            data.append("idEstructura", estructura)
            data.append("electronico", electronico)
            data.append("caja", caja)

            const list = await API.post(`/api/reporte-recibo`, data, { headers: authHeader() });
            return list.data;
        } catch (e) {
            console.log(e);
        }
    },

    getReportUtis: async (user, initDate, endDate, rubro, unidad, concepto, cuenta, estructura, electronico, caja) => {
        try {
            let data = new FormData()
            data.append("idUser", user)
            data.append("initDate", initDate)
            data.append("endDate", endDate)
            data.append("idRubro", rubro)
            data.append("idUnidad", unidad)
            data.append("idConcepto", concepto)
            data.append("idCuenta", cuenta)
            data.append("idEstructura", estructura)
            data.append("electronico", electronico)
            data.append("caja", caja)

            const list = await API.post(`/api/reporte-utis`, data, { headers: authHeader() });
            return list.data;
        } catch (e) {
            console.log(e);
        }
    },

    getReportTiposEstudiante: async (user, initDate, endDate, rubro, unidad, concepto, cuenta, estructura, electronico, caja) => {
        try {
            let data = new FormData()
            data.append("idUser", user)
            data.append("initDate", initDate)
            data.append("endDate", endDate)
            data.append("idRubro", rubro)
            data.append("idUnidad", unidad)
            data.append("idConcepto", concepto)
            data.append("idCuenta", cuenta)
            data.append("idEstructura", estructura)
            data.append("electronico", electronico)
            data.append("caja", caja)

            const list = await API.post(`/api/reporte-tipos-estudiante`, data, { headers: authHeader() });
            return list.data;
        } catch (e) {
            console.log(e);
        }
    },


    getReportIntitucional: async (initDate, endDate, electronico, caja) => {
        try {
            let data = new FormData()
            data.append("initDate", initDate)
            data.append("endDate", endDate)
            data.append("electronico", electronico)
            data.append("caja", caja)

            const list = await API.post(`/api/reporte-institucional`, data, { headers: authHeader() });
            return list.data;
        } catch (e) {
            console.log(e);
        }
    },

    getReportComision: async (initDate, endDate) => {
        try {
            let data = new FormData()
            data.append("initDate", initDate)
            data.append("endDate", endDate)

            const list = await API.post(`/api/reporte-comision-banco`, data, { headers: authHeader() });
            return list.data;
        } catch (e) {
            console.log(e);
        }
    },

    getReportAportes: async (initDate, endDate) => {
        try {
            let data = new FormData()
            data.append("initDate", initDate)
            data.append("endDate", endDate)

            const list = await API.post(`/api/reporte-distribucion-aportes`, data, { headers: authHeader() });
            return list.data;
        } catch (e) {
            console.log(e);
        }
    },

    getReportResumenRecibos: async (user, initDate, endDate, rubro, unidad, concepto, cuenta, estructura, electronico, caja) => {
        try {
            let data = new FormData()
            data.append("idUser", user)
            data.append("initDate", initDate)
            data.append("endDate", endDate)
            data.append("idRubro", rubro)
            data.append("idUnidad", unidad)
            data.append("idConcepto", concepto)
            data.append("idCuenta", cuenta)
            data.append("idEstructura", estructura)
            data.append("electronico", electronico)
            data.append("caja", caja)

            const list = await API.post(`/api/reporte-resumen-recibos`, data, { headers: authHeader() });
            return list.data;
        } catch (e) {
            console.log(e);
        }
    },

    sendReceipt: async (parameter, type, id, motivo) => {
        let res = null;
        let data = {};
        if (type === 'anular')
            data = { recibo: parameter, accion: type, motivo: motivo, userId: id };
        else
            data = { recibo: parameter, accion: type }
        await API.post('/api/recibos', data, { headers: authHeader() }).then(result => res = result).catch(err => console.log(err));
        return res;
    },
    saveIngress: async (quantity, id) => {
        let res = null;
        let data = {};
        data = {
            id: id,
            cantidad: quantity
        };
        await API.post('/api/ingresar-valorados', data, { headers: authHeader() }).then(result => res = result).catch(err => console.log(err));
        return res;
    },
    getValorados: async () => {
        let res = null;
        await API.get('/api/valorados', { headers: authHeader() }).then(result => res = result).catch(err => console.log(err));
        return res.data.result;
    },
    postAccounts: (busqueda, tipo, addAccount) => new Promise(
        (resolve, reject) => {
            var data = {};
            if (tipo === 'codigo')
                data = { codigo: busqueda, valorado: addAccount };
            if (tipo === 'nombre')
                data = { nombre: busqueda.toUpperCase(), valorado: addAccount };

            API.post('/api/listar-cuentas', data, { headers: authHeader() })
                .then(res => res.data.result)
                .then(data => resolve(data))
                .catch(err => reject(err))
        }
    ),

    postPersons: (busqueda, tipo) => new Promise(
        (resolve, reject) => {
            var data = {};
            if (tipo === 'documento')
                data = { ci: busqueda };
            if (tipo === 'sis')
                data = { sis: busqueda };

            API.post('/api/listar-personas', data, { headers: authHeader() })
                .then(res => res.data.result)
                .then(data => resolve(data))
                .catch(err => reject(err))
        }
    ),

    postDebts: (busqueda, tipo) => new Promise(
        (resolve, reject) => {
            var data = {};
            if (tipo === 'sispay')
                data = { sis: busqueda };
            if (tipo === 'cipay')
                data = { ci: busqueda };

            API.post('/api/listar-deudas/', data, { headers: authHeader() })
                .then(res => res.data.result)
                .then(data => resolve(data))
                .catch(err => reject(err))
        }
    ),

    postDebtsDetails: (busqueda) => new Promise(
        (resolve, reject) => {
            var data = { padre: busqueda };

            API.post('/api/listar-detalle-deudas/', data, { headers: authHeader() })
                .then(res => res.data.result)
                .then(data => resolve(data))
                .catch(err => reject(err))
        }
    ),

    payDebt: async(debtId, preprint) => {
        let response = null;

        await API.post('/api/pagar-deuda/', { id: debtId, preprint: preprint }, { headers: authHeader() })
        .then(res => response = res)
        .catch((err) => { response = err.response ? err.response : {}; console.log(err) });

        return response;
    },

    expireDebt: async(debtId) => {
        let response = null;

        await API.post('/api/caducar-deuda/', {id: debtId}, {headers: authHeader()})
        .then(res => response = res)
        .catch((err) => { response = err.response ? err.response : {}; console.log(err) });

        return response;
    },

    registerMovement: data => new Promise(
        (resolve, reject) => {
            API.post('/api/registrar-movimientos', data, { headers: authHeader() })
                .then(res => res.data)
                .then(data => resolve(data))
                .catch(err => reject(err))
        }
    ),

    registerUser: data => new Promise(
        (resolve, reject) => {
            API.post('/api/registrar-usuario', data, { headers: authHeader() })
                .then(res => console.log())
                .then(data => resolve(data))
                .catch(err => reject(err))
        }
    ),

    getUserData: async () => {
        try {
            const list = await API.get(`/api/user-data/`, { headers: authHeader() });
            return list.data;
        } catch (e) {
            console.log(e);
        }
    },
    registerPerson: data => new Promise(
        (resolve, reject) => {
            API.post('/api/registrar-personas', data, { headers: authHeader() })
                .then(res => res.data)
                .then(data => resolve(data))
                .catch(err => reject(err))

        }
    ),
    getUserRole: async () => {
        try {
            const list = await API.get(`/api/user-role/`, { headers: authHeader() });
            return list.data;
        } catch (e) {
            console.log(e);
        }
    },



    accountPlanRole: async () => {
        try {
            const res = await API.get('/api/get-user-role', { headers: authHeader() })
            return res.data
        } catch (e) {
            console.log(e)
        }
    },

    isClosureMovement: async (actualDate) => {
        try {
            const dateTime = new Date(actualDate)
            const date = String(dateTime.getDate()).padStart(2, '0') + '-'
                        + String(dateTime.getMonth() + 1).padStart(2, '0') + '-'
                        + dateTime.getFullYear().toString()

            const actualYear = dateTime.getFullYear().toString()

            const res = await API.post('/api/is-closure', { year: actualYear, date: date }, { headers: authHeader() })
            return res.data
        } catch (e) {
            console.log(e)
        }
    },

    closureMoviment: async (actualDate, type) => {
        try {
            const dateTime = new Date(actualDate)
            const date = String(dateTime.getDate()).padStart(2, '0') + '-'
                        + String(dateTime.getMonth() + 1).padStart(2, '0') + '-'
                        + dateTime.getFullYear().toString() + " "
                        + dateTime.getHours() + ':' + dateTime.getMinutes() + ':' + dateTime.getSeconds()

            const actualYear = dateTime.getFullYear().toString()

            const res = await API.post('/api/closusure-movement', { type: type, date: date, year: actualYear }, { headers: authHeader() })
            return res.data
        } catch (e) {
            console.log(e)
        }
    },

    resetPassword: email => new Promise(
        (resolve, reject) => {
            API.post('/usuario/reset?email='+email, { headers: authHeader() })
                .then(res => res.data )
                .then(data => resolve(data))
                .catch(err => reject(err))
        }
    ),
    //funcion que envia el token que autoriza al usuario para el reseteo de contrasenia
    //verifica si el token esta activo y no ha expirado
    controlToken: token => new Promise(
        (resolve, reject) => {
            API.post('/usuario/control-reset-token?token='+token, { headers: authHeader() })
                .then(res => res.data )
                .then(data => resolve(data))
                .catch(err => reject(err))
        }
    ),
    //funcion que envia a backend una nueva contrasenia y el token de autorizacion para cambiar la contrasenia
    changePassword: (pwd, token) => new Promise (
        (resolve, reject) => {
            API.post('/usuario/change-password-front?token='+token+'&password='+pwd, { headers: authHeader() })
                .then(res => res.data)
                .then(data => resolve(data))
                .catch(err => reject(err))
        }
    ),

    getPreprinted: async () => {
        try{
            const res = await API.get('/api/get-preprinted-number', { headers: authHeader() })
            return res.data
        }catch(error){
            console.log(error)
        }
    },

    getStockValuedAccount: async (account) => {
        try{
            const res = await API.post('/api/cantidad-valorados', { cuenta_id: account }, { headers: authHeader() })
            return res.data
        } catch(error){
            console.log(error)
        }
    }, 

    getValuedList: async () => {
        try{
            const res = await API.get('/api/lista-valorados', { headers: authHeader() });
            return res.data.result;
        }catch(error){
            console.log(error);
        }
    },

    deleteMovement: async (movementId) => {
        try{
            const res = await API.delete('/api/delete-movement?movement=' + movementId, { headers: authHeader() });
            return res.data.message;
        }catch(error){
            console.log(error);
        }
    },

    getReportCobroComision: async (initDate, endDate) => {
        try {
            let data = new FormData()
            data.append("initDate", initDate)
            data.append("endDate", endDate)

            const list = await API.post(`/api/reporte-cobro-comision-banco`, data, { headers: authHeader() });
            return list.data;
        } catch (e) {
            console.log(e);
        }
    },
    //facturas
    postDebtsFacturas: (busqueda, tipo) => new Promise(
        (resolve, reject) => {
            var data = {};
            if (tipo === 'cipay')
                data = { ci: busqueda };

            API.post('/api/listar-deudas-eupg/', data, { headers: authHeader() })
                .then(res => res.data.result)
                .then(data => resolve(data))
                .catch(err => reject(err))
        }
    ),
    pagarFacturas: async(debtId) => {
        let response = null;

        await API.post('/api/pagar-deuda-eupg/', { id: debtId}, { headers: authHeader() })
        .then(res => response = res)
        .catch((err) => { response = err.response ? err.response : {}; console.log(err) });

        return response;
    },

    getReporteUnidadCuenta: async (user, initDate, endDate, rubro, unidad, concepto, cuenta, estructura, electronico, caja) => {
        try {
            let data = new FormData()
            data.append("idUser", user)
            data.append("initDate", initDate)
            data.append("endDate", endDate)
            data.append("idRubro", rubro)
            data.append("idUnidad", unidad)
            data.append("idConcepto", concepto)
            data.append("idCuenta", cuenta)
            data.append("idEstructura", estructura)
            data.append("electronico", electronico)
            data.append("caja", caja)

            // const res = await API.post(`/api/reporte-unidad-cuenta`, data, { headers: authHeader() })
            const res = await API.post(`/api/reporte-unidad-cuenta`, data, { headers: authHeader() })
            return res.data
        } catch (ex) {
            console.log(ex)
        }
    },
    
}

export default services;