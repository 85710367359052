/**
 * Obtiene el literal de un número.
 * @param {string | number} number Número.
 * @param {'floor' | 'ceil' | 'round' | 'trunc'} roundingMethod Método de redondeo para números con decimales.
 */
export function numberName(number, roundingMethod = 'floor') {
	let numeral = ''
	let _number = 0
	if (typeof number === 'number') {
		_number = number
	} else if (typeof number === 'string') {
		_number = parseInt(number)
	} else {
		_number = NaN
	}
	if (!isNaN(_number)) {
		// Verificar que el número es positivo.
		if (_number < 0) _number = Math.abs(_number)
		// Redondear número.
		if (roundingMethod === 'ceil') _number = Math.ceil(_number)
		else if (roundingMethod === 'round') _number = Math.round(_number)
		else if (roundingMethod === 'trunc') _number = Math.trunc(_number)
		else _number = Math.floor(_number)

		if (_number < 30) {
			// Entre 0 y 29.
			numeral = unidades[_number]
		} else if (_number < 100) {
			// Entre 30 y 99.
			const decena = Math.floor(_number / 10)
			const unidad = _number % 10
			if (unidad === 0) {
				numeral = decenas[decena]
			} else {
				numeral = decenas[decena] + ' y ' + unidades[unidad]
			}
		} else if (_number < 1000) {
			// Entre 100 y 999.
			const centena = Math.floor(_number / 100)
			const resto = _number % 100
			if (resto === 0) {
				numeral = _number === 100 ? 'cien' : centenas[centena]
			} else {
				numeral = `${centenas[centena]} ${numberName(resto)}`
			}
		} else if (_number < 1000000) {
			// Entre 1,000 y 999,999.
			const miles = Math.floor(_number / 1000)
			const resto = _number % 1000
			const restoNumeral = resto === 0 ? '' : ` ${numberName(resto)}`
			const milesNumeral = miles === 1 ? 'un mil' : `${numberName(miles)} mil`
			numeral = `${milesNumeral}${restoNumeral}`
		} else if (_number < 1000000000000) {
			// Entre 1,000,000 y 999,999,999,999.
			const millones = Math.floor(_number / 1000000)
			const resto = _number % 1000000
			const restoNumeral = resto === 0 ? '' : ` ${numberName(resto)}`
			const millonesNumeral = millones === 1 ? 'un millón' : `${numberName(millones)} millones`
			numeral = `${millonesNumeral}${restoNumeral}`
		} else {
			numeral = 'Número fuera de rango'
		}
	}
	return numeral
}

// Array con las palabras para los números del 0 al 19.
const unidades = [
	'cero',
	'uno',
	'dos',
	'tres',
	'cuatro',
	'cinco',
	'seis',
	'siete',
	'ocho',
	'nueve',
	'diez',
	'once',
	'doce',
	'trece',
	'catorce',
	'quince',
	'dieciséis',
	'diecisiete',
	'dieciocho',
	'diecinueve',
	'veinte',
	'veintiuno',
	'veintidós',
	'veintitrés',
	'veinticuatro',
	'veinticinco',
	'veintiséis',
	'veintisiete',
	'veintiocho',
	'veintinueve',
]

// Array con las palabras para las decenas.
const decenas = ['', '', '', 'treinta', 'cuarenta', 'cincuenta', 'sesenta', 'setenta', 'ochenta', 'noventa']

// Array con las palabras para las centenas.
const centenas = [
	'',
	'ciento',
	'doscientos',
	'trescientos',
	'cuatrocientos',
	'quinientos',
	'seiscientos',
	'setecientos',
	'ochocientos',
	'novecientos',
]
