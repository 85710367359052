import React from "react";

export default class SearchTable extends React.Component{

    search=e=> {
        this.props.setBusquedaData(e);
        this.props.cleanSelectRowColor();
        if(this.props.textInputName === "inputAccount"){
            this.searchAccount(e.target.value);
        }
        if(this.props.textInputName === "inputPerson"){
            this.searchPerson(e.target.value); 
        }
        if(this.props.textInputName === "extra"){
            this.searchAddAccount(e.target.value);
        }
    }

    searchAccount = (busqueda) => {
        let esCodigo = this.checkPoint(busqueda);
        if(isFinite(esCodigo)){
            this.props.searchAccount(busqueda,'codigo',false);
        }else{
            this.props.searchAccount(busqueda,'nombre',false);
        }
    }

    searchAddAccount = (busqueda) => {
        let esCodigo = this.checkPoint(busqueda);
        if(isFinite(esCodigo)){
            this.props.searchAddAccount(busqueda,'codigo');
        }else{
            this.props.searchAddAccount(busqueda,'nombre');
        }
    }

     searchPerson = (busqueda) => {
         if(this.props.typeSearch === 'documento'){
            this.props.searchPerson(busqueda,'documento',false);
         }else{
            this.props.searchPerson(busqueda,'sis',false);
         }
    } 

    checkPoint = (text) => {
        var result = '';
        for(var i = 0; i < text.length; i++){
            if(text[i] !== '.'){
                result = result+text[i];
            }
        }
        return result;
    }

    keyPress = (e) => {
        if(e.key === 'Enter'){
            this.props.focusFirstRow();
        }
    }

    render() {
        return (
            <div className='input-group'>
                <label className='size-buscar'>Buscar</label>
                <input type='text' className='form-control' 
                value={this.props.busqueda} 
                placeholder={this.props.textInputName === "inputAccount" || this.props.textInputName === "extra" ? 'Busqueda por Código, Nombre' : this.props.typeSearch === 'sis' ? 'Busqueda por Código Sis' : 'Busqueda por Documento' }
                onChange={this.search} 
                ref={this.props.refSearch} 
                onKeyPressCapture={this.keyPress} />
            </div>
        );
    }
}
