import LoadingSpinner from 'Component/LoadingSpinner'
import { useEffect } from 'react'
import { useParams } from 'react-router-dom'
import RecibosServices from 'services/recibos.services'

export default function ComprobantePdf() {
    const { id } = useParams()

    useEffect(() => {
        const getFactura = async () => {
            const { status, data } = await RecibosServices.obtenerComprobante(id)
            const pdfContent = status === 200 ? data : ''
            // Crear una URL local para el blob recibido
            const blobUrl = window.URL.createObjectURL(new Blob([pdfContent], { type: 'application/pdf' }));

            // Abrir una nueva pestaña con el PDF
            window.open(blobUrl, '_self');
        }
        getFactura()
    }, [])

    return <LoadingSpinner />
}
