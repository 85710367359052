/**
 * Retorna la cabecera de Autorizacion con el token asociado al usuario \
 * que ha iniciado sesion en el sistema
 */
export default function authHeader() {
    const user = JSON.parse(localStorage.getItem('user'));
    if (user && user.accessToken) {
        return { Authorization: 'Bearer ' + user.accessToken };
    }else {
        return {};
    }
}
