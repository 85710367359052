import React, { useEffect, useRef } from 'react'
import { Modal } from 'react-bootstrap';
import "../Styles/PopUp.css";


function PopUp(props) {

    const refButton = useRef(null);

    const close = () => {
        props.close(false);
    }

    useEffect(() => {
        if(props.visible === true)
            if(refButton.current) refButton.current.focus()
        return () => {
        }
    }, [props.visible])

    return (
        <Modal show={props.visible} onHide={props.close} centered >
                <div className='PopUp' style={{visibility:props.visible?"visible":"hidden"}}>
                    <h3>
                        {props.message}
                    </h3>
                    <button className={'btn btn-secondary bt2 kardex-submit'} onClick={close} ref={refButton}>
                        Cerrar
                    </button>
                </div>
        </Modal>
    );
}

export default PopUp