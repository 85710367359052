//funcion que valida cantidades
const validateQuantities = values => {
    const errors = []
    //recorre una lista de cantidades
    for (let i = 0; i < values.length; i++) {
        //verifica si la cantidad es valida
        if(!validateExtraAccountQuantity(values[i].cantidad)){
            //se agrega el id de la cantidad a la lista de errores
            errors.push(values[i].id)
        }
    }
    return errors;
}

//funcion que valida el codigo sis
const validateCodSis = value =>{
    let isValid = true;
    //verifica si cod sis solo son numeros
    if(!(/^[0-9]+$/.test(value))){
        isValid = false
    }else if(value.toString().length  > 10){
        isValid = false
    }
    return isValid
}

//funcion que valida el ci 
const validateDoc = value =>{
    let isValid = true
    ///verifica si ci solo son numeros
    if(!(/^[0-9]+$/.test(value))){
        isValid = false
    //verifica si ci sea menor igual a 10
    }else if(value.length > 10){
        isValid = false
    }
    return true
}
//funcion que valida nombres
const validateNames = value => {
    let isValid = true
    //verifica que el nombre solo sea de caracteres alfabeticos y tildes
    if(!(/^[a-zA-ZáéíóúñÑÁÉÍÓÚäëïöüÜÄËÏÖ&\Ç\-\'\`\s]*$/.test(value))){
        isValid = false
    //verifica que el nombre no este vacio    
    }else if(value === ''){
        isValid = false
    }
    return isValid
}

const validateSecondName = value => {
    //verifica que el nombre solo sea de caracteres alfabeticos y tildes
    if(!(/^[a-zA-ZáéíóúñÑÁÉÍÓÚäëïöüÜÄËÏÖ&\Ç\-\'\`\s]*$/.test(value))){
        return false
    }
    return true
}

//funcion que valida el apellido materno 
const validateApM = value => {
    let isValid = true
    //verifica que el valor no sea vacion
    if(value.trim() !== ''){
        //verifica que el apellido solo sea de caracteres alfabeticos y tildes
        if(!(/^[a-zA-ZáéíóúñÑÁÉÍÓÚäëïöüÜÄËÏÖ&\Ç\-\'\`\s]*$/.test(value))){
            isValid = false
        //limite maximo de caracteres del apellido materno
        }else if(value.length > 24){
            isValid = false
        }
    }
    return isValid
}
//funcion que valida el apellido paterno 
const validateApP = value => {
    let isValid = true
     //verifica que el apellido solo sea de caracteres alfabeticos y tildes
    if(!(/^[a-zA-ZáéíóúñÑÁÉÍÓÚäëïöüÜÄËÏÖ&\Ç\-\'\`\s]*$/.test(value))){
        isValid = false
     //limite maximo de caracteres del apellido paterno
    }else if(value.length > 24){
        isValid = false
    //verifica que el apellido paterno no este vacio  
    } else if(value === ''){
        isValid = false
    }
    return isValid
}

//valida el complemento del CI
const validateComp = value => {
    let isValid = true
    //verifica que el complemento no esta vacio
    if(value !== ''){
        //verifica que solo sean numeros y letras
        if(!/[A-Za-z0-9]/.test(value)){
            isValid = false
        //verifica que la longitud del complemento sea de 2
        }else if(value.length !== 2){
            isValid = false
        }
    }
    return isValid
}

const validateQuantityGeneric = (value, maxQuantity) => {
    Number.isFloat = Number.isFloat || function(value) {
        return typeof value === 'number' && 
            isFinite(value) &&
            Math.floor(value) !== value;
    };
    if (isNaN(value)) {
        return false;
    }
    if(value < 1 || value > maxQuantity){
        return false;
    }
    if(!/^[0-9]+$/.test(value)){
        return false;
    }
    if(Number.isFloat(value)){
        return false;
    }
    return true;
}

const validateExtraAccountQuantity = value => {
    return validateQuantityGeneric(value, 2000);
}

const validateReceiptQuantity = value => {
    return validateQuantityGeneric(value, 10);
}

const validateAccountQuantity = value => {
    return validateQuantityGeneric(value, 2000);
}

//cambia el color del borde del input a rojo en caso de no cumplir con las validaciones 
const showError = (res, element) => {
    //obtiene la referencia al input
    var e = document.getElementById(element);
    //verifica que si el valor es false
    if(!res){
        //aniade una clase al input
        e.classList.add('is-invalid')
    }else{
        //verifica si contiene la clase is-invalid
        if(e.classList.contains('is-invalid')){
            //quita la clase
            e.classList.remove('is-invalid')
        }
    }
}
//valida un correo electronico
const validateEmail = email => {
    let valid = true
    let exp = /^[-\w.%+]{1,64}@(?:[A-Z0-9-]{1,63}\.){1,125}[A-Z]{2,63}$/i
    //verifica que el correo cumpla con la validacion de la expresion
    if(!exp.test(email)){
        valid = false
    //verifica que el correo no este vacion
    }else if(email === ''){
        valid = false
    }
    return valid
}

const validateBirthday = birthday => {
    if (birthday === '') {
        return false;
    }
    birthday = new Date(birthday);
    let today = new Date();

    return today.getFullYear() - birthday.getFullYear() >= 15;
}

export {
    validateQuantities,
    validateReceiptQuantity,
    validateCodSis,
    validateApP,
    validateApM,
    validateNames,
    validateDoc,
    validateComp,
    showError,
    validateEmail,
    validateSecondName,
    validateBirthday,
    validateAccountQuantity,
    validateExtraAccountQuantity
};
