import React, { Fragment } from "react";
import { Modal } from "react-bootstrap";

export default class DetailPayModal extends React.Component{
  render() {

    return (
      <Fragment>
        <Modal show={this.props.showModalPay} onHide={this.props.handleCloseModalPay} size="xl" aria-labelledby="contained-modal-title-vcenter" centered>
          <Modal.Header closeButton>
            <Modal.Title>{this.props.title}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
          <div className="App">
            <div className="container m-0">
              <div className='header'>
                  <label className='title'>CUENTA PRINCIPAL</label>
              </div>
              {this.props.selectRowAccount.id ?
                <table className="table table-payHeader table-borderless table-sm" style={{minWidth: "1000px"}}>
                  <tbody>
                    <tr className="payHeader">
                        <th colSpan='2'>CUENTA</th>
                        <th colSpan='2'>PERSONA</th>
                    </tr> 
                    <tr>
                        <th>CODIGO</th>
                        <td> {this.props.selectRowAccount.cuenta_codigo}</td>
                        <th>DOCUMENTO</th>
                        <td> {this.props.selectRowAccount.persona_ci}{this.props.selectRowAccount.persona_complemento ? ` - ${this.props.selectRowAccount.persona_complemento}` : null}</td>
                    </tr> 
                    <tr>
                        <th>NOMBRE</th>
                        <td> {this.props.selectRowAccount.cuenta_nombre}</td>
                        <th>APELLIDOS</th>
                        <td> {this.props.selectRowAccount.persona_paterno}</td>
                    </tr>  
                    <tr>
                        <th>CANTIDAD</th>
                        <td> {this.props.selectRowAccount.cantidad}</td>
                        <th>NOMBRES</th>
                        <td> {this.props.selectRowAccount.persona_nombres}</td>
                    </tr>  
                    <tr>
                        <th>PRECIO</th>
                        <td> {this.props.selectRowAccount.precio}</td>
                        <th>CODIGO</th>
                        <td> {this.props.selectRowAccount.persona_codigo}</td>
                    </tr>  
                    <tr>
                        <th>FECHA</th>
                        <td> {this.props.selectRowAccount.fecha}</td>
                    </tr>  
                    <tr>
                        <th>ESTRUCTURA</th>
                        <td> {this.props.selectRowAccount.estructura}</td>
                    </tr>

                  </tbody>
                </table>
              :
              <></>
              }
              <div className='header'>
                  <label className='title'>CUENTAS ASOCIADAS</label>
              </div>
                <div className='table-scroll table-paybody'>
                    <table className='table PayLine table-borderless table-responsive table-sm table-hover'>
                        <thead>
                          <tr >
                            <th style={{backgroundColor:'#fff'}} colSpan='3'>Persona</th>
                            <th style={{backgroundColor:'#fff'}} colSpan='5'>Cuenta</th>
                          </tr>
                          <tr>
                              <th style={{minWidth: '11%'}}>Documento</th>
                              {/* <th>Complemento</th> */}
                              <th>Código</th>
                              <th>Apellidos y Nombres</th>
                              <th>Codigo</th>
                              <th>Nombre</th>
                              <th>Cant.</th>
                              <th>Precio</th>
                              <th>Total</th>

                          </tr>    
                        </thead>
                        <tbody>
                            {this.props.detailSisPay.length > 0 ? this.props.detailSisPay.map((account, key) => {
                              return (    
                                  <tr key={key}> 
                                      <td>{account.persona_ci}{this.props.selectRowAccount.persona_complemento ? ` - ${this.props.selectRowAccount.persona_complemento}` : null}</td>
                                      {/* <td>{account.persona_complemento}</td> */}
                                      <td>{account.persona_codigo}</td>
                                      <td>{account.persona_paterno+' '+account.persona_nombres}</td>
                                      <td className='text-start'>{account.cuenta_codigo}</td>
                                      <td>{account.cuenta_nombre}</td>
                                      <td>{account.cantidad}</td>
                                      <td>{account.precio}</td>
                                      <td>{(account.cantidad*account.precio).toFixed(2)}</td>

                                  </tr>  
                              )
                            }) : 
                            <tr>
                              <td colSpan={"11"}>
                                  No existen cuentas asociadas
                              </td>
                            </tr>}
                        </tbody>
                    </table>
                </div>
                {this.props.selectRowAccount.id ?
                  <div>
                    <table className="table table-payHeader table-borderless table-sm">
                      <tbody>
                        <tr>
                          <td className="fw-bold">Precio subtotal de cuentas asociasdas Bs.</td>
                          <td className="text-end pe-4">{(this.props.selectRowAccount.subtotal).toFixed(2)}</td>
                        </tr>
                        <tr>
                          <td className="fw-bold">Precio TOTAL Bs.</td>
                          <td className="text-end pe-4">{(this.props.selectRowAccount.total).toFixed(2)}</td>
                        </tr>
                      </tbody>
                    </table>
                  </div> :
                  null
                }
            </div>
            {
                <div className='container'>
                    <div className='btnCuenta'>
                        <button className='btn btn-primary' onClick={this.props.handleCloseModalPay}>Cerrar</button>
                    </div>
                </div>
                }
            </div>
          </Modal.Body>
        </Modal>
      </Fragment>
    );
  }
}
