import pdfMake from 'pdfmake/build/pdfmake'
import pdfFonts from 'pdfmake/build/vfs_fonts'
import printJS from 'print-js';

import RobotoFont from '../fonts/Roboto'
import RobotoBoldFont from '../fonts/RobotoBold'

import reportConf, { styles } from './components/reportConf'
import reportHeader from './components/reportHeader'
import reportFooter from './components/reportFooter'
import { formatCurrency, formatDate } from '../utils/utils'
import { RobotoCondensed } from 'Reports/fonts/roboto/robotoCondensed';

export default class ReportResumenRecibos {

    /**
     * método encargado de generar el reporte
     * @param {Array} itemsReport
     * @param {string} userName
     * @param {string} receivedFrom
     * @param {date} initDate
     * @param {date} endDate
     * @param {boolean} isPrint
     * @param {boolean} isFilter
     * @param {JSON} filter
     * @param {boolean} isAdmin
     */
    generarPdf(itemsReport, usuario, procesado, fechaInicio, fechaFin, isPrint, isFilter, filter, isAdmin,userUnidad, electronico, caja) {

        // Cabecera de página.
        const header = (currentPage, pageCount, pageSize) => {
            return reportHeader(
                currentPage,
                pageCount,
                'Resumen - Recibos',
                userUnidad,
                usuario,
                fechaInicio,
                fechaFin,
                electronico,
                caja
            );
        }

        // Contenido de página.
        const content = []
        itemsReport.forEach(item => {

            const recibos = item.recibos.map(f => {
                return [[
                    {
                        table: {
                            widths: [35, '*', 65, 55, 55, 90],
                            body: [
                                [
                                    { text: 'Fecha:', style: ['fw-bold'] },
                                    { text: formatDate(f.fecha_pago, 'd-m-Y') },
                                    { text: 'Recibo:', style: ['fw-bold'] },
                                    { text: f.numero_recibo },
                                    { text: 'Preimpreso:', style: ['fw-bold'] },
                                    { text: f.numero_preimpreso },
                                  
                                ],
                                [
                                    { text: 'Nombre:', style: ['fw-bold'] },
                                    { text: f.nombre },
                                    { text: 'Documento:', style: ['fw-bold'] },
                                    { text: f.documento },
                                    {},{},
                                    
                                ],
                            ],
                        },
                        layout: {
                            hLineWidth: () => 0,
                            vLineWidth: () => 0,
                            paddingTop: () => 0,
                            paddingBottom: () => 0,
                        },
                    },
                    {
                        table: {
                            widths: [20, '*', 90, 80],
                            body: [
                                ...f.detalle.map(d => [
                                    { text: d.cantidad, style: ['text-center'] },
                                    { text: d.descripcion, colSpan: 2, style: ['font-roboto-condensed'] }, {},
                                    { text: formatCurrency(d.total), style: ['text-end'] },
                                ]),
                                [
                                    {}, {},
                                    { text: 'TOTAL RECIBO:', style: ['fw-bold'], marginTop: 3 },
                                    { text: formatCurrency(f.total_recibo), style: ['text-end'], marginTop: 3 },
                                ],
                                [
                                    {
                                        text: f.estado === 'A' ? 'ANULADO' : f.glosa || '',
                                        colSpan: 2,
                                        bold: f.estado === 'A',
                                    }, {},
                                    {},
                                    {},
                                ],
                                [
                                    {}, {},
                                    {},
                                    {} 
                                ],
                            ],
                        },
                        layout: {
                            hLineWidth: () => 0,
                            vLineWidth: () => 0,
                            paddingTop: () => 0,
                            paddingBottom: () => 0,
                        },
                    },
                ]]
            })
            content.push({
                table: {
                    dontBreakRows: true,
                    widths: ['*'],
                    headerRows: 1,
                    body: [
                        [{text: ['Usuario: ', item.usuario], style: ['fw-bold', 'my-1']}],
                        ...recibos
                    ],
                },
                layout: {
                    hLineWidth: (i, node) => {
                        const tableLength = node.table.body.length
                        return i > 1 && i < tableLength ? .5 : 0
                    },
                    vLineWidth: () => 0,
                    paddingLeft: () => 0,
                    paddingRight: () => 0,
                }
            })
        })

        // Pie de página.
        const footer = {
                ...reportFooter(procesado)[0],
                margin: [0, 0, reportConf.pageMarginRight, 38],
            }

        const report = {
            pageSize: 'letter',
            pageMargins: [reportConf.pageMarginLeft, reportConf.pageMarginTop, reportConf.pageMarginRight, 25],
            header,
            content,
            footer,
            styles,
            defaultStyle: {
                font: 'Roboto',
                fontSize: 9,
            },
        }

        pdfMake.vfs = {
            ...pdfFonts.pdfMake.vfs,
            'Roboto-Regular.ttf': RobotoFont,
            'Roboto-Bold.ttf': RobotoBoldFont,
            'Roboto-Condensed-Regular.ttf': RobotoCondensed,
            // 'Roboto-Condensed-Bold.ttf': RobotoCondensedBold,
            // 'Roboto-Condensed-Italic.ttf': RobotoCondensedItalic,
        }
        pdfMake.fonts = {
            Roboto: {
                normal: 'Roboto-Regular.ttf',
                bold: 'Roboto-Bold.ttf'
            },
            // Add font Roboto Condensed
            RobotoCondensed: {
                normal: 'Roboto-Condensed-Regular.ttf',
                // bold: 'Roboto-Condensed-Bold.ttf',
                // italics: 'Roboto-Italic.ttf',
                // bolditalics: 'Roboto-Italic.ttf'
            },
        }
        if (isPrint) {
            let document = pdfMake.createPdf(report)

            document.getBase64(function (docBlob) {
                printJS({
                    printable: docBlob,
                    type: 'pdf',
                    base64: true
                })
            })
        } else {
            pdfMake.createPdf(report).open()
        }
    }
}