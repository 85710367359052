import React from 'react'
import '../Styles/spinner.css'

const LoadingSpinner = () => {
    return (
        <div className='spinner-container'>
            <div className='loding-spinner'></div>
        </div>
    )
}

export default LoadingSpinner
