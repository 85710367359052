import { useRef, useState } from 'react'
import FacturaServices from '../../services/factura.service'
import ModalAdicionarCuentas from './ModalAdicionarCuentas'
import { classNames } from '../../utils/ClassNames'

function DatosExtra({ datos, setDatos, cuentasAdicionales, setCuentasAdicionales, errors, touched, validar }) {
    const [show, setShow] = useState(false)
    const [cuentas, setCuentas] = useState([])
    const precioRef = useRef(null)

    const handleChange = (e) => {
        const {name, value} = e.target
        const _datos = { ...datos, [name]: value }
        setDatos(_datos)
        validar({extra: _datos}, name)
    }

    const handleUpdate = (e, index) => {
        const cuenta = {...cuentasAdicionales[index],[e.target.name]:e.target.value}
        const _cuentasAdicionales = cuentasAdicionales.map((ca, i)=> i===index ? cuenta:ca)
        setCuentasAdicionales(_cuentasAdicionales)
        validar({adicionales: _cuentasAdicionales}, `adicional-${index}`)
    }
    
    const handleRemoved = (index) => {
        setCuentasAdicionales(cuentasAdicionales.filter((_,i)=>i!==index))
    }

    const buscarCuenta = async (codigo, nombre = '') => {
        const response = await FacturaServices.listarCuentasFacturas(codigo, nombre)
        if (response.data.isOk) {
            const data = response.data.result
            // if(response.data.result.length === 0){
            //     setSinDeudas(true);
            // }
            if (show) {
                setCuentas(data)
            } else {
                setCuentas(data)
                setShow(true)
            }
        }
    }

    const seleccionarCuenta = (cuenta) => {
        const { id, codigo, nombre, unidad, precio, tipo_monto } = cuenta //cuando el array devuelve un solo elemento
        setCuentasAdicionales([
            ...cuentasAdicionales,
            { id, codigo, nombre, unidad, precio, tipo_monto, cantidad: '1' },
        ])

        if(cuenta.tipo_monto ==='V'){
            // precioRef.current.focus()
            setTimeout(() => { precioRef.current.focus() }, 100)
        }

    }
    return (
        <>
            <div className='header clearfix mb-3'>
                <label className='title float-start'>CUENTAS ASOCIADAS</label>
            </div>
            <button
                type='button'
                style={{ marginBottom: '1%' }}
                className='btn btn-primary btn-sm'
                //disabled={cargando}
                onClick={() => {
                    buscarCuenta(1)
                    setShow(true)
                }}
                //ref={facturarRef}
            >
                <span>Adicionar Cuentas</span>
            </button>
            <div className='PayLine table-responsive'>
                {cuentas.length > 0 ? (
                    <table className='table table-responsive table-borderless'>
                        <thead>
                            <tr>
                                <td></td>
                                <th className='size1'>
                                    <b>Codigo</b>
                                </th>
                                <th className='size2'>
                                    <b>Cuenta</b>
                                </th>
                                <th className='size3'>
                                    <b>P/U</b>
                                </th>
                                <th className='size3'>
                                    <b>Cant.</b>
                                </th>
                                <th className='size3'>
                                    <b>Bs.</b>
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                //recorre la lista de cuentas asociadas
                                cuentasAdicionales.map((ca, index) => (
                                    <tr key={index}>
                                        <td>
                                            <input
                                                className='form-check-input'
                                                type='checkbox'
                                                checked
                                                onClick={() => handleRemoved(index)}
                                            />
                                        </td>
                                        <td>
                                            <input
                                                className='form-control form-control-sm'
                                                value={ca.codigo}
                                                disabled
                                            />
                                        </td>
                                        <td>
                                            <input
                                                className='form-control form-control-sm'
                                                value={ca.nombre}
                                                disabled
                                            />
                                        </td>
                                        <td>
                                            <input
                                                className='form-control form-control-sm'
                                                type='number'
                                                name='precio'
                                                ref={precioRef}
                                                onChange={(e) => handleUpdate(e, index)}
                                                value={ca.precio}
                                                // value={parseFloat(ca.precio).toFixed(2)}
                                                style={{ textAlign: 'right' }}
                                                disabled={ca.tipo_monto==='F'}
                                            />
                                        </td>
                                        <td>
                                            <input
                                                className='form-control form-control-sm'
                                                type='number'
                                                name='cantidad'
                                                style={{ textAlign: 'right' }}
                                                onChange={(e) => handleUpdate(e, index)}
                                                value={ca.cantidad}
                                                min={1}
                                            />
                                        </td>
                                        <td>
                                            <input
                                                className={classNames([
                                                    'form-control form-control-sm',
                                                    touched[`adicional-${index}`] && errors[`adicional-${index}`] && 'is-invalid'
                                                ])}
                                                value={
                                                    isNaN(ca.cantidad * ca.precio)
                                                        ? 0
                                                        : (ca.cantidad * ca.precio).toFixed(2)
                                                }
                                                style={{ textAlign: 'right' }}
                                                disabled
                                            />
                                        </td>
                                    </tr>
                                ))
                            }
                        </tbody>
                    </table>
                ) : (
                    <div>No existen resultados</div>
                )}
            </div>
            <table className='table table-responsive table-borderless'>
                <tbody>
                    <tr>
                        <th>Aclaración: </th>
                        <td>
                            <input
                                type='text'
                                name='observacion'
                                value={datos.observacion}
                                onChange={handleChange}
                                className='form-control form-control-sm'
                                required
                            />
                        </td>
                        <th className='text-end'>Sub Total Importe Bs. </th>
                        <td>
                            <input
                                name='subtotal'
                                type='number'
                                value={(datos.subtotal||0).toFixed(2)}
                                // value={isNaN((parseFloat(sumTotal()))}
                                onChange={handleChange}
                                style={{ textAlign: 'right' }}
                                className='form-control form-control-sm'
                                disabled
                            />
                        </td>
                    </tr>
                    <tr>
                        <th colSpan={3} className='text-end'>Descuento Bs.</th>
                        <td>
                            <input
                                name='descuento'
                                type='number'
                                value={datos.descuento}
                                onChange={handleChange}
                                style={{ textAlign: 'right' }}
                                className={classNames([
                                    'form-control form-control-sm', 
                                    touched.descuento && errors.descuento && 'is-invalid',
                                ])}
                                min={0}
                                // disabled
                            />
                        </td>
                    </tr>
                    <tr>
                        <th colSpan={3} className='text-end'>Total Importe Bs. </th>
                        <td>
                            <input
                                name='total'
                                type='number'
                                value={(datos.subtotal-datos.descuento).toFixed(2)}
                                // value={isNaN((parseFloat(sumTotal()))}
                                onChange={handleChange}
                                style={{ textAlign: 'right' }}
                                className='form-control form-control-sm'
                                disabled
                            />
                        </td>
                    </tr>
                </tbody>
            </table>
            <ModalAdicionarCuentas
                show={show}
                onHide={() => {
                    setShow(false)
                }}
                cuentas={cuentas}
                valor={1}
                buscarCuentas={buscarCuenta}
                seleccionarCuenta={seleccionarCuenta}
            />
        </>
    )
}

export default DatosExtra
