import React, { useEffect, useRef } from 'react'
import { Modal } from 'react-bootstrap';
import "../Styles/PopUp.css";


function PopUp(props) {

    const refButton = useRef(null);

    const close = () => {
        props.close(false);
        props.focusStart();
        props.clean();
    }

    useEffect(() => {
        if(props.visible === true)
            if(refButton.current) refButton.current.focus()
        return () => {
        }
    }, [props.visible])
    

    return (
        <Modal show={props.visible} onHide={props.close} centered>
            <div className='PopUp' style={{visibility:props.visible?"visible":"hidden"}}> 
                {/* <h3>
                    se mando a  {props.cancel?"anular":"imprimir"} el recibo
                </h3> */}
                <h3>
                    {props.message}
                </h3>
                <button className={'btn btn-secondary bt2 kardex-submit'} onClick={close} ref={refButton}>
                    cerrar
                </button>
            </div>
        </Modal>
    )
}

export default PopUp
