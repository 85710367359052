import Header from 'Component/Header'
import { fuenteFondoOptions, initialPagination } from 'data/constants'
import useFilters, { buildFilters, objectToQueryString } from 'hooks/useFilters'
import useHttpToast from 'hooks/useHttpToast'
import { Button } from 'modules/modular/Button'
import { Checkbox } from 'modules/modular/Checkbox'
import { DataTable } from 'modules/modular/DataTable'
import { FieldGroup } from 'modules/modular/FieldGroup'
import { Input } from 'modules/modular/Input'
import { Confirm } from 'modules/modular/Modal'
import { Select } from 'modules/modular/Select'
import { useEffect, useState } from 'react'
import ContabilidadServices from 'services/contabilidad.service'
import { formatCurrency, formatDate } from 'utils'

const tiposFondo = {
    '': '',
    R: 'ROTATORIO',
    A: 'AVANCE',
}
const filterOptions = {
    equal: ['tipo', 'fuente_fondo', 'numero'],
    contain: ['beneficiario', 'actividad'],
    bool: ['activo'],
    betweenDate: ['fecha_creacion'],
}
const currentDate = new Date()
const currentYear = currentDate.getFullYear()
const formattedCurrentDate = formatDate(currentDate, 'Y-m-d')
const initialExtraFilters = { fecha_inicio: `${currentYear}-01-01`, fecha_fin: formattedCurrentDate }

export default function ListAllFondo() {
    const { showHttpToast } = useHttpToast()
    const { filters, handlePage, setFilters } = useFilters()
    const [extraFilters, setExtraFilters] = useState(initialExtraFilters)
    const [show, setShow] = useState({ increase: false })
    const [fondos, setFondos] = useState([])
    const [pagination, setPagination] = useState(initialPagination)
    const [selected, setSelected] = useState(null)
    const [loading, setLoading] = useState(false)

    useEffect(() => {
        load()
    }, [filters])

    const load = async () => {
        const params = objectToQueryString(filters)
        const { status, data } = await ContabilidadServices.fondo.list(params)
        if (status === 200) {
            setFondos(data.data)
            setPagination(data.pagination)
        } else {
            showHttpToast(status, { detail: data.message })
        }
    }
    const reloadList = () => load()

    const openModal = (key) => setShow({ ...show, [key]: true })
    const closeModal = (key) => setShow({ ...show, [key]: false })
    const oncloseModal = () => setSelected(null)

    const handleAction = (action, rowData) => {
        if (action === 'increase') {
            setSelected(rowData)
            openModal('increase')
        }
    }

    const increaseLimitFondo = async () => {
        if (selected) {
            const { status, data } = await ContabilidadServices.fondo.increaseLimit(selected.id)
            showHttpToast(status, { detail: data.message })
            if (status === 200) reloadList()
            closeModal('increase')
        }
    }
    const generatePdf = async () => {
        setLoading(true)
        const fechaInicio = extraFilters.fecha_inicio || `${currentYear}-01-01`
        const fechaFin = extraFilters.fecha_fin || formattedCurrentDate
        const params = objectToQueryString({ ...filters, fechas: { fecha_inicio: fechaInicio, fecha_fin: fechaFin } })
        const { status, data } = await ContabilidadServices.fondo.reportPdf(params)
        if (status === 200) {
            const pdfContent = data
            // Crear una URL local para el blob recibido
            const blobUrl = window.URL.createObjectURL(new Blob([pdfContent], { type: 'application/pdf' }))

            // Abrir una nueva pestaña con el PDF
            window.open(blobUrl, '_blank')
        } else {
            showHttpToast(status, { detail: 'Ocurrió un error al obtener el PDF' })
        }
        setLoading(false)
    }

    const handleChangeExtraFilters = (e) => {
        const _extra = { ...extraFilters, [e.target.name]: e.target.value }
        setExtraFilters(_extra)
        const fechaInicio = _extra.fecha_inicio || `${currentYear}-01-01`
        const fechaFin = _extra.fecha_fin || formattedCurrentDate
        setFilters({
            ...filters,
            filter: {
                ...filters.filter,
                fecha_creacion: { '>': `${fechaInicio} 00:00:00`, '<': `${fechaFin} 23:59:59` },
            },
        })
    }
    const handleFilter = (filterValues) => {
        const fechaInicio = extraFilters.fecha_inicio || `${currentYear}-01-01`
        const fechaFin = extraFilters.fecha_fin || formattedCurrentDate
        const fecha_creacion = [fechaInicio, fechaFin]
        const _filters = { filter: buildFilters({ ...filterValues, fecha_creacion }, filterOptions) }
        if (typeof filterValues.cantidad_finalizado === 'boolean') {
            if (filterValues.cantidad_finalizado) {
                _filters.filter.cantidad_finalizado = { '>': 0 }
            } else {
                _filters.filter.cantidad_finalizado = 0
            }
        }
        if (filters) {
            if (filters.sort) _filters.sort = filters.sort
            if (filters.pageSize) _filters.pageSize = filters.pageSize
        }
        setFilters(_filters)
    }
    const tipoFilterElement = ({ value, filterCallback }) => (
        <Select
            options={[
                { label: 'ROTATORIO', value: 'R' },
                { label: 'AVANCE', value: 'A' },
            ]}
            value={value}
            onChange={(e) => filterCallback(e.target.value)}
            style={{ minWidth: '8.5rem' }}
        />
    )
    const numeroFilterElement = ({ value, filterCallback }) => (
        <Input value={value} onChange={(e) => filterCallback(e.target.value)} type='number' />
    )
    const fuenteFondoFilterElement = ({ value, filterCallback }) => (
        <Select
            options={fuenteFondoOptions}
            value={value}
            onChange={(e) => filterCallback(e.target.value)}
            style={{ minWidth: '10rem' }}
        />
    )
    const checkboxFilterElement = ({ value, filterCallback }) => (
        <div className='d-flex justify-content-center'>
            <Checkbox
                value={value}
                onChange={(e) => filterCallback(e.target.value)}
                triState
                style={{ fontSize: '1.5rem' }}
            />
        </div>
    )
    const optionBodyTemplate = (rowData) => {
        return (
            <Button
                startIcon={<span className='bi-plus-lg' />}
                size='sm'
                title='Incrementar Limite'
                disabled={!rowData.activo}
                onClick={() => handleAction('increase', rowData)}
            >
                Limite
            </Button>
        )
    }
    const columns = [
        {
            field: 'tipo',
            header: 'Tipo',
            alignHeader: 'center',
            body: (rowData) => tiposFondo[rowData.tipo],
            bodyClassName: 'text-center',
            filter: true,
            filterElement: tipoFilterElement,
        },
        {
            field: 'numero',
            header: 'Nro.',
            alignHeader: 'center',
            body: (rowData) => rowData.numero ?? '-',
            bodyClassName: 'text-center',
            filter: true,
            filterElement: numeroFilterElement,
            style: { width: '5rem' },
        },
        { field: 'beneficiario', header: 'Beneficiario', alignHeader: 'center', filter: true },
        { field: 'actividad', header: 'Unidad', alignHeader: 'center', filter: true },
        {
            field: 'monto_asignado',
            header: 'Monto Asignado',
            alignHeader: 'center',
            headerClassName: 'text-nowrap',
            body: (rowData) => formatCurrency(rowData.monto_asignado),
            bodyClassName: 'text-end',
        },
        {
            field: 'fuente_fondo',
            header: 'Fondos',
            alignHeader: 'center',
            bodyClassName: 'text-center',
            filter: true,
            filterElement: fuenteFondoFilterElement,
        },
        {
            field: 'fecha_creacion',
            header: 'Fecha Registro',
            alignHeader: 'center',
            headerClassName: 'text-nowrap',
            body: (rowData) => formatDate(rowData.fecha_creacion, 'd/m/Y'),
            bodyClassName: 'text-center',
        },
        {
            field: 'cantidad_finalizado',
            header: 'Finalizado',
            alignHeader: 'center',
            body: (rowData) => `${rowData.cantidad_finalizado}/${rowData.limite_finalizado}`,
            bodyClassName: 'text-center',
            filter: true,
            filterElement: checkboxFilterElement,
        },
        {
            field: 'activo',
            header: 'Habilitado',
            alignHeader: 'center',
            body: (rowData) => (rowData.activo ? 'Si' : 'No'),
            bodyClassName: 'text-center',
            filter: true,
            filterElement: checkboxFilterElement,
        },
        {
            field: 'id',
            header: 'Opciones',
            alignHeader: 'center',
            body: optionBodyTemplate,
            bodyClassName: 'text-center',
        },
    ]

    return (
        <>
            <Header title='LISTA DE FONDOS' />
            <div className='p-3 w-100 h-100'>
                <div className='p-4 bg-body rounded h-100'>
                    <div className='d-flex justify-content-end gap-3 p-3 bg-body-tertiary'>
                        <FieldGroup label='Desde:' inline className='mb-0'>
                            <Input
                                type='date'
                                name='fecha_inicio'
                                value={extraFilters.fecha_inicio}
                                onChange={handleChangeExtraFilters}
                            />
                        </FieldGroup>
                        <FieldGroup label='Hasta:' inline className='mb-0'>
                            <Input
                                type='date'
                                name='fecha_fin'
                                value={extraFilters.fecha_fin}
                                onChange={handleChangeExtraFilters}
                            />
                        </FieldGroup>
                        <Button onClick={generatePdf} disabled={loading}>
                            Generar PDF
                        </Button>
                    </div>
                    <DataTable
                        columns={columns}
                        values={fondos}
                        responsive
                        lazy
                        pagination
                        currentPage={pagination.currentPage}
                        pageSize={pagination.pageSize}
                        totalItems={pagination.totalRecords}
                        onPage={handlePage}
                        paginationDetail
                        filterable
                        onFilter={handleFilter}
                        filterDelay={1000}
                    />
                    <Confirm
                        show={show.increase}
                        onHide={() => closeModal('increase')}
                        onExited={oncloseModal}
                        onReject={() => closeModal('increase')}
                        onAccept={increaseLimitFondo}
                        closeOnBackdrop={false}
                    >
                        ¿Esta seguro de <span className='text-primary'>INCREMENTAR</span> el limite de finalizaciones
                        del Fondo
                        {selected?.tipo === 'R' ? `Rotatorio N° ${selected.numero}` : 'en Avance'}?
                    </Confirm>
                </div>
            </div>
        </>
    )
}
