import { useToast } from 'modules/modular/Toaster'

export default function useHttpToast() {
    const { toasts, showToast, dismissToast, removeToast, pauseToast } = useToast()

    /**
     * Muestra un toast con datos según un código de estado HTTP.
     * @param {number} status Código de estado HTTP.
     * @param {import('modules/modular/Toaster').ToastProps} props Propiedades del Toast
     * @returns Retorna un objeto con las siguientes propiedades:
     * - id (string): ID del toast.
     * - update ((props: ToasterProps) => void): Función para actualizar los datos del toast.
     */
    const showHttpToast = (status, props) => {
        let variant = 'info'
        let title = 'Información'
        let delay = 10000
        if (status >= 400) {
            variant = 'error'
            title = 'Error'
            delay = 30000
        } else if (status >= 200 && status < 300) {
            variant = 'success'
            title = 'Éxito'
        }
        return showToast({ variant, title, delay, ...props })
    }

    return {
        toasts,
        showToast,
        showHttpToast,
        dismissToast,
        removeToast,
        pauseToast,
    }
}
