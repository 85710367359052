import saveAs from "file-saver"
const Excel = require('exceljs')

export default class ExcelReportDiezmoAccountUnit {
    list = []

    totalReport = 0.00
    totalDiezmo = 0.00
    totalNeto = 0.00

    /**
     * Metodo que manda a generar el informe consolidado por unidad y concepto en formato excel
     * 
     * @param JSON details 
     * @param string userName 
     * @param string initDate 
     * @param string endDate 
     * @param string fileName nombre que tendra el archivo al descargar
     */
    generateExcelFile(details, userName, initDate, endDate, fileName) {
        this.totalReport = 0.00
        this.totalDiezmo = 0.00
        this.totalNeto = 0.00

        this.listItemsDiezmo(details)

        const date = new Date()
        const outputDate = date.getFullYear().toString() + String(date.getMonth() + 1).padStart(2, '0') + String(date.getDate()).padStart(2, '0')
        const outputTime = `${date.getHours()}${date.getMinutes()}${date.getSeconds()}`

        const workbook = new Excel.Workbook();
        const worksheet = workbook.addWorksheet("Inf-Con_Unid_Cuent", { views: { showGridlines: false } });

        worksheet.columns = [
            { width: 20, alignmen: 'left' }, 
            { width: 40 }, 
            { width: 60 }, 
            { width: 15, alignment: 'right', style: { numFmt: '#,##0.00_);(#,##0.00)' } },
            { width: 15, alignment: 'right', style: { numFmt: '#,##0.00_);(#,##0.00)' } },
            { width: 15, alignment: 'right', style: { numFmt: '#,##0.00_);(#,##0.00)' } }
        ]

        worksheet.mergeCells(`A1:F1`)
        worksheet.getCell("A1").value = "Informe Consolidado por Unidad y Cuenta"
        worksheet.getCell("A1").alignment = { horizontal: "center", vertical: "middle" }
        worksheet.getCell("A1").font = {
            bold: true,
            size: 14
        }
        worksheet.getRow(1).height = 25

        worksheet.getCell("A2").value = "Usuario"
        worksheet.getCell("B2").value = userName

        worksheet.getCell("A3").value = "Fecha"
        worksheet.getCell("B3").value = `Del ${initDate} al ${endDate}`

        var indexCell = 5
    
        //this.listItemsDiezmo(details).then(() => {
            for (var i = 0; i < details.length; i++) {
                //codigo y rubro
                worksheet.getCell(`A${indexCell}`).value = details[i].codigo
                worksheet.getCell(`A${indexCell}`).font = {
                    bold: true,
                    size: 14
                }
                worksheet.getCell(`B${indexCell}`).value = details[i].rubro
                worksheet.getCell(`B${indexCell}`).font = {
                    bold: true,
                    size: 14
                }
                ++indexCell
                //tabla items
                const tableName = details[i].rubro.replace(/ /g, "")
                const auxTable = {
                    name: `${tableName}${i}`,
                    ref: `A${indexCell}`,
                    headerRow: true,
                    totalsRow: true,
                    columns: [
                        { name: "codigo" },
                        { name: "unidad" },
                        { name: "cuenta" },
                        { name: "diezmo", totalsRowFunction: 'sum' },
                        { name: "ing. neto", totalsRowFunction: 'sum' },
                        { name: "total", totalsRowFunction: 'sum' }
                    ],
                    rows: this.list[i],
                }

                indexCell += (details[i].lista.length + 4)

                worksheet.addTable(auxTable)

                worksheet.getCell(`A${(indexCell - 3)}`).font = {
                    bold: true,
                }
                worksheet.getCell(`D${(indexCell - 3)}`).font = {
                    bold: true,
                }
                worksheet.getCell(`E${(indexCell - 3)}`).font = {
                    bold: true,
                }
                worksheet.getCell(`F${(indexCell - 3)}`).font = {
                    bold: true,
                }

                worksheet.getCell(`A${(indexCell - 3)}`).border = {
                    top: { style: "medium" },
                    left: { style: "medium" },
                    bottom: { style: "medium" }
                }
                worksheet.getCell(`B${(indexCell - 3)}`).border = {
                    top: { style: "medium" },
                    bottom: { style: "medium" }
                }
                worksheet.getCell(`C${(indexCell - 3)}`).border = {
                    top: { style: "medium" },
                    bottom: { style: "medium" }
                }
                worksheet.getCell(`D${(indexCell - 3)}`).border = {
                    top: { style: "medium" },
                    bottom: { style: "medium" }
                }
                worksheet.getCell(`E${(indexCell - 3)}`).border = {
                    top: { style: "medium" },
                    bottom: { style: "medium" }
                }
                worksheet.getCell(`F${(indexCell - 3)}`).border = {
                    top: { style: "medium" },
                    right: { style: "medium" },
                    bottom: { style: "medium" }
                }

            }
        //})

        worksheet.getCell(`C${indexCell}`).value = "Total Consolidado"
        worksheet.getCell(`C${indexCell}`).font = {
            bold: true,
            size: 14
        }
        worksheet.getCell(`C${indexCell}`).border = {
            top: { style: "medium" },
            left: { style: "medium" },
            bottom: { style: "medium" }
        }
        worksheet.getCell(`C${indexCell}`).alignment = { horizontal: "left", vertical: "middle" }

        worksheet.getCell(`D${indexCell}`).value = this.totalDiezmo
        worksheet.getCell(`D${indexCell}`).font = {
            bold: true,
            size: 14
        }
        worksheet.getCell(`D${indexCell}`).border = {
            top: { style: "medium" },
            bottom: { style: "medium" }
        }
        worksheet.getCell(`D${indexCell}`).alignment = { horizontal: "right", vertical: "middle" }

        worksheet.getCell(`E${indexCell}`).value = this.totalNeto
        worksheet.getCell(`E${indexCell}`).font = {
            bold: true,
            size: 14
        }
        worksheet.getCell(`E${indexCell}`).border = {
            top: { style: "medium" },
            bottom: { style: "medium" }
        }
        worksheet.getCell(`E${indexCell}`).alignment = { horizontal: "right", vertical: "middle" }

        worksheet.getCell(`F${indexCell}`).value = this.totalReport
        worksheet.getCell(`F${indexCell}`).font = {
            bold: true,
            size: 14
        }
        worksheet.getCell(`F${indexCell}`).border = {
            top: { style: "medium" },
            right: { style: "medium" },
            bottom: { style: "medium" }
        }
        worksheet.getCell(`F${indexCell}`).alignment = { horizontal: "right", vertical: "middle" }

        worksheet.getRow(indexCell).height = 25

        workbook.xlsx.writeBuffer().then(function (buffer) {
            if(fileName === ''){
                saveAs(
                    new Blob([buffer], { type: "application/octet-stream" }),
                    `${outputDate}${outputTime}_Report.xlsx`
                );
            }else{
                saveAs(
                    new Blob([buffer], { type: "application/octet-stream" }),
                    `${fileName}.xlsx`
                );
            }
        });
    }

    async listItemsDiezmo(items){
        this.list = []
        for(var i=0; i<items.length; i++){
            var list2 = []            
            for(var a=0; a<items[i].lista.length; a++){
                this.totalReport += parseFloat(items[i].lista[a].total)
                this.totalDiezmo += (parseFloat(items[i].lista[a].total) * 0.1)
                this.totalNeto += (parseFloat(items[i].lista[a].total) * 0.9)
                list2.push([
                    `${items[i].lista[a].codigo}`,
                    items[i].lista[a].unidad,
                    items[i].lista[a].cuenta,
                    (parseFloat(items[i].lista[a].total) * 0.1),
                    (parseFloat(items[i].lista[a].total) * 0.9),
                    parseFloat(items[i].lista[a].total)
                ]) 
            }
            this.list.push(list2)
        }
    }

}