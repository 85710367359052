import React, { Component } from 'react';
import {validateQuantities} from './Validation/validate';

/**
 * Componente que retorna la lista de cuentas asociadas con propios estilos y funciones
 */
class Accountsection extends Component {

  constructor(props){
    super(props);
    this.state = {
      check:true,
    }

    this.delRow = this.delRow.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleCheck = this.handleCheck.bind(this);
  }
  //elimina una fila de la tabla con un id proporcionado
  delRow = (id) => {
    //obtiene el indice asociado a la cuenta a la que le corresponde el id
    const index = this.props.account.findIndex((d)=>d.id === id)
    //elimina la cuenta asociada al indice
    this.props.account.splice(index,1)
    //define una variable auxiliar con la nueva lista de cuentas asociadas
    let aux = this.props.account
    //actualiza la lista en todas las vistas
    this.props.updateAccount(aux)
    //debido al cambio, valida nuevamente los inputs
    this.validateInputs()
    //debido al cambio, vuelve a sumar el total
    this.props.sumTotal()
  }

  //maneja el estado de los checkboxes
  handleCheck = () => {
    //actualiza su valor al opuesto
    this.setState({check : !this.state.check})
  }
  //actualiza el valor del input cantidad correspondiente a una cuenta
  handleChange = (value, id) => { 
    //asigna la lista de las cuentas a una variable auxiliar
    let aux = this.props.account
    //recorre la lista
    for (let i = 0; i < aux.length; i++) {
      //verifica si el id de la lista auxiliar es la proporcionada
      if(aux[i].id === id){
        //actualiza la cantidad en la lista auxiliar
        aux[i].cantidad = parseInt(value)
        //actualiza la lista original con la lista auxiliar
        this.props.updateAccount(aux)
        //rompe el ciclo
        break;
      }
    }
    //debido al cambio, suma el total
    this.props.sumTotal()
    //debido al cambio, valida los inputs
    this.validateInputs()
  }
  //funcion que valida los inputs (cantidades)
  validateInputs = () => {
    //obtiene todos los id de la lista de cuentas asociadas
    let ids = this.props.account.map((e)=>e.id)
    //llama a la funcion validateQuantities y obtiene una lista con los id de los valores que no son validos
    let res = validateQuantities(this.props.account)
    //recorre la lista de ids y filtra los que no se encuentren en la lista res
    var result = ids.filter(x => !res.includes(x));
    //verifica que el tamanio de la lista sea mayor a 0
    if(res.length>0){
      //recorre la lista res
      for (let i = 0; i < res.length; i++) {
        //obtiene el elemento html asociado con el id
        var elemento = document.getElementById("c"+res[i]);
        //aniade una clase (invalido)
        elemento.classList.add('is-invalid')
      }
    }
    //recorre la lista result
    for (let j = 0; j < result.length; j++) {
      //obtiene el elemento html asociado con el id
      let e = document.getElementById("c"+result[j]);
      //verifica si contiene la clase (invalido)
      if(e.classList.contains('is-invalid')){
        //elimina la clase del input
        e.classList.remove('is-invalid')
      }
    }    
  }

  render() { 
  
    return (
      <div>
        <div className='header'>
          <label className='title'>CUENTAS ASOCIADAS</label>
        </div>
        <table className='table table-responsive table-borderless'>
          <thead>
            <tr>
              <td colSpan={1}></td>
              <th className='size3'><b>Codigo</b></th>
              <th className='size3'><b>Cuenta</b></th>
              <th className='size3'><b>P/U</b></th>
              <th className='size3'><b>Cant.</b></th>
              <th className='size3'><b>Bs.</b></th>
            </tr>
          </thead>
          <tbody>
            {//recorre la lista de cuentas asociadas
              this.props.account.map((acc)=>
                  <tr key={acc.id}>
                    <td className='size4' >
                      <input className="form-check-input" type="checkbox" value="" id="flexCheckDefault" checked onChange={this.handleCheck} onClick={() => this.delRow(acc.id)}/>
                    </td>
                    <td className='size1'><input className='form-control form-control-sm' value={acc.codigo} disabled/></td>
                    <td className='size2'><input className='form-control form-control-sm' value={acc.cuenta_asociada} style={{textTransform:"uppercase"}} disabled/></td>
                    <td className='size1'><input className='form-control form-control-sm' value={parseFloat(acc.precio).toFixed(2)} style={{textAlign:"right"}} disabled/></td>
                    <td ><input className='form-control form-control-sm' type='number' name='cantidad' id={'c'+acc.id} style={{textAlign:"right"}} onChange={(e) => this.handleChange(e.target.value, acc.id)} value={acc.cantidad} onWheel={(e) => e.target.blur()} min={1}/></td>
                    <td><input className='form-control form-control-sm' value={isNaN(acc.cantidad*acc.precio) ? 0 : (acc.cantidad*acc.precio).toFixed(2)} style={{textAlign:"right"}} disabled /></td>
                  </tr>
              )
            }
            <tr>
              <td colSpan={3} style={{textAlign:"left"}}><button type="button" className="btn btn-primary btn-sm" onClick={()=>this.props.handleModal()}>Adicionar Cuentas</button></td>
              <td colSpan={2}><label>Sub Total Cuentas Bs.</label></td>
              <td><input className='form-control form-control-sm' disabled value={isNaN(this.props.sumTotal()) ? '' : this.props.sumTotal()} style={{textAlign:"right"}} /></td>
            </tr>
           
        </tbody>
        </table>
      </div> 
    );
  }
}

export default Accountsection;

