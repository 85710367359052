import React, { useEffect, useState } from 'react'
import Services from "../services/user.service"
import "../Styles/all.css"
import "../Styles/KardexValores.css"

import LoadingSpiner from '../Component/LoadingSpinner';

function KardexValores() {

    const services = new Services();

    useEffect(() => {
        setSpinner(true)
        loadData()
    }, [])

    const [data, setData] = useState([]);
    const [message, setMessage] = useState('');
    const [messageStyle, setMessageStyle] = useState('');
    const [quantity, setQuantity] = useState([]);

    const[spinner, setSpinner] = useState(false)

    useEffect(() => {
        document.title = 'Caja UMSS - Ingreso de valores'
        setTimeout(() => {
            if(messageStyle.split(' ').length < 2 && messageStyle !== ''){
                setMessageStyle(messageStyle + ' fade1');
            }
        }, 3000)
    }, [messageStyle])

    const loadData = async () => {
        let newData = [];
        newData = await services.getValorados();
        newData = newData.map(element => {
            let newElement = {};
            newElement.id = element.id;
            newElement.codigo = element.codigo;
            newElement.concepto = element.nombre;
            newElement.precio = element.precio;
            newElement.ingresos = 0;
            return newElement;
        })
        for (const element of newData) {
            let cantidad = await services.getCantidadValorados(element.id);
            Object.assign(element, {cantidadActual: cantidad})
        }
        setQuantity(new Array(newData.length).fill(0));
        setData(newData);
        setSpinner(false)
    }

    const change = (value, row) => {
        value = Number(value);
        let newQuantity = [...quantity]
        newQuantity[row] = value;
        if(value >= 0)
        setQuantity(newQuantity);
    }

    const save = async () => {
        let saveOk = true;
        await Promise.all(quantity.map(async (element, idx) => {
            if (element > 0) {
                let res = await services.saveIngress(element, data[idx].id);
                saveOk = saveOk && res.data.isOK;
            }
        }))
        if(saveOk){
            loadData()
        }
    }

    return (
        <>
            { spinner ? <LoadingSpiner/> : null }
            <div className='container shadow-sm p-3 bg-body rounded min-width-web-only' style={{marginLeft: 'auto',marginRight: "auto"}}>
            {
                !data.length ?
                    <h1 className='text-center w-100'>No hay valores registrados para esta estructura</h1>
                    :
            <table className='table'>
                <thead>
                    <tr className='table-secondary kardex-tr'>
                        <th style={{ width: '17.5%' }}>Código</th>
                        <th style={{ width: '25%' }}>Cuenta</th>
                        <th style={{ width: '17.5%' }}>Precio Bs.</th>
                        <th style={{ width: '20%' }}>Cantidad Actual</th>
                        <th style={{ width: '20%' }}>Ingresos</th>
                    </tr>
                </thead>
                <tbody>
                    {data.map((element, id) => (
                        <tr key={'row' + id} className={'kardex-tr'}>
                            <td style={{width: '17.5%' }}>
                                <div>{element.codigo}</div>
                            </td>
                            <td style={{width: '25%' }}>
                                <div style={{textAlign: 'left'}}>{element.concepto}</div>
                            </td>
                            <td style={{width: '17.5%' }}>
                                <div>{element.precio}</div>
                            </td>
                            <td style={{width: '20%' }}>
                                <div style={{textAlign: 'right'}}>{element.cantidadActual.toFixed(2)}</div>
                            </td>
                            <td style={{width: '20%' }}>
                                <input
                                    value={quantity[id]}
                                    style={{padding:'0', border:'none', width: '100%', height:'40px', textAlign:'center'}}
                                    className='form-control form-control-sm'
                                    onChange={e => change(e.target.value, id)}
                                    min='0'
                                    type='number'
                                />
                            </td>
                        </tr>))}
                </tbody>
            </table>
            }
            <div className={'message '  + messageStyle} >{message}</div>
            {
                data.length ?
                    <button onClick={save} className='btn btn-primary bt2 kardex-submit'>Guardar Kardex</button>
                    : null
            }
        </div>
        </>
    )
}

export default KardexValores


