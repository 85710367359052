import Header from 'Component/Header'
import { fuenteFondoOptions, initialPagination } from 'data/constants'
import useFilters, { objectToQueryString } from 'hooks/useFilters'
import useHttpToast from 'hooks/useHttpToast'
import { Button } from 'modules/modular/Button'
import { Checkbox } from 'modules/modular/Checkbox'
import { DataTable } from 'modules/modular/DataTable'
import { EditableSelect } from 'modules/modular/EditableSelect'
import { FieldGroup } from 'modules/modular/FieldGroup'
import { Input } from 'modules/modular/Input'
import { Modal } from 'modules/modular/Modal'
import { PickList } from 'modules/modular/PickList'
import { Select } from 'modules/modular/Select'
import { classNames } from 'modules/modular/utils'
import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import ContabilidadServices from 'services/contabilidad.service'
import { verifyAccess } from 'utils/verifyAccess'

const initialUsuario = {
    email: '',
    nombres: '',
    apellidos: '',
    password: '',
    confirmar: '',
    activo: true,
    info: '',
    actividad: '',
    actividad_id: '',
    administrador_da: '',
    beneficiario_fondo_rotatorio: '',
    beneficiario_fondo_avance: '',
    send_account: true,
}
const initialFondoAvance = {
    monto_asignado: '0.00',
    fuente_fondo: 'PROPIOS',
}
const pwdRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[\W_]).+$/
const filterOptions = { contain: ['username', 'email', 'apellidos', 'nombres'], bool: ['activo'] }

export default function ListUsuario() {
    const { showHttpToast, showToast } = useHttpToast()
    const { filters, handlePage, handleSort, handleFilter } = useFilters({ filterOptions })
    const { user } = useSelector((state) => state.auth)
    const [show, setShow] = useState({ form: false, assign: false })
    const [usuarios, setUsuarios] = useState([])
    const [pagination, setPagination] = useState(initialPagination)
    const [selected, setSelected] = useState(initialUsuario)
    const [fondoAvance, setFondoAvance] = useState(initialFondoAvance)
    const [errors, setErrors] = useState({})
    const [visiblePwd, setVisiblePwd] = useState({ password: false, confirmar: false, rotatorio: false, avance: false })
    const [submitting, setSubmitting] = useState(false)
    const [actividades, setActividades] = useState([])

    useEffect(() => {
        load()
    }, [filters])
    useEffect(() => {
        if (user.da_codigo) {
            searchActividades(user.da_codigo)
        }
    }, [user])

    const load = async () => {
        const params = objectToQueryString(filters)
        const { status, data } = await ContabilidadServices.usuario.list(params)
        if (status === 200) {
            setUsuarios(data.data)
            setPagination(data.pagination)
        }
    }
    const reloadList = () => load()

    const openModal = (key) => setShow({ ...show, [key]: true })
    const closeModal = (key) => setShow({ ...show, [key]: false })
    const onCloseModal = () => {
        setSelected(initialUsuario)
        setErrors({})
    }

    const toggleVisiblePwd = (key) => setVisiblePwd({ ...visiblePwd, [key]: !visiblePwd[key] })

    const validateUsuario = (scenario) => {
        const _errors = {}
        if (scenario === 'create') {
            if (!selected.email) _errors.email = 'Requerido'
            if (!selected.nombres) _errors.nombres = 'Requerido'
            if (!selected.apellidos) _errors.apellidos = 'Requerido'
            if (!selected.username) _errors.username = 'Requerido'
            if (!selected.actividad?.id) _errors.actividad = 'Requerido'
            if (!selected.password) _errors.password = 'Requerido'
            if (!selected.confirmar) _errors.confirmar = 'Requerido'
            if (selected.password && selected.confirmar) {
                if (selected.password === selected.confirmar) {
                    if (!pwdRegex.test(selected.password)) {
                        _errors.password = 'Formato incorrecto'
                    }
                } else {
                    _errors.confirmar = 'Las contraseñas deben coincidir'
                }
            }
        } else if (scenario === 'update') {
            if (!selected.username) _errors.username = 'Requerido'
            if (!selected.actividad?.id) _errors.actividad = 'Requerido'
        } else if (scenario === 'rotatorio') {
            if (!selected.fuente_fondo) _errors.fuente_fondo = 'Requerido'
            if (selected.monto_asignado) {
                if (!(selected.monto_asignado > 0)) _errors.monto_asignado = 'El monto debe ser mayor a cero'
            } else {
                _errors.monto_asignado = 'Requerido'
            }
        }
        setErrors(_errors)
        return Object.keys(_errors).length === 0
    }
    const validateFondoAvance = () => {
        const _errors = {}
        if (!fondoAvance.fuente_fondo) _errors.fuente_fondo = 'Requerido'
        if (fondoAvance.monto_asignado) {
            if (!(fondoAvance.monto_asignado > 0)) _errors.monto_asignado = 'El monto debe ser mayor a cero'
        } else {
            _errors.monto_asignado = 'Requerido'
        }
        setErrors(_errors)
        return Object.keys(_errors).length === 0
    }
    const handleAction = (action, rowData = null) => {
        if (action === 'create') {
            setSelected(initialUsuario)
            openModal('form')
        } else if (action === 'update') {
            const roles = {
                administrador_da: rowData.roles.includes('AdministradorDA'),
                beneficiario_fondo_rotatorio: rowData.roles.includes('BeneficiarioFondoRotatorio'),
                beneficiario_fondo_avance: rowData.roles.includes('BeneficiarioFondoAvance'),
            }
            setSelected({
                ...rowData,
                actividad: rowData.codigo_actividad ?? '',
                ...roles,
            })
            openModal('form')
        } else if (action === 'assign') {
            setSelected(rowData)
            openModal('assign')
        } else if (action === 'rotatorio') {
            setSelected({ ...rowData, fuente_fondo: rowData.fuente_fondo ?? 'PROPIOS' })
            openModal(action)
        } else if (action === 'avance') {
            loadFondoAvance(rowData)
        }
    }
    const handleChangeUsuario = (e) => {
        const name = e.target.name
        setSelected({ ...selected, [name]: e.target.value })
        errors[name] && setErrors({ ...errors, [name]: '' })
    }
    const handleChangeFondoAvance = (e) => {
        const name = e.target.name
        setFondoAvance({ ...fondoAvance, [name]: e.target.value })
        errors[name] && setErrors({ ...errors, [name]: '' })
    }
    const updateFondoRotatorio = async () => {
        setSubmitting(true)
        if (selected.id) {
            if (validateUsuario('rotatorio')) {
                const bodyParams = {
                    fuente_fondo: selected.fuente_fondo,
                    monto_asignado: selected.monto_asignado,
                }
                const { status, data } = await ContabilidadServices.usuario.update(selected.id, bodyParams)
                showHttpToast(status, { detail: data.message })
                if (status === 200) {
                    reloadList()
                    closeModal('rotatorio')
                }
            }
        }
        setSubmitting(false)
    }
    const updateFondoAvance = async () => {
        setSubmitting(true)
        if (selected.id) {
            if (validateFondoAvance()) {
                const bodyParams = {
                    ...fondoAvance,
                    beneficiario_id: selected.id,
                    actividad_id: selected.actividad_id,
                }
                const { status, data } = await ContabilidadServices.fondoAvance.update(bodyParams)
                showHttpToast(status, { detail: data.message })
                if (status === 200) {
                    closeModal('avance')
                }
            }
        }
        setSubmitting(false)
    }
    const handleSubmit = async () => {
        setSubmitting(true)
        const userRoles = []
        if (selected.administrador_da) userRoles.push('AdministradorDA')
        if (selected.beneficiario_fondo_rotatorio) userRoles.push('BeneficiarioFondoRotatorio')
        if (selected.beneficiario_fondo_avance) userRoles.push('BeneficiarioFondoAvance')

        const userData = {
            username: selected.username,
            actividad_id:
                selected.actividad?.id ??
                (typeof selected.actividad === 'string' && selected.actividad.trim() === '' ? null : selected.actividad_id),
            roles: userRoles,
            activo: selected.activo,
        }
        if (selected.id) {
            if (validateUsuario('update')) {
                const bodyParams = userData
                const { status, data } = await ContabilidadServices.usuario.update(selected.id, bodyParams)
                showHttpToast(status, { detail: data.message })
                if (status === 200) {
                    closeModal('form')
                    reloadList()
                }
            }
        } else {
            if (validateUsuario('create')) {
                const bodyParams = {
                    ...userData,
                    password: selected.password,
                    info: selected.info,
                    send_account: selected.send_account,
                }
                const { status, data } = await ContabilidadServices.usuario.create(bodyParams)
                showHttpToast(status, { detail: data.message })
                if (status === 201) {
                    if (!data.data.successAssignRoles) {
                        showHttpToast(400, {
                            detail: 'Algunos roles no se asignaron correctamente. Por favor, revisa los roles.',
                            sticky: true,
                        })
                    }
                    if (!data.data.successSendAccount) {
                        showHttpToast(500, {
                            detail: 'No se pudo enviar el correo con los datos de la cuenta.',
                            sticky: true,
                        })
                    }
                    reloadList()
                    closeModal('form')
                } else if (status === 422) {
                    const _errors = Object.keys(data.errors).reduce((carry, field) => {
                        carry[field] = data.errors[field][0]
                        return carry
                    }, {})
                    setErrors(_errors)
                }
            }
        }
        setSubmitting(false)
    }

    const searchFuncionario = async () => {
        if (selected.email) {
            const { status, data } = await ContabilidadServices.usuario.buscarFuncionario(selected.email)
            if (status === 200) {
                setSelected({ ...selected, ...data.data })
                setErrors({
                    ...errors,
                    nombres: '',
                    apellidos: '',
                })
            } else if (status === 404) {
                showToast({ title: 'Información', detail: data.message })
            } else {
                showHttpToast(status, { detail: data.message })
            }
        }
    }
    const loadFondoAvance = async (usuario) => {
        const { status, data } = await ContabilidadServices.fondoAvance.byUsuario(usuario.id)
        if (status === 200) {
            setSelected(usuario)
            setFondoAvance(data.data)
            openModal('avance')
        } else {
            showHttpToast(status, { detail: data.message })
        }
    }

    /**
     * Busca las actividades de la estructura programática que coinciden con el código pasado como parametro.
     * @param {string} codigo Código de la actividad con el siguiente formato:
     * - 01-02-000-000-10 -> DAF, 2 dígitos DA + 2 dígitos UE + 3 dígitos Programa + 3 dígitos Proyecto + 2 dígitos Actividad
     */
    const searchActividades = async (codigo) => {
        if (codigo.length > 0) {
            if (codigo.length < 17) {
                const { status, data } = await ContabilidadServices.searchActividades(codigo)
                if (status === 200) {
                    setActividades(data)
                }
            }
        } else {
            setActividades([])
        }
    }
    const actividadOptionTemplate = (actividad) => (
        <span title={actividad.descripcion}>
            {`${actividad.codigo_actividad} / ${actividad.abreviacion_da} -  ${actividad.descripcion}`}
        </span>
    )
    const optionsTemplate = (rowData) => (
        <div className='text-center text-nowrap'>
            <Button onClick={() => handleAction('update', rowData)} size='sm' className='me-2' title='Editar'>
                <span className='bi-pencil' />
            </Button>
            {/* <Button onClick={() => handleAction('assign', rowData)} size='sm' className='me-2' title='Editar roles'>
                <span className='bi-person-gear' />
            </Button> */}
            <Button
                onClick={() => handleAction('rotatorio', rowData)}
                size='sm'
                variant='success'
                className='me-2'
                disabled={!rowData.actividad_id || !rowData.roles.includes('BeneficiarioFondoRotatorio')}
                title='Fondo Rotatorio'
            >
                F. Rotatorio
            </Button>
            <Button
                onClick={() => handleAction('avance', rowData)}
                variant='success'
                size='sm'
                disabled={!rowData.actividad_id || !rowData.roles.includes('BeneficiarioFondoAvance')}
                title='Fondo en Avance'
            >
                F. Avance
            </Button>
        </div>
    )
    const activoTemplate = ({ value, filterCallback }) => (
        <div className='d-flex justify-content-center'>
            <Checkbox
                value={value}
                onChange={(e) => filterCallback(e.target.value)}
                style={{ fontSize: '1.5rem' }}
                triState
            />
        </div>
    )
    const columns = [
        { field: 'username', header: 'Usuario', alignHeader: 'center', sortable: true, filter: true },
        { field: 'email', header: 'Correo', alignHeader: 'center', sortable: true, filter: true },
        { field: 'apellidos', header: 'Apellidos', alignHeader: 'center', sortable: true, filter: true },
        { field: 'nombres', header: 'Nombres', alignHeader: 'center', sortable: true, filter: true },
        {
            field: 'actividad',
            header: 'Actividad',
            alignHeader: 'center',
            default: 'NO ASIGNADO',
        },
        {
            field: 'activo',
            header: 'Activo',
            alignHeader: 'center',
            body: (rowData) => (rowData.activo ? 'Si' : 'No'),
            bodyClassName: 'text-center',
            sortable: true,
            filter: true,
            filterElement: activoTemplate,
        },
        { field: 'id', header: 'Opciones', alignHeader: 'center', body: optionsTemplate },
    ]

    return (
        <>
            <Header title='LISTA DE USUARIOS' />
            <div className='p-3 w-100 h-100'>
                <div className='p-4 bg-body rounded h-100'>
                    <div className='text-end px-3 py-2 bg-body-tertiary'>
                        <Button startIcon={<span className='bi-plus-lg' />} onClick={() => handleAction('create')}>
                            Nuevo Usuario
                        </Button>
                    </div>
                    <DataTable
                        columns={columns}
                        values={usuarios}
                        responsive
                        lazy
                        onSort={handleSort}
                        filterable
                        onFilter={handleFilter}
                        pagination
                        paginationDetail
                        currentPage={pagination.currentPage}
                        totalItems={pagination.totalRecords}
                        pageSize={pagination.pageSize}
                        onPage={handlePage}
                    />
                </div>
            </div>
            <Modal
                show={show.form}
                onHide={() => closeModal('form')}
                onExited={onCloseModal}
                style={{ width: '45rem' }}
                closeOnBackdrop={false}
                keyboard={false}
            >
                <div className='px-3 py-2 border-bottom fs-4 fw-medium'>{selected.id ? 'Editar' : 'Crear'} Usuario</div>
                <div className='p-3'>
                    <FieldGroup label='Correo Institucional' feedback={errors.email} showFeedback={!!errors.email}>
                        <div className='d-flex gap-3'>
                            <Input
                                name='email'
                                value={selected.email}
                                onChange={handleChangeUsuario}
                                disabled={!!selected.id}
                                isInvalid={errors.email}
                                onKeyUp={(e) => e.key === 'Enter' && searchFuncionario()}
                            />
                            {!selected.id && (
                                <Button
                                    onClick={searchFuncionario}
                                    className='py-0 px-1 lh-1'
                                    style={{ fontSize: '1.25rem' }}
                                >
                                    <span className='bi-person-fill' />
                                    <span className='bi-list' />
                                </Button>
                            )}
                        </div>
                    </FieldGroup>
                    <div className='row'>
                        <FieldGroup
                            label='Nombres'
                            className='col-sm-6'
                            feedback={errors.nombres}
                            showFeedback={!!errors.nombres}
                        >
                            <div
                                className={classNames('form-control', errors.nombres && 'is-invalid')}
                                style={{ backgroundColor: '#e9ecef' }}
                            >
                                {selected.nombres || <span className='text-transparent'>_</span>}
                            </div>
                        </FieldGroup>
                        <FieldGroup
                            label='Apellidos'
                            className='col-sm-6'
                            feedback={errors.apellidos}
                            showFeedback={!!errors.apellidos}
                        >
                            <div
                                className={classNames('form-control', errors.nombres && 'is-invalid')}
                                style={{ backgroundColor: '#e9ecef' }}
                            >
                                {selected.apellidos || <span className='text-transparent'>_</span>}
                            </div>
                        </FieldGroup>
                    </div>
                    <div className='row'>
                        <FieldGroup
                            label='Usuario'
                            className='col-sm-6'
                            feedback={errors.username}
                            showFeedback={!!errors.username}
                        >
                            <Input
                                name='username'
                                value={selected.username}
                                onChange={handleChangeUsuario}
                                isInvalid={errors.username}
                            />
                        </FieldGroup>
                        <FieldGroup
                            label='Actividad'
                            className='col-sm-6'
                            feedback={errors.actividad}
                            showFeedback={!!errors.actividad}
                        >
                            <EditableSelect
                                options={actividades}
                                optionValue='codigo_actividad'
                                optionTemplate={actividadOptionTemplate}
                                valueAsOption
                                name='actividad'
                                value={selected.actividad}
                                onChange={handleChangeUsuario}
                                lazy
                                onSearch={user.da_codigo ? undefined : searchActividades}
                                autoSelect
                                isInvalid={!!errors.actividad}
                                placeholder='Buscar'
                                containerClassName='w-100'
                                menuStyle={{ minWidth: '100%' }}
                            />
                        </FieldGroup>
                    </div>
                    <div className='row g-2 mb-3'>
                        {verifyAccess('AdministradorContabilidad') && (
                            <>
                                <label className='col-sm-6 d-flex align-items-center'>
                                    <Checkbox
                                        name='activo'
                                        value={selected.activo}
                                        onChange={handleChangeUsuario}
                                        style={{ fontSize: '1.25rem', cursor: 'pointer' }}
                                        className='me-2'
                                    />
                                    Activo
                                </label>
                                <label className='col-sm-6 d-flex align-items-center'>
                                    <Checkbox
                                        name='administrador_da'
                                        value={selected.administrador_da}
                                        onChange={handleChangeUsuario}
                                        style={{ fontSize: '1.25rem', cursor: 'pointer' }}
                                        className='me-2'
                                    />
                                    Administrador de Unidad/Facultad
                                </label>
                            </>
                        )}
                        <label className='col-sm-6 d-flex align-items-center'>
                            <Checkbox
                                name='beneficiario_fondo_rotatorio'
                                value={selected.beneficiario_fondo_rotatorio}
                                onChange={handleChangeUsuario}
                                style={{ fontSize: '1.25rem', cursor: 'pointer' }}
                                className='me-2'
                            />
                            Beneficiario de Fondo Rotatorio
                        </label>
                        <label className='col-sm-6 d-flex align-items-center'>
                            <Checkbox
                                name='beneficiario_fondo_avance'
                                value={selected.beneficiario_fondo_avance}
                                onChange={handleChangeUsuario}
                                style={{ fontSize: '1.25rem', cursor: 'pointer' }}
                                className='me-2'
                            />
                            Beneficiario de Fondo en Avance
                        </label>
                    </div>
                    {!selected.id && (
                        <>
                            <div className='row'>
                                <FieldGroup
                                    label='Contraseña'
                                    className='col-sm-6'
                                    feedback={errors.password}
                                    showFeedback={!!errors.password}
                                >
                                    <div className='position-relative'>
                                        <Input
                                            name='password'
                                            type={visiblePwd.password ? 'text' : 'password'}
                                            value={selected.password}
                                            onChange={handleChangeUsuario}
                                            isInvalid={errors.password}
                                            className='bg-img-none'
                                            style={{ paddingRight: '2.5rem' }}
                                        />
                                        <div
                                            className='position-absolute cursor-pointer'
                                            style={{
                                                fontSize: '1.25rem',
                                                lineHeight: '1.5rem',
                                                right: '.75rem',
                                                top: '.375rem',
                                            }}
                                            onClick={() => toggleVisiblePwd('password')}
                                        >
                                            <span className={visiblePwd.password ? 'bi-eye-slash' : 'bi-eye'} />
                                        </div>
                                    </div>
                                </FieldGroup>
                                <FieldGroup
                                    label='Confirmar Contraseña'
                                    className='col-sm-6'
                                    feedback={errors.confirmar}
                                    showFeedback={!!errors.confirmar}
                                >
                                    <div className='position-relative'>
                                        <Input
                                            name='confirmar'
                                            type={visiblePwd.confirmar ? 'text' : 'password'}
                                            value={selected.confirmar}
                                            onChange={handleChangeUsuario}
                                            isInvalid={errors.confirmar}
                                            className='bg-img-none'
                                            style={{ paddingRight: '2.5rem' }}
                                        />
                                        <div
                                            className='position-absolute cursor-pointer'
                                            style={{
                                                fontSize: '1.25rem',
                                                lineHeight: '1.5rem',
                                                right: '.75rem',
                                                top: '.375rem',
                                            }}
                                            onClick={() => toggleVisiblePwd('confirmar')}
                                        >
                                            <span className={visiblePwd.confirmar ? 'bi-eye-slash' : 'bi-eye'} />
                                        </div>
                                    </div>
                                </FieldGroup>
                            </div>
                            <div className='text-primary fst-italic mb-2'>
                                La contraseña debe contener al menos una mayúscula, una minúscula, un número y un
                                carácter especial.
                            </div>
                            <div className='d-flex align-items-center'>
                                <Checkbox
                                    name='send_account'
                                    value={selected.send_account}
                                    onChange={handleChangeUsuario}
                                    style={{ fontSize: '1.25rem', cursor: 'pointer' }}
                                    className='me-2'
                                />
                                <span className='fw-medium'>Enviar datos de la cuenta de usuario por correo.</span>
                            </div>
                        </>
                    )}
                </div>
                <div className='px-3 py-2 border-top text-end'>
                    <Button
                        variant='outline-primary'
                        className='me-2'
                        onClick={() => closeModal('form')}
                        loading={submitting}
                    >
                        Cancelar
                    </Button>
                    <Button onClick={handleSubmit} loading={submitting}>
                        Guardar
                    </Button>
                </div>
            </Modal>
            <Modal
                show={show.rotatorio}
                onHide={() => closeModal('rotatorio')}
                onExited={onCloseModal}
                style={{ width: '25rem' }}
                closeOnBackdrop={false}
                keyboard={false}
            >
                <div className='px-3 py-2 border-bottom fs-4 fw-medium'>Fondo Rotatorio</div>
                <div className='p-3'>
                    <div className='mb-3'>
                        <span className='fw-medium me-2'>Usuario:</span>
                        {selected.email}
                    </div>
                    <FieldGroup label='Fondos' feedback={errors.fuente_fondo} showFeedback={!!errors.fuente_fondo}>
                        <Select
                            options={fuenteFondoOptions}
                            name='fuente_fondo'
                            value={selected.fuente_fondo}
                            onChange={handleChangeUsuario}
                            isInvalid={!!errors.fuente_fondo}
                            placeholder={null}
                        />
                    </FieldGroup>
                    <FieldGroup
                        label='Monto Asignado'
                        feedback={errors.monto_asignado}
                        showFeedback={!!errors.monto_asignado}
                    >
                        <Input
                            name='monto_asignado'
                            type='currency'
                            value={selected.monto_asignado}
                            onChange={handleChangeUsuario}
                            isInvalid={errors.monto_asignado}
                        />
                    </FieldGroup>
                </div>
                <div className='px-3 py-2 border-top text-end'>
                    <Button
                        variant='outline-primary'
                        className='me-2'
                        onClick={() => closeModal('rotatorio')}
                        loading={submitting}
                    >
                        Cancelar
                    </Button>
                    <Button onClick={updateFondoRotatorio} loading={submitting}>
                        Guardar
                    </Button>
                </div>
            </Modal>
            <Modal
                show={show.avance}
                onHide={() => closeModal('avance')}
                onExited={onCloseModal}
                style={{ width: '25rem' }}
                closeOnBackdrop={false}
                keyboard={false}
            >
                <div className='px-3 py-2 border-bottom fs-4 fw-medium'>Fondo en Avance</div>
                <div className='p-3'>
                    <div className='mb-3'>
                        <span className='fw-medium me-2'>Usuario:</span>
                        {selected.email}
                        <br />
                        <span className='fw-medium me-2'>Fondo en Avance asignado:</span>
                        {fondoAvance.id ? 'Si' : 'No'}
                    </div>
                    <FieldGroup label='Fondos' feedback={errors.fuente_fondo} showFeedback={!!errors.fuente_fondo}>
                        <Select
                            options={fuenteFondoOptions}
                            name='fuente_fondo'
                            value={fondoAvance.fuente_fondo}
                            onChange={handleChangeFondoAvance}
                            isInvalid={!!errors.fuente_fondo}
                            placeholder={null}
                        />
                    </FieldGroup>
                    <FieldGroup
                        label='Monto Asignado'
                        feedback={errors.monto_asignado}
                        showFeedback={!!errors.monto_asignado}
                    >
                        <Input
                            name='monto_asignado'
                            type='currency'
                            value={fondoAvance.monto_asignado}
                            onChange={handleChangeFondoAvance}
                            isInvalid={errors.monto_asignado}
                        />
                    </FieldGroup>
                </div>
                <div className='px-3 py-2 border-top text-end'>
                    <Button
                        variant='outline-primary'
                        className='me-2'
                        onClick={() => closeModal('avance')}
                        loading={submitting}
                    >
                        Cancelar
                    </Button>
                    <Button onClick={updateFondoAvance} loading={submitting}>
                        {fondoAvance.id ? 'Guardar' : 'Asignar'}
                    </Button>
                </div>
            </Modal>
            <ModalAssignRoles
                userId={selected.id}
                show={show.assign}
                onHide={() => closeModal('assign')}
                onCloseModal={onCloseModal}
                onSuccess={reloadList}
            />
        </>
    )
}

function ModalAssignRoles({ userId, show, onHide, onCloseModal, onSuccess }) {
    const { showHttpToast } = useHttpToast()
    const [submitting, setSubmitting] = useState(false)
    const [source, setSource] = useState([])
    const [target, setTarget] = useState([])

    useEffect(() => {
        const load = async () => {
            const { status, data } = await ContabilidadServices.role.getToAssign(userId)
            if (status === 200) {
                setSource(data.data.availableRoles)
                setTarget(data.data.userRoles)
            } else {
                showHttpToast(status, { detail: data.message })
            }
        }
        if (show && userId) {
            load()
        }
    }, [userId])

    const clearData = () => {
        source.length > 0 && setSource([])
        target.length > 0 && setTarget([])
    }
    const handleCloseModal = () => {
        clearData()
        onCloseModal?.()
    }

    const handleChange = (values) => {
        setSource(values.source)
        setTarget(values.target)
    }
    const handleSubmit = async () => {
        if (userId) {
            setSubmitting(true)
            const bodyParams = { roles: target.map((el) => el.name) }
            const { status, data } = await ContabilidadServices.role.batchUpdate(userId, bodyParams)
            showHttpToast(status, { detail: data.message })
            if (status === 200) {
                onHide()
                onSuccess?.()
            }
            setSubmitting(false)
        }
    }

    return (
        <Modal
            show={show}
            onHide={onHide}
            onExited={handleCloseModal}
            style={{ width: '50rem' }}
            closeOnBackdrop={false}
            keyboard={false}
        >
            <div className='px-3 py-2 border-bottom fs-4 fw-medium'>Editar roles</div>
            <div className='p-3'>
                <PickList
                    sourceHeader='Roles disponibles'
                    source={source}
                    targetHeader='Roles asignados'
                    target={target}
                    optionLabel='name'
                    style={{ minHeight: '15rem' }}
                    onChange={handleChange}
                />
            </div>
            <div className='px-3 py-2 border-top text-end'>
                <Button variant='outline-primary' className='me-2' onClick={onHide} loading={submitting}>
                    Cancelar
                </Button>
                <Button onClick={handleSubmit} loading={submitting}>
                    Guardar
                </Button>
            </div>
        </Modal>
    )
}
