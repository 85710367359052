import { SET_MESSAGE, CLEAR_MESSAGE } from "../actions/types";

const initialState = {};

/**
 * Este reductor actualiza message del estado cuando la acción del mensaje se envía 
 * desde cualquier lugar de la aplicación.
 */

export default function (state = initialState, action) {
    const { type, payload } = action;
    switch (type) {
        case SET_MESSAGE:
            return { message: payload };
        case CLEAR_MESSAGE:
            return { message: "" };
        default:
            return state;
    }
}